import styles from "./index.module.css";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";

function IframePopupModal({ isOpen, onClose, iframe }) {
  const ajax = useAjax();
  const alert = useAlert();
  const user = useUser();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
    }
  }, [isOpen, ajax, user, iframe]);

  async function handleCancelPackageClicked() {
    setLoading(true);

    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      size="md"
      toggle={() => onClose(false)}
    >
      {/* <ModalHeader toggle={() => onClose(false)}>
        <Message id="3ds" />
      </ModalHeader> */}
      <ModalBody className={styles.bodyC}>
        <iframe
          className={styles.iframe}
          width={400}
          height={600}
          id="iframe"
          src={iframe}
        />
      </ModalBody>
    </Modal>
  );
}

IframePopupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  iframe: PropTypes.string,
};

export default IframePopupModal;
