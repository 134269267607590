import React from "react";
import PropTypes from "prop-types";
import LogoSvg from "../svgs/logo.svg?react";
import styles from "./index.module.css";
import { useState } from "react";
import { useLogout } from "../../utils/hooks";
import Message from "../message";

function LoginLayout({ title, description, children, logoutBtn = false }) {
  const logout = useLogout();

  const [loggingOut, setLoggingOut] = useState(false);

  async function handleLogoutClicked() {
    setLoggingOut(true);

    logout();
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div>
            <a
              href="#"
              className={`${styles.logout} ${loggingOut ? "disabled" : ""}`}
              onClick={handleLogoutClicked}
            >
              <LogoSvg className="logo" />
            </a>
          </div>

          <div className={styles.flexH}>
            {logoutBtn && (
              <a
                href="#"
                className={`${styles.logout} ${loggingOut ? "disabled" : ""}`}
                onClick={handleLogoutClicked}
              >
                <Message id="general.logout" />
              </a>
            )}
          </div>
        </div>
        <div className={styles.loginWrapper}>
          <div className={styles.title}>
            <h1>{title}</h1>
          </div>
          {description ? (
            <div
              className={`${styles.description} ${
                description ? styles.empty : ""
              }`}
            >
              {description}
            </div>
          ) : (
            ""
          )}

          {children}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

LoginLayout.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  children: PropTypes.any,
  logoutBtn: PropTypes.bool,
};

export default LoginLayout;
