import React, { useState, useEffect } from "react";
import Permissions1 from "../../../components/svgs/permissions1.svg?react";
import Permissions2 from "../../../components/svgs/permissions2.svg?react";
import Permissions3 from "../../../components/svgs/permissions3.svg?react";
import { useParams } from "react-router-dom";
import PermissionsStep1 from "../../../components/permissions/step1";
import PermissionsStep2 from "../../../components/permissions/step2";
import PermissionsStep3 from "../../../components/permissions/step3";
import { useAjax } from "../../../utils/hooks";
import Message from "../../../components/message";
import LayoutMainAction from "../../../components/layout-main-action";
import Wizard from "../../../components/wizard";
import Spinner from "../../../components/spinner";

export default function AccountPermissionsNewRole() {
  const ajax = useAjax();
  const { editID } = useParams();

  const [editUser, setEditUser] = useState(null);
  const [emailsArray, setEmailsArray] = useState([]);
  const [permission, setPermission] = useState("sites");

  const [step, setStep] = useState(1);

  useEffect(() => {
    async function getUserInfo() {
      const data = await ajax("/users/userInfo", { userID: editID });

      if (data.result === "success") {
        setEditUser(data.user);
      }
    }

    if (editID) {
      getUserInfo();
      const urlSearchParams = new URLSearchParams(location.search);

      if (urlSearchParams.get("permission")) {
        setPermission(urlSearchParams.get("permission"));
      }
    }
  }, [ajax, editID]);

  const steps = [
    {
      title: <Message id="new-role.step1-title" />,
      icon: Permissions1,
      component: (
        <PermissionsStep1 setStep={setStep} setEmailsArray={setEmailsArray} />
      ),
    },
    {
      title: <Message id="new-role.step2-title" />,
      icon: Permissions2,
      component: (
        <PermissionsStep2
          setStep={setStep}
          step={step}
          permission={permission}
          setPermission={setPermission}
          user={editUser}
        />
      ),
    },
    {
      title: <Message id="new-role.step3-title" />,
      icon: Permissions3,
      component: (
        <PermissionsStep3
          setStep={setStep}
          step={step}
          emails={emailsArray}
          permission={permission}
          user={editUser}
        />
      ),
    },
  ];

  if (editID) {
    if (editUser) {
      return (
        <LayoutMainAction>
          <Wizard steps={steps.slice(1)} currentStep={step} />
        </LayoutMainAction>
      );
    }

    return (
      <LayoutMainAction>
        <Spinner />
      </LayoutMainAction>
    );
  }

  return (
    <LayoutMainAction>
      <Wizard steps={steps} currentStep={step} />
    </LayoutMainAction>
  );
}
