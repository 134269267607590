import styles from "./index.module.css";

import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import AddNewClientModal from "../../components/modals/add-new-client";
import { WithRole } from "../../components/with-role";
import { Link, useNavigate } from "react-router-dom";
import { useAjax, useMessage, useUser } from "../../utils/hooks";
import Message from "../../components/message";
import LayoutMainAction from "../../components/layout-main-action";
import Pagination from "../../components/pagination";
import Box from "../../components/box";
import ClickTable from "../../components/click-table";

const maxRowsInPage = 50;

export default function ClientsList() {
  const message = useMessage();
  const ajax = useAjax();
  const user = useUser();
  const navigate = useNavigate();

  const [users, setUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [filter, setFilter] = useState("");
  const [showOnly, setShowOnly] = useState("active-users");
  const [isShowOnlyDropdownOpen, setIsShowOnlyDropdownOpen] = useState(false);
  const [isAddNewClientModalOpen, setIsAddNewClientModalOpen] = useState(false);
  const [page, setPage] = useState(0);

  const [sort, setSort] = useState({ name: "created_at", asc: false });

  const buttons = [
    {
      title: <Message id="clients-list.add-new" />,
      onClick: () => setIsAddNewClientModalOpen(true),
    },
  ];

  const getUsers = useCallback(async () => {
    const data = await ajax("/admin/getUsers");

    setUsers(data.users);
  }, [ajax]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (!users) {
      return;
    }

    setFilteredUsers(
      users
        .filter(
          (usr) =>
            (showOnly === "active-users" ||
              (showOnly === "need-to-fill-details" && usr.registerStep === 1) ||
              (showOnly === "need-to-verify-phone" && usr.registerStep === 2) ||
              (showOnly === "need-to-add-credit-card" &&
                usr.registerStep === 3)) &&
            (!filter ||
              usr.email.toLowerCase().includes(filter.toLowerCase()) ||
              usr.clientNumber.toString().includes(filter) ||
              (usr.firstName &&
                usr.firstName.toLowerCase().includes(filter.toLowerCase())) ||
              (usr.lastName &&
                usr.lastName.toLowerCase().includes(filter.toLowerCase())) ||
              (usr.firstName &&
                usr.lastName &&
                `${usr.firstName} ${usr.lastName}`
                  .toLowerCase()
                  .includes(filter.toLowerCase())) ||
              (usr.companyName &&
                usr.companyName.toLowerCase().includes(filter.toLowerCase())))
        )
        .sort((a, b) => {
          const first = sort.asc ? a : b;
          const second = sort.asc ? b : a;

          if (
            ["email", "firstName", "lastName", "companyName"].includes(
              sort.name
            )
          ) {
            return (first[sort.name] || "").localeCompare(
              second[sort.name] || ""
            );
          }

          if (["created_at"].includes(sort.name)) {
            return new Date(first[sort.name]) - new Date(second[sort.name]);
          }

          return first[sort.name] - second[sort.name];
        })
    );
  }, [filter, showOnly, users, user, sort]);

  function handleShowOnlyDropdownToggle() {
    setIsShowOnlyDropdownOpen(!isShowOnlyDropdownOpen);
  }

  function handleAddNewClientModalClosed(state) {
    if (state) {
      return navigate(`/clients/clients-list/${state._id}`);
    }

    setIsAddNewClientModalOpen(false);
  }

  function handleThClicked(name) {
    setSort({ name, asc: sort.name !== name || !sort.asc });
  }

  return (
    <WithRole permission="admin.clients.add">
      <LayoutMainAction
        title={<Message id="clients-list.title" />}
        buttons={buttons}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div>
              <Message id="clients-list.show-only" />
              <ButtonDropdown
                className={styles.ghost}
                isOpen={isShowOnlyDropdownOpen}
                toggle={handleShowOnlyDropdownToggle}
              >
                <DropdownToggle caret>
                  <Message id={`clients-list.${showOnly}`} />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => setShowOnly("active-users")}>
                    <Message id="clients-list.active-users" />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setShowOnly("need-to-fill-details")}
                  >
                    <Message id="clients-list.need-to-fill-details" />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setShowOnly("need-to-verify-phone")}
                  >
                    <Message id="clients-list.need-to-verify-phone" />
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setShowOnly("need-to-add-credit-card")}
                  >
                    <Message id="clients-list.need-to-add-credit-card" />
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>

            <div className={styles.filterInputWrapper}>
              <input
                className={styles.filterInput}
                placeholder={message({ id: "filter" })}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>

            <div>
              {users && (
                <Message
                  id="total-users-number"
                  values={{ number: users ? users.length : "-" }}
                />
              )}
            </div>

            {filteredUsers && (
              <Pagination
                page={page}
                setPage={setPage}
                totalRows={filteredUsers.length}
                maxRowsInPage={maxRowsInPage}
              />
            )}
          </div>

          <Box>
            <ClickTable asPlaceholder={!filteredUsers} className={styles.table}>
              <thead>
                <tr>
                  <th style={{ width: "40px" }}>#</th>
                  <th onClick={() => handleThClicked("email")}>
                    <Message id="clients-list.email" />
                  </th>
                  <th onClick={() => handleThClicked("clientNumber")}>
                    <Message id="clients-list.client-number" />
                  </th>
                  <th onClick={() => handleThClicked("firstName")}>
                    <Message id="clients-list.first-name" />
                  </th>
                  <th onClick={() => handleThClicked("lastName")}>
                    <Message id="clients-list.last-name" />
                  </th>
                  <th onClick={() => handleThClicked("companyName")}>
                    <Message id="clients-list.company-name" />
                  </th>
                  <th onClick={() => handleThClicked("created_at")}>
                    <Message id="clients-list.registration-date" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.length === 0 && (
                  <tr>
                    <td colSpan={8}>
                      <Message id="no-rows" />
                    </td>
                  </tr>
                )}

                {filteredUsers &&
                  filteredUsers
                    .slice(
                      page * maxRowsInPage,
                      page * maxRowsInPage + maxRowsInPage
                    )
                    .map((user, key) => (
                      <tr key={key}>
                        <td>{key + 1 + maxRowsInPage * page}</td>
                        <td>
                          <Link
                            href="/clients/clients-list/[id]"
                            to={`/clients/clients-list/${user._id}`}
                          >
                            {user.email}
                          </Link>
                        </td>
                        <td>{user.clientNumber}</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.companyName}</td>
                        <td>{format(new Date(user.created_at), "d/M/yyyy")}</td>
                      </tr>
                    ))}
              </tbody>
            </ClickTable>
          </Box>

          {filteredUsers && (
            <Pagination
              page={page}
              setPage={setPage}
              totalRows={filteredUsers.length}
              maxRowsInPage={maxRowsInPage}
            />
          )}
        </div>

        <AddNewClientModal
          isOpen={isAddNewClientModalOpen}
          onClose={handleAddNewClientModalClosed}
        />
      </LayoutMainAction>
    </WithRole>
  );
}
