import styles from "./login-sms.module.css";

import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../../components/login-layout";

export default function LoginWithSMS() {
  const ajax = useAjax();
  const navigate = useNavigate();

  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [code, setCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!window.p1 || !window.p2) {
      delete window.p1;
      delete window.p2;

      setTimeout(() => {
        navigate(`/user/login`);
      }, 1);

      return;
    }

    setEmail(window.p1);
    setPassword(window.p2);

    delete window.p1;
    delete window.p2;
  }, [navigate]);

  const sendSMS = useCallback(async () => {
    if (!email || !password) {
      return;
    }

    const data = await ajax(`/users/sendSMS`, {
      email,
      password,
    });

    if (data.result === "success") {
      setPhoneNumber(data.number);
    }
  }, [ajax, email, password]);

  useEffect(() => {
    sendSMS();
  }, [sendSMS]);

  async function handleVerifyClicked() {
    const _code = code.trim();

    setError(false);

    if (!_code) {
      return setError("general.empty-code");
    }

    setLoading(true);
    const data = await ajax(`/users/verifySMS`, {
      code,
      email,
      password,
      action: "login-2fa",
    });

    if (data.result === "success") {
      navigate(`/workspaces`);
    } else {
      setError("general.wrong-code");
      setLoading(false);
    }
  }

  return (
    <LoginLayout
      title={<Message id="login-with-sms.title" />}
      description={
        <Message
          id="login-with-sms.description"
          values={{ phoneNumber: sanitize(phoneNumber) }}
        />
      }
    >
      <div className={styles.form}>
        <KeyDetector
          className={styles.inputWrapper}
          onKeyDetected={handleVerifyClicked}
        >
          <TextField
            icon="lock"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={<Message id="login-with-sms.code-placeholder" />}
          />
        </KeyDetector>

        {error && (
          <div className={`error ${styles.error}`}>
            <Message id={error} />
          </div>
        )}

        <div className={styles.verifyWrp}>
          <DreamButton
            disabled={loading}
            loading={loading}
            onClick={handleVerifyClicked}
          >
            <Message id="general.verify" />
          </DreamButton>
        </div>

        <div className={styles.backWrapper}>
          <Link href={`/user/login`} to={`/user/login`}>
            <Message id="forgot-password.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
