import styles from "./placeholder-layout.module.css";
import LogoSvg from "../svgs/logo.svg?react";

import React from "react";

export default function PlaceholderLayout() {
  return (
    <div className={styles.wrapper}>
      <LogoSvg className="logo" />
    </div>
  );
}
