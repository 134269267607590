import styles from "./index.module.css";

import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Checkbox from "../checkbox";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Message from "../message";
import { placeholder } from "../../utils";

function ClickTable({
  className,
  bulkAction,
  asPlaceholder,
  placeholderCount = 5,
  children,
}) {
  const [selectedRows, setSelectedRows] = useState({});
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);

  const rows = useMemo(() => {
    if (children[1]) {
      return children[1].props.children;
    }

    if (children[0]) {
      return children[0].props.children;
    }

    return null;
  }, [children]);

  const isAllSelected = useMemo(() => {
    return rows && Object.keys(selectedRows).length === rows.length;
  }, [rows, selectedRows]);

  function handleCheckboxChange(key) {
    if (key === -1) {
      let selectedRows = {};

      if (!isAllSelected) {
        for (let i = 0; i < rows.length; i++) {
          selectedRows[i] = true;
        }
      }

      return setSelectedRows(selectedRows);
    }

    if (selectedRows[key]) {
      delete selectedRows[key];
    } else {
      selectedRows[key] = true;
    }

    setSelectedRows({ ...selectedRows });
  }

  function handleItemClick(item) {
    item.onClick(Object.keys(selectedRows));
  }

  function renderChildren() {
    if (asPlaceholder) {
      return (
        <>
          {children[0]}
          <tbody>
            {Array(placeholderCount)
              .fill(0)
              .map((n, key) => (
                <tr key={key}>
                  {Array(children[0].props.children.props.children.length)
                    .fill(0)
                    .map((n, key2) => (
                      <td key={`${key}_${key2}`}>
                        {key2 >= 10 ? "" : placeholder("", "20px", "0 10px")}
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </>
      );
    }

    function renderTBody() {
      if (!bulkAction) {
        if (children[1]) {
          return <tbody>{children[1].props.children}</tbody>;
        }

        if (children[0]) {
          return <tbody>{children[0].props.children}</tbody>;
        }

        return children;
      }

      return (
        <tbody>
          {rows.length === 0 && (
            <tr>
              <td colSpan={100}>
                <Message id="no-rows" />
              </td>
            </tr>
          )}

          {rows.map((elm, key) => (
            <tr key={key}>
              {bulkAction && (
                <td style={{ width: "50px" }}>
                  <Checkbox
                    checked={!!selectedRows[key]}
                    onChange={() => handleCheckboxChange(key)}
                  />
                </td>
              )}
              {elm.props && elm.props.children.map((elm2) => elm2)}
            </tr>
          ))}
        </tbody>
      );
    }

    function renderTHead() {
      if (!children[0]) {
        return null;
      }

      return (
        <thead>
          <tr>
            {bulkAction && (
              <th style={{ width: "50px" }}>
                <Checkbox
                  checked={isAllSelected}
                  onChange={() => handleCheckboxChange(-1)}
                />
              </th>
            )}
            {children[0].props.children.props.children.map((elm) => elm)}
          </tr>
        </thead>
      );
    }

    return (
      <>
        {renderTHead()}

        {renderTBody()}
      </>
    );
  }

  const totalSelectedRows = useMemo(
    () => Object.keys(selectedRows).length,
    [selectedRows]
  );

  return (
    <div className={`${styles.responsive} ${className || ""}`}>
      {bulkAction && (
        <div className={styles.header}>
          <ButtonDropdown
            className={`${styles.purple} ${
              totalSelectedRows == 0 ? "disabled" : ""
            }`}
            isOpen={isActionDropdownOpen}
            toggle={() => setIsActionDropdownOpen(!isActionDropdownOpen)}
          >
            <DropdownToggle caret>
              <Message
                id={
                  totalSelectedRows == 0
                    ? "no-selected"
                    : totalSelectedRows === 1
                    ? "one-selected"
                    : "total-selected"
                }
                values={{ count: totalSelectedRows }}
              />
            </DropdownToggle>
            <DropdownMenu right>
              {bulkAction.map((item, key) => (
                <DropdownItem key={key} onClick={() => handleItemClick(item)}>
                  {item.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      )}

      <table className={`${styles.table}`}>{renderChildren()}</table>
    </div>
  );
}

ClickTable.propTypes = {
  className: PropTypes.string,
  bulkAction: PropTypes.array,
  children: PropTypes.any,
  placeholderCount: PropTypes.number,
  asPlaceholder: PropTypes.bool,
};

export default ClickTable;
