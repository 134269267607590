import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAjax } from "../../utils/hooks";

function WithUser({ children, setUser, reload }) {
  const ajax = useAjax();
  const { id: userID } = useParams();

  const getUser = useCallback(async () => {
    const data = await ajax("/admin/getUser", { userID, a: 5 });

    if (data.result === "success") {
      setUser(data.user);
    }
  }, [ajax, setUser, userID]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (reload) {
      reload.do = () => getUser();
    }
  }, [getUser, reload]);

  return children;
}

WithUser.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  reload: PropTypes.object,
};

export default WithUser;
