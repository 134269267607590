import styles from "./item.module.css";

import React from "react";
import PropTypes from "prop-types";
import { DropdownItem } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

function CustomMenuItem({
  onClick,
  children,
  disabled = false,
  to = null,
  target = null,
  color = "default",
  divider = false,
}) {
  const navigate = useNavigate();

  function handleDropdownItemClicked(...args) {
    if (to) {
      if (target === "_blank") {
        return;
      }

      return navigate(to);
    }

    onClick(...args);
  }

  function renderDropdownItem() {
    return (
      <DropdownItem
        className={`${styles.wrapper} ${disabled ? styles.disabled : ""}`}
        onClick={handleDropdownItemClicked}
        divider={divider}
      >
        <div className={`${styles.childrenWrapper} ${styles[color]}`}>
          {children}
        </div>
      </DropdownItem>
    );
  }

  if (to) {
    if (target === "_blank") {
      return (
        <a href={to} target={target}>
          {renderDropdownItem()}
        </a>
      );
    }

    return (
      <Link to={to} target={target}>
        {renderDropdownItem()}
      </Link>
    );
  }

  return renderDropdownItem();
}

CustomMenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  target: PropTypes.string,
  color: PropTypes.string,
  divider: PropTypes.bool,
};

export default CustomMenuItem;
