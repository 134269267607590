import styles from "./checkout.module.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SslSvg from "../../components/svgs/ssl.svg?react";
import LockSvg from "../../components/svgs/lock2.svg?react";
import StripeForm from "../../components/stripe-form";
import { useUser } from "../../utils/hooks";
import Message from "../../components/message";
import CustomText from "../../components/custom-text";
import CustomReactSelect from "../../components/custom-react-select";
import { ajax } from "../../utils";
import PlanDetailsModal from "../../components/modals/plan-details";
import Checkbox from "../../components/checkbox";
import DreamButton from "../../components/dream-button";
import { useRef } from "react";

const stripePromise = loadStripe(
  import.meta.env.VITE_APP_STRIPE_API_KEY || "pk_live_nL0rSp00qKl6X0H71MIAxJPu",
  {
    locale: "en",
  }
);

const appearance = {
  theme: "stripe",
};

const options = {
  mode: "setup",
  setupFutureUsage: "off_session",
  currency: "usd",
  paymentMethodCreation: "manual",
  appearance,
};

const billingCycleOptions = [
  { label: <Message id="monthly" />, value: "monthly" },
  { label: <Message id="yearly" />, value: "yearly" },
];

export default function BillingCheckout() {
  const formRef = useRef();
  const navigate = useNavigate();
  const user = useUser();

  const [billingCycle, setBillingCycle] = useState(billingCycleOptions[0]);
  const [users, setUsers] = useState(10);

  const [prices, setPrices] = useState({});

  const [isPlanDetailsModalOpen, setIsPlanDetailsModalOpen] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function listPrices() {
      const data = await ajax("/billing/prices/list");

      setPrices({ monthly: data.monthly, yearly: data.yearly });
    }

    listPrices();
  }, []);

  useEffect(() => {
    if (user.registerStep === -1) {
      return navigate("/");
    }
  }, [navigate, user]);

  useEffect(() => {
    if (
      prices[billingCycle.value] &&
      (parseInt(users) < prices[billingCycle.value].minimumUsers ||
        parseInt(users) > prices[billingCycle.value].maximumUsers)
    ) {
      setError(
        <Message
          id="wrong-users-for-purchase"
          values={{
            min: prices[billingCycle.value].minimumUsers,
            max: prices[billingCycle.value].maximumUsers,
          }}
        />
      );
    } else {
      setError(false);
    }
  }, [users, billingCycle.value, prices]);

  function handlePlanDetailsClicked(e) {
    e.preventDefault();
    setIsPlanDetailsModalOpen(true);
  }

  function handlePlanDetailsModalClosed() {
    setIsPlanDetailsModalOpen(false);
  }

  function handleCompletePurchaseClicked() {
    formRef.current.querySelector("button").click();
  }

  return (
    <div className={styles.wrapper}>
      <div className={`userForm ${styles.form}`}>
        <div className={`${styles.formSection} ${styles.box}`}>
          <div className={styles.inner}>
            <h2 className={`${styles.title}`}>
              <Message id="checkout-title" />
            </h2>
          </div>

          <div className={styles.app}>
            <Elements options={options} stripe={stripePromise}>
              <StripeForm
                formRef={formRef}
                users={users}
                billingCycle={billingCycle.value}
                setLoading={setLoading}
              />
            </Elements>
          </div>

          <div className={styles.acceptCards}>
            <LockSvg className={styles.lockSvg} />
            <Message id="accept-following-cards" />
          </div>

          <div className={styles.purchaseSecured}>
            {/* <Message id="your-purchase-secured" /> */}
            <div className={styles.images}>
              <img src="/img/pci.jpeg" />
              <SslSvg />
            </div>
          </div>
        </div>

        <div className={`${styles.box} ${styles.summary}`}>
          <div>
            <Message id="summary" />
          </div>
          <div className="drow f100">
            <span>
              <Message id="billing-cycle" />
            </span>
            <CustomReactSelect
              options={billingCycleOptions}
              value={billingCycle}
              onChange={(item) => setBillingCycle(item)}
            />
          </div>
          <div className="drow f100">
            <span>
              <Message id="users" />
            </span>
            <CustomText
              value={users}
              onChange={(e) => setUsers(e.target.value)}
            />
          </div>
          <div>
            <a href="#" onClick={handlePlanDetailsClicked}>
              <Message id="view-plan-details" />
            </a>
          </div>
          <hr />
          {billingCycle.value === "yearly" && (
            <>
              <div className={styles.labels}>
                <span>
                  <Message id="annual-savings" />
                </span>
                $
                {(users * prices.monthly?.costPerUser -
                  users * prices.yearly?.costPerUser) *
                  12}
              </div>
              <hr />
            </>
          )}
          <div>
            <div className={`${styles.labels} ${styles.total}`}>
              <span>
                <Message id="total" />
              </span>
              $
              {users *
                prices[billingCycle.value]?.costPerUser *
                (billingCycle.value === "monthly" ? 1 : 12)}
              /{billingCycle.value === "monthly" ? "mo" : "yr"}
            </div>
            <div className={styles.labels}>
              <span>
                <Message
                  id={
                    billingCycle.value === "monthly"
                      ? "billed-monthly"
                      : "billed-annually"
                  }
                />
              </span>
              <Message id="tax-and-fees" />
            </div>
          </div>
          <div>
            <Checkbox
              label={
                <Message
                  id="i-agree-to-services"
                  values={{
                    services: (val) => `<a href="#">${val}</a>`,
                    privacy: (val) => `<a href="#">${val}</a>`,
                  }}
                />
              }
            />
          </div>
          {error && <div className="error">{error}</div>}
          <div>
            <DreamButton
              disabled={loading || !!error}
              loading={loading}
              icon="lock"
              onClick={handleCompletePurchaseClicked}
            >
              <Message id="complete-purchase" />
            </DreamButton>
          </div>
        </div>
      </div>

      <PlanDetailsModal
        isOpen={isPlanDetailsModalOpen}
        onClose={handlePlanDetailsModalClosed}
      />
    </div>
  );
}
