import styles from "./documents.module.css";

import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useAjax, useAlert } from "../../../utils/hooks";
import { useConfirm } from "../../../utils/hooks";
import { usePrompt } from "../../../utils/hooks";
import Message from "../../../components/message";
import { sanitize } from "../../../utils";
import Box from "../../../components/box";
import ClickTable from "../../../components/click-table";
import CustomMenu from "../../../components/custom-menu";
import CustomMenuItem from "../../../components/custom-menu/item";
import DreamButton from "../../../components/dream-button";

function ClientsListDocuments({ user }) {
  const ajax = useAjax();
  const alert = useAlert();
  const confirm = useConfirm();
  const prompt = usePrompt();

  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFilesToUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const fetchFiles = useCallback(async () => {
    if (!user) {
      return;
    }

    const data = await ajax(`/users/getUploadedFiles`, {
      userID: user._id,
    });

    if (data.result === "success") {
      setFiles(data.files);
    }
  }, [ajax, user]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  async function handleUploadClicked() {
    const formData = new FormData();

    formData.append("userID", user._id);

    filesToUpload.forEach((file) => {
      formData.append(file.name, file);
    });

    setLoading(true);
    await ajax(`/users/uploadFiles`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    await fetchFiles();
    setLoading(true);

    setFilesToUpload([]);

    await alert({
      title: <Message id="clients-list-documents.uploaded.title" />,
      message: <Message id="clients-list-documents.uploaded.content" />,
      notification: true,
    });
  }

  function handleDropDownToggle(file) {
    file.dropdownOpen = !file.dropdownOpen;
    setFiles([...files]);
  }

  async function handleDeleteFileMenuClicked(file) {
    const state = await confirm({
      title: <Message id="clients-list-documents.delete.title" />,
      message: (
        <Message
          id="clients-list-documents.delete.content"
          values={{ fileName: sanitize(file.name) }}
        />
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax(`/users/deleteFile`, {
      userID: user._id,
      fileName: file.name,
    });

    await fetchFiles();

    setLoading(false);
  }

  async function handleEditFileNotesMenuClicked(file) {
    const notes = await prompt({
      title: <Message id="clients-list-documents.edit-notes" />,
      message: <Message id="clients-list-documents.edit-notes-type" />,
      defaultText: file.metadatas?.notes,
      textType: "textarea",
    });

    if (notes === null) {
      return;
    }

    setLoading(true);
    await ajax(`/users/updateFileNotes`, {
      userID: user._id,
      fileName: file.name,
      notes,
    });

    await fetchFiles();
    setLoading(true);
  }

  function renderFiles() {
    if (!files.length) {
      return null;
    }

    return (
      <Box>
        <ClickTable>
          <thead>
            <tr>
              <th>
                <Message id="clients-list-documents.file-name" />
              </th>
              <th>
                <Message id="clients-list-documents.notes" />
              </th>
              <th>
                <Message id="clients-list-documents.created-at" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, idx) => (
              <tr key={idx}>
                <td>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${
                      import.meta.env.VITE_APP_SERVER_URL
                    }/users/getFile?userid=${user._id}&filename=${file.name}`}
                  >
                    {file.name}
                  </a>
                </td>
                <td>{file.metadatas?.notes}</td>
                <td>{format(new Date(file.createdAt), "d/M/y HH:mm")}</td>
                <td>
                  <CustomMenu
                    isOpen={file.dropdownOpen}
                    toggle={() => handleDropDownToggle(file)}
                  >
                    <CustomMenuItem
                      onClick={() => handleEditFileNotesMenuClicked(file)}
                    >
                      <Message id="clients-list-documents.edit-notes" />
                    </CustomMenuItem>
                    <CustomMenuItem
                      onClick={() => handleDeleteFileMenuClicked(file)}
                    >
                      <Message id="general.delete" />
                    </CustomMenuItem>
                  </CustomMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </ClickTable>
      </Box>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Box className={styles.dropzoneWrapper}>
        <div className={styles.dropperArea} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Message id="clients-list-documents.after-drag" />
          ) : (
            <Message id="clients-list-documents.before-drag" />
          )}
        </div>

        {filesToUpload.length > 0 && (
          <div>
            <div>
              <Message
                id="clients-list-documents.files-selected"
                values={{
                  count: filesToUpload.length,
                  b: (arr) => sanitize(`<b>${arr[0]}</b>`),
                }}
              />
            </div>
            {filesToUpload.map((file, idx) => (
              <div key={idx}>{file.name}</div>
            ))}
            <DreamButton
              disabled={loading}
              loading={loading}
              color="light-purple"
              onClick={handleUploadClicked}
            >
              <Message id="general.upload" />
            </DreamButton>
          </div>
        )}
      </Box>

      {renderFiles()}
    </div>
  );
}

ClientsListDocuments.propTypes = {
  user: PropTypes.object,
};

export default ClientsListDocuments;
