export const creditCardStatuses = {
  0: "עסקה תקינה",
  1: "כרטיס חסום",
  2: "כרטיס גנוב",
  3: "התקשר לחברת האשראי.",
  4: "סירוב.",
  5: "מזויף החרם כרטיס.",
  6: "ת.ז.  או CVV שגויים.",
  7: "קוד אישור אינו חוקי- נא לפנות למנהל המערכת.",
  8: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  9: "תקלת תקשורת, יש לנסות שוב או לפנות למנהל המערכת ולמסור את קוד התשובה",
  10: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  11: "אין לסולק הרשאה לבצע עסקאות מט'ח",
  12: "אין אישור לעסקת מט'ח בכרטיס זה",
  13: "אין אישור למסוף לבצע טעינת/פריקת מט'ח בכרטיס זה",
  14: "כרטיס לא רשאי לבצע עסקת טעינה/פריקה",
  15: "אין התאמה בין המספר שהוקלד לפס המגנטי",
  16: "לא הוקלדו נתונים נוספים (שדה Z )",
  17: "לא הוקלדו 4 ספרות אחרונות",
  18: "סטטוס AVS אינו מאושר",
  19: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  20: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  21: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  22: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  23: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  24: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  25: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  26: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  27: "תקלה בקריאת  הפס המגנטי. נסה לבצע כעיסקה טלפונית או חתימת לקוח",
  28: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  29: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  30: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  31: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  32: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  33: "מספר הכרטיס אינו תקין",
  34: "אין אישור לסוג עסקה או לסוג כרטיס זה במסוף זה",
  35: "כרטיס לא רשאי לבצע עסקה עם סוג אשראי זה.",
  36: "כרטיס פג תוקף.",
  37: "שגיאה בתשלומים – סכום עסקה צריך להיות שווה תשלום ראשון +(תשלום קבוע כפול מספר תשלומים).",
  38: "סכום העיסקה גדול מתקרה לכרטיס",
  39: "סיפרת בקורת לא תקינה.",
  40: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  41: "יש לבצע בדיקה בסכום אפס",
  42: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  43: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  44: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  45: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  46: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  47: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  51: "מספר רכב לא תקין.",
  52: "מד מרחק  לא הוקלד.",
  53: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  57: "חייב להקליד מספר תעודת הזהות",
  58: "חייב להקליד C.V.V.",
  59: "חייב להקליד מספר תעודת הזהות ו-C.V.V",
  60: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  61: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  62: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  63: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  64: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  65: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  66: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  67: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  68: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  69: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  70: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  71: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  72: "מספר סודי לא הוקלד.",
  73: "מספר סודי שגוי.",
  74: "מספר סודי שגוי – ניסיון אחרון.",
  75: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  76: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  77: "הוקש מספר סודי שגוי",
  79: "הגדרות המסוף אינן מאפשרות לסלוק מטבע זה",
  80: "הוכנס 'קוד מועדון' לסוג אשראי לא מתאים.",
  81: "סוג בקשה (J) אינו תקין",
  90: "לא ניתן לבטל עסקת טעינה",
  91: "לא ניתן לבטל עסקת פריקה",
  92: "לא ניתן לבטל עסקת מט'ח",
  99: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  100: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  101: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  106: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  107: "סכום העסקה גדול מידי – חלק במספר העסקאות.",
  108: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  109: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  110: "למסוף אין אישור לכרטיס חיוב מיידי.",
  111: "למסוף אין אישור לעסקה בתשלומים.",
  112: "למסוף אין אישור לעסקה טלפון/ חתימה בלבד תשלומים.",
  113: "למסוף אין אישור לעסקה טלפונית.",
  114: "למסוף אין אישור לעסקה 'חתימה בלבד'.",
  115: "למסוף אין אישור לעסקאות במטבע זר או עסקה לא מאושרת.",
  116: "למסוף אין אישור לעסקת מועדון.",
  117: "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים.",
  118: "למסוף אין אישור לאשראי ישראקרדיט.",
  119: "למסוף אין אישור לאשראי אמקס  קרדיט.",
  120: "למסוף אין אישור להצמדה לדולר.",
  121: "למסוף אין אישור להצמדה למדד.",
  122: "למסוף אין אישור להצמדה למדד לכרטיסי חו'ל.",
  123: "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים לסוג אשראי זה.",
  124: "למסוף אין אישור לאשראי ישרא 36",
  125: "למסוף איו אישור לאשראי אמקס 36.",
  126: "למסוף אין אישור לקוד מועדון זה.",
  127: "למסוף אין אישור לעסקת חיוב מיידי פרט לכרטיסי חיוב מיידי.",
  128: "למסוף אין אישור לקבל כרטיסי ויזה אשר מתחילים ב – 3.",
  129: "למסוף אין אישור לבצע עסקת זכות מעל תקרה.",
  130: "כרטיס  לא רשאי לבצע עסקת מועדון.",
  131: "כרטיס לא רשאי לבצע עסקת כוכבים/נקודות/מיילים.",
  132: "כרטיס לא רשאי לבצע עסקאות בדולרים (רגילות או טלפוניות).",
  133: "כרטיס לא תקף על פי רשימת כרטיסים תקפים של ישראכרט.",
  134: "כרטיס לא תקין עפ”י הגדרת המערכת (VECTOR1 של ישראכרט)- מספר הספרות בכרטיס- שגוי.",
  135: "כרטיס לא רשאי לבצע עסקאות דולריות עפ”י הגדרת המערכת (VECTOR1 של ישראכרט).",
  136: "הכרטיס שייך לקבוצת כרטיסים אשר אינה רשאית לבצע עסקאות עפ”י הגדרת המערכת (VECTOR20 של ויזה).",
  137: "קידומת הכרטיס (7 ספרות) לא תקפה עפ”י הגדרת המערכת (21VECTOR של דיינרס).",
  138: "כרטיס לא רשאי לבצע עסקאות בתשלומים על פי רשימת כרטיסים תקפים של ישראכרט.",
  139: "מספר תשלומים גדול מידי על פי רשימת כרטיסים תקפים של ישראכרט.",
  140: "כרטיסי ויזה ודיינרס לא רשאים לבצע עסקאות מועדון בתשלומים.",
  141: "סידרת כרטיסים לא תקפה עפ”י הגדרת המערכת. (VECTOR5 של ישראכרט).",
  142: "קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR6 של ישראכרט).",
  143: "קידומת הכרטיס (2 ספרות) לא תקפה עפ”י הגדרת המערכת. (VECTOR7  של ישראכרט).",
  144: "קוד שרות לא תקף עפ”י הגדרת המערכת. (VECTOR12 של ויזה).",
  145: "קוד שרות לא תקף עפ”י הגדרת המערכת. (VECTOR13 של ויזה).",
  146: "לכרטיס חיוב מיידי אסור לבצע עסקת זכות.",
  147: "כרטיס לא רשאי לבצע עסקאות בתשלומים עפ'י וקטור 31  של לאומיקארד.",
  148: "כרטיס לא רשאי לבצע עסקאות טלפוניות וחתימה בלבד עפ'י ווקטור 31 של לאומיקארד.",
  149: "כרטיס אינו רשאי לבצע עסקאות טלפוניות עפ'י  וקטור 31 של לאומיקארד.",
  150: "אשראי לא מאושר לכרטיסי חיוב מיידי.",
  151: "אשראי לא מאושר לכרטיסי חו'ל.",
  152: "קוד מועדון לא תקין.",
  153: "כרטיס לא רשאי לבצע עסקת אשראי גמיש (עדיף +30/) עפ'י הגדרת המערכת. (VECTOR21 של דיינרס).",
  154: "כרטיס לא רשאי לבצע עסקאות חיוב מיידי עפ'י הגדרת המערכת. (VECTOR21 של דיינרס).",
  155: "סכום לתשלום בעסקת קרדיט קטן מידי.",
  156: "מספר תשלומים לעסקת קרדיט לא תקין.",
  157: "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי רגיל או קרדיט.",
  158: "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי חיוב מיידי.",
  159: "תקרה 0 לסוג כרטיס זה  בעסקת חיוב מיידי בדולרים.",
  160: "תקרה 0 לסוג כרטיס זה בעסקה טלפונית.",
  161: "תקרה 0 לסוג כרטיס זה בעסקת זכות.",
  162: "תקרה 0 לסוג כרטיס זה בעסקת תשלומים.",
  163: "כרטיס אמריקן אקספרס אשר הונפק בחו'ל לא רשאי לבצע עסקאות בתשלומים.",
  164: "כרטיסיJCB  רשאי לבצע עסקאות רק באשראי רגיל.",
  165: "סכום בכוכבים/נקודות/מיילים גדול מסכום העסקה.",
  166: "כרטיס מועדון לא בתחום של המסוף.",
  167: "לא ניתן לבצע עסקת כוכבים/נקודות/מיילים בדולרים.",
  168: "למסוף אין אישור לעסקה דולרית עם סוג אשראי זה.",
  169: "לא ניתן לבצע עסקת זכות עם אשראי שונה מ'רגיל'",
  170: "סכום הנחה בכוכבים/נקודות/מיילים גדול מהמותר.",
  171: "לא ניתן לבצע עסקה מאולצת לכרטיס/אשראי חיוב מיידי.",
  172: "לא ניתן לבטל עסקה קודמת (עסקת זכות או מספר כרטיס אינו זהה).",
  173: "עסקה כפולה.",
  174: "למסוף אין אישור להצמדה למדד לאשראי זה.",
  175: "למסוף אין אישור להצמדה לדולר לאשראי זה.",
  176: "כרטיס אינו תקף עפ”י הגדרת ה מערכת (וקטור 1 של ישראכרט).",
  177: "בתחנות דלק לא ניתן לבצע 'שרות עצמי' אלא 'שרות עצמי בתחנות דלק'.",
  178: "אסור לבצע עסקת זכות בכוכבים/נקודות/מיילים.",
  179: "אסור לבצע עסקת זכות בדולר בכרטיס תייר.",
  180: "בכרטיס מועדון לא ניתן לבצע עסקה טלפונית.",
  200: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  201: "תקלה בקריאת נתונים מוצפנים בפס המגנטי",
  250: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  251: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  252: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  253: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  300: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  301: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  302: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  303: "יש לנסות שנית או לפנות למנהל המערכת ולמסור את קוד התשובה",
  304: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  305: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  306: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  307: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  308: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  309: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  310: "אין עסקת חיוב התואמת את הזיכוי",
  311: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  312: "עסקת מקור לא קיימת לביטול",
  313: "עסקת מקור לבטול כבר שודרה",
  314: "תנועה כבר בוטלה",
  315: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  316: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  317: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  318: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  319: "מסוף אינו רשאי לקבל ביטול",
  320: "זיכוי כבר בוצע כנגד תנועת חיוב  זאת",
  321: "סכום הזיכוי גדול/קטן מסכום החיוב",
  322: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  323: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  324: "מסוף אינו רשאי לבצע תנועות זיכוי",
  325: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  326: "אין אישור לסליקת כרטיסים מסוג זה",
  327: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  328: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  329: "מסוף לא רשאי לבצע שאילתות עסקאות",
  330: "מסוף  לא רשאי לשדר",
  331: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  332: "נמצאו יותר מתנועת חיוב אחת לביטול זיכוי",
  333: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  334: " עסקה זהה כבר בוצעה",
  335: "כרטיס לא רשאי לבצע זיכוי במסוף CFT",
  336: "לא ניתן לבצע עסקאות שאינן דולריות",
  337: "לא ניתן לבצע עסקאות ללא שדה X",
  338: "סך כל סכום העסקאות לכרטיס זה גבוה מהמותר ליממה",
  339: "סוג עסקת הזיכוי שונה מעסקת החיוב",
  340: "מספר תשלומים לא זהה לעסקת המקור",
  341: "סכום עסקת הזיכוי לא תואם לעסקת המקור",
  342: "מזהה כרטיס לא נמצא",
  343: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  344: "בית עסק אינו קיים",
  345: "בית העסק קיים",
  346: "מסוף אינו רשאי לסלוק מטבע זה",
  347: "מגזר אינו קיים",
  348: "סיסמא צריכה להכיל אותיות גדולות וקטנות מספרים וסימני ניקוד",
  349: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  350: "סולק אינו קיים",
  351: "לא נמצא ניתוב מסוף מתאים",
  352: "לא ניתן לשדר – חריגה ממכסת שידורים יומית",
  353: "אין עסקאות לשידור",
  354: "יש לנסות שנית או לפנות למנהל המערכת ולמסור את קוד התשובה",
  355: "עסקה מושהית כבר שוחררה לשידור",
  356: "לא נמצאה עיסקה מושהית מתאימה לשחרור",
  357: "נמצאו יותר מעסקת מושהית אחת המתאימה לשחרור",
  358: "פרטים רגישים לא נמצאו בבסיס הנתונים",
  359: "לא נמצא מזהה דוח",
  360: "פעולת חסימה מוגדרת לקוח",
  361: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  362: "אין הרשאה לבין עסק עבור הגדרת דוח",
  363: "הגדרת דוח לא נמצאה",
  364: "נתונים שנשלחו אינם נמצאים בהגדרות הדוח",
  365: "שגיאה בהרצת דוח",
  366: "מזהה הרצת דוח לא קיים",
  367: "לא קיים מזהה כרטיס פנימי עבור כרטיס אשראי",
  369: "עסקת מקור בוצעה על מסוף שאינו מורשה",
  370: "סכום עסקת העברה אינו תואם לעסקת המקור",
  371: "לא נמצאה עסקה להעברה",
  372: "עסקה הועברה למסוף אחר",
  373: "אין אישור להחלטת בית עסק עבור CVV",
  374: "אין אפשרות לבצע עסקה מאולצת בכרטיס זה",
  375: "מזהה cgUid לא קיים",
  399: "לא נמצאה עסקת מקור",
  400: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  401: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  402: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  403: "נשארו פחות משישה ימים לפקיעת תוקף כרטיס האשראי",
  404: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  405: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  408: "מספר אישור לא קיים במערכת",
  409: "כרטיס ברשימה השחורה של בית העסק",
  410: "מסוף אינו רשאי לסוג זה של סליקה",
  411: "לקוח אינו רשאי לסלוק במסוף זה",
  412: "לא קיים מספר אישור עבור עסקה זו",
  413: "תהליך מחיקת התנועה מטבלת הסליקה נכשל",
  414: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  415: "לא ניתן לחייב פעמיים עם אותו מספר אישור",
  416: "לא ניתן לזכות פעמיים עם אותו מספר אישור",
  417: "כרטיס ברשימה השחורה של בית העסק בגלל סירוב באשראית",
  418: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  419: "מספר אישור שהתקבל מחברת האשראי אינו תקין",
  420: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  421: "עברו יתר מארבעה ימים מאז החיוב",
  422: "כרטיס ברשימה השחורה של בית העסק בגלל Charge Back",
  423: "כרטיס נחסם בבדיקת זיהוי סיכונים",
  424: "כשלון בבדיקת זיהוי סיכונים",
  425: "יש לנסות שנית או לפנות למנהל המערכת ולמסור את קוד התשובה",
  426: "יש לנסות שנית או לפנות למנהל המערכת ולמסור את קוד התשובה",
  427: "חסרים פרטים בקבלה",
  428: "לא נמצאה תנועה מתאימה להדפסת פתקית",
  429: "נמצאה יותר מתנועה אחת להדפסת פתקית",
  430: "השדה מספר קופה חסר להדפסת פתקית.",
  431: "שדה X חסר להדפסת פתקית.",
  432: "בקשה לא מוכרת נשלחה על ידי הקופה",
  433: "סכום העסקה גבוה מסכום העסקה המותר לעסקה מקומית",
  434: "לא ניתן לבצע עסקאות J5 במצב offline",
  441: "סה'כ סכום הזיכוי גבוה מהתנועה המקורית",
  444: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  449: "סכום הנחה לא תקין",
  450: "כרטיס לא פעיל",
  451: "כרטיס לא קיים",
  452: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  453: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  454: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  455: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  456: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  457: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  458: "עסקה כפולה",
  459: "הנתון orgUid/cgUid חסר",
  460: "לא נמצאה עסקת מקור",
  461: "סירוב",
  462: "ערך לא תקין בבקשה",
  463: "נמצאו מספר עסקאות מקור",
  499: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  500: "לא נמצא מספר שידור (transmitID)",
  501: "אין אפשרות לבצע 'שידור חוזר'. התקשר לתמיכה טכנית. ודווח קוד שגיאה",
  502: "מסוף נמצא בתהליך שידור. אין אפשרות להתחיל שידור נוסף.",
  503: "אין אפשרות לבצע 'שידור חוזר' במסוף זה- לא נמצא שידור קודם שנכשל",
  504: "אין אפשרות לשדר כיווון ששידור קודם נכשל. נסה לבצע 'שידור חוזר'",
  505: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  506: "קו הטלפון אינו מחובר",
  507: "מנסה שנית",
  508: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  509: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  510: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  511: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  512: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  513: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  514: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  516: "אין אישור",
  517: "נסיון התקשרות נכשל, מנסה בקו גיבוי",
  518: "נסיון התקשרות נכשל",
  521: "כרטיס כבר נמצא בקובץ חסומים",
  522: "כרטיס  אינו נמצא בקובץ חסומים",
  523: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  524: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  525: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  526: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  527: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  528: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  529: "לא ניתן להתחיל שידור עקב ריבוי שידורים",
  530: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  531: "שידור נפסק",
  532: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  533: "שגיאה בשידור",
  550: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  551: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  552: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  553: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  554: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  555: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  556: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  557: "נא לפנות למנהל המערכת ולמסור את קוד התשובה",
  640: "חובה להזין CVV",
  650: "מוצר עם מק'ט זה כבר קיים במערכת",
  651: "מוצר עם מק'ט זה לא קיים במערכת",
  652: "מספר לקוחות מתאימים לפרמטרים שנשלחו",
  653: "קיים לקוח עם תעודת זהות זהה",
  654: "קיים לקוח עם מספר לקוח זהה",
  655: "קיים לקוח עם שם ומספרי טלפון זהים",
  656: "מספר הלקוח לא קיים",
  657: "מזהה הלקוח לא קיים",
  658: "לא קיים לקוח עם שם זה",
  659: "לא ניתן לעדכן חשבונית סופית",
  660: "תבנית לא נמצאה",
  661: "דואר אלקטרוני חייב להיות מלא",
  662: "חשבונית לא קיימת",
  663: "לא ניתן למחוק לקוח עם חשבוניות ללא בקשה מפורשת",
  664: "לא ניתן למחוק מוצר הנמצא בחשבוניות ללא בקשה מפורשת",
  665: "לא ניתן לזכות חשבונית שכבר זוכתה",
  666: "קטגוריה לא קיימת",
  667: "יחידה לא קיימת",
  674: "לא נמצאה עסקה התואמת למזהה שנשלח",
  675: "סוג עסקה אינו מתאים לסוג החשבונית",
  676: "החשבונית כבר הופקה בעבר",
  677: "שגיאה ביצירת החשבונית",
  681: "הזדהות נכשלה מול שרת הMPI",
  682: "זיהוי עסקת MPI נכשל",
  683: "MPI Invalid request or post parameters",
  684: "שרת הCG GATEWAY החזיר שגיאה לשרת הMPI",
  685: "שגיאה בגישה לשרת הCG GATEWAY",
  686: "שגיאה פנימית בשרת הMPI",
  687: "תם תוקפה של עסקת הMPI",
  688: "עסקת MPI נשלחה בעבר",
  689: "עסקת MPI לא נמצאה",
  690: "יותר מדי תהליכים רצים",
  691: "לא נמצא תהליך – לא נמצאו קבצים",
  692: "לא נמצא מזהה תהליך",
  702: "להמשך תהליך נא להתקשר ללאומי צ`ק 1700-700-693",
  703: "בצע אימות נוסף",
  704: "נתוני בקשה שגויים",
  705: "ניתן אישור טלפוני",
  710: "ת.ז. שגוייה",
  711: "מספר טלפון שגוי",
  712: "מיקוד שגוי",
  713: "סכום עסקה שגוי",
  714: "סכום צ`ק שגוי",
  715: "מס` בית עסק, ש`, שגוי",
  716: "בית עסק אינו מורשה לצ`קים מחברות בע'מ",
  720: "קוד בנק-סניף, מסלקה, שגוי",
  721: "מספר חשבון בנק שגוי",
  722: "מס` הצ`ק שגוי",
  740: "לא התקבל ת.ז ערב",
  750: "אין אישור לסליקת צ`קים. נא להתקשר ללאומי צ`ק 1700-700-693",
  751: "אין אישור לביצוע עסקה דחויה",
  752: "אין אישור לביצוע עסקת תשלומים",
  753: "מספר תשלומים גדול מהמותר עבור עסקת תשלומים",
  754: "סכום תשלום ראשון צריך להיות גדול או שווה לשאר התשלומים",
  755: "תאריך פרעון שגוי עבור עסקה דחויה",
  756: "סכום העסקה/צ`ק עולה על הסכום המותר",
  757: "תאריך פרעון שגוי עבור עסקה רגילה",
  758: "לא ניתן להזין תשלומים עבור עסקה שאינה עסקת תשלומים",
  759: "מספר התשלומים חורג מסך כל התשלומים בעסקה",
  760: "פרטי תשלום שגוי – מס` תשלום שגוי",
  761: "פרטי תשלום שגוי – חסרים פרטים",
  762: "פרטי תשלום שגוי – סכום התשלום חורג מסכום העסקה הכולל",
  763: "פרטי תשלום שגוי – תאריך פרעון",
  764: "עסקה/צ`ק אושר כבר בעבר",
  765: "סכום כל התשלומים לא תואם לסכום הכולל של העסקה",
  766: "מספר התשלומים המפורטים בעסקה אינו תואם למספר התשלומים המוגדר בה",
  767: "לא נמצאה עסקה לשם דווח תשלומים",
  768: "מספר הצ`ק בתשלום אינו תקין",
  769: "מספר הצ`ק כפול באחד התשלומים",
  770: "חסר שדה מזהה לביטול עסקה",
  771: "לא נמצאה עסקה לביטול",
  772: "עסקה כבר בוטלה",
  773: "נמצאה יותר מעסקה אחת לביטול",
  774: "מס` אישור אינו תואם נתוני עסקת מקור",
  775: "מס` אישור אינו תואם מס` בית עסק",
  776: "מס` אישור אינו תואם קוד מסלקה",
  777: "מס` אישור אינו תואם מס` חשבון",
  778: "מס` אישור אינו תואם סכום עסקה",
  779: "מס` אישור אינו תואם מס` תשלומים",
  780: "תאריך פירעון צ`ק/ים חלף",
  781: "עסקה כבר חויבה",
  786: "מס` אישור אינו תואם עם מס` צ`ק",
  787: "מס` אישור אינו תואם עם סכום צ`ק",
  788: "מס` אישור אינו תואם עם תאריך פירעון הצ`ק",
  789: "צ`ק כבר בוטל",
  795: "תקלת תקשורת 795. נא להתקשר ללאומי צ`ק 1700-700-693",
  796: "תקלת תקשורת 796. נא להתקשר ללאומי צ`ק 1700-700-693",
  797: "תקלת תקשורת 797. נא להתקשר ללאומי צ`ק 1700-700-693",
  798: "תקלת תקשורת 798. נא להתקשר ללאומי צ`ק 1700-700-693",
  799: "תקלת כללית 799. נא להתקשר ללאומי צ`ק 1700-700-693",
  800: "חסר אחוז לפיצול העסקה",
  801: "לא ניתן לבצע עסקה מפוצלת עם פרמטר J זה",
  802: "בנק לא חוקי",
  803: "בנק לא קיים",
  804: "סניף לא חוקי",
  805: "סניף לא קיים",
  806: "חשבון לא חוקי",
  807: "קבוצת בנק חסרה",
  808: "צירוף לא חוקי של פרטי חשבון",
  809: "חשבון מוגבל",
  810: "סוג עסקה לא חוקי",
  811: "קוד מטבע לא חוקי",
  820: "מוסד לא קיים",
  830: "עסקה כפולה",
  831: "מסוף אינו רשאי לסלוק מטבע זה",
  850: "שגיאת מודול Mpos",
  851: "שגיאת מודול Pos",
  852: "קיימת כבר נקודת קצה עם כתובת המחשב הזו",
  853: "בקשה לא בוצעה",
  854: "תקלת תקשורת",
  855: "תקלה במשיכת קבצי מסוף",
  856: "נקודת קצה לא מצליחה לשדר עסקאות מקומיות",
  857: "תקלה בשנמוך גרסה,יש עסקאות ממתינות לשידור",
  858: "תקלה בשנמוך גרסה,לא נמצא קובץ גיבוי לאפליקציה",
  859: "תקלה בשדרוג גרסה,לא נמצא קובץ שדרוג",
  900: "עסקה נחסמה עקב אי התחייבות לעסקה בטוחה",
  901: "תהליך 3DS חסום עבור מסוף זה",
  902: "תהליך 3DS חסום עבור מותג כרטיס זה",
  903: "לא נמצאה עסקת 3DS Lookup (J107) תואמת",
  904: "עסקת 3DS כפולה",
  910: "לא ניתן להוסיף פריט EMD.לא נמצאה עסקת EMD מתאימה",
  911: "פריט EMD זוכה בעבר",
  912: "פריט EMD בוטל בעבר",
  913: "לא ניתן לזכות פריט EMD – התקשר לתמיכה",
  914: "לא ניתן לבטל פריט EMD – התקשר לתמיכה",
  915: "בעיה כללית בניהול פריטי EMD – התקשר לתמיכה",
  916: "סה'כ סכום פריטי EMD עבר את סכום עסקת המקור",
  917: "מטבע פריט EMD חייב להיות זהה למטבע עסקת המקור",
  918: "פריט EMD כפול.מספר כרטיס טיסה כבר קיים במערכת",
  919: "פריט EMD לא קיים במערכת",
  920: "עסקת EMD זוכתה במלואה – לא ניתן להוסיף פריט",
  921: "עסקת EMD בוטלה – לא ניתן להוסיף פריט",
  989: "מזהה קבוצה לא קיים",
  990: "שגיאה בהרצת בילינג",
  991: "תהליך בילינג קודם לא הסתיים",
  992: "לא נמצא קובץ",
  993: "לעסקה אין אישור ע'י משתמש",
  994: "פרטים שגויים של העסקה",
  995: "עסקה כפולה",
  996: "עסקה נדחתה ע'י משתמש",
  1001: "התקשר לחברת האשראי",
  1002: "התקשר לחברת האשראי",
  1003: "בית עסק לא חוקי",
  1004: "החרם כרטיס",
  1005: "סירוב",
  1006: "שגיאה כללית",
  1007: "החרם כרטיס",
  1009: "בקשה בתהליך",
  1012: "סוג עסקה לא חוקי,או לא נתמך",
  1013: "סכום לא חוקי או מעבר לתקרה",
  1014: "כרטיס לא חוקי",
  1019: "בצע עסקה שוב",
  1021: "לא ננקטה פעולה",
  1030: "שגיאת פורמט",
  1041: "כרטיס אבוד,החרם כרטיס",
  1043: "כרטיס גנוב,החרם כרטיס",
  1051: "חריגה ממסגרת אשראי",
  1052: "לא בשימוש",
  1053: "לא בשימוש",
  1054: "כרטיס פג תוקף",
  1055: "קוד סודי אינו נכון",
  1057: "סוג הבקשה לא נתמך עבור הכרטיס",
  1058: "סוג הבקשה לא נתמך עבור בית העסק",
  1061: "חריגה ממסגרת אשראי",
  1062: "כרטיס חסום",
  1063: "עסקה אינה עומדת בהגדרות אבטחה",
  1065: "חריגה ממספר עסקאות מותרות",
  1075: "אישור קוד סודי נכשל",
  1077: "אין תאימות למקור",
  1078: "אין הרשאה לסלוק עסקה זו",
  1081: "סוג בקשה (J) אינו נתמך",
  1082: "סוג עסקה (Transaction Type) אינו נתמך",
  1083: "סוג כרטיס אינו נתמך",
  1084: "זמן האישור ארוך מהזמן המותר",
  1086: "לא ניתן לוודא חוקיות קוד סודי",
  1091: "שגיאת בנק פרוקסי",
  1092: "שגיאה בקוד בנק",
  1093: "עסקה אינה מאושרת לפי הגדרות",
  1094: "עסקה כפולה",
  1096: "תקלת מערכת – התקשר לתמיכה",
  1099: "אין תקשורת לבנק פרוקסי",
  1101: "לא נמצא מסוף בטבלת המרה",
  1102: "עסקת מקור כבר נתפסה",
  1103: "זמן מינימום לתגובה של בנק עבר",
  1104: "זמן מינימום לתגובה של בנק פרוקסי עבר",
  1105: "סכום מצטבר לחיוב גבוה מסכום האישור",
  1106: "לא נמצאה עסקת מקור לחייב",
  1163: "שגיאה בביצוע שידור",
  1164: "תקלה בשמירת מזהה עסקה",
  1165: "שגיאה בקבלת תשובה עבור עסקה",
  1166: "שגיאה בעדכון שידור",
  1167: "שגיאה באימות קובץ",
  1168: "שגיאה בבניית קובץ שידור",
  1169: "שגיאה בבדיקת גודל קובץ שידור",
  1170: "שגיאה בביצוע שידור",
  1200: "לא ניתן לשדר – חריגה ממכסת שידורים יומית",
  1201: "אין עסקאות לשידור",
  1202: "חסר פרמטר בבקשת השידור",
  1300: "עסקת masterPass מבוטלת",
  1301: "עסקת masterPass נכשלה",
  1302: "ערך לא תקין בשדה addonData עבור עסקת masterPass",
  1303: "מסוף לא תומך בעסקה דרך masterPass",
  1304: " הגדרת קונפיגורציה עבור שדה Z אינה תקינה",
  1305: "חסר מזהה masterPass לספק",
  1310: "שגיאת ולידציה",
  1311: "שגיאה בבקשת טוקן של masterPass",
  1312: "שגיאת עגלת קניות של masterPass",
  1313: "שגיאה באתחול סוחר ב masterPass",
  1314: "מבנה בקשה ל masterPass לא תקין",
  1316: "שגיאה בגישה לטוקן",
  1317: "שגיאה בהחזרת פרטי תשלום",
  1319: "שגיאה בעדכון סטטוס העסקה ב masterPass",
  1400: "שגיאה כללית – התקשר לתמיכה",
  1401: "סכום עסקה שגוי",
  1402: "סכום העסקה עולה על הסכום המותר למסוף",
  1403: "סירוב",
  1404: "שגיאת מערכת בנק",
  1405: "כרטיס פג תוקף.",
  1406: "מספר הכרטיס אינו חוקי",
  1407: "מזהה מסוף אינו חוקי",
  1408: "מזהה בית עסק אינו חוקי",
  1409: "נתוני בקשה שגויים",
  1410: "תקלת תקשורת",
  1411: "תקלה, יש לנסות שוב או לפנות למנהל המערכת ולמסור את קוד התשובה",
  1412: "התקשר לחברה הסולקת",
  1413: "בקשה לא חוקית",
  1414: "סכום שגוי",
  1415: "התקשר לחברה הסולקת",
  1416: "CVV שגוי",
  1417: "מזויף החרם כרטיס.",
  1418: "סכום מעל תקרה",
  1600: "שגיאה כללית – התקשר לתמיכה",
  1601: "כרטיס גנוב",
  1602: "כרטיס חסום",
  1603: "חריגה ממסגרת אשראי",
  1604: "סירוב",
  1605: "תקלת מערכת – התקשר לתמיכה",
  1606: "הבקשה לא נתמכת",
  1607: "סכום העסקה חייב להיות יותר מ – 0",
  1609: "תקלת תקשורת לבנק",
  1616: "CVV שגוי",
  1633: "מספר הכרטיס אינו חוקי",
  1634: "אין אישור לסוג כרטיס זה",
  1636: "כרטיס פג תוקף.",
  1641: "סכום העסקה לא תואם לעסקת המקור",
  1673: "עסקה כפולה.",
  1674: "פרמטר שגוי",
  1675: "התקשר לחברת האשראי.",
  1702: "סירוב.",
  1703: "סירוב. התקשר לחברת האשראי לקבלת פרטים נוספים.",
  1704: "סירוב. החרם כרטיס (אם אפשרי).",
  1705: "קשר לא מאובטח.",
  1706: "שיטת HTTP אינה POST.",
  1707: "שדה חסר.",
  1708: "מבנה שדה לא תקין.",
  1710: "סוחר לא קיים.",
  1711: "ההזדהות נכשלה (חתימת העסקה אינה תקינה).",
  1712: "סוחר לא פעיל.",
  1713: "סוחר לא מורשה לכרטיס זה.",
  1714: "סוחר לא מורשה למטבע זה.",
  1715: "הגדרות הסוחר אינן תקינות.",
  1716: "תהליך עיבוד העסקה נכשל.",
  1717: "תהליך עיבוד העסקה נכשל.",
  1718: "תהליך עיבוד העסקה נכשל.",
  1719: "תהליך עיבוד העסקה נכשל.",
  1720: "תהליך עיבוד העסקה נכשל.",
  1721: "תהליך עיבוד העסקה נכשל.",
  1722: "תהליך עיבוד העסקה נכשל.",
  1723: "תהליך עיבוד העסקה נכשל.",
  1732: "תהליך ההזדהות נכשל (3-D Secure)",
  1734: "תהליך ההזדהות נכשל.",
  1735: "תהליך עיבוד הבקשה נכשל. נסה שנית מאוחר יותר.",
  1736: "בעל הכרטיס ביטל את הבקשה.",
  1737: "מספר הנסיונות עבר את הגבול המותר.",
  1738: "תהליך עיבוד העסקה נכשל. נסה שנית מאוחר יותר.",
  1739: "מנפיק או מתג לא נגיש. נסה שנית מאוחר יותר.",
  1740: "עסקה כפולה.",
  1741: "פג תוקף החיבור.",
  1742: "פעולה אינה חוקית.",
  1770: "לא נמצא קוד תשובה מJCC",
  1771: "חתימת העסקה שונה.",
  1772: "לא ניתן לבצע פעולה זו.",
  1773: "קונפיגורציה לא מתאימה למסוף JCC.",
  1790: "שגיאה כללית. נסה שנית מאוחר יותר.",
  1798: "המערכת זמנית למטה. נסה שנית מאוחר יותר.",
  1803: "התקשר לחברה הסולקת",
  1804: "סירוב",
  1809: "בית עסק לא חוקי",
  1810: "סכום לא חוקי",
  1811: "חשבון לא חוקי",
  1812: "מנפיק לא נגיש. נסה שנית מאוחר יותר.",
  1815: "הפעולה המבוקשת לא נתמכת",
  1822: "CVV שגוי",
  1836: "כרטיס פג תוקף.",
  1883: "מטבע לא נתמך",
  1887: "סירוב – כרטיס חדש הונפק",
  1888: "סירוב – חשבון בוטל",
  1889: "סירוב – בית עסק בוטל או נסגר",
  1890: "סירוב. החרם כרטיס",
  1900: "שגיאה כללית – התקשר לתמיכה",
  1901: "פג תוקף ה – token",
  1902: "סכום פריטי עגלת הקניות לא תואם את סכום העסקה",
  1903: "התבצעה עסקה מוצלחת עם ה – token הנוכחי",
  1904: "סירוב",
  1905: "חסר פרמטר בבקשה – התקשר לתמיכה",
  1906: "ערך פרמטר לא חוקי",
  1907: "לקוח לא אישר את תשלום העסקה",
  1908: "עסקת האישור בוטלה",
  1909: "פג תוקף עסקת האישור",
  1910: "עסקת אישור קודמת הצליחה",
  1911: "חשבון מוגבל ללקוח",
  1912: "מטבע לא נתמך",
  1913: "סירוב – צור קשר עם הלקוח",
  1914: "סכום העסקה מעל התקרה המותרת",
  1915: "עסקת מקור נלכדה",
  1916: "המטבע הנסלק שונה ממטבע עסקת המקור",
  1917: "עסקת מקור בוטלה או פג תוקפה או נלכדה",
  1918: "פג תוקף עסקת מקור",
  1919: "עסקת מקור בוטלה",
  1920: "סכום עסקה מעל סכום מקסימלי לעסקה",
  1921: "תהליך עיבוד הבקשה נכשל. נסה שנית מאוחר יותר.",
  1922: "חסר token להשלמת העסקה",
  1923: "token זה לא קיים במערכת",
  1924: "עסקה זוכתה במלואה",
  1925: "שגיאה כללית – נסה שוב",
  1926: "קניין לא יכול לשלם",
  1927: "לא ניתן לחייב סכום אפס",
  1928: "לא ניתן להשלים העסקה.נא הפנה לקוח לפייפאל",
  1929: "הקונה עוד לא אישר את הוראת הקבע",
  1930: "מזהה הוראת קבע אינו תקין",
  1931: "הוראת קבע בוטלה",
  1932: "המשתמש לא אישר את התשלום",
  1933: "פרמטר לא תקין בבקשה",
  1934: "שדה כתובת משלוח הינו חובה",
  1935: "שדה עיר משלוח הינו חובה",
  1936: "שדה מדינת משלוח הינו חובה",
  1937: "שדה מיקוד משלוח הינו חובה",
  1938: "שדה ארץ משלוח הינו חובה",
  1939: "ההתאמה של עיר, מדינה ומיקוד המשלוח נכשלה",
  1940: "מדינה לא תקינה בכתובת המשלוח",
  1941: "ערך תגית NoteToBuyer נחתך, עולה על האורך המרבי",
  1942: "פרמטר חובה חסר",
  1943: "חשבון לא תקין, הסוחר לא מוגדר כסוחר חברת תעופה ואינו יכול להעביר נתוני מסלול תעופה",
  1944: "החשבון מוגבל",
  1945: "הקונה צריך לחזור לPayPal כדי לבחור מקורות מימון חדשים",
  1946: "סכום אינו תקין",
  1947: "חריגה ממספר מקסימלי של ניסיונות לחייב עם ה – token הנוכחי",
  1948: "סכום פריט או עסקה לא חוקי",
  1949: "מטבע העסקה שצוין חייב להיות זהה לאורך כל העסקה",
  2001: "סכום עסקה שגוי",
  2002: "סכום מעל תקרה",
  2003: "התקשר לחברה הסולקת",
  2004: "נכשל בהמרה",
  2005: "סירוב.",
  2006: "סירוב.",
  2007: "סירוב. בעיית מערכת.",
  2008: "מספר הצ`ק כפול באחד התשלומים",
  2009: "כרטיס פג תוקף.",
  2010: "סיסמא לא חוקית.",
  2011: "מספר הכרטיס אינו חוקי",
  2012: "מזהה מסוף אינו חוקי",
  2013: "מספר מעבר אינו חוקי",
  2014: "מזהה בית עסק אינו חוקי",
  2015: "מספר SE לא חוקי",
  2016: "מידע לא חוקי נשלח או עסקת ביטול",
  2017: "בעייה בקריאת הצ`ק",
  2018: "קובץ שידור ישן",
  2019: "שגיאה כללית – התקשר לתמיכה",
  2020: "אנא נסה שנית",
  2021: "רשומה לא נמצאה בelavon",
  2022: "הבקשה עברה את התקרה",
  2023: "התקשר לחברה הסולקת",
  2024: "בקשה לא חוקית",
  2025: "מעל תקרה (צ`ק)",
  2026: "התקשר לחברת האשראי.",
  2027: "התקשר לחברת האשראי.",
  2028: "סירוב. CVV2 לא זהה.",
  2029: "סירוב. החרם כרטיס.",
  2030: "שגיאה כללית – התקשר לתמיכה (כרטיס מתנה)",
  2031: "מספר הכרטיס אינו חוקי (כרטיס מתנה)",
  2032: "מזהה מסוף אינו חוקי (כרטיס מתנה)",
  2033: "שגיאה בסכום העסקה (כרטיס מתנה)",
  2034: "כרטיס מתנה כבר הופעל",
  2035: "הבקשה עברה את התקרה (כרטיס מתנה)",
  2036: "לא ניתן לטעון את כרטיס המתנה בסכום שניתן",
  2037: "לא ניתן לטעון מחדש את הכרטיס",
  2038: "כרטיס מתנה לא הופעל",
  2039: "התקשר לחברה הסולקת (כרטיס מתנה)",
  2040: "שגיאה כללית – התקשר לתמיכה (כרטיס מתנה)",
  2171: "עסקה נחסמה בגלל שנמוך ECI",
  2200: "אין תמיכה בסוג הבקשה",
  2201: "שגיאת מערכת : לא ניתן כרגע לטפל בבקשת 3ds",
  2202: "אין תמיכה בגרסת הבקשה",
  2203: "פורמט XML לא תקין",
  2204: "בקשה ריקה",
  2205: "לא נמצאה קונפיגורצית בית העסק",
  2206: "שגיאה בעיבוד הבקשה",
  2207: "שגיאה בעיבוד ה – cg3dsPaRes",
  2208: "cg3dsPaRes חסר או לא חוקי",
  2209: "סוג כרטיס וסוג בקשה לא תואמים",
  2210: "זהות מעבד לא חוקי",
  2211: "בית עסק לא חוקי",
  2212: "בית עסק לא פעיל",
  2213: "שגיאה בבדיקת חוקיות בקשה – מזהה עסקה ריק",
  2214: "שגיאה בניתוח cg3dsPaRes",
  2215: "שגיאה בחוקיות סכום העסקה",
  2216: "שגיאה כללית – התקשר לתמיכה",
  2217: "שגיאה בעיבוד בקשת הרישום (lookup)",
  2218: "שגיאה בהזדהות מול המנפיק",
  2219: "שגיאה בקבלת נתוני הרישום (lookup)",
  2220: "נתוני ה – cg3dsPaRes נשלחו בעבר",
  2221: "נתוני 3DSAuth נשלחו בעבר",
  2222: "נתוני ה – VERes נשלחו בעבר",
  2223: "נתוני ה – PAReq נשלחו בעבר",
  2224: "נתוני ה – VEReq נשלחו בעבר",
  2225: "יוזמת התשלום לא נתמכת",
  2230: "לא ניתן לוודא חוקיות cg3dsPaRes",
  2300: "אין הרשאה לפעולה זו",
  2301: "מספר הכרטיס אינו חוקי",
  2302: "CVV שגוי",
  2303: "שגיאה בנתוני AVS",
  2304: "שדה בעל כרטיס חסר",
  2305: "סכום עסקה לא חוקי",
  2306: "מטבע לא נתמך",
  2307: "חשבון בית עסק לא חוקי",
  2308: "שגיאת סולק פנימית",
  2309: "תהליך עיבוד הבקשה נכשל. נסה שנית מאוחר יותר.",
  2311: "שגיאה בנתוני AVS (avsCity)",
  2312: "שגיאה בנתוני AVS (avsStreet)",
  2313: "שגיאה בנתוני AVS (avsHouseNumber)",
  2314: "שגיאה בנתוני AVS (avsCountry)",
  2315: "שגיאה בנתוני AVS (avsState)",
  2316: "סירוב",
  2317: "אין תמיכה בפרטי העסקה",
  5001: "נא הפנה את הקניין להזדהות מול מנפיק הכרטיס",
  8001: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8002: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8003: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8004: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8005: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8006: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8007: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8008: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8011: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8012: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8013: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8014: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8015: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8016: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8017: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8018: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8019: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8020: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8021: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8022: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8023: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8024: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8025: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8026: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8027: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8028: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8029: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8030: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8031: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8032: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8033: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  8034: "העסקה נחסמה על ידי פרופיל לבדיקת סיכונים",
  9000: "פעולת חסימה מוגדרת לקוח",
  9001: "פעולת חסימה מוגדרת לקוח",
  9002: "פעולת חסימה מוגדרת לקוח",
  9003: "פעולת חסימה מוגדרת לקוח",
  9004: "פעולת חסימה מוגדרת לקוח",
  9005: "פעולת חסימה מוגדרת לקוח",
  9006: "פעולת חסימה מוגדרת לקוח",
  9007: "פעולת חסימה מוגדרת לקוח",
  9008: "פעולת חסימה מוגדרת לקוח",
  9009: "פעולת חסימה מוגדרת לקוח",
  9010: "פעולת חסימה מוגדרת לקוח",
  9011: "פעולת חסימה מוגדרת לקוח",
  9012: "פעולת חסימה מוגדרת לקוח",
  9013: "פעולת חסימה מוגדרת לקוח",
  9014: "פעולת חסימה מוגדרת לקוח",
  9015: "פעולת חסימה מוגדרת לקוח",
  9016: "פעולת חסימה מוגדרת לקוח",
  9017: "פעולת חסימה מוגדרת לקוח",
  9018: "פעולת חסימה מוגדרת לקוח",
  9019: "פעולת חסימה מוגדרת לקוח",
  9020: "פעולת חסימה מוגדרת לקוח",
  9021: "פעולת חסימה מוגדרת לקוח",
  9023: "פעולת חסימה מוגדרת לקוח",
  9024: "פעולת חסימה מוגדרת לקוח",
  9025: "פעולת חסימה מוגדרת לקוח",
  9026: "פעולת חסימה מוגדרת לקוח",
  9027: "פעולת חסימה מוגדרת לקוח",
  9028: "פעולת חסימה מוגדרת לקוח",
  9029: "פעולת חסימה מוגדרת לקוח",
  9030: "פעולת חסימה מוגדרת לקוח",
  9031: "פעולת חסימה מוגדרת לקוח",
  9032: "פעולת חסימה מוגדרת לקוח",
  9033: "פעולת חסימה מוגדרת לקוח",
  9034: "פעולת חסימה מוגדרת לקוח",
  9035: "פעולת חסימה מוגדרת לקוח",
  9036: "פעולת חסימה מוגדרת לקוח",
  9037: "פעולת חסימה מוגדרת לקוח",
  9038: "פעולת חסימה מוגדרת לקוח",
  9039: "פעולת חסימה מוגדרת לקוח",
  9040: "פעולת חסימה מוגדרת לקוח",
  9041: "פעולת חסימה מוגדרת לקוח",
  9042: "פעולת חסימה מוגדרת לקוח",
  9043: "פעולת חסימה מוגדרת לקוח",
  9044: "פעולת חסימה מוגדרת לקוח",
  9045: "פעולת חסימה מוגדרת לקוח",
  9046: "פעולת חסימה מוגדרת לקוח",
  9047: "פעולת חסימה מוגדרת לקוח",
  9048: "פעולת חסימה מוגדרת לקוח",
  9049: "פעולת חסימה מוגדרת לקוח",
  9050: "פעולת חסימה מוגדרת לקוח",
  9051: "פעולת חסימה מוגדרת לקוח",
  9052: "פעולת חסימה מוגדרת לקוח",
  9053: "פעולת חסימה מוגדרת לקוח",
  9054: "פעולת חסימה מוגדרת לקוח",
  9055: "פעולת חסימה מוגדרת לקוח",
  9056: "פעולת חסימה מוגדרת לקוח",
  9057: "פעולת חסימה מוגדרת לקוח",
  9058: "פעולת חסימה מוגדרת לקוח",
  9059: "פעולת חסימה מוגדרת לקוח",
  9060: "פעולת חסימה מוגדרת לקוח",
  9061: "פעולת חסימה מוגדרת לקוח",
  9062: "פעולת חסימה מוגדרת לקוח",
  9063: "פעולת חסימה מוגדרת לקוח",
  9064: "פעולת חסימה מוגדרת לקוח",
  9065: "פעולת חסימה מוגדרת לקוח",
  9066: "פעולת חסימה מוגדרת לקוח",
  9067: "פעולת חסימה מוגדרת לקוח",
  9068: "פעולת חסימה מוגדרת לקוח",
  9069: "פעולת חסימה מוגדרת לקוח",
  9070: "פעולת חסימה מוגדרת לקוח",
  9071: "פעולת חסימה מוגדרת לקוח",
  9072: "פעולת חסימה מוגדרת לקוח",
  9073: "פעולת חסימה מוגדרת לקוח",
  9074: "פעולת חסימה מוגדרת לקוח",
  9075: "פעולת חסימה מוגדרת לקוח",
  9076: "פעולת חסימה מוגדרת לקוח",
  9077: "פעולת חסימה מוגדרת לקוח",
  9078: "פעולת חסימה מוגדרת לקוח",
  9079: "פעולת חסימה מוגדרת לקוח",
  9080: "פעולת חסימה מוגדרת לקוח",
  9081: "פעולת חסימה מוגדרת לקוח",
  9082: "פעולת חסימה מוגדרת לקוח",
  9083: "פעולת חסימה מוגדרת לקוח",
  9084: "פעולת חסימה מוגדרת לקוח",
  9085: "פעולת חסימה מוגדרת לקוח",
  9086: "פעולת חסימה מוגדרת לקוח",
  9087: "פעולת חסימה מוגדרת לקוח",
  9088: "פעולת חסימה מוגדרת לקוח",
  9089: "פעולת חסימה מוגדרת לקוח",
  9090: "פעולת חסימה מוגדרת לקוח",
  9091: "פעולת חסימה מוגדרת לקוח",
  9092: "פעולת חסימה מוגדרת לקוח",
  9093: "פעולת חסימה מוגדרת לקוח",
  9094: "פעולת חסימה מוגדרת לקוח",
  9095: "פעולת חסימה מוגדרת לקוח",
  9096: "פעולת חסימה מוגדרת לקוח",
  9097: "פעולת חסימה מוגדרת לקוח",
  9098: "פעולת חסימה מוגדרת לקוח",
  9099: "פעולת חסימה מוגדרת לקוח",
  9100: "פעולת חסימה מוגדרת לקוח",
  9101: "פעולת חסימה מוגדרת לקוח",
  9102: "פעולת חסימה מוגדרת לקוח",
  9103: "פעולת חסימה מוגדרת לקוח",
  9104: "פעולת חסימה מוגדרת לקוח",
  9105: "פעולת חסימה מוגדרת לקוח",
  9106: "פעולת חסימה מוגדרת לקוח",
  9107: "פעולת חסימה מוגדרת לקוח",
  9108: "פעולת חסימה מוגדרת לקוח",
  9109: "פעולת חסימה מוגדרת לקוח",
  9110: "פעולת חסימה מוגדרת לקוח",
  9111: "פעולת חסימה מוגדרת לקוח",
  9112: "פעולת חסימה מוגדרת לקוח",
  9113: "פעולת חסימה מוגדרת לקוח",
  9114: "פעולת חסימה מוגדרת לקוח",
  9115: "פעולת חסימה מוגדרת לקוח",
  9116: "פעולת חסימה מוגדרת לקוח",
  9117: "פעולת חסימה מוגדרת לקוח",
  9118: "פעולת חסימה מוגדרת לקוח",
  9119: "פעולת חסימה מוגדרת לקוח",
  9120: "פעולת חסימה מוגדרת לקוח",
  9121: "פעולת חסימה מוגדרת לקוח",
  9122: "פעולת חסימה מוגדרת לקוח",
  9123: "פעולת חסימה מוגדרת לקוח",
  9124: "פעולת חסימה מוגדרת לקוח",
  9125: "פעולת חסימה מוגדרת לקוח",
  9126: "פעולת חסימה מוגדרת לקוח",
  9127: "פעולת חסימה מוגדרת לקוח",
  9128: "פעולת חסימה מוגדרת לקוח",
  9129: "פעולת חסימה מוגדרת לקוח",
  9130: "פעולת חסימה מוגדרת לקוח",
  9131: "פעולת חסימה מוגדרת לקוח",
  9132: "פעולת חסימה מוגדרת לקוח",
  9133: "פעולת חסימה מוגדרת לקוח",
  9134: "פעולת חסימה מוגדרת לקוח",
  9135: "פעולת חסימה מוגדרת לקוח",
  9136: "פעולת חסימה מוגדרת לקוח",
  9137: "פעולת חסימה מוגדרת לקוח",
  9138: "פעולת חסימה מוגדרת לקוח",
  9139: "פעולת חסימה מוגדרת לקוח",
  9140: "פעולת חסימה מוגדרת לקוח",
  9141: "פעולת חסימה מוגדרת לקוח",
  9142: "פעולת חסימה מוגדרת לקוח",
  9143: "פעולת חסימה מוגדרת לקוח",
  9144: "פעולת חסימה מוגדרת לקוח",
  9145: "פעולת חסימה מוגדרת לקוח",
  9146: "פעולת חסימה מוגדרת לקוח",
  9147: "פעולת חסימה מוגדרת לקוח",
  9148: "פעולת חסימה מוגדרת לקוח",
  9149: "פעולת חסימה מוגדרת לקוח",
  9150: "פעולת חסימה מוגדרת לקוח",
  9151: "פעולת חסימה מוגדרת לקוח",
  9152: "פעולת חסימה מוגדרת לקוח",
  9153: "פעולת חסימה מוגדרת לקוח",
  9154: "פעולת חסימה מוגדרת לקוח",
  9155: "פעולת חסימה מוגדרת לקוח",
  9156: "פעולת חסימה מוגדרת לקוח",
  9157: "פעולת חסימה מוגדרת לקוח",
  9158: "פעולת חסימה מוגדרת לקוח",
  9159: "פעולת חסימה מוגדרת לקוח",
  9160: "פעולת חסימה מוגדרת לקוח",
  9161: "פעולת חסימה מוגדרת לקוח",
  9162: "פעולת חסימה מוגדרת לקוח",
  9163: "פעולת חסימה מוגדרת לקוח",
  9164: "פעולת חסימה מוגדרת לקוח",
  9165: "פעולת חסימה מוגדרת לקוח",
  9166: "פעולת חסימה מוגדרת לקוח",
  9167: "פעולת חסימה מוגדרת לקוח",
  9168: "פעולת חסימה מוגדרת לקוח",
  9169: "פעולת חסימה מוגדרת לקוח",
  9170: "פעולת חסימה מוגדרת לקוח",
  9171: "פעולת חסימה מוגדרת לקוח",
  9172: "פעולת חסימה מוגדרת לקוח",
  9173: "פעולת חסימה מוגדרת לקוח",
  9174: "פעולת חסימה מוגדרת לקוח",
  9175: "פעולת חסימה מוגדרת לקוח",
  9176: "פעולת חסימה מוגדרת לקוח",
  9177: "פעולת חסימה מוגדרת לקוח",
  9178: "פעולת חסימה מוגדרת לקוח",
  9179: "פעולת חסימה מוגדרת לקוח",
  9180: "פעולת חסימה מוגדרת לקוח",
  9181: "פעולת חסימה מוגדרת לקוח",
  9182: "פעולת חסימה מוגדרת לקוח",
  9183: "פעולת חסימה מוגדרת לקוח",
  9184: "פעולת חסימה מוגדרת לקוח",
  9185: "פעולת חסימה מוגדרת לקוח",
  9186: "פעולת חסימה מוגדרת לקוח",
  9187: "פעולת חסימה מוגדרת לקוח",
  9188: "פעולת חסימה מוגדרת לקוח",
  9189: "פעולת חסימה מוגדרת לקוח",
  9190: "פעולת חסימה מוגדרת לקוח",
  9191: "פעולת חסימה מוגדרת לקוח",
  9192: "פעולת חסימה מוגדרת לקוח",
  9193: "פעולת חסימה מוגדרת לקוח",
  9194: "פעולת חסימה מוגדרת לקוח",
  9195: "פעולת חסימה מוגדרת לקוח",
  9196: "פעולת חסימה מוגדרת לקוח",
  9197: "פעולת חסימה מוגדרת לקוח",
  9198: "פעולת חסימה מוגדרת לקוח",
  9199: "פעולת חסימה מוגדרת לקוח",
  9501: "Decline",
  9502: "Referral",
  9503: "Call issuer",
  9504: "Lost/Stolen",
  9505: "Timeout/Retry",
  9506: "Expired card",
  9507: "Black listed by the bank",
  9508: "Retain card",
  9509: "Invalid card number",
  9510: "Stolen card, pick up",
  9511: "Restricted card",
  9512: "Suspicion of manipulation",
  9513: "Insufficient funds",
  9514: "Call Issuer",
  9515: "Invalid Merchant",
  9516: "Pick-up card",
  9517: "Do not honor",
  9518: "External Error in Processing",
  9519: "Invalid transaction",
  9520: "Invalid amount",
  9521: "Invalid card number",
  9523: "No such issuer",
  9524: "Unable to locate record in file",
  9525: "File temporarily not available",
  9526: "Format error",
  9527: "No credit account",
  9528: "Lost card, pick-up",
  9529: "Stolen card, pick-up",
  9530: "Insufficient Funds",
  9531: "No checking account",
  9532: "No savings account",
  9533: "Expired card",
  9534: "Incorrect PIN",
  9535: "Transaction not permitted to cardholder",
  9536: "Transaction not permitted on terminal",
  9537: "Suspected fraud",
  9538: "Exceeds withdrawal limit",
  9539: "Error in decryption of PIN block",
  9540: "Exceeds withdrawal frequency",
  9541: "Invalid transaction; contact card issuer",
  9542: "PIN not changed",
  9543: "PIN tries exceeded",
  9544: "Invalid -To- account specified",
  9545: "Invalid -From- account specified",
  9546: "Invalid account specified",
  9547: "Already reversed",
  9548: "System not available",
  9549: "Cryptographic error found in PIN",
  9550: "Cannot verify PIN",
  9551: "PIN unacceptable. Retry.",
  9552: "Issuer or switch inoperative",
  9553: "Routing error",
  9554: "Transaction cannot be completed",
  9555: "Invalid CVV2",
  9556: "Revocation of Authorization Order",
  9557: "Revocation of All Authorizations Order",
  9558: "Duplicate transaction",
  9559: "Pick up card, special condition (fraud account)",
  9560: "Invalid expiration date",
  9561: "Expiration date too old",
  9562: "Invalid card number (alpha numeric)",
  9563: "Invalid card number (digits count)",
  9564: "Invalid card number (MOD 10)",
  9565: "Invalid amount",
  9566: "Invalid currency",
  9567: "Invalid CVV2",
  9568: "Auth Code/Trans ID/Credit card number mismatch",
  9569: "Credit amount exceed total charges",
  9570: "Cannot credit this credit card company",
  9571: "Illegal interval between auth and force",
  9572: "Not allowed to process this credit card company",
  9573: "Unrecognized credit card company",
  9574: "This transaction was charged back",
  9575: "Sale/Settle was already credited",
  9576: "Terminal is not ready for this credit card company",
  9577: "Black listed card number",
  9578: "Illegal BIN number",
  9579: "Custom Fraud Screen Filter",
  9580: "N cannot be a positive CVV2 Reply",
  9581: "B/N Cannot be a Positive AVS Reply",
  9582: "Invalid AVS",
  9583: "CVV2 check is not allowed in Credit/Settle/Void",
  9584: "AVS check is not allowed in Credit/Settle/Void",
  9585: "Credits total amount exceeds restriction",
  9586: "Format Error",
  9587: "Credit amount exceeds ceiling",
  9588: "Limit exceeding amount",
  9589: "Invalid transaction type code",
  9590: "General filter error",
  9591: "This transaction type is not allowed for this bank",
  9592: "Amount exceeds bank limit",
  9593: "GW required fields are missing",
  9594: "AVS processor error",
  9595: "Only one credit per sale is allowed",
  9596: "Mandatory fields are missing",
  9597: "Credit count exceeded credit card restriction",
  9598: "Invalid credit type",
  9599: "This card is not supported in the OCT program",
  9600: "Card must be processed in the Gateway",
  9601: "Transaction type is not allowed",
  9602: "AVS required fields are missing or incorrect",
  9603: "Country does not match ISO Code",
  9604: "Must provide UserID in a Rebill transaction",
  9605: "Your Rebill profile does not supprt this transaction type",
  9606: "Void is not allowed due to customer credit card restriction",
  9607: "Invalid account number",
  9608: "Invalid cheque number",
  9609: "Account Number/Trans ID mismatch",
  9610: "UserID/Trans Type /Trans ID mismatch",
  9611: "Transaction does not exist in the rebill system",
  9612: "Transaction was already canceled",
  9613: "Invalid bank code(digits count)",
  9614: "Invalid bank code (alpha numeric)",
  9615: "Debit card required fields are missing or incorrect",
  9616: "No update parameters were supplied",
  9617: "3D Related transaction is missing or incorrect",
  9618: "3D PARes value is incorrect",
  9619: "State does not match ISO Code",
  9620: "Invalid bank code (checksum digit)",
  9621: "This bank allows only 3 digits in CVV2",
  9622: "Age verification failed",
  9623: "Transaction must contain a Card/Token/Account",
  9624: "Invalid token",
  9625: "Token mismatch",
  9626: "Invalid email address",
  9627: "Transaction already settled",
  9628: "Transaction already voided",
  9629: "sg_ResponseFormat field is not valid",
  9630: "Version field is missing or incorrect",
  9631: "Issuing country is invalid",
  9632: "Phone is missing or format error",
  9633: "Check number is missing or incorrect",
  9634: "Birth date format error",
  9635: "Zip code format error",
  9636: "Invalid start date",
  9637: "Cannot void an auth transaction",
  9638: "Cannot void a credit transaction",
  9639: "Cannot void a void transaction",
  9640: "Cannot perform this void",
  9641: "Merchant Name is too long (>25)",
  9642: "Transaction must be sent as 3Dsecure",
  9643: "Account is not 3D enabled",
  9644: "Transaction 3D status is incorrect",
  9645: "Related transaction must be of type AUTH3D",
  9646: "Related transaction must be 3D authenticated",
  9647: "Country does not support the CFT program",
  9648: "Invalid token ID",
  9649: "Risk processor error",
  9650: "RiskOnly is supported from version 4.0.0 and above",
  9651: "AGV processor error",
  9652: "AGVOnly is supported from version 4.0.1 and above",
  9653: "Age verification mismatch",
  9654: "Requested acquirer is unrecognized",
  9655: "sale transaction isnt processed on acquirer side yet",
  9656: "Payment method is not supported",
  9657: "IBAN does not match country code",
  9658: "invalid issuing bank country code",
  9659: "Envoy payout transaction requires credit transaction of type 1",
  9660: "Credit card details are not allowed for the given payment method",
  9661: "Input data is not sufficient",
  9662: "The requested acquirer bank is temporarily unavailable",
  9663: "ApmRisk is disabled",
  9664: "APM risk processor error",
  9665: "Bank required fields are missing or incorrect",
  9666: "Error In 3DSecure Processing",
  9667: "Acquirer Unknown Issue",
  9668: "UNEXPECTED SYSTEM ERROR – PLEASE RETRY LATER",
  9669: "Acquirer validation",
  9670: "IP out of range",
  9671: "Invalid login",
  9672: "Application error",
  9673: "Invalid Username field",
  9674: "Invalid Password field",
  9675: "Invalid FromDate field",
  9676: "Invalid ToDate field",
  9677: "Invalid RelatedRequestID Field",
  9678: "Invalid input fields",
  9679: "Cannot find original acquirer settled transaction id for refund",
  9680: "Cannot find original acquirer transaction id for inquire",
  9681: "Failed 3DSecure Authentication",
  9683: "Settle amount exceeds total charges",
  9684: "Cannot perform this credit",
  9685: "Converted transaction cannot be handled please use alternative interfaces",
  9686: "Client is not allowed for Partial Approval",
  9687: "Invalid FinalizationURL",
  9688: "One year passed since last sale Payout declined",
  9689: "Maximal length for the parameter passportNumber is",
  10001: "קמעונאי לא קיים",
  10002: "כרטיס לא זוהה בוקטור 90",
  10003: "לא נמצאה רשומה מתאימה בפרמטרים לקמעונאי – כרטיסים ישראלים",
  10004: "מספר ספק לא קיים",
  10005: "מסר ACK עם קוד יישום שגוי",
  10006: "מנפיק לא קיים בוקטור מנפיקים",
  10007: "כרטיס לא זוהה בוקטור הכרטיסים של המנפיק",
  10008: "טווח ספרות לא תקין לזיהוי סוג כרטיס",
  10009: "לא נמצאה רשומה מתאימה בפרמטרים לקמעונאי – כרטיס תייר",
  10010: "סולק לא קיים בוקטור 91",
  10011: "לא נמצאה רשומה מתאימה בפרמטרים לקמעונאי – נתונים כללים",
  10012: "לא בוצעה התקשרות ראשונית",
  10013: "ערך אחד הסכומים לא חוקי לבקשה זו",
  10019: "מסוף ניתק בקבלת חסומים קטן",
  10020: "TIMEOUT מיתוג כללי",
  10021: "אורך לא חוקי בתשובה של מיתוג כללי",
  10022: "תשובה לא חוקית ממיתוג כללי",
  10023: "דחיה של 99 ממיתוג כללי",
  10040: "לא הצליח לקבל מספר אישור",
  10041: "SESSION לא הצליח לקבל מספר",
  10050: "בעיה בקובץ",
  10053: "חסר מצביע לנתון שנכתב ב ISO, בתשובה למסוף",
  10059: "מנפיק לא תומך במטבע זה",
  10061: "ערך לא חוקי לשיטת חישוב סוג כרטיס בוקטור 90",
  10062: "ערך לא מתאים לשיטת חישוב סוג כרטיס בוקטור 90",
  10063: "מותג לא קיים בוקטור מותגים",
  10064: "סוג כרטיס לא קיים בוקטור הרשאות מנפיק – 1 (ישרכארד)",
  10065: "סוג כרטיס לא קיים בוקטור הרשאות מנפיק – 15 (כאל)",
  10066: "סוג כרטיס לא קיים בוקטור הרשאות מנפיק – 31 (לאומיקארד)",
  10067: "סוג כרטיס לא קיים בוקטור הרשאות מנפיק – 41 (מנפיק עתידי)",
  10068: "לא נמצא תהליך יעד במיתוג כללי עבור כרטיס זה",
  10071: "ערך לא חוקי לשדה ISO בבניית התשובה",
  10072: "ערך לא חוקי בפרמטרים של סולק",
  10099: "בעיית סיסטם",
  10100: "תשובה לא חוקית מחברת אשראי, אין לבצע סטנד-אין",
  10101: "נכשל ב STIP בבדיקת USAGE",
  10102: "נכשל ב STIP בבדיקת חסומים",
  10103: "נכשל ב STIP בבדיקת CVV",
  10104: "נכשל ב STIP אורך מספר כרטיס אשראי לא חוקי בבדיקת CVV",
  10105: "נכשל ב STIP בבדיקת CVV2",
  10106: "נכשל בבדיקת USAGE למכשיר וידיומט",
  10107: "נכשל ב STIP בבדיקת חסומים , סיבת חסימה לא תקינה",
  10108: "נכשל בבדיקת ספרת ביקורת של מספר כרטיס ב STIP",
  10109: "נכשל ב STIP בבדיקת 3 CVC",
  10110: "נכשל ב STIP בעדכון קובץ USAGE",
  10111: "חסר תקרה לכרטיס זה בקובץ פרמטרים ל STIP",
  10112: "אין לבצע סטנדאין לעסקת זיכוי עבור לקוח זה",
  10113: "אין לבצע סטנדאין למטבע שאינו שקל או דולר",
  10115: "אין לבצע לכרטיס תייר סטנדאין",
  10116: "אין לבצע סטנדאין לעסקת מזומן למנפיק זה",
  10117: "אין לבצע סטנדאין לעסקת CASHBACK למנפיק זה",
  10118: "אין לבצע סטנדאין לעסקת הוראת קבע למנפיק זה מעל סכום",
  10119: "שגיאה פנימית – תשובת סטנדאין שגויה ב ISO",
  10120: "אין לבצע סטנדאין לעסקה טלפונית – ישרכארד",
  10121: "אין לבצע סטנדאין לכרטיס חיוב מיידי",
  10122: "אין לבצע סטנדאין לכרטיס חיוב נטען – ישרכארד",
  10123: "אין לבצע סטנדאין לכרטיס לא משופעל של ישרכארד",
  10124: "אין לבצע סטנדאין לעסקת ביטול",
  10125: "אין לבצע סטנדאין לפעולת REVERSAL",
  10126: "אין לבצע סטנדאין לפעולת בירור יתרה",
  10127: "אין לבצע סטנדאין לפעולת טעינה",
  10128: "אין לבצע סטנדאין לפעולת פריקה",
  10129: "כרטיס לא נמצא בקובץ אב של כאל",
  10130: "נכשל ב STIP בבדיקת ICVV",
  10131: "כרטיס לא נמצא בקובץ אב של לאומיקארד",
  10132: "תאריך בקובץ אב פג-תוקף או לא תואם לתאריך בשאילתא",
  10133: "סכום משיכה גבוה מהמקסימום",
  10134: "כרטיס לוגו",
  10135: "בעיה בקריאת רשומה 83 L",
  10136: "ערך לקוח לא חוקי בקובץ אב",
  10137: "אין סטנדאין לסכום שחורג מ 8 ספרות (מגבלה של אשראית 96)",
  10138: "מנפיק שונה מ 0 בקובץ אב",
  10139: "בעיה מול שרת גישה ל USG83",
  10140: "אורך לא חוקי בתשובה משרת גישה ל USG83",
  10141: "אין לבצע סטנדאין לעסקה טלפונית בהוראת קבע",
  10142: "אין לבצע סטנדאין לעסקת זיכוי בכרטיס PL תוי שי.",
  10143: "אין לבצע סטנדאין לכרטיס DEBIT",
  10144: "אין לבצע סטנדאין ב FALLBACK לכרטיס זה",
  10145: "סכום העסקה גבוה מתקרת FALLBACK לכרטיס זה",
  10146: "אין לבצע סטנדאין לכרטיס חכם CONTACTLESS או סלולארי שאינו",
  10147: "אין לבצע סטנדאין לכרטיס מגנטי CONTACTLESS או סלולארי",
  10148: "סכום העסקה גבוה מתקרת CASHBACK לכרטיס זה",
  10149: "סכום העסקה גבוה מתקרת מזומן לכרטיס זה",
  10150: "סכום לזיכוי גבוה מתקרת זיכוי",
  10151: "אין לבצע סטנדאין לעסקת טעינה בכרטיס שאינו  PL תוי שי",
  10152: "אין הרשאה לביצוע טעינה לכרטיס זה בקובץ אב",
  10153: "אין הרשאה לביצוע טעינה נוספת  לכרטיס זה בקובץ אב",
  10154: "סכום טעינה אינו זהה לסכום מוגדר בקובץ אב לכרטיס זה",
  10155: "סכום לטעינה גבוה מתקרת טעינה. (קובץ אב)",
  10156: "סכום הזיכוי + זיכויים קודמים היום, גבוה מתקרת זיכויים",
  10157: "סכום טעינה  + זיכויית קודמים היום, גבוה מתקרת זיכויים",
  10158: "אין לבצע סטנדאין לעסקה ללא ערוץ 2, בסכום גבוה מ 400 ש'ח",
  10159: "אין לבצע סטנדאין לכרטיס צעיר של ישראכרד",
  10160: "שגיאה פנימית: חסרה רשומה לזיכוי",
  10161: "קובץ זיכוי לא נמצא",
  10162: "חריגה מסכום יומי לזיכוי לספק זה",
  10163: "חריגה מסכום חודשי לזיכוי לספק זה",
  10164: "חריגה מסכום חד-פעמי מקסימאלי לזיכוי לספק זה",
  10220: "אין הרשאת סולק לעסקת טעינה/פריקה",
  10221: "אין הרשאת סולק לעסקת טעינה/פריקה בכרטיס שאינו נטען",
  10224: "אין הרשאת סולק לעסקת CASHBACK",
  10225: "אין הרשאת סולק לעסקת מזומן",
  10226: "אין הרשאת סולק לעסקת הוראת-קבע",
  10227: "אין הרשאת סולק לבקשה לאישור ללא עסקה (תשלום ראשון הו'ק)",
  10228: "אין הרשאת סולק לעסקת זיכוי",
  10229: "אין הרשאת סולק לעסקת חתימה לפי phone-auth",
  10230: "אין הרשאת סולק לעסקה טלפונית",
  10250: "סוג אשראי לא מתאים לכרטיס חיוב מיידי",
  10251: "אין הרשאת סולק לסוג אשראי רגיל",
  10252: "אין הרשאת סולק לסוג אשראי עדיף/ישראקרדיט",
  10253: "אין הרשאת סולק לסוג אשראי חיוב מיידי",
  10254: "אין הרשאת סולק לסוג אשראי קרדיט",
  10255: "אין הרשאת סולק לסוג אשראי תשלומים",
  10256: "סוג אשראי חיוב מיידי אינו מתאים לסוג הכרטיס",
  10300: "אין הרשאה בעסקת פריקה לסוג אשראי זה",
  10301: "אין הרשאה בעסקת CASHBACK לסוג אשראי זה",
  10302: "אין הרשאה בעסקת מזומן לסוג אשראי זה",
  10303: "אין הרשאה בעסקת טעינה לסוג אשראי זה",
  10304: "אין הרשאה בעסקת הוראת קבע לסוג אשראי זה",
  10305: "אין הרשאה למטבע שאינו שקל או דולר לסוג אשראי זה",
  10321: "אין הרשאה בעסקת הוראת קבע לכרטיס נטען או חיוב מיידי",
  10322: "אין הרשאה לעסקת טעינה בכרטיס חיוב מיידי",
  10323: "אין הרשאה בעסקת פריקה לכרטיס חיוב מיידי",
  10350: "מס תשלומים קטן ממס תשלומים מינימאלי לסוג אשראי קרדיט",
  10351: "מס תשלומים גדול ממס תשלומים מקסימאלי לסוג אשראי קרדיט",
  10352: "בדיקת סולק לסכום מינימאלי לסוג אשראי קרדיט",
  10353: "מס תשלומים גדול ממס תשלומים מקסימאלי לסוג אשראי תשלומים",
  10354: "אין הרשאה לסוג אשראי תשלומים בכרטיס שאינו נוכח",
  10400: "אין הרשאת סולק לעסקה בשקלים",
  10401: "אין הרשאת סולק לעסקה במטח",
  10402: "אין הרשאת סולק לעסקה ביחידות שאינן מטבע",
  10403: "אין תמיכה לסולק זה במטבע זה",
  10420: "אין הרשאה לעסקת CASHBACK בסוג כרטיס זה",
  10421: "סכום CASHBACK מעל המקסימום המותר בעסקת  CASHBACK",
  10422: "אין הרשאה לעסקת CASHBACK לא חכמה בכרטיסי מאסטרקארד",
  10423: "אין הרשאה לעסקת  CASHBACK בכרטיס  לא נוכח.",
  10424: "אין הרשאה לעסקת מזומן בכרטיסי מאסטרקארד",
  10425: "אין הרשאה לעסקת מזומן לא חכמה בכרטיסי ויזה",
  10426: "אין הרשאה לעסקת מזומן בכרטיס  לא נוכח.",
  10440: "אין לבצע סטנדאין למטבע שאינו שקל או דולר בכרטיס מקומי",
  10441: "אין לבצע סטנדאין למטבע שאינו יורו או לישט בכרטיס תייר",
  10442: "אין לבצע סטנדאין לאימות אישור לסולק זה",
  10450: "בדיקת סולק לסכום מקסימאלי לעסקה",
  10451: "בדיקת סולק לסכום מינימאלי לעסקה",
  10460: "אין הרשאת סולק ל FALLBACK",
  10481: "סוג עסקה לא מורשה במסוף הוראת קבע",
  10482: "אין הרשאה לעסקה עם ערוץ 2 (כרטיס נוכח) במסוף זה",
  10483: "אין הרשאה לעסקת פריקה במסוף זה",
  10484: "אין הרשאה לעסקת סלולאר",
  10500: "חסר IPAY-CODE בעסקת טעינה (שדה 58)",
  10501: "(TAG 54) CASHBACK ערך תשלום נוסף גדול מסכום עסקה בעסקת",
  10502: "חסר מספר תשלום בעסקת הוראת קבע",
  10503: "חסר מספר תשלומים או סכום כולל",
  10505: "חסר ORIG-UID בעסקת הוראת קבע לא תשלום ראשון (שדה 90)",
  10506: "שדה מאופס בעסקת CASHBACK (שדה 54)",
  10507: "ערך לא חוקי ORIG-UID בעסקת הוראת קבע (זהה לעסקה נוכחית)",
  10508: "חסר IPAY-NUMBER (שדה 58) בעסקת טעינה לא במטבע",
  10509: "חסר IPAY-NUMBER (שדה 58) בעסקת פריקה לא במטבע",
  10510: "חסר נתוני עסקת מקור (שדה 90) בעסקת ביטול",
  10511: "חסר נתוני עסקת מקור (שדה 90) בבקשת REVERSAL",
  10512: "שדה 60 (ASH-REASON) לא תואם לתדלוק דו-שלבי",
  10513: "עסקת זיכוי לא נתמכת בתדלוק דו-שלבי",
  10520: "חסר סוג אשראי (שדה 57)",
  10521: "חסר תת שדה עבור POS-DATA (שדה 61)",
  10522: "חסר MANUFACTOR-ID בשדה 115",
  10523: "חסר MANUFACTOR-USE בשדה 115",
  10524: "חסר מספר סידורי של המסוף בשדה 115",
  10530: "חסר ערוץ 2 (שדה 35) לפי ערך: מקור נתוני הכרטיס (שדה 22)",
  10600: "סוג כרטיס מיוחד אינו מורשה לסוג עסקה ,קיים בוקטור 80",
  10601: "אין הרשאת מנפיק לעסקה טלפונית",
  10602: "אין הרשאת מנפיק לעסקת אינטרנט",
  10603: "אין הרשאת מנפיק לעסקת סלולר",
  10604: "אין הרשאת מנפיק לעסקת טעינה",
  10605: "אין הרשאת מנפיק לעסקת טעינה בכרטיס שאינו נטען",
  10606: "אין הרשאת מנפיק לעסקת טעינה בכרטיס תייר",
  10607: "אין הרשאת מנפיק לסוג אשראי תשלומים",
  10608: "מס תשלומים גדול ממס תשלומים מקסימאלי לסוג אשראי תשלומים",
  10609: "אין הרשאת מנפיק לסוג אשראי קרדיט",
  10610: "אין הרשאת מנפיק לסוג אשראי קרדיט לעסקה במטבע זר",
  10611: "סוג אשראי לא מתאים לכרטיס חיוב מיידי",
  10612: "אין הרשאת מנפיק לסוג אשראי חיוב מיידי",
  10613: "אין הרשאת מנפיק לסוג אשראי חיוב מיידי לעסקה במטבע זר",
  10614: "אין הרשאת מנפיק לסוג אשראי עדיף/ישראקרדיט",
  10615: "אין הרשאת מנפיק לסוג אשראי עדיף/ישראקרדיט לעסקה במטבע זר",
  10616: "אין הרשאת מנפיק לדחיית תשלום",
  10617: "אין הרשאת מנפיק לדחיית תשלום לעסקה במטבע זר",
  10618: "אין הרשאת מנפיק לסוג אשראי רגיל",
  10619: "אין הרשאת מנפיק לסוג אשראי רגיל לעסקה במטבע זר",
  10700: "אין סטנדאין לכרטיס חכם של תייר",
  10701: "פניה לתהליך EMV נכשלה",
  10702: "ARQC שגוי",
  10703: "בדיקת ARQC נכשלה בתשתית",
  10720: "אימות בעל הכרטיס נכשל",
  10721: "אימות קוד סודי עי הכרטיס נכשל",
  10722: "אין אפשרות להקיש קוד סודי במכשיר, ונדרש עי הכרטיס",
  10723: "לא הוקש קוד סודי, ונדרש עי הכרטיס",
  10724: "עבר את מספר הנסיונות המותר לקוד סודי שגוי בכרטיס",
  10725: "אפליקציה במסוף פג תוקף",
  10726: "DDA שגוי",
  10727: "בקשה לאישור יזומה עי הסוחר – אין סטנדאין",
  11010: "סכום עסקאות לא תואם",
  11020: "מסר ACK עם קוד יישום שגוי",
  11021: "מסר בקשה לדוח תקשורת באורך לא חוקי",
  11022: "נכשל בשמירת דוח תקשורת בקובץ",
  11023: "לא נמצא דוח תקשורת מתאים לבקשה",
  11024: "שדה RETAILER-ID לא חוקי בבקשה לדוח תקשורת",
  11030: "שגיאה בקריאת זמן הקובץ",
  11031: "קובץ עסקאות השתנה במהלך השידור",
  11032: "קובץ לוג השתנה במהלך השידור",
  11040: "מסוף לא מוגדר כרב ספק. אין אפשרות להמשך תקשורת",
  11050: "קובץ CONFIG של פרמטרים ל PINPAD ארוך מדי",
  11051: "קובץ בינים של פרמטרים ל PINPAD ארוך מדי",
  11052: "קובץ מפתחות CA של פרמטרים ל PINPAD ארוך מדי",
  12001: "ערך לא חוקי לשדה ISO",
  12002: "בירור יתרה שייך ל MTI 100 בלבד",
  12003: "ערך לא חוקי לשדה MTI",
  12004: "ערך לא חוקי לתת שדה ISO",
  12005: "מסר ISO לא חוקי",
  12006: "שדה חובה חסר ב ISO",
  12007: "חסר שדה חובה לסוג פעולה",
  12008: "אורך מסר לא חוקי",
  13101: "מסוף שלח בקשת ניתוק לא תקינה POSFE",
  13102: "ניתוק בין POSP ל POSFE  י POSFE",
  13103: "י TIMEOUT בתשובה למסוף  POSFE",
  13104: "ה SOCKET למסוף נעלם  POSFE",
  13105: "י TIMEOUT בהמתנה למסוף  POSFE",
  13106: "שגיאת פרוטוקול מהמסוף  POSFE",
  13107: "הקשר למסוף כבר לא קיים בזמן שליחת התשובה  POSFE",
  13108: "י POSFE במצב של SHUTDOWN י  POSFE",
  13109: "הקשר למסוף כבר לא קיים בזמן המתנה לתשובה  POSFE",
  14001: "חסרה רשומת CCINFO בקובץ PARAMS",
  14002: "מספר סולקים אינו בין 1 ל 10 ברשומת CCINFO בקובץ PARAMS",
  15001: "חסום. החרם כרטיס",
  15002: "גנוב. החרם כרטיס",
  15003: "התקשר לחברת האשראי",
  15004: "עסקה לא אושרה",
  15005: "כרטיס מזויף. החרם",
  15006: "דחה עסקה: cvv2 שגוי",
  15007: "דחה עסקה: cavv/ucaf שגוי",
  15008: "דחה עסקה: נתוני כתובת שגויים",
  15009: "דחה עסקה: הכרטיס אינו בתוקף",
  15010: "אישור חלקי",
  15011: "דחה עסקה:חוסר בנק'/כוכבים/מיילים/הטבה אחרת",
  15012: "הכרטיס לא מורשה במסוף",
  15013: "דחייה. קוד יתרה שגוי",
  15014: "דחייה.כרטיס לא משויך לרשת",
  15015: "דחייה.נתק בתקשורת",
  15016: "דחייה.אין הרשאה לסוג מטבע",
  15017: "דחייה. אין הרשאה לסוג אשראי בעסקה",
  15026: "דחה עסקה: תז שגוי",
  20000: "הצלחה",
  20001: "כרטיס חסום",
  20002: "גנוב החרם כרטיס",
  20003: "התקשר לחברת האשראי",
  20004: "העסקה לא אושרה",
  20005: "כרטיס מזוייף החרם",
  20006: "דחה עסקה: cvv2/id שגוי",
  20007: "דחה עסקה: cavv/ucaf שגוי",
  20008: "דחה עסקה: כתובת שגויה",
  20009: "דחיה: נתק בתקשורת",
  20010: "אישור חלקי",
  20011: "דחה עסקה: חוסר בנקודות/כוכבים/מיילים/הטבה אחרת",
  20012: "הכרטיס לא מורשה במסוף",
  20013: "דחה בקשה. קוד יתרה שגוי",
  20014: "דחיה. כרטיס לא משוייך לרשת",
  20015: "דחה עסקה: הכרטיס אינו בתוקף",
  20016: "דחיה-אין הרשאה לסוג מטבע",
  20017: "דחייה-אין הרשאה לסוג אשראי בעסקה",
  20026: "דחה עסקה: תז שגוי",
  20041: "ישנה חובת יציאה לשאילתא בגין תקרה בלבד לעסקה עם פרמטר J2",
  20042: "ישנה חובת יציאה לשאילתא לא רק בגין תקרה, לעסקה עם פרמטר J2",
  20051: "חסר קובץ וקטור 1",
  20052: "חסר קובץ וקטור 4",
  20053: "חסר קובץ וקטור 6",
  20055: "חסר קובץ וקטור 11",
  20056: "חסר קובץ וקטור 12",
  20057: "חסר קובץ וקטור 15",
  20058: "חסר קובץ וקטור 18",
  20059: "חסר קובץ וקטור 31",
  20060: "חסר קובץ וקטור 34",
  20061: "חסר קובץ וקטור 41",
  20062: "חסר קובץ וקטור 44",
  20063: "חסר קובץ וקטור 64",
  20064: "חסר קובץ וקטור 80",
  20065: "חסר קובץ וקטור 81",
  20066: "חסר קובץ וקטור 82",
  20067: "חסר קובץ וקטור 83",
  20068: "חסר קובץ וקטור 90",
  20069: "חסר קובץ וקטור 91",
  20070: "חסר קובץ וקטור 92",
  20071: "חסר קובץ וקטור 93",
  20073: "חסר קובץ PARAM_3_1",
  20074: "חסר קובץ PARAM_3_2",
  20075: "חסר קובץ PARAM_3_3",
  20076: "חסר קובץ PARAM_3_4",
  20077: "חסר קובץ PARAM_361",
  20078: "חסר קובץ PARAM_363",
  20079: "חסר קובץ PARAM_364",
  20080: "חסר קובץ PARAM_61",
  20081: "חסר קובץ PARAM_62",
  20082: "חסר קובץ PARAM_63",
  20083: "חסר קובץ CEIL_41",
  20084: "חסר קובץ CEIL_42",
  20085: "חסר קובץ CEIL_43",
  20086: "חסר קובץ CEIL_44",
  20087: "חסר קובץ DATA",
  20088: "חסר קובץ JENR",
  20089: "חסר קובץ Start",
  20101: "חסרה כניסה בוקטור 1",
  20103: "חסרה כניסה בוקטור 4",
  20104: "חסרה כניסה בוקטור 6",
  20106: "חסרה כניסה בוקטור 11",
  20107: "חסרה כניסה בוקטור 12",
  20108: "חסרה כניסה בוקטור 15",
  20110: "חסרה כניסה בוקטור 18",
  20111: "חסרה כניסה בוקטור 31",
  20112: "חסרה כניסה בוקטור 34",
  20113: "חסרה כניסה בוקטור 41",
  20114: "חסרה כניסה בוקטור 44",
  20116: "חסרה כניסה בוקטור 64",
  20117: "חסרה כניסה בוקטור 81",
  20118: "חסרה כניסה בוקטור 82",
  20119: "חסרה כניסה בוקטור 83",
  20120: "חסרה כניסה בוקטור 90",
  20121: "חסרה כניסה בוקטור 91",
  20122: "חסרה כניסה בוקטור 92",
  20123: "חסרה כניסה בוקטור 93",
  20141: "חסרה כניסה מתאימה בקובץ פרמטרים 3.2",
  20142: "חסרה כניסה מתאימה בקובץ פרמטרים 3.3",
  20143: "חסרה כניסה בקובץ תחומי מועדון 3.6.1",
  20144: "חסרה כניסה בקובץ תחומי מועדון 3.6.3",
  20145: "חסרה כניסה בקובץ תחומי מועדון 3.6.4",
  20146: "חסרה כניסה בקובץ תקרות לכרטיסי PL 4.1",
  20147: "חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 0 4.2",
  20148: "חסרה כניסה בקובץ תקרות לכרטיסים ישראלים שאינם PL שיטה 1 4.3",
  20149: "חסרה כניסה בקובץ תקרות לכרטיסי תייר 4.4",
  20150: "חסרה כניסה בקובץ כרטיסים תקפים ישראכרט",
  20151: "חסרה כניסה בקובץ כרטיסים תקפים כאל",
  20152: "חסרה כניסה בקובץ כרטיסים תקפים מנפיק עתידי",
  20182: "שגיאה בערכי וקטור 4",
  20183: "שגיאה בערכי וקטור 6/12",
  20186: "שגיאה בערכי וקטור 18",
  20187: "שגיאה בערכי וקטור 34",
  20188: "שגיאה בערכי וקטור 64",
  20190: "שגיאה בערכי וקטור 90",
  20191: "נתונים לא תקינים בוקטור הרשאות מנפיק",
  20192: "נתונים לא ולידים בסט הפרמטרים",
  20193: "נתונים לא ולידים בקובץ פרמטרים ברמת מסוף",
  20300: "אין הרשאה לסוג עסקה – הרשאת סולק",
  20301: "אין הרשאה למטבע – הרשאת סולק",
  20303: "אין הרשאת סולק לביצוע עסקה כאשר הכרטיס לא נוכח",
  20304: "אין הרשאה לאשראי – הרשאת סולק",
  20308: "אין הרשאה להצמדה – הרשאת סולק",
  20309: "אין הרשאת סולק לאשראי במועד קבוע",
  20310: "אין הרשאה להקלדת מספר אישור מראש",
  20311: "אין הרשאה לבצע עסקאות לקוד שירות 587",
  20312: "אין הרשאת סולק לאשראי דחוי",
  20313: "אין הרשאת סולק להטבות",
  20314: "אין הרשאת סולק למבצעים",
  20315: "אין הרשאת סולק לקוד מבצע ספציפי",
  20316: "אין הרשאת סולק לעסקת טעינה",
  20317: "אין הרשאת סולק לטעינה/פריקה בקוד אמצעי התשלום בשילוב קוד מטבע",
  20318: "אין הרשאת סולק למטבע בסוג אשראי זה",
  20319: "אין הרשאת סולק לטיפ",
  20341: "אין הרשאה לעסקה – הרשאת מנפיק",
  20342: "אין הרשאה למטבע – הרשאת מנפיק",
  20343: "אין הרשאת מנפיק לביצוע עסקה כאשר הכרטיס לא נוכח",
  20344: "אין הרשאה לאשראי – הרשאת מנפיק",
  20348: "אין הרשאה לביצוע אישור בקשה יזומה עי קמעונאי",
  20349: "אין הרשאה מתאימה לביצוע בקשה לאישור ללא עסקה J5",
  20350: "אין הרשאת מנפיק להטבות",
  20351: "אין הרשאת מנפיק לאשראי דחוי",
  20352: "אין הרשאת מנפיק לעסקת טעינה",
  20353: "אין הרשאת מנפיק לטעינה/פריקה בקוד אמצעי התשלום",
  20354: "אין הרשאת מנפיק למטבע בסוג אשראי זה",
  20381: "אין הרשאה לבצע עסקת contactless מעל סכום מרבי",
  20382: "במסוף המוגדר כשירות עצמי ניתן לבצע רק עסקאות בשירות עצמי",
  20384: "מסוף מוגדר כרב ספק/מוטב-חסר מספר ספק/מוטב",
  20385: "במסוף המוגדר כמסוף סחר אלקטרוני חובה להעביר eci",
  20401: "מספר התשלומים גדול מערך שדה מספר תשלומים מקסימלי",
  20402: "מספר התשלומים קטן מערך שדה מספר תשלומים מינימלי",
  20403: "סכום העסקה קטן מערך שדה סכום מינימלי לתשלום",
  20404: "לא הוזן שדה מספר תשלומים",
  20405: "חסר נתון סכום תשלום ראשון קבוע",
  20406:
    "סהכ סכום העסקה שונה מסכום תשלום ראשון + סכום תשלום קבוע * מספר תשלומים",
  20408: "ערוץ 2 קצר מ37 תוים",
  20410: "דחיה מסיבת dcode",
  20414: "בעסקה עם חיוב בתאריך קבוע הוכנס תאריך מאוחר משנה מבצוע העסקה",
  20415: "הוזנו נתונים לא תקינים",
  20416: "תאריך תוקף לא במבנה תקין",
  20417: "מספר מסוף אינו תקין",
  20418: "חסרים פרמטרים חיוניים",
  20419: "שגיאה בהעברת מאפיין clientInputPan",
  20420: "מספר כרטיס לא ולידי-במצב של הזנת ערוץ 2 בעסקה ללא כרטיס נוכח",
  20421: "שגיאה כללית-נתונים לא ולידים",
  20422: "שגיאה בבניית מסר ISO",
  20424: "שדה לא נומרי",
  20425: "רשומה כפולה",
  20426: "הסכום הוגדל לאחר ביצוע בדיקות אשראית",
  20428: "חסר קוד שירות בכרטיס",
  20429: "כרטיס אינו תקף לפי קובץ כרטיסים תקפים",
  20431: "שגיאה כללית",
  20432: "אין הרשאה להעברת כרטיס דרך קורא מגנטי",
  20433: "חיוב להעביר ב PinPad",
  20434: "אסור להעביר כרטיס במכשיר ה- PinPad",
  20435: "המכשיר לא מוגדר להעברת כרטיס מגנטי CTL",
  20436: "המכשיר לא מוגדר להעברת כרטיס EMV CTL",
  20439: "אין הרשאה לסוג אשראי לפי סוג עסקה",
  20440: "כרטיס תייר אינו מורשה לסוג אשראי זה",
  20441: "אין הרשאה לביצוע סוג עסקה-כרטיס קיים בוקטור 80",
  20442: "אין לבצע סטנדאין לאימות אישור לסולק זה",
  20443: "לא ניתן לבצע עסקת ביטול-כרטיס לא נמצא בקובץ תנועות הקיים במסוף",
  20445: "בכרטיס חיוב מיידי ניתן לבצע אשראי חיוב מיידי בלבד",
  20447: "מספר כרטיס שגוי",
  20448: "חיוב להקליד כתובת לקוח (מיקוד, מספר בית ועיר)",
  20449: "חיוב להקליד מיקוד",
  20450: "קוד מבצע מחוץ לתחום, צל 1-12",
  20451: "שגיאה במהלך בניית רשומת עסקה",
  20452: "בעסקת טעינה/פריקה/בירור יתרה חיוב להזין שדה קוד אמצעי תשלום",
  20453: "אין אפשרות לבטל עסקת פריקה 7.9.3",
  20455: "לא ניתן לבצע עסקת חיוב מאולצת כאשר נדרשת בקשה לאישור (למעט תקרות)",
  20456: "כרטיס נמצא בקובץ תנועות עם קוד תשובה 'החרם כרטיס'",
  20457: "בכרטיס חיוב מיידי מותרת עסקת חיוב רגילה/זיכוי/ביטול",
  20458: "קוד מועדון לא בתחום",
  20472: "בעסקת חיוב עם מזומן חיוב להזין סכום במזומן",
  20473: "בעסקת חיוב עם מזומן סכום המזומן צריך להיות קטן מסכום העסקה",
  20474: "עסקת איתחול בהוראת קבע מחייבת פרמטר J5",
  20475: "עסקת הק מחייבת אחד מהשדות: מספר תשלומים או סכום כולל",
  20476: "עסקת תורן בהוראת קבע מחייבת שדה מספר תשלום",
  20477: "עסקת תורן בהוראת קבע מחייבת מספר מזהה של עסקת איתחול",
  20478: "עסקת תורן בהוראת קבע מחייבת מספר אישור של עסקת איתחול",
  20479: "עסקת תורן בהוראת קבע מחייבת שדות תאריך וזמן עסקת איתחול",
  20480: "חסר שדה מאשר עסקת מקור",
  20481: "חסר שדה מספר יחידות כאשר העסקה מתבצעת בקוד אמצעי תשלום השונה ממטבע",
  20482: "בכרטיס נטען מותרת עסקת חיוב רגילה/זיכוי/ביטול/פריקה/טעינה/בירור יתרה",
  20483: "עסקה עם כרטיס דלק במסוף דלק חיוב להזין מספר רכב",
  20484:
    "מספר רכב המוקלד שונה ממספר הרכב הצרוב עג הפס המגנטי/מספר בנק שונה מ-012/ספרות שמאליות של מספר הסניף שונה מ-44",
  20485:
    "מספר רכב קצר מ-6 ספרות/שונה ממספר הרכב המופיע עג ערוץ 2 (פוזיציה 34 בערוץ 2) כרטיס מאפיין דלק של לאומי קארד",
  20486:
    "ישנה חובת הקלדת קריאת מונה (פוזיציה 30 בערוץ 2) כרטיס מאפיין דלק של לאומי קארד",
  20487: "רק במסוף המוגדר כדלק דו שלבי ניתן להשתמש בעדכון אובליגו",
  20489: "בכרטיס דלקן מותרת עסקת חיוב רגילה בלבד (עסקת ביטול אסורה)",
  20490: "בכרטיסי דלק/דלקן/דלק מועדון ניתן לבצע עסקאות רק במסופי דלק",
  20491:
    "עסקה הכוללת המרה חייבת להכיל את כל השדות conversion_amount_06, conversion_rate_09, conversion_currency_51",
  20492: "אין המרה על עסקאות שקל/דולר",
  20493:
    "בעסקה הכוללת הטבה חיוב שיהיו רק אחד מהשדות הבאים: סכום הנחה/מספר יחידות/אחוז ההנחה",
  20494: "מספר מסוף שונה",
  20495: "אין הרשאת fallback",
  20496: "לא ניתן להצמיד אשראי השונה מאשראי קרדיט/תשלומים",
  20497: "TBD",
  20498: "TBD",
  20500: "TBD",
  20504: "TBD",
  20505: "TBD",
  20506: "TBD",
  20507: "TBD",
  20512: "TBD",
  20551: "TBD",
  20552: "TBD",
  20553: "TBD",
  20554: "TBD",
  20555: "TBD",
  20556: "TBD",
  20557: "TBD",
  20558: "TBD",
  20559: "TBD",
  20560: "TBD",
  20561: "TBD",
  20562: "TBD",
  20563: "TBD",
  20564: "TBD",
  20565: "TBD",
  20566: "TBD",
  20567: "TBD",
  20777: "פעולה הושלמה בהצלחה",
  20997: "שגיאה כללית",
  20998: "שגיאה בקובץ חסומים",
  21001: "העבר/הצג/הכנס כרטיס",
  21002: "העבר/הצג כרטיס",
  21003: "העבר/הכנס כרטיס",
  21004: "הכנס כרטיס לקורא חכם",
  21005: "הכנס/הצג כרטיס",
  21006: "הצג כרטיס",
  21007: "נא הוצא כרטיס",
  21008: "העבר שנית",
  21009: "השתמש בקורא פס מגנטי",
  21010: "המסוף לא רשאי לבצע עסקאות בכרטיס",
  21011: "אנא המתן השאר כרטיס",
  21012: "כרטיס לא תקין",
  21013: "כרטיס לא נתמך",
  21014: "קיימות עסקאות מאוד ישנות – נא בצע שידור",
  21015: "קובץ חסומים אינו מעודכן – נא בצע שידור",
  21016: "כרטיס לא מורשה לסוג אשראי",
  21017: "אין הרשאה לסוג מטבע",
  21018: "מספר כרטיס שגוי",
  21019: "הכרטיס לא מורשה במסוף",
  21020: "כרטיס לא מורשה לסוג העסקה",
  21021: "TBD",
  21022: "TBD",
  21023: "מנפיק לא קיים",
  21024: "מותג לא קיים",
  21025: "TBD",
  21026: "TBD",
  21027: "TBD",
  21028: "הקש 3 ספרות אחרונות בגב הכרטיס",
  21029: "TBD",
  21030: "נא הקש ת.ז. כולל ספרת ביקורת",
  21031: "TBD",
  21032: "TBD",
  21033: "TBD",
  21034: "TBD",
  21035: "TBD",
  21036: "TBD",
  21037: "TBD",
  21038: "TBD",
  21039: "TBD",
  21040: "TBD",
  21041: "TBD",
  21042: "TBD",
  21043: "TBD",
  21044: "TBD",
  21045: "TBD",
  21046: "TBD",
  21047: "TBD",
  21048: "TBD",
  21049: "TBD",
  21050: "TBD",
  21051: "TBD",
  21052: "TBD",
  21053: "TBD",
  21054: "מסוף לא ביצע הקמה",
  21055: "TBD",
  21056: "סולק לא קיים",
  21057: "סט פרמטרים שגוי",
  21059: "דחייה, כרטיס נטען",
  21060: "TBD",
  21061: "אין הרשאה לביצוע עסקה",
  21062: "TBD",
  21063: "TBD",
  22000: "שגיאה אפליקטיבית",
  22001: "דחיה. קוד ביצוע בכרטיס",
  26001: "מסוף כבר קיים",
  26002: "מס שידור לא נמצא",
  26003: "מסוף לא קיים",
  26004: "נדחה על ידי ולידציה מקומית",
  26005: "ערך לא חוקי",
  26006: "שדה חובה לא קיים",
  26007: "מסוף אינו רב ספק/מוטב, אך הבקשה מכילה נתוני רב ספק/מוטב",
  26008: "אין התאמה בין מספר מסוף בבקשה למספר מסוף בנתונים",
  26009: "אורך/פורמט שדה לא תקין",
  26010: "שדה לא חוקי בבקשה מסוג זה",
  26011: "לא ניתן לבצע כי השרת אינו משתף וקטורים",
  26012: "דחייה בגלל J0",
  26013: "ספרת ביקורת לא תקינה במספר הכרטיס",
  26103: "שגיאת התחברות",
  26104: "שגיאת התחברות (timeout)",
  26996: "פקודה לא חוקית לכתובת",
  26997: "המסוף בתהליך שידור",
  26998: "תקלת תקשורת לשבא",
  26999: "שגיאת מערכת",
  30408: "פס מגנטי אינו באורך 37",
};
