import styles from "./node-wrapper.module.css";
import GroupSvg from "../svgs/group.svg?react";
import React from "react";
import NodeWrapper from "./node-wrapper";
import { Handle } from "@xyflow/react";

export default function GroupNode(props) {
  return (
    <NodeWrapper {...props}>
      <Handle className={styles.handle} type='source' id={props.id} />
      <Handle className={styles.handle} type='target' id={props.id} />
      <div>
        <GroupSvg />
      </div>
      <div className={styles.label}>{props.data.name}</div>
    </NodeWrapper>
  );
}

export const GroupNodeSidebarHeader = (node) => {
  return (
    <div className={styles.header}>
      <GroupSvg /> {node.data.name}
    </div>
  );
};

export const GroupNodeSidebarSingle = (node) => {
  function renderValue(value, key) {
    if (key === "identities") {
      return value.map((identity) => identity.name).join(", ");
    }
    if (typeof value === "boolean") {
      return value ? "True" : "";
    }
    if (!value || value.toString().trim() === "") {
      return "-";
    }

    return value;
  }

  return (
    <>
      {GroupNodeSidebarHeader(node)}
      <hr />
      <div className={styles.tableWrapper}>
        <table>
          <tbody>
            {Object.entries(node.data).map(([key, value]) => (
              <tr key={key}>
                <td className={styles.tableKey}>{key}</td>
                <td>{renderValue(value, key)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
