import styles from "./invoices.module.css";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import useIsMounted from "ismounted";
import { WithRole } from "../../components/with-role";
import { getSocket } from "../../utils/globals";
import { handleSortClicked, renderSortIcon, sanitize } from "../../utils";
import { useNavigate } from "react-router-dom";
import InfoTooltip from "../../components/info-tooltip";
import { useAjax, useConfirm, useUser } from "../../utils/hooks";
import Message from "../../components/message";
import { SUPER_ADMIN } from "../../utils/user";
import CustomMenuItem from "../../components/custom-menu/item";
import CustomMenu from "../../components/custom-menu";
import { creditCardStatuses } from "../../utils/credit-card";
import Checkbox from "../../components/checkbox";
import {
  getInvoiceAmountFormatted,
  getInvoicePaymentMethod,
} from "../../utils/billing";
import ClickTable from "../../components/click-table";
import DreamButton from "../../components/dream-button";

const maxRowsInPage = 50;
let timerID;

const envOptions = [
  { label: "All", value: null },
  { label: "Development", value: "development" },
  { label: "Production", value: "production" },
];

function BillingInvoices({ miniMode = null }) {
  const ajax = useAjax();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const user = useUser();
  const socket = getSocket();
  const isMounted = useIsMounted();

  const [status, setStatus] = useState(undefined);
  const [typeToShow, setTypeToShow] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [selectedInvoicesIds, setSelectedInvoicesIds] = useState({});
  const [userFilter, setUserFilter] = useState("");

  const [invoiceDropdownOpen, setInvoiceDropdownOpen] = useState({});

  const [sort, setSort] = useState("invoice_due_date");
  const [env, setEnv] = useState(undefined);

  const typeOptions = useMemo(() => {
    const items = [
      {
        label: <Message id="deal-invoice" />,
        value: "deal",
      },
      {
        label: <Message id="tax-receipt-invoice" />,
        value: "tax-receipt",
      },
      {
        label: <Message id="refund-invoice" />,
        value: "refund",
      },
      {
        label: <Message id="negative-receipt" />,
        value: "receipt",
      },
    ];

    return items;
  }, []);

  const statusOptions = useMemo(() => {
    const items = [
      {
        label: <Message id="all" />,
        value: undefined,
      },
      {
        label: <Message id="open" />,
        value: "open",
      },
      {
        label: <Message id="closed" />,
        value: "closed",
      },
    ];

    return items;
  }, []);

  useEffect(() => {
    if (miniMode) {
      setUserFilter(miniMode.email);
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const filter = urlSearchParams.get("filter");

    if (filter) {
      setUserFilter(filter);
    }
  }, [miniMode]);

  useEffect(() => {
    if (typeOptions) {
      setTypeToShow(typeOptions[0]);
    }
  }, [typeOptions]);

  useEffect(() => {
    if (import.meta.env.NODE_ENV === "development") {
      setEnv(envOptions[1]);
    } else {
      setEnv(envOptions[0]);
    }
  }, []);

  const listInvoices = useCallback(
    (interval = 300) => {
      if (!typeToShow) {
        return;
      }

      clearTimeout(timerID);

      timerID = setTimeout(async () => {
        const params = {
          type: typeToShow.value,
          maxRowsInPage,
          userFilter,
          sort,
          status: status.value,
        };

        if (env && env.value) {
          params.env = env.value;
        }

        const data = await ajax("/invoices/list", params);

        if (!isMounted.current) {
          return;
        }

        if (data.result === "success") {
          setInvoices(data.invoices);
        }
      }, interval);
    },
    [ajax, typeToShow, userFilter, sort, isMounted, status, env]
  );

  useEffect(() => {
    listInvoices();
  }, [listInvoices]);

  const handleUpdateInvoicesSocket = useCallback(() => {
    listInvoices(1);
  }, [listInvoices]);

  useEffect(() => {
    if (!socket || user.role !== SUPER_ADMIN) {
      return;
    }

    socket.on("update-invoices", handleUpdateInvoicesSocket);

    return () => {
      socket.off("update-invoices", handleUpdateInvoicesSocket);
    };
  }, [listInvoices, socket, user.role, handleUpdateInvoicesSocket]);

  useEffect(() => {
    setSelectedInvoicesIds({});

    if (typeToShow && ["deal", "refund"].includes(typeToShow.value)) {
      setStatus(statusOptions[1]);
    } else {
      setStatus(statusOptions[0]);
    }
  }, [typeToShow, statusOptions]);

  useEffect(() => {
    function handleInvoiceReady({ invoiceID, invoiceUrl, urlKey }) {
      const invoice = invoices.find((item) => item._id === invoiceID);

      if (invoice) {
        invoice[urlKey] = invoiceUrl;
        setInvoices([...invoices]);
      }
    }

    if (!socket) {
      return;
    }

    socket.on("invoice-ready", handleInvoiceReady);

    return () => {
      socket.off("invoice-ready", handleInvoiceReady);
    };
  }, [socket, invoices]);

  function formatDate(date) {
    try {
      const retDate = format(new Date(date), "d/M/y");
      return retDate;
    } catch (err) {
      return "-";
    }
  }

  function handleInvoiceDropdownToggle(key) {
    invoiceDropdownOpen[key] = !invoiceDropdownOpen[key];
    setInvoiceDropdownOpen({ ...invoiceDropdownOpen });
  }

  function handleCheckboxChanged(invoiceID) {
    if (!invoiceID) {
      const allInvoicesSelected = areAllInvoicesSelected();

      const selectedInvoicesIds = invoices.reduce((object, item) => {
        object[item._id] = !allInvoicesSelected;
        return object;
      }, {});

      setSelectedInvoicesIds(selectedInvoicesIds);
    } else {
      selectedInvoicesIds[invoiceID] = !selectedInvoicesIds[invoiceID];
      setSelectedInvoicesIds({ ...selectedInvoicesIds });
    }
  }

  function areAllInvoicesSelected() {
    return (
      invoices &&
      Object.keys(selectedInvoicesIds).filter((k) => selectedInvoicesIds[k])
        .length === invoices.length
    );
  }

  const handleCreateDocFromDocClicked = useCallback(
    (invoice) => {
      const buttons = [
        { text: <Message id="deal" />, value: "deal" },
        // { text: <Message id="tax" />, value: "tax" },
        // { text: <Message id="receipt" />, value: "receipt" },
        { text: <Message id="tax-receipt" />, value: "tax-receipt" },
        { text: <Message id="cancel" />, color: "red", value: "cancel" },
      ];

      if (invoice.invoice_type === "deal") {
        buttons.splice(0, 1);
      }
      if (invoice.invoice_type === "tax") {
        buttons.splice(1, 1);
        buttons.splice(2, 1);
      }
      if (invoice.invoice_type === "receipt") {
        buttons.splice(2, 1);
        buttons.splice(2, 1);
        buttons.splice(2, 1);
      }

      confirm({
        title: <Message id="create-doc-from-doc" />,
        message: <Message id="create-doc-from-doc.which-one" />,
        buttons,
        beforeClose: (type) => {
          if (["cancel", "modal"].includes(type)) {
            return;
          }

          let url = `/billing/create-new-invoice?user_id=${invoice.user_id}&type=${type}&linked_invoice=${invoice._id}&link_type=link`;
          if (userFilter) {
            url += `&user_filter=${userFilter}`;
          }

          navigate(url);
        },
      });
    },
    [userFilter, confirm, navigate]
  );

  const handleDeleteDocumentClicked = useCallback(
    (invoice) => {
      confirm({
        title: <Message id="delete-document" />,
        message: (
          <Message
            id="delete-document.deal"
            values={{ b: (val) => sanitize(`<b>${val}</b>`) }}
          />
        ),
        beforeClose: async (state) => {
          if (state !== "button2") {
            return;
          }

          await ajax("/billing/invoices/delete", { invoiceID: invoice._id });
          await listInvoices();
        },
      });
    },
    [ajax, confirm, listInvoices]
  );

  const handleCancelDocumentClicked = useCallback(
    (invoice) => {
      let message;
      if (invoice.invoice_type === "tax") {
        message = <Message id="cancel-document.tax" />;
      } else if (invoice.invoice_type === "receipt") {
        message = <Message id="cancel-document.receipt" />;
      } else if (invoice.invoice_type === "tax-receipt") {
        if (invoice.linkedInvoices.length === 1) {
          message = <Message id="cancel-document.tax-receipt-step2" />;
        } else {
          message = <Message id="cancel-document.tax-receipt" />;
        }
      } else if (invoice.invoice_type === "refund") {
        message = <Message id="cancel-document.negative-receipt" />;
      } else {
        message = <Message id="cancel-document.deal" />;
      }

      confirm({
        title: <Message id="cancel-document" />,
        message,
        beforeClose: async (state) => {
          if (state !== "button2") {
            return;
          }

          if (invoice.invoice_type === "tax") {
            return navigate(
              `/billing/create-new-invoice?user_id=${invoice.user_id}&type=refund&linked_invoice=${invoice._id}&link_type=cancel`
            );
          }
          if (invoice.invoice_type === "receipt") {
            return navigate(
              `/billing/create-new-invoice?user_id=${invoice.user_id}&type=receipt&neg=1&linked_invoice=${invoice._id}&link_type=cancel`
            );
          }
          if (invoice.invoice_type === "tax-receipt") {
            if (invoice.linkedInvoices.length === 1) {
              return navigate(
                `/billing/create-new-invoice?user_id=${invoice.user_id}&type=receipt&neg=1&linked_invoice=${invoice._id}&link_type=cancel&tax_receipt_step=2`
              );
            } else {
              return navigate(
                `/billing/create-new-invoice?user_id=${invoice.user_id}&type=refund&linked_invoice=${invoice._id}&link_type=cancel&tax_receipt_step=1`
              );
            }
          }
          if (invoice.invoice_type === "refund") {
            return navigate(
              `/billing/create-new-invoice?user_id=${invoice.user_id}&type=receipt&neg=1&linked_invoice=${invoice._id}&link_type=negative`
            );
          }

          await ajax("/billing/invoices/close", { invoiceID: invoice._id });
          await listInvoices();
        },
      });
    },
    [confirm, ajax, listInvoices, navigate]
  );

  function renderInvoiceMenu(invoice, key) {
    const retArr = [];

    if (["deal"].includes(invoice.invoice_type)) {
      retArr.push(
        <CustomMenuItem
          key="create-doc-from-doc"
          onClick={() => handleCreateDocFromDocClicked(invoice)}
        >
          <Message id="create-doc-from-doc" />
        </CustomMenuItem>,
        <CustomMenuItem
          key="delete-document"
          color="red"
          onClick={() => handleDeleteDocumentClicked(invoice)}
        >
          <Message id="delete-document" />
        </CustomMenuItem>
      );
    }

    if (
      invoice.invoice_status === "open" &&
      ["tax", "receipt"].includes(invoice.invoice_type)
    ) {
      retArr.push(
        <CustomMenuItem
          key="create-doc-from-doc"
          onClick={() => handleCreateDocFromDocClicked(invoice)}
        >
          <Message id="create-doc-from-doc" />
        </CustomMenuItem>,
        <CustomMenuItem
          key="cancel-document"
          color="red"
          onClick={() => handleCancelDocumentClicked(invoice)}
        >
          <Message id="cancel-document" />
        </CustomMenuItem>
      );
    }

    if (
      ["tax-receipt"].includes(invoice.invoice_type) &&
      (invoice.invoice_status === "closed" || invoice.linkedInvoices.length < 2)
    ) {
      retArr.push(
        <CustomMenuItem
          key="cancel-document"
          color="red"
          onClick={() => handleCancelDocumentClicked(invoice)}
        >
          <Message id="cancel-document" />
        </CustomMenuItem>
      );
    }

    if (["refund"].includes(invoice.invoice_type)) {
      retArr.push(
        <CustomMenuItem
          key="create-negative-receipt"
          color="red"
          onClick={() => handleCancelDocumentClicked(invoice)}
        >
          <Message id="create-negative-receipt" />
        </CustomMenuItem>
      );
    }

    if (retArr.length === 0) {
      return null;
    }

    return (
      <>
        <CustomMenu
          isOpen={invoiceDropdownOpen[key]}
          toggle={() => handleInvoiceDropdownToggle(key)}
        >
          {retArr}
        </CustomMenu>
      </>
    );
  }

  function renderLastPaymentStatus(invoice) {
    if (
      !typeToShow ||
      typeToShow.value !== "deal" ||
      !invoice ||
      !invoice.lastPayment ||
      !invoice.lastPayment.provider_data ||
      invoice.lastPayment.provider_data.ashStatus === "0" ||
      !invoice.lastPayment.provider_data.ashStatus
    ) {
      return null;
    }
    return (
      <InfoTooltip
        content={
          !invoice.lastPayment.provider_data.ashStatus
            ? invoice.lastPayment.terminal_message
            : `${invoice.lastPayment.provider_data.ashStatus} - ${
                creditCardStatuses[invoice.lastPayment.provider_data.ashStatus]
              }`
        }
      >
        <div className={styles.badge}>
          <Message id="last-charge-failed" />
        </div>
      </InfoTooltip>
    );
  }

  function renderDot(invoice) {
    let color;

    if (invoice.invoice_status === "open") {
      color = styles.red;
    } else if (invoice.invoice_status === "closed") {
      color = styles.green;
    } else if (["canceled", "canceling"].includes(invoice.invoice_status)) {
      color = styles.orange;
    }

    return <div className={`${styles.dot} ${color}`}></div>;
  }

  function renderInvoices() {
    if (!invoices) {
      return null;
    }

    if (invoices.length === 0) {
      return (
        <tr>
          <td colSpan={100}>
            <Message id="no-rows" />
          </td>
        </tr>
      );
    }

    return invoices.map((invoice, key) => {
      return (
        <tr key={key}>
          {[SUPER_ADMIN].includes(user.role) && (
            <>
              <td>
                <Checkbox
                  checked={selectedInvoicesIds[invoice._id] || false}
                  onChange={() => handleCheckboxChanged(invoice._id)}
                />
              </td>
              <td>
                <a
                  href={`/clients/clients-list/${invoice.user_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {invoice.user ? invoice.user.email : "-"}
                </a>
              </td>
            </>
          )}
          <td>
            <div>
              <a href={invoice.url} target="_blank" rel="noreferrer">
                {invoice.invoice_provider_number}
              </a>
              {invoice.env === "production" ? "" : ` [${invoice.env}]`}
            </div>
            {invoice.linkedInvoice && invoice.linked_invoice_id && (
              <div className={styles.linkedInvoice}>
                <Message
                  id="linked-to-invoice"
                  values={{
                    number: invoice.linkedInvoice.invoice_provider_number,
                  }}
                />
              </div>
            )}
          </td>
          {typeToShow && ["deal", "refund"].includes(typeToShow.value) && (
            <td>
              <div className="elements-r-gutter">
                <Message id={invoice.invoice_status} />
                {renderDot(invoice)}
                {renderLastPaymentStatus(invoice)}
              </div>
            </td>
          )}
          <td>{formatDate(invoice.created_at)}</td>
          <td>{formatDate(invoice.invoice_due_date)}</td>
          <td>{getInvoiceAmountFormatted(invoice)}</td>
          <td>{getInvoicePaymentMethod(invoice)}</td>
          <td>
            {(import.meta.env.VITE_APP_ENV === "development" ||
              user.email === "afaf@dreamvps.com" ||
              user.role === SUPER_ADMIN) &&
              renderInvoiceMenu(invoice, key)}
          </td>
        </tr>
      );
    });
  }

  function renderContent() {
    return (
      <>
        <div className={styles.invoicesWrapper}>
          <ClickTable layout="auto" asPlaceholder={!invoices}>
            <thead>
              <tr>
                {[SUPER_ADMIN].includes(user.role) && (
                  <>
                    <th className="table-checkbox">
                      <Checkbox
                        checked={!!areAllInvoicesSelected()}
                        onChange={() => handleCheckboxChanged()}
                      />
                    </th>
                    <th>
                      <a
                        href="#"
                        onClick={(e) =>
                          handleSortClicked(e, "user.email", sort, setSort)
                        }
                      >
                        <Message id="billing-invoices.table.email" />
                        {renderSortIcon("user.email", sort)}
                      </a>
                    </th>
                  </>
                )}
                <th>
                  <Message id="billing-invoices.table.invoice" />
                </th>
                {typeToShow &&
                  ["deal", "refund"].includes(typeToShow.value) && (
                    <th>
                      <Message id="status" />
                    </th>
                  )}
                <th>
                  <a
                    href="#"
                    onClick={(e) =>
                      handleSortClicked(e, "invoice_date", sort, setSort)
                    }
                  >
                    <Message id="billing-invoices.table.invoice-date" />
                    {renderSortIcon("invoice_date", sort)}
                  </a>
                </th>
                <th>
                  <a
                    href="#"
                    onClick={(e) =>
                      handleSortClicked(e, "invoice_due_date", sort, setSort)
                    }
                  >
                    <Message id="billing-invoices.table.due-date" />
                    {renderSortIcon("invoice_due_date", sort)}
                  </a>
                </th>
                <th>
                  <Message id="billing-invoices.table.total" />
                </th>
                <th>
                  <Message id="billing-invoices.table.payment-method" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderInvoices()}</tbody>
          </ClickTable>
        </div>
      </>
    );
  }

  if (miniMode) {
    return (
      <>
        <div className={styles.createNewInvoiceMini}>
          <DreamButton
            color="green"
            href="/billing/create-new-invoice"
            as={`/billing/create-new-invoice?filter=${userFilter}&back-to-admin=1`}
          >
            <Message id="billing-invoices.create-new-invoice" />
          </DreamButton>
        </div>
        {renderContent()}
      </>
    );
  }

  return (
    <WithRole permission="billing">
      <div className={styles.wrapper}>
        <hr className={styles.hide} />
        {renderContent()}
      </div>
    </WithRole>
  );
}

BillingInvoices.propTypes = {
  miniMode: PropTypes.object,
};

export default BillingInvoices;
