import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Sidebar from "./sidebar";
import LockedUser from "../locked-user";
import { Outlet } from "react-router-dom";
import PlaceholderLayout from "../layouts/placeholder-layout";
import { useRoles, useUser } from "../../utils/hooks";
import Box from "../box";
import Message from "../message";
import { getFullName } from "../../utils/user";

function CloudLayout({ allowedCheck = false, children = null, asPlaceholder = false, fullWidth = false }) {
  const user = useUser();
  const { isAllowed } = useRoles();

  function renderNotAllowed() {
    let parentUser;

    if (user && Array.isArray(user.roles)) {
      const usr = user.roles.find((role) => role.parent_user_id === user.current_parent);

      if (usr) {
        parentUser = usr.parentUser;
      }
    }

    return (
      <div className={styles.notAllowed}>
        <Box>
          <Message id='general.not-allowed' />
          {parentUser ? getFullName(parentUser) : <Message id='general.not-allowed-administrator' />}
        </Box>
      </div>
    );
  }

  function renderChildren() {
    if (typeof user.status === "undefined" || user.status === "USER_STATUS_ACTIVE") {
      if (children) {
        return children;
      } else {
        return <Outlet />;
      }
    }

    return <LockedUser />;
  }

  function renderContent() {
    if (!allowedCheck || isAllowed(allowedCheck)) {
      return renderChildren();
    }

    return renderNotAllowed();
  }

  if (asPlaceholder) {
    return <PlaceholderLayout />;
  }

  function renderCompleteCompanyNameAccount() {
    return null;
  }

  return (
    <>
      {renderCompleteCompanyNameAccount()}
      <div className={styles.cloudLayout}>
        <Sidebar />
        <main className={styles.childrenWrapper}>
          <Header />
          <div className={`${styles.shrink} ${fullWidth ? styles.fullWidth : ""}`}>
            <div className={styles.container}>{renderContent()}</div>
          </div>
        </main>
      </div>
    </>
  );
}

CloudLayout.propTypes = {
  allowedCheck: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.any,
  asPlaceholder: PropTypes.bool,
};

export default CloudLayout;
