import styles from "./two-factor-authentication.module.css";

import React, { useState } from "react";
import TwoFactorAuthenticationModal from "../modals/two-factor-authentication";
import {
  useAjax,
  useAlert,
  useConfirm,
  useInitialDataFetching,
  useUser,
} from "../../utils/hooks";
import Message from "../message";
import DreamButton from "../dream-button";

export default function TwoFactorAuthentication() {
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();
  const confirm = useConfirm();
  const initialDataFetching = useInitialDataFetching();

  const [
    isTwoFactorAuthenticationModalOpen,
    setIsTwoFactorAuthenticationModalOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);

  async function handleDisableClicked() {
    const state = await confirm({
      title: <Message id="two-factor-authentication.disable.title" />,
      message: <Message id="two-factor-authentication.disable.content" />,
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    await ajax("/users/disable2FA");

    await initialDataFetching();
    setLoading(false);
  }

  function handleTwoFactorAuthenticationModalOpen() {
    setIsTwoFactorAuthenticationModalOpen(true);
  }

  async function handleTwoFactorAuthenticationModalClosed(state) {
    if (state) {
      alert({
        title: <Message id="two-factor-authentication.add-new-device" />,
        message: (
          <Message id="two-factor-authentication.add-new-device-successfully" />
        ),
        notification: true,
      });
    }

    setIsTwoFactorAuthenticationModalOpen(false);
  }

  if (!user._id) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {user.twoFA.activated ? (
        <>
          <div className={styles.buttonsWrapper}>
            <DreamButton
              disabled={loading}
              loading={loading}
              color="red"
              onClick={handleDisableClicked}
            >
              <Message id="general.disable" />
            </DreamButton>
            <DreamButton
              disabled={loading}
              color="white"
              onClick={handleTwoFactorAuthenticationModalOpen}
            >
              <Message id="two-factor-authentication.add-new-device" />
            </DreamButton>
          </div>

          <div>
            <Message
              id="two-factor-authentication.total-devices"
              values={{
                number: user.twoFA.activated_devices,
                b: (val) => `<b>${val}</b>`,
              }}
            />
          </div>
        </>
      ) : (
        <DreamButton
          disabled={loading}
          loading={loading}
          color="white"
          onClick={handleTwoFactorAuthenticationModalOpen}
        >
          <Message id="general.enable" />
        </DreamButton>
      )}

      <TwoFactorAuthenticationModal
        isOpen={isTwoFactorAuthenticationModalOpen}
        onClose={handleTwoFactorAuthenticationModalClosed}
      />
    </div>
  );
}
