import styles from "./index.module.css";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import validator from "validator";
import { useAjax, useAlert, useUser } from "../../../utils/hooks";
import Message from "../../message";
import KeyDetector from "../../key-detector";
import CustomText from "../../custom-text";
import DreamAlert from "../../dream-alert";
import DreamButton from "../../dream-button";

function EmailVerificationModal({ isOpen, onClose }) {
  const user = useUser();
  const alert = useAlert();
  const ajax = useAjax();

  const [email, setEmail] = useState("");
  const [hash, setHash] = useState(null);
  const [code, setCode] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setEmail("");
      setError(false);
      setLoading(false);
      setEmailSent(false);
    }
  }, [isOpen]);

  async function handleChangeClicked() {
    if (user.email === email) {
      return setError(<Message id="email-verification-modal.your-email" />);
    } else if (!validator.isEmail(email)) {
      return setError(<Message id="general.wrong-email" />);
    }

    setLoading(true);
    const data = await ajax("/users/changeEmail", { email });
    setLoading(false);

    if (data.result === "success") {
      setHash(data.hash);
      setEmailSent(true);
    } else {
      setError(<Message id="general.email-exists" />);
    }
  }

  async function handleConfirmClicked() {
    setError(false);

    setLoading(true);
    const data = await ajax("/users/changeEmailAction", { hash, code });
    setLoading(false);

    if (data.result === "success") {
      await alert({
        title: <Message id="email-verification.confirm-modal.title" />,
        message: <Message id="email-verification.confirm-modal.message" />,
      });

      location.reload();
    } else {
      setError(<Message id="general.wrong-code" />);
    }
  }

  function renderBodyBeforeEmail() {
    return (
      <>
        <KeyDetector className={styles.row} onKeyDetected={handleChangeClicked}>
          <Message id="email-verification-modal.content" />
          <CustomText
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </KeyDetector>

        {error && <DreamAlert alertStyle={"danger"}>{error}</DreamAlert>}
      </>
    );
  }

  function renderBodyAfterEmail() {
    return (
      <KeyDetector className={styles.row} onKeyDetected={handleConfirmClicked}>
        <Message
          id="email-verification-modal.after-email.message"
          tagName="span"
        />
        <CustomText value={code} onChange={(e) => setCode(e.target.value)} />

        {error && <DreamAlert alertStyle={"danger"}>{error}</DreamAlert>}
      </KeyDetector>
    );
  }

  function renderFooterBeforeEmail() {
    return (
      <>
        <DreamButton
          disabled={loading}
          color="gray"
          onClick={() => onClose(false)}
        >
          <Message id="general.cancel" />
        </DreamButton>
        <DreamButton
          disabled={!email || loading}
          loading={loading}
          color="purple"
          onClick={handleChangeClicked}
        >
          <Message id="general.send" />
        </DreamButton>
      </>
    );
  }

  function renderFooterAfterEmail() {
    return (
      <>
        <DreamButton
          disabled={loading}
          color="gray"
          onClick={() => onClose(false)}
        >
          <Message id="general.cancel" />
        </DreamButton>
        <DreamButton
          disabled={!code || loading}
          loading={loading}
          color="purple"
          onClick={handleConfirmClicked}
        >
          <Message id="general.confirm" />
        </DreamButton>
      </>
    );
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <Message id="email-verification-modal.title" />
      </ModalHeader>
      <ModalBody>
        {!emailSent && renderBodyBeforeEmail()}

        {emailSent && renderBodyAfterEmail()}
      </ModalBody>
      <ModalFooter>
        {!emailSent && renderFooterBeforeEmail()}

        {emailSent && renderFooterAfterEmail()}
      </ModalFooter>
    </Modal>
  );
}

EmailVerificationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EmailVerificationModal;
