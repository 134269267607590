import React from "react";
import NoItems from "..";
import DreamButton from "../../dream-button";
import Message from "../../message";
import PolicySchemaSvg from "../../../components/svgs/policy-schema.svg?react";
import { useParams } from "react-router-dom";

export default function NoZtna() {
  const { workspaceId } = useParams();

  return (
    <NoItems title="no-ztna-domain" description={"no-ztna-domain-desc"} image={<PolicySchemaSvg />}>
      <DreamButton to={`/workspaces/${workspaceId}/overview`}>
        <Message id="create-staging-env" />
      </DreamButton>
    </NoItems>
  );
}
