import styles from "./confirm-password.module.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../../components/login-layout";

export default function ConfirmPassword() {
  const message = useMessage();
  const ajax = useAjax();
  const alert = useAlert();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    if (!token || !token.trim()) {
      return navigate(`/user/login`);
    }
  }, [navigate]);

  async function handleVerifyClicked() {
    const url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    setError(false);

    if (password.trim() === "" || token.trim() === "") {
      return setError(<Message id="confirm-password.empty-field" />);
    }

    setLoading(true);
    const data = await ajax(`/users/verifyPassword`, {
      token: decodeURIComponent(token),
      password,
    });

    if (data.result === "success") {
      await alert({
        title: <Message id="confirm-password.title" />,
        message: <Message id="confirm-password.verification-succeeded" />,
        notification: true,
      });
      navigate(`/user/login`);
      return;
    }

    setLoading(false);
    setError(<Message id="confirm-password.wrong-password" />);
  }

  return (
    <LoginLayout>
      <div className={styles.register}>
        <div className={`mb8 ${styles.bold}`}>
          <Message id="confirm-password.title" />
        </div>
        <div className={`mb28 ${styles.normal}`}>
          <Message id="confirm-password.subtitle" />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleVerifyClicked}>
          <TextField
            icon="user"
            value={password}
            type="password"
            maxLength={100}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={message({ id: "general.password" })}
          />
        </KeyDetector>
        {error && <div className={`mb28 ${styles.errorWrapper}`}>{error}</div>}
        <div className="mb28">
          <DreamButton
            disabled={loading}
            loading={loading}
            icon="arrow"
            onClick={handleVerifyClicked}
            color="purple"
            className={styles.registerButton}
          >
            <Message id="confirm-password.verify" />
          </DreamButton>
        </div>
        <div className={styles.backWrapper}>
          <Link href={`/user/login`} to={`/user/login`}>
            <Message id="forgot-password.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
