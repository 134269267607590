import styles from "./parent-selector.module.css";

import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useAjax, useAlert, useUser } from "../../utils/hooks";
import Message from "../message";

export default function ParentSelector() {
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  async function handleChangeParent(parentID) {
    setLoading(true);

    const data = await ajax(`/users/changeWorkspace`, {
      parentID,
    });

    if (data.result === "error") {
      await alert({
        title: <Message id="error" />,
        message: <Message id="general.ip-not-authorized" />,
      });
      setLoading(false);
    } else {
      window.location.href = "/";
    }
  }

  if (!user.roles || user.roles.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <UncontrolledDropdown className="ml-2">
        <DropdownToggle
          disabled={loading}
          caret
          color="light"
          size="sm"
          className="language-button"
        >
          <span className="name">
            <Message id="parent-selector.working-under" />
            {user.current_parent ? (
              user.roles.find((r) => r.parent_user_id === user.current_parent)
                .parentUser.email
            ) : (
              <Message id="parent-selector.my-account" />
            )}
          </span>
        </DropdownToggle>
        <DropdownMenu className="mt-3" right>
          <DropdownItem
            key={-1}
            onClick={() => handleChangeParent(null)}
            disabled={!user.current_parent}
          >
            <Message id="parent-selector.my-account" />
          </DropdownItem>
          {user.roles.map((role, idx) => (
            <DropdownItem
              key={idx}
              onClick={() => handleChangeParent(role.parentUser._id)}
              disabled={user.current_parent === role.parentUser._id}
            >
              {role.parentUser.email}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
