import React from "react";
import styles from "./index.module.css";
import DreamButton from "../dream-button";
import { useNavigate } from "react-router-dom";

export default function BackButton({ toPath, title, text }) {
  const navigate = useNavigate();

  return (
    <DreamButton className={styles.wrapper} color='text' icon='arrow-left' onClick={() => navigate(toPath)} title={title}>
      {text}
    </DreamButton>
  );
}
