import React, { useState } from "react";
// import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useUser } from "../../utils/hooks";
import Message from "../message";
import { useEffect } from "react";
// import { ajax } from "../../utils";
// import { useMemo } from "react";
import CreateNewWorkspaceModal from "../modals/create-new-workspace";
import { useWorkspaces } from "../../utils/atoms";
//import { Fragment } from "react";

import DreamButton from "../dream-button";

export default function WorkspaceSelector() {
  const user = useUser();
  const { workspaces, updateWorkspaces } = useWorkspaces();

  //const [withOtherUsers, setWithOtherUsers] = useState(false);

  const [isCreateNewWorkspaceModalOpen, setIsCreateNewWorkspaceModalOpen] = useState(false);

  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateWorkspaces();
  }, [updateWorkspaces]);

  // useEffect(() => {
  //   const other = workspaces.find((workspace) => workspace.user_id !== user._id);

  //   setWithOtherUsers(!!other);
  // }, [user._id, workspaces]);

  // async function handleChangeWorkspace(workspaceID) {
  //   setLoading(true);

  //   await ajax("/workspace/switchToWorkspace", {
  //     workspaceID,
  //   });

  //   location.reload();
  // }

  function handleCreateNewWorkspaceClicked() {
    setIsCreateNewWorkspaceModalOpen(true);
  }

  function handleCreateNewWorkspaceModalClosed() {
    setIsCreateNewWorkspaceModalOpen(false);
  }

  // const currentWorkspace = useMemo(() => {
  //   if (!workspaces) {
  //     return null;
  //   }

  //   return workspaces.find((workspace) => user.current_workspace_id === workspace._id);
  // }, [user.current_workspace_id, workspaces]);

  // if (!workspaces || !currentWorkspace) {
  //   return null;
  // }

  return (
    <>
      <DreamButton icon='plus' color='white' onClick={handleCreateNewWorkspaceClicked}>
        <Message id='create-new' />
      </DreamButton>

      {/* <UncontrolledDropdown className='ml-2'>
        <DropdownToggle disabled={loading} caret color='light' size='sm'>
          <span className='name'>
            <Message id='current-workspace' />: {currentWorkspace.name}
          </span>
        </DropdownToggle>
        <DropdownMenu className='mt-3' right>
          {withOtherUsers && (
            <DropdownItem className={styles.unclickable}>
              <b>{user.email}</b>
            </DropdownItem>
          )}

          {workspaces.map((workspace, idx) => (
            <Fragment key={idx}>
              {idx > 0 && workspace.user_id !== workspaces[idx - 1].user_id && (
                <DropdownItem className={styles.unclickable}>
                  <b>{workspace.assigned_user.email}</b>
                </DropdownItem>
              )}

              <DropdownItem onClick={() => handleChangeWorkspace(workspace._id)} disabled={user.current_workspace_id === workspace._id}>
                {workspace.name}
              </DropdownItem>
            </Fragment>
          ))}
          <DropdownItem divider />
          <DropdownItem className={styles.asButton} onClick={handleCreateNewWorkspaceClicked}>
            <Message id='create-new' />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}

      <CreateNewWorkspaceModal isOpen={isCreateNewWorkspaceModalOpen} onClose={handleCreateNewWorkspaceModalClosed} />
    </>
  );
}
