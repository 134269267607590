import styles from "./index.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Message from "../../message";
import DreamButton from "../../dream-button";

function PlanDetailsModal({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <Message id="plan-details.title" />
      </ModalHeader>
      <ModalBody>
        <div></div>
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          loading={loading}
          color="purple"
          onClick={() => onClose(false)}
        >
          <Message id="close" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

PlanDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PlanDetailsModal;
