import styles from "./forgot-password.module.css";

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginLayout from "../../components/login-layout";
import isEmail from "validator/lib/isEmail";
import SmsBwSvg from "../../components/svgs/sms-bw.svg?react";
import EmailBwSvg from "../../components/svgs/email-bw.svg?react";
import EmailBox from "../../components/email-box";

import WhatsAppSvg from "../../components/svgs/whatsapp.svg?react";
import { useAjax, useSmsCounter } from "../../utils/hooks";
import { replaceNCharsWithAsterisks, sanitize } from "../../utils";
import Message from "../../components/message";
import CodeDigits from "../../components/code-digits";
import DreamAlert from "../../components/dream-alert";
import DreamButton from "../../components/dream-button";

let codeDigitsHelpers = {};

export default function ForgotPassword() {
  const ajax = useAjax();
  const navigate = useNavigate();
  const smsCounter = useSmsCounter();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(null);
  const [hash, setHash] = useState(null);

  const [pageState, setPageState] = useState("choose-method");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const email = urlSearchParams.get("email");

    if (isEmail(email)) {
      setEmail(email);
    } else {
      navigate(`/user/login`);
    }
  }, [navigate]);

  useEffect(() => {
    async function checkEmail() {
      const data = await ajax("/users/checkEmail", { email });

      if (data.user.phoneCode && data.user.phoneNumber) {
        const phone = replaceNCharsWithAsterisks(
          data.user.phoneNumber,
          data.user.phoneNumber.length - 3
        );

        setPhone(`${data.user.phoneCode}${phone}`);
      }
    }

    if (email) {
      checkEmail();
    }
  }, [ajax, email]);

  async function handleCodeToEmailClicked() {
    setLoading(true);

    const data = await ajax("/users/resetPassword", { email });
    setHash(data.hash);
    smsCounter.start();

    setPageState("code-to-email");

    setLoading(false);
  }

  async function handleSmsToNumberClicked(sendBy) {
    setLoading(true);

    const data = await ajax("/users/resetPassword", {
      email,
      withPhone: true,
      sendBy,
    });
    setHash(data.hash);
    smsCounter.start();
    setPageState("sms-to-number");
    setLoading(false);
  }

  async function handleNextClicked(code) {
    setLoading(true);

    const data = await ajax("/users/resetPasswordCheck", { code, hash });

    if (data.result === "success") {
      navigate(`/user/reset-password?token=${hash}&code=${code}`);
    } else {
      codeDigitsHelpers.reset();
      setError(<Message id="wrong-code" />);
      setLoading(false);
    }
  }

  function handleResendClicked(sendBy) {
    smsCounter.reset();
    codeDigitsHelpers.reset();

    if (pageState === "sms-to-number") {
      handleSmsToNumberClicked(sendBy);
    } else {
      handleCodeToEmailClicked();
    }
  }

  function renderFormBody() {
    if (pageState === "sms-to-number") {
      return (
        <div className={styles.waitingForCode}>
          <div className={styles.sendTo}>
            <Message
              id="sms-to-number.description"
              values={{
                phone: sanitize(`<b>${phone}</b>`),
                span: (val) => sanitize(`<span>${val}</span>`),
              }}
            />
          </div>

          <div className={`userForm`}>
            <CodeDigits
              className={styles.codeDigits}
              onDone={handleNextClicked}
              helpers={codeDigitsHelpers}
              autoFocus
            />
          </div>

          {error && <DreamAlert alertStyle={"danger"}>{error}</DreamAlert>}

          <div className={styles.loginWrapper}>
            {smsCounter.counter > 0 ? (
              <div className={styles.resend}>
                <Message
                  id="general.resend-code-counter"
                  values={{ counter: smsCounter.counter }}
                />
              </div>
            ) : (
              <div className={styles.resend}>
                {/* <Message id="general.dont-recive-code" /> */}

                <div>
                  <DreamButton
                    disabled={loading || smsCounter.counter > 0}
                    color="text"
                    onClick={() => handleResendClicked("sms")}
                    loading={loading}
                  >
                    <Message id="general.resend-code" />
                  </DreamButton>
                </div>

                <DreamButton
                  color="white"
                  onClick={() => handleResendClicked("whatsapp")}
                  loading={loading}
                >
                  <Message id="general.send-via-whatsapp" />
                  <WhatsAppSvg />
                </DreamButton>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (pageState === "code-to-email") {
      return (
        <div className={styles.codeToEmail}>
          <div className={styles.sendTo}>
            <Message
              id="code-to-email.description"
              values={{ email: sanitize(`<b>${email}</b>`) }}
            />
          </div>

          <div className={`userForm`}>
            <CodeDigits
              className={styles.codeDigits}
              onDone={handleNextClicked}
              helpers={codeDigitsHelpers}
              autoFocus
            />
          </div>

          {error && <DreamAlert alertStyle={"danger"}>{error}</DreamAlert>}

          <div className={styles.loginWrapper}>
            {smsCounter.counter > 0 ? (
              <div className={styles.resend}>
                <Message
                  id="general.resend-code-counter"
                  values={{ counter: smsCounter.counter }}
                />
              </div>
            ) : (
              <div className={styles.resend}>
                <Message id="general.dont-recive-code" />
                <DreamButton
                  disabled={loading || smsCounter.counter > 0}
                  color="text"
                  onClick={handleResendClicked}
                  loading={loading}
                >
                  <Message id="general.resend-code" />
                </DreamButton>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.options}>
        {phone && (
          <button
            className={`${styles.option} ${loading ? "disabled" : ""}`}
            onClick={() => handleSmsToNumberClicked("sms")}
          >
            <SmsBwSvg />
            <Message
              id="sms-to-number"
              values={{
                number: sanitize(phone),
                span: (number) => sanitize(`<span><b>${number}</b></span>`),
              }}
            />
          </button>
        )}
        <button
          className={`${styles.option} ${loading ? "disabled" : ""}`}
          onClick={handleCodeToEmailClicked}
        >
          <EmailBwSvg className={styles.emailSvg} />
          <Message id="code-to-email" />
        </button>
      </div>
    );
  }

  const layoutTitle = useMemo(() => {
    if (pageState === "sms-to-number") {
      return (
        <div>
          <Message id="forgot-password.title.sms-to-number" />
        </div>
      );
    }

    if (pageState === "code-to-email") {
      return <Message id="forgot-password.title.code-to-email" />;
    }

    return <Message id="forgot-password.title" />;
  }, [pageState]);

  const description = useMemo(() => {
    if (pageState === "choose-method") {
      return phone ? (
        <Message id="forgot-password.description" />
      ) : (
        <Message id="forgot-password.description.one" />
      );
    }

    return "";
  }, [pageState, phone]);

  return (
    <LoginLayout title={layoutTitle} description={description} logoutBtn>
      <div className={styles.form}>
        {pageState === "choose-method" && <EmailBox>{email}</EmailBox>}

        {renderFormBody()}
      </div>
    </LoginLayout>
  );
}
