import styles from "./index.module.css";

import React from "react";
import validator from "validator";
import { useWorkspace, useWorkspaces } from "../../../../utils/atoms";
import ClickTable from "../../../../components/click-table";
import Message from "../../../../components/message";
import DreamButton from "../../../../components/dream-button";
import { usePrompt } from "../../../../utils/hooks";
import { ajax } from "../../../../utils";

export default function ZtnaManagementAllowedAccess() {
  const { workspace } = useWorkspace();
  const { updateWorkspaces } = useWorkspaces();
  const prompt = usePrompt();

  function handleAddClicked() {
    prompt({
      title: <Message id='add-user-title' values={{ name: workspace.name }} />,
      message: <Message id='add-user-message' />,
      acceptOnlyValue: async (email) => {
        if (!validator.isEmail(email)) {
          return {
            status: false,
            reason: <Message id='errors.email' />,
          };
        }

        const data = await ajax("/workspace/assignUser", {
          workspaceID: workspace._id,
          email,
        });

        if (data.result === "error") {
          return {
            status: false,
            reason: <Message id={data.message} />,
          };
        }

        return { status: true };
      },
      beforeClose: async () => {
        await updateWorkspaces();
      },
    });
  }

  async function handleUnassignedUserClicked(userID) {
    await ajax("/workspace/unassignUser", {
      workspaceID: workspace._id,
      userID,
    });

    await updateWorkspaces();
  }

  if (!workspace) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h1 className='title'>
        <Message id='allowed-access-title' />
      </h1>
      <ClickTable>
        <thead>
          <tr>
            <th>
              <Message id='email' />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {workspace.assigned_users.length === 0 && (
            <tr>
              <td colSpan={100}>
                <Message id='no-rows' />
              </td>
            </tr>
          )}

          {workspace.assigned_users.map((assignedUser) => (
            <tr key={assignedUser._id}>
              <td>{assignedUser.email}</td>
              <td>
                <DreamButton onClick={() => handleUnassignedUserClicked(assignedUser._id)}>
                  <Message id='remove' />
                </DreamButton>
              </td>
            </tr>
          ))}
        </tbody>
      </ClickTable>

      <div>
        <DreamButton onClick={handleAddClicked}>
          <Message id='add' />
        </DreamButton>
      </div>
    </div>
  );
}
