import styles from "./index.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import LogoSvg from "../svgs/logo.svg?react";
import { useLogout, useUser } from "../../utils/hooks";
import Message from "../message";

function RegisterLayout({
  title,
  subtitle,
  description,
  step,
  backCustomer,
  children,
  className = "",
}) {
  const user = useUser();
  const logout = useLogout();

  const [loggingOut, setLoggingOut] = useState(false);

  async function handleLogoutClicked() {
    setLoggingOut(true);

    logout();
  }

  function renderInner() {
    return (
      <>
        <div className={`${styles.registerWrapper} ${styles[`step${step}`]}`}>
          <div className={styles.title}>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
          <div className={styles.description}>
            <h2>{description}</h2>
          </div>
          {!backCustomer && step !== 4 && step !== 5 && (
            <div className={`${styles.steps} ${styles[`step${step}`]}`}>
              <div
                className={`${styles.stepItem} ${
                  step >= 3 ? styles.selected : ""
                }`}
              >
                <div className={`${styles.step} `}>3</div>
                <span className={styles.stepText}>
                  <Message id="steps.step3" />
                </span>
              </div>
              <div className={styles.line}></div>
              <div
                className={`${styles.stepItem} ${
                  step >= 2 ? styles.selected : ""
                }`}
              >
                <div className={`${styles.step} `}>2</div>
                <span className={styles.stepText}>
                  <Message id="steps.step2" />
                </span>
              </div>
              <div className={styles.line}></div>
              <div
                className={`${styles.stepItem} ${
                  step >= 1 ? styles.selected : ""
                }`}
              >
                <div className={`${styles.step} `}>1</div>
                <span className={styles.stepText}>
                  <Message id="steps.step1" />
                </span>
              </div>
            </div>
          )}
          {children}
        </div>
        {/* <Footer /> */}
      </>
    );
  }

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.header}>
        <a href="#" onClick={handleLogoutClicked}>
          <LogoSvg className="logo" />
        </a>
        <div className={styles.flexBtns}>
          {user._id && (
            <a
              href="#"
              className={`${styles.logout} ${loggingOut ? "disabled" : ""}`}
              onClick={handleLogoutClicked}
            >
              <Message id="general.logout" />
            </a>
          )}
        </div>
      </div>
      {step !== 5 && renderInner()}
      {step === 5 && <div className={styles.step5Wrapper}>{renderInner()}</div>}
    </div>
  );
}

RegisterLayout.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  description: PropTypes.any,
  step: PropTypes.number,
  backCustomer: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default RegisterLayout;
