let socket = null;

export const getSocket = () => {
  return socket;
};

export const setSocket = (value) => {
  socket = value;
};
export const truncateToDecimalPlace = (num, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.floor(num * factor) / factor;
};
