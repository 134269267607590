import styles from "./summary.module.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import validator from "validator";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import GeneratePasswordModal from "../../../components/modals/generate-password";
import AddCreditCardModal from "../../../components/modals/add-credit-card";
import { getSymbolsForReactSelect } from "../../../utils/billing";
import { countries, getCountryByCode } from "../../../utils/countries";
import { useAjax, useAlert, useUser } from "../../../utils/hooks";
import { useConfirm } from "../../../utils/hooks";
import { useRoles } from "../../../utils/hooks";
import Message from "../../../components/message";
import { getFullName, SUPER_ADMIN } from "../../../utils/user";
import { sanitize } from "../../../utils";
import DreamButton from "../../../components/dream-button";
import CustomText from "../../../components/custom-text";
import KeyDetector from "../../../components/key-detector";
import Box from "../../../components/box";
import DreamPasswordStrengthBar from "../../../components/password-strength-bar";
import CustomReactSelect from "../../../components/custom-react-select";
import ClickTable from "../../../components/click-table";
import { useIntl } from "react-intl";

const currencies = getSymbolsForReactSelect();

const payWithOptions = [
  { label: "Credit card", value: "credit-card" },
  { label: "Masav", value: "masav" },
  { label: "Bank Transfer", value: "bank-transfer" },
  { label: "Free Of Charge", value: "free-of-charge" },
];

function ClientsListSummary({ user, reloadUser }) {
  const alert = useAlert();
  const confirm = useConfirm();
  const { isAllowed } = useRoles();
  const currentUser = useUser();
  const navigate = useNavigate();
  const { id } = useParams();
  const ajax = useAjax();
  const intl = useIntl();

  const registrationSteps = [-1, 1, 2, 3, -2].map((num) => ({
    label: <Message id={`clients-list-summary.registration-step-${num}`} />,
    value: num,
    key: num,
  }));
  const registrationStepsRef = useRef(registrationSteps);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ldOrphc, setLdOrphc] = useState("");
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [totalSmsSent, setTotalSmsSent] = useState("0");
  const [registerStep, setRegisterStep] = useState(false);
  const [notes, setNotes] = useState("");
  const [parentUser, setParentUser] = useState(null);
  const [uniqePackage, setUniqePackage] = useState(false);
  const [affiliate, setAffiliate] = useState("");

  const [workspaces, setWorkspaces] = useState(null);

  const [isGeneratePasswordModalOpen, setIsGeneratePasswordModalOpen] =
    useState(false);

  const [allUsers, setAllUsers] = useState([]);

  const [currency, setCurrency] = useState(false);
  const [payWith, setPayWith] = useState(null);
  const [nextPayDay, setNextPayDay] = useState(null);
  const [bankCode, setBankCode] = useState("");
  const [branchNumber, setBranchNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [clientIdentificationNumber, setClientIdentificationNumber] =
    useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  const [doNotRenewYearlyPackage, setDoNotRenewYearlyPackage] = useState(false);

  const [
    isCreditCardsManagementModalOpen,
    setIsCreditCardsManagementModalOpen,
  ] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUserByEmail = useCallback(async () => {
    const data = await ajax("/admin/getUserByEmail", {
      email: id,
    });

    if (data.result === "success") {
      navigate(`/clients/clients-list/${data.user._id}`);
    }
  }, [ajax, navigate, id]);

  useEffect(() => {
    if (validator.isEmail(id)) {
      getUserByEmail();
    }
  }, [getUserByEmail, id]);

  const fetchAllUsers = useCallback(async () => {
    const data = await ajax(`/users/getAllUsers`, {
      filter: { email: 1 },
      withMe: true,
    });

    if (!data.users) {
      setAllUsers([
        {
          label: <Message id="general.none" />,
          value: null,
          key: -1,
        },
      ]);
    } else {
      setAllUsers([
        {
          label: <Message id="general.none" />,
          value: null,
          key: -1,
        },
        ...data.users.map((user, idx) => ({
          label: user.email,
          value: user._id,
          key: idx,
        })),
      ]);
    }
  }, [ajax]);

  useEffect(() => {
    if (!user) {
      return;
    }

    setUniqePackage(user.uniqe_package || false);
    setEmail(user.email || "");
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setCompanyName(user.companyName || "");
    setLdOrphc(user.ld_or_phc || "");
    setCountry(
      user.country
        ? { label: getCountryByCode(user.country), value: user.country }
        : null
    );
    setCity(user.city || "");
    setAddress(user.address || "");
    setState(user.state || "");
    setPhoneCode(user.phoneCode || "");
    setPhoneNumber(user.phoneNumber || "");
    setTotalSmsSent(user.totalSmsSent ? user.totalSmsSent.toString() : "0");
    setRegisterStep(
      registrationStepsRef.current.find(
        (step) => step.value === user.registerStep
      )
    );

    setNotes(user.notes || "");

    setCurrency(
      user.currency
        ? currencies.find((c) => c && c.value === user.currency)
        : currencies[0]
    );

    setPayWith(
      user.pay_with
        ? payWithOptions.find((item) => item.value === user.pay_with)
        : payWithOptions[0]
    );

    setNextPayDay(new Date(user.next_pay_day || null));

    setBankCode((user.masav && user.masav.bankCode) || "");
    setBranchNumber((user.masav && user.masav.branchNumber) || "");
    setAccountNumber((user.masav && user.masav.accountNumber) || "");
    setClientIdentificationNumber(
      (user.masav && user.masav.clientIdentificationNumber) || ""
    );

    setPaypalEmail((user.paypal && user.paypal.email) || "");

    setDoNotRenewYearlyPackage(
      user.billing && user.billing.do_not_renew_yearly
        ? user.billing && user.billing.do_not_renew_yearly
        : false
    );
  }, [user]);

  useEffect(() => {
    if (currentUser.role === SUPER_ADMIN) {
      fetchAllUsers();
    }
  }, [currentUser.role, fetchAllUsers]);

  useEffect(() => {
    if (user && allUsers) {
      if (user.created_via_userid) {
        const parentUser = allUsers.find(
          (u) => u.value === user.created_via_userid
        );

        setParentUser(parentUser || allUsers[0]);
      } else {
        setParentUser(allUsers[0]);
      }
    }
  }, [allUsers, user]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const openCreditCardsManagement = urlSearchParams.get(
      "open-credit-cards-management"
    );
    if (openCreditCardsManagement) {
      setIsCreditCardsManagementModalOpen(true);
    }
  }, []);

  const listWorkspaces = useCallback(async () => {
    const data = await ajax("/workspace/list", { userID: user._id });

    setWorkspaces(data.workspaces);
  }, [ajax, user]);

  useEffect(() => {
    if (user?._id) {
      listWorkspaces();
    }
  }, [listWorkspaces, user]);

  async function handleUpdateClicked(withNotification = true) {
    const _email = email.trim().toLowerCase();
    const _password = password.trim();

    if (!validator.isEmail(_email)) {
      return setError(<Message id="general.wrong-email" />);
    } else if (_password && passwordScore <= 1) {
      return setError(<Message id="general.weak-password" />);
    }

    const axiosData = {
      userID: user._id,
      email: _email,
      password: _password,
      firstName,
      lastName,
      companyName,
      ldOrphc,
      country: country.value,
      city,
      billing: {
        ...user.billing,
        payment_gateway: "stripe",
        billing_gateway: "dreamvps",
        do_not_renew_yearly: doNotRenewYearlyPackage,
      },
      address,
      state,
      phoneCode,
      phoneNumber,
      totalSmsSent,
      registerStep: registerStep.value,
      notes,
      uniqe_package: uniqePackage,

      currency: currency.value,
      payWith: payWith.value,
      nextPayDay,
    };

    if (payWith?.value === "masav") {
      axiosData.masav = {
        bankCode,
        branchNumber,
        accountNumber,
        clientIdentificationNumber,
      };
    }

    if (payWith?.value === "bank-transfer") {
      axiosData.masav = {
        bankCode,
        branchNumber,
        accountNumber,
      };
    }

    if (payWith?.value === "paypal") {
      axiosData.paypal = {
        email: paypalEmail,
      };
    }

    setLoading(true);
    const data = await ajax(`/admin/editUser_deprecated`, axiosData);

    let additionalMessages = "";

    if (isAllowed("super-admin.clients.advance-changes")) {
      const data = await ajax(`/admin/editUserAdvance`, {
        userID: user._id,
        created_via_userid: parentUser.value,
        companyName,
        noG: true,
      });

      if (data.result === "error") {
        additionalMessages += `<br /><br /><b>אזהרה מחשבונית ירוקה:</b> ${data.errorMessage}`;
      }
    }

    if (data.greenInvoice?.result === "error") {
      additionalMessages += `<br /><br /><b>אזהרה מחשבונית ירוקה:</b> ${
        data.greenInvoice.errorCode === 1010
          ? "לקוח כבר קיים - יש לרשום שם אחר"
          : data.greenInvoice.errorMessage
      }`;
    }

    reloadUser.do();
    setLoading(false);

    if (!withNotification) {
      return;
    }

    await alert({
      title: <Message id="clients-list-summary.edit.title" />,
      message: (
        <Message
          id="clients-list-summary.edit.content"
          values={{ additionalMessages }}
        />
      ),
      notification: !additionalMessages,
    });
  }

  async function handleDeleteUserClicked() {
    const state = await confirm({
      title: <Message id="clients-list-summary.delete-user-modal-title" />,
      message: (
        <Message
          id="clients-list-summary.delete-user-modal-content"
          values={{ name: sanitize(getFullName(user)) }}
        />
      ),
    });

    if (state !== "button2") {
      return;
    }

    setLoading(true);
    const data = await ajax(`/admin/deleteUser`, {
      userID: user._id,
    });
    setLoading(false);

    if (data.result === "success") {
      navigate(`/clients/clients-list`);
    } else {
      await alert({
        title: <Message id="clients-list-summary.delete-user-failed" />,
        message: (
          <Message id="clients-list-summary.delete-user-failed-reason" />
        ),
      });
    }
  }

  async function handleDisable2FAclicked() {
    setLoading(true);

    await ajax("/users/disable2FA", { userID: user._id });

    user.twoFA.activated = false;

    setLoading(false);
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  async function handleCreditCardsManagementClicked() {
    setIsCreditCardsManagementModalOpen(true);
  }

  function handleCreditCardsManagementModalClosed() {
    setIsCreditCardsManagementModalOpen(false);
  }

  const primaryCard = useMemo(
    () =>
      payWith?.value === "credit-card" &&
      user?.creditCards.find((cc) => cc.primary),
    [user, payWith]
  );

  function renderPayWithForm() {
    if (!payWith) {
      return null;
    }

    if (payWith.value === "credit-card") {
      return (
        <div className={styles.creditCardWrapper}>
          {primaryCard && (
            <div className={styles.texts}>
              <div>
                <span>
                  <Message
                    id="clients-list-summary.current-primary"
                    values={{ digits: primaryCard.last4Digits }}
                  />
                </span>
              </div>
              <div>
                <span>
                  <Message
                    id="clients-list-summary.current-primary-expiration-date"
                    values={{
                      monthYear: `${primaryCard.cardMonth}/${primaryCard.cardYear}`,
                    }}
                  />
                </span>
              </div>
            </div>
          )}
          <div className={styles.buttonsWrapper}>
            <DreamButton
              color="light-purple"
              onClick={handleCreditCardsManagementClicked}
            >
              <Message id="credit-cards-management" />
            </DreamButton>
          </div>
        </div>
      );
    }

    if (payWith.value === "masav" || payWith.value === "bank-transfer") {
      return (
        <>
          <div className={styles.row}>
            <span>
              <Message id="clients-list-summary.bank-code" />
            </span>
            <CustomText
              value={bankCode}
              onChange={(e) => setBankCode(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <Message id="clients-list-summary.branch-number" />
            </span>
            <CustomText
              value={branchNumber}
              onChange={(e) => setBranchNumber(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          <div className={styles.row}>
            <span>
              <Message id="clients-list-summary.account-number" />
            </span>
            <CustomText
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              autoComplete="new-password"
            />
          </div>
          {payWith.value === "masav" && (
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.client-identification-number" />
              </span>
              <CustomText
                value={clientIdentificationNumber}
                onChange={(e) => setClientIdentificationNumber(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          )}
        </>
      );
    }

    if (payWith.value === "paypal") {
      return (
        <>
          <div className={styles.row}>
            <span>
              <Message id="clients-list-summary.paypal-email" />
            </span>
            <CustomText
              value={paypalEmail}
              onChange={(e) => setPaypalEmail(e.target.value)}
              autoComplete="new-password"
            />
          </div>
        </>
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <KeyDetector className={styles.boxes} onKeyDetected={handleUpdateClicked}>
        <div className={styles.column}>
          <Box title={<Message id="general-info" />}>
            {user && (
              <div className={styles.datesWrapper}>
                <div>
                  <Message
                    tagName="b"
                    id="clients-list-summary.registration-date"
                  />
                  : {format(new Date(user.created_at), "d/M/y")}
                </div>
                <div>
                  <Message tagName="b" id="clients-list-summary.last-login" />:{" "}
                  {format(new Date(user.last_login), "d/M/y HH:mm")}
                </div>
              </div>
            )}

            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.email" />
              </span>
              <CustomText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.password" />
              </span>
              <CustomText
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={intl.formatMessage({ id: "general.leave-blank" })}
                autoComplete="new-password"
              />
              <DreamButton
                className={styles.button}
                color="white"
                onClick={() => setIsGeneratePasswordModalOpen(true)}
              >
                <Message id="general.generate" />
              </DreamButton>
            </div>
            {password && (
              <div className={styles.row}>
                <span></span>
                <DreamPasswordStrengthBar
                  className={styles.passwordStrengthBarWrapper}
                  password={password}
                  onChangeScore={setPasswordScore}
                />
              </div>
            )}
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.first-name" />
              </span>
              <CustomText
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.last-name" />
              </span>
              <CustomText
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.company-name" />
              </span>
              <CustomText
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                autoComplete="new-password"
                disabled={
                  user?.billing?.green_invoice &&
                  user?.billing?.green_invoice[
                    `client_id_${import.meta.env.VITE_APP_ENV}`
                  ]
                    ? true
                    : false
                }
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.tax-id" />
              </span>
              <CustomText
                value={ldOrphc}
                onChange={(e) => setLdOrphc(e.target.value)}
                autoComplete="new-password"
                disabled={
                  user?.billing?.green_invoice &&
                  user?.billing?.green_invoice[
                    `client_id_${import.meta.env.VITE_APP_ENV}`
                  ] &&
                  user.registerStep === -1
                    ? true
                    : false
                }
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.country" />
              </span>
              <CustomReactSelect
                instanceId="clients-list-summary-country"
                options={Object.keys(countries).map((key) => ({
                  label: key,
                  value: countries[key],
                }))}
                value={country}
                onChange={(item) => setCountry(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.city" />
              </span>
              <CustomText
                value={city}
                onChange={(e) => setCity(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.address" />
              </span>
              <CustomText
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            {country?.value !== "IL" && (
              <div className={styles.row}>
                <span>
                  <Message id="clients-list-summary.state" />
                </span>
                <CustomText
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  autoComplete="new-password"
                />
              </div>
            )}
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.phone-number" />
              </span>
              <div className={styles.phoneWrapper}>
                <CustomText
                  className={styles.phoneCode}
                  value={phoneCode}
                  onChange={(e) => setPhoneCode(e.target.value)}
                  autoComplete="new-password"
                />
                <CustomText
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.affiliate" />
              </span>
              <CustomText
                value={affiliate}
                onChange={(e) => setAffiliate(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </Box>
          <Box title={<Message id="system-data" />}>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.registration-step" />
              </span>
              <CustomReactSelect
                options={registrationSteps}
                value={registerStep}
                onChange={(item) => setRegisterStep(item)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.total-sms-sent" />
              </span>
              <CustomText
                value={totalSmsSent}
                onChange={(e) => setTotalSmsSent(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.notes" />
              </span>
              <CustomText
                type="textarea"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.2fa-status" />
              </span>
              <Message
                id={
                  user?.twoFA.activated
                    ? "general.activated"
                    : "general.disabled"
                }
              />

              {user?.twoFA.activated && (
                <DreamButton
                  color="light-purple"
                  className={styles.disable2FA}
                  onClick={handleDisable2FAclicked}
                  disabled={loading}
                  loading={loading}
                >
                  <Message id="general.disable" />
                </DreamButton>
              )}
            </div>
          </Box>
        </div>
        <div className="elements-c-gutter">
          <Box title={<Message id="billing" />}>
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.pay-with" />
              </span>
              <CustomReactSelect
                options={payWithOptions}
                value={payWith}
                onChange={(item) => setPayWith(item)}
              />
            </div>
            {renderPayWithForm()}
            <div className={styles.row}>
              <span>
                <Message id="clients-list-summary.user-currency" />
              </span>
              <CustomReactSelect
                options={currencies}
                value={currency}
                onChange={(item) => setCurrency(item)}
              />
            </div>
            <div className={styles.row}>
              <span>
                <Message id="general.next-pay-day" />
              </span>
              <div className="elements-r-gutter">
                <DatePicker
                  wrapperClassName="select"
                  selected={nextPayDay}
                  onChange={(date) => setNextPayDay(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
          </Box>
          <Box title={<Message id="workspaces" />}>
            <ClickTable>
              <thead>
                <tr>
                  <th>
                    <Message id="name" />
                  </th>
                  <th>
                    <Message id="total-users" />
                  </th>
                  <th>
                    <Message id="cost-per-user" />
                  </th>
                  <th>
                    <Message id="billing-cycle" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {workspaces?.map((workspace) => (
                  <tr key={workspace._id}>
                    <td>{workspace.name}</td>
                    <td>{workspace.total_users}</td>
                    <td>${workspace.cost_per_user}</td>
                    <td>{workspace.billing_cycle}</td>
                  </tr>
                ))}
              </tbody>
            </ClickTable>
          </Box>
          {isAllowed("super-admin.clients.advance-changes") && (
            <Box title={<Message id="advanced-details" />}>
              <div className={styles.row}>
                <span>
                  <Message id="clients-list-summary.parent-user" />
                </span>
                <CustomReactSelect
                  options={allUsers}
                  value={parentUser}
                  onChange={(item) => setParentUser(item)}
                />
              </div>
            </Box>
          )}
        </div>
      </KeyDetector>

      {(!firstName || !lastName) && (
        <Box className={styles.buttonsBox}>
          <Message id="clients-list-summary.greeninvoice-warning" />
        </Box>
      )}

      {password && (
        <Box className={styles.buttonsBox}>
          <Message id="clients-list-summary.password-warning" />
        </Box>
      )}

      <Box className={styles.buttonsBox}>
        <div>
          {error && <div className={`error ${styles.error}`}>{error}</div>}

          <div className={styles.buttonsWrapper}>
            <DreamButton
              disabled={loading}
              loading={loading}
              color="light-purple"
              onClick={handleUpdateClicked}
            >
              <Message id="general.update" />
            </DreamButton>
            {currentUser?._id !== user?._id && (
              <DreamButton
                disabled={loading}
                loading={loading}
                color="light-purple"
                onClick={handleDeleteUserClicked}
              >
                <Message id="general.delete" />
              </DreamButton>
            )}
          </div>
        </div>
      </Box>

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
      {user && (
        <AddCreditCardModal
          isOpen={isCreditCardsManagementModalOpen}
          onClose={handleCreditCardsManagementModalClosed}
          user={user}
        />
      )}
    </div>
  );
}

ClientsListSummary.propTypes = {
  user: PropTypes.object,
  reloadUser: PropTypes.object,
};

export default ClientsListSummary;
