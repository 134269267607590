import styles from "./index.module.css";

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Message from "../message";

function GoogleAuthComplete({
  onChange,
  defaultValue = "",
  restrictedCountry = null,
  showRequired = false,
}) {
  const onChangeRef = useRef(onChange);

  const searchInputRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    function onload() {
      const params = {
        types: ["geocode"],
      };

      if (restrictedCountry) {
        params.componentRestrictions = { country: restrictedCountry };
      }

      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        searchInputRef.current,
        params
      );

      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current.getPlace();

        let countryComponent;
        let streetNumberComponent;
        let streetAddressComponent;
        let cityComponent;

        if (place.address_components) {
          countryComponent = place.address_components.find((component) =>
            component.types.includes("country")
          );

          streetNumberComponent = place.address_components.find((component) =>
            component.types.includes("street_number")
          );

          streetAddressComponent = place.address_components.find((component) =>
            component.types.includes("route")
          );

          cityComponent = place.address_components.find((component) =>
            component.types.includes("locality")
          );
        }

        const streetNumber = streetNumberComponent
          ? streetNumberComponent.long_name
          : null;
        const streetAddress = streetAddressComponent
          ? streetAddressComponent.long_name
          : null;
        const city = cityComponent ? cityComponent.long_name : null;
        const country = countryComponent ? countryComponent.short_name : null;

        onChangeRef.current({ streetNumber, streetAddress, city, country });
      });
    }

    if (
      document.querySelector(
        "script[src*='https://maps.googleapis.com/maps/api/js']"
      )
    ) {
      onload();
    } else {
      window.googleMapLoaded = () => {};

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAD_Ts6pH0f_9WH-6WHeOC1LB6L_4q3Du0&libraries=places&language=en&callback=googleMapLoaded";
      script.onload = onload;

      document.body.appendChild(script);
    }
  }, [restrictedCountry]);

  return (
    <>
      <label className={showRequired ? "required" : ""}>
        <Message id="general.address" />
      </label>
      <input
        type="text"
        ref={searchInputRef}
        placeholder=""
        className={styles.autocompleteTextWrapper}
        defaultValue={defaultValue}
      />
    </>
  );
}

GoogleAuthComplete.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  restrictedCountry: PropTypes.string,
  showRequired: PropTypes.bool,
};

export default GoogleAuthComplete;
