import styles from "./index.module.css";
import CrossSvg from "../svgs/cross.svg?react";

import React from "react";
import InfoAlert from "../info-alert";
import Message from "../message";
import validator from "validator";
import CustomText from "../custom-text";
import DreamButton from "../dream-button";
import { useState } from "react";
import DreamAlert from "../dream-alert";
import { ajax } from "../../utils";
import { useParams } from "react-router-dom";
import { useWorkspaces } from "../../utils/atoms";

export default function NewZtnaDomain() {
  const { updateWorkspaces } = useWorkspaces();

  const [subdomain, setSubdomain] = useState("");
  const { workspaceId } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleNextClicked() {
    setError(false);

    if (!validator.isFQDN(`${subdomain}.ztna.me`)) {
      setError(<Message id="invalid-domain" />);
      return;
    }

    setLoading(true);

    const data = await ajax("/ztna-domain/create", { subdomain, workspaceId });

    if (data.result === "error") {
      setError(<Message id={data.message} />);
      setLoading(false);
      return;
    }

    updateWorkspaces();
  }

  return (
    <div className={styles.wrapper}>
      <h1 className="title">
        <Message id="choose-your-ztna-ssl-domain" />
      </h1>
      <InfoAlert className={styles.infoAlert}>
        <CrossSvg />
        <Message id="ssl-will-be-created" />
      </InfoAlert>
      <div className={styles.textWrapper}>
        <Message id="enter-domain-address" />
        <div className={styles.subdomain}>
          <div className={styles.domain}>.ztna.me</div>
          <div>
            <CustomText className={styles.domainText} placeholder="subdomain" value={subdomain} onChange={(e) => setSubdomain(e.target.value)} />
          </div>
        </div>
      </div>
      {error && <DreamAlert alertStyle="danger">{error}</DreamAlert>}
      <div className={styles.buttons}>
        <DreamButton loading={loading} disabled={loading} onClick={handleNextClicked}>
          <Message id="create" />
        </DreamButton>
      </div>
    </div>
  );
}
