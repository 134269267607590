import styles from "./index.module.css";
import WindowsSvg from "../svgs/windows.svg?react";

import React from "react";
import Message from "../message";
import DreamButton from "../dream-button";
import { useState } from "react";
import { ajax, createLinkAndGo } from "../../utils";
import { useAlert } from "../../utils/hooks";
import { useCallback } from "react";
import { useRef } from "react";
import Box from "../box";
import CopyRow from "../copy-row";

const items = [
  {
    id: "windows-server",
    name: "Windows server agent v1.1",
    icon: <WindowsSvg />,
    packaging: "MSI",
    encryption: "OpenSSL",
    linkage: "Dynamic",
    checksum: {
      sha256: "uhkk3bLNWquhkk3bLNWq",
      sha1: "uhkk3bLNWquhkk3bLNWq",
      md5: "uhkk3bLNWquhkk3bLNWq",
    },
  },
];

export default function ZtnaDownloadServer() {
  const alert = useAlert();
  const alertRef = useRef(alert);

  const [downloadLink, setDownloadLink] = useState("");

  const getDownloadLink = useCallback(async (itemID) => {
    const data = await ajax("/server/download", { id: itemID });

    if (data.result === "error") {
      await alertRef.current({
        title: <Message id="general.error" />,
        message: <Message id="general.unexpected-error" />,
      });
      return;
    }

    return data.link;
  }, []);

  async function handleDownloadClicked(item) {
    const link = await getDownloadLink(item.id);

    if (link) {
      createLinkAndGo(link);
    }
  }

  async function handleCopyDownloadLinkClicked(item) {
    const link = await getDownloadLink(item.id);

    if (link) {
      setDownloadLink(link);
    }
  }

  return (
    <Box title="install-server" titleTag="h2">
      <div className={styles.boxes}>
        {items.map((item, key) => (
          <div key={key} className={styles.box}>
            <div className={styles.title}>
              {item.icon}
              {item.name}
            </div>
            <div className={styles.rows}>
              <div className={styles.row}>
                <span>Packaging:</span>
                <span>{item.packaging}</span>
              </div>
              <div className={styles.row}>
                <span>Encryption:</span>
                <span>{item.encryption}</span>
              </div>
              <div className={styles.row}>
                <span>Linkage:</span>
                <span>{item.linkage}</span>
              </div>
              <div className={styles.row}>
                <span>Checksum:</span>
                <span className={`${styles.row} ${styles.short}`}>
                  <span>sha256:</span>
                  <span>{item.checksum.sha256}</span>
                </span>
              </div>
              <div className={styles.row}>
                <span></span>
                <span className={`${styles.row} ${styles.short}`}>
                  <span>sha1:</span>
                  <span>{item.checksum.sha1}</span>
                </span>
              </div>
              <div className={styles.row}>
                <span></span>
                <span className={`${styles.row} ${styles.short}`}>
                  <span>md5:</span>
                  <span>{item.checksum.md5}</span>
                </span>
              </div>
            </div>
            <div className={styles.buttons}>
              <DreamButton onClick={() => handleDownloadClicked(item)}>
                <Message id="download" />
              </DreamButton>
              {downloadLink ? (
                <CopyRow text={downloadLink} toCopy={downloadLink} size="sm" />
              ) : (
                <DreamButton color="text" icon="link" className={styles.download} onClick={() => handleCopyDownloadLinkClicked(item)}>
                  <Message id="copy-download-link" />
                </DreamButton>
              )}
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
}
