import styles from "./index.module.css";
import "@xyflow/react/dist/style.css";

import React, { useEffect, useState, useRef, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Background, MiniMap, ReactFlow, useEdgesState, useNodesState, MarkerType } from "@xyflow/react";
import { WithRole } from "../../../../components/with-role";
import { ajax } from "../../../../utils/ajax";
import { settingsState } from "../../../../utils/atoms";
import Spinner from "../../../../components/spinner";
import DeviceNode from "../../../../components/react-flow/device-node";
import ResourceNode from "../../../../components/react-flow/resource-node";
import PolicyNode from "../../../../components/react-flow/policy-node";
import GroupNode from "../../../../components/react-flow/group-node";
import NetworkNode from "../../../../components/react-flow/network-node";
import { findNodesForEdge } from "../../../../utils/react-flow/edges";
import FiltersBar, { actionFilterOptions, resourceGroupsFilterOptions, statusFilterOptions, trustedFilterOptions } from "../../../../components/policy-schema/filters-bar";
import ActionsBar from "../../../../components/policy-schema/actions-bar";
import { useRecoilState } from "recoil";
import Sidebar from "../../../../components/react-flow/sidebar";
import { hideConnectedNodes, layoutElements } from "../../../../utils/react-flow/nodes";
import PolicyResourceEdge from "../../../../components/react-flow/policy-resource-edge";
import ServiceNode from "../../../../components/react-flow/service-node";
import ConnectorPolicyNode from "../../../../components/react-flow/connector-policy-node";
import ConnectorNode from "../../../../components/react-flow/connector-node";
import IdentityNode from "../../../../components/react-flow/identity-node";
import HostNode from "../../../../components/react-flow/host-node";
import WorkgroupPolicyNode from "../../../../components/react-flow/workgroup-policy";
import SimpleNode from "../../../../components/react-flow/simple-node";
import NoZtna from "../../../../components/no-items/no-ztna";
import { useWorkspace } from "../../../../utils/atoms";
import { useWorkspaces } from "../../../../utils/atoms";
import { Link } from "react-router-dom";
import BackButton from "../../../../components/back-button/index.jsx";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { isLanIP } from "../../../../utils/networks.js";
import { endOfDay, startOfDay } from "date-fns";
import HelpPanel from "../../../../components/policy-schema/help-panel.jsx";
import { getNodesBounds } from "@xyflow/react";
import { getViewportForBounds } from "@xyflow/react";
import { toPng } from "html-to-image";
import {
  cropWhiteBackground,
  replaceWhiteWithColor,
} from "../../../../utils/base64.js";

const nodeTypes = {
  SimpleNode,
  ConnectorPolicyNode,
  ConnectorNode,
  PolicyNode,
  GroupNode,
  IdentityNode,
  NetworkNode,
  HostNode,
  WorkgroupPolicyNode,
  DeviceNode,
  ResourceNode,
  ServiceNode,
};

const edgeTypes = {
  PolicyResourceEdge,
};

const markerEnd = {
  type: MarkerType.ArrowClosed,
  width: 20,
  height: 20,
  color: "black",
};

const lastFetchResult = {};

export default function PolicySchema() {
  const [settings] = useRecoilState(settingsState);
  const { updateWorkspaces } = useWorkspaces();
  const { view, workspaceId } = useParams();
  const navigate = useNavigate();

  const { workspace } = useWorkspace();
  const workspaceRef = useRef(workspace);
  workspaceRef.current = workspace;

  const [nodes, setNodes, onNodesChange] = useNodesState(null);
  const nodesRef = useRef(nodes);
  nodesRef.current = nodes;
  const setNodesRef = useRef(setNodes);
  setNodesRef.current = setNodes;
  const onNodesChangeRef = useRef(onNodesChange);
  onNodesChangeRef.current = onNodesChange;

  const [edges, setEdges, onEdgesChange] = useEdgesState(null);
  const edgesRef = useRef(edges);
  edgesRef.current = edges;
  const setEdgesRef = useRef(setEdges);
  setEdgesRef.current = setEdges;
  const onEdgesChangeRef = useRef(onEdgesChange);
  onEdgesChangeRef.current = onEdgesChange;

  const [selectedNodes, setSelectedNodes] = useState(null);
  const [selectedEdges, setSelectedEdges] = useState(null);

  const [search, setSearch] = useState("");
  const [firewallFromDate, setFirewallFromDate] = useState(startOfDay(new Date()));
  const [firewallToDate, setFirewallToDate] = useState(endOfDay(new Date()));
  const [componentsFilter, setComponentsFilter] = useState([]);
  const [resourceGroupsFilter, setResourceGroupsFilter] = useState(resourceGroupsFilterOptions[0]);
  const [statusFilter, setStatusFilter] = useState(statusFilterOptions[0]);
  const [trustedFilter, setTrustedFilter] = useState(trustedFilterOptions[0]);
  const [actionFilter, setActionFilter] = useState(actionFilterOptions[0]);
  const [lastUpdate, setLastUpdate] = useState(null);

  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    updateWorkspaces();
  }, [updateWorkspaces]);

  useEffect(() => {
    if (view) {
      if (!["security-policy", "resource-group", "firewall"].includes(view)) {
        return navigate(`/workspaces/${workspaceId}/policy-schema/security-policy`);
      }

      setNodes(null);
      setEdges(null);
    }
  }, [view, workspaceId, navigate, setNodes, setEdges]);

  const renderSecurityPolicyView = useCallback(async (items) => {
    let nodes = [];
    const edges = [];

    for (const connectorPolicy of items.connectorsPolicies) {
      nodes.push({
        id: `connector-policy-${connectorPolicy.id}`,
        type: "ConnectorPolicyNode",
        position: { x: 0, y: 0 },
        data: connectorPolicy,
      });
    }

    for (const connector of items.connectors) {
      nodes.push({
        id: `connector-${connector.id}`,
        type: "ConnectorNode",
        position: { x: 0, y: 0 },
        data: connector,
      });

      edges.push({
        id: `connector-${connector.id}_connector-policy-${connector.policyID}`,
        source: `connector-${connector.id}`,
        target: `connector-policy-${connector.connectorPolicyID}`,
        style: { stroke: "#b1b1b7" },
        data: {},
      });
    }

    const policiesArr = ["workgroup-policy", "domain-policy"];
    for (const policy of policiesArr) {
      let key, name;
      if (policy === "workgroup-policy") {
        key = "workgroupPolicy";
        name = "Workgroup Policy";
      } else {
        key = "domainPolicy";
        name = "Domain Policy";
      }

      nodes.push({
        id: policy,
        type: "WorkgroupPolicyNode",
        position: { x: 0, y: 0 },
        data: { name },
      });

      for (const group of items[key].groups) {
        nodes.push({
          id: `group-${group.id}`,
          type: "GroupNode",
          position: { x: 0, y: 0 },
          data: group,
        });

        edges.push({
          id: `group-${group.id}_${policy}`,
          source: `group-${group.id}`,
          target: policy,
          markerEnd,
          style: { stroke: "#b1b1b7" },
          data: {},
        });
      }

      for (const device of items[key].devices) {
        nodes.push({
          id: `device-${device.id}`,
          type: "DeviceNode",
          position: { x: 0, y: 0 },
          data: { Name: device.name, details: {} },
        });

        edges.push({
          id: `device-${device.id}_${policy}`,
          source: `device-${device.id}`,
          target: policy,
          markerEnd,
          style: { stroke: "#b1b1b7" },
          data: {},
        });
      }

      let tk = `active-directory_${policy}`;
      nodes.push({
        id: tk,
        type: "SimpleNode",
        position: { x: 0, y: 0 },
        data: { name: "Active directory (1)" },
      });
      edges.push({
        id: `${policy}_${tk}`,
        source: policy,
        target: tk,
        markerEnd,
        style: { stroke: "#b1b1b7" },
        data: {},
      });

      tk = `duo-mfa_${policy}`;
      nodes.push({
        id: tk,
        type: "SimpleNode",
        position: { x: 0, y: 0 },
        data: { name: "Duo MFA" },
      });
      edges.push({
        id: `${policy}_${tk}`,
        source: policy,
        target: tk,
        markerEnd,
        style: { stroke: "#b1b1b7" },
        data: {},
      });

      tk = `compliance_${policy}`;
      nodes.push({
        id: tk,
        type: "SimpleNode",
        position: { x: 0, y: 0 },
        data: { name: "Compliance" },
      });
      edges.push({
        id: `${policy}_${tk}`,
        source: policy,
        target: tk,
        markerEnd,
        style: { stroke: "#b1b1b7" },
        data: {},
      });
    }

    nodes.push({
      id: "application-policy",
      type: "WorkgroupPolicyNode",
      position: { x: 0, y: 0 },
      data: { name: "Application policy" },
    });

    const layouted = await layoutElements(nodes, edges);

    setNodesRef.current(layouted.nodes);
    setEdgesRef.current(layouted.edges);
    setLastUpdate(new Date());
  }, []);

  const renderResourceGroupView = useCallback(async (items) => {
    let nodes = [];
    const edges = [];

    for (const collection of items) {
      nodes.push({
        id: `collection-${collection.collectionId}`,
        type: "PolicyNode",
        position: { x: 0, y: 0 },
        data: collection,
      });

      for (const group of collection.groups) {
        const groupID = `group-${group.id}-${collection.collectionId}`;
        nodes.push({
          id: groupID,
          type: "GroupNode",
          position: { x: 0, y: 0 },
          data: group,
        });

        edges.push({
          id: `${groupID}_collection-${collection.collectionId}`,
          source: groupID,
          target: `collection-${collection.collectionId}`,
          markerEnd,
          style: { stroke: "#b1b1b7" },
          data: {},
        });
      }

      for (const identity of collection.identities) {
        const identityID = `identity-${identity.id}-${collection.collectionId}`;
        nodes.push({
          id: identityID,
          type: "IdentityNode",
          position: { x: 0, y: 0 },
          data: identity,
        });

        edges.push({
          id: `${identityID}_policy-${collection.collectionId}`,
          source: identityID,
          target: `_collection-${collection.collectionId}`,
          markerEnd,
          style: { stroke: "#b1b1b7" },
          data: {},
        });
      }

      for (const resourcesGroup of collection.resourcesGroups) {
        for (const resource of resourcesGroup.resources) {
          const resourceID = `resource-${resource.id}-${resourcesGroup.id}-${collection.collectionId}`;

          if (resource.type === "host") {
            nodes.push({
              id: resourceID,
              type: "HostNode",
              position: { x: 0, y: 0 },
              data: { HostName: resource.name || "" },
            });
          }

          if (resource.type === "network") {
            nodes.push({
              id: resourceID,
              type: "NetworkNode",
              position: { x: 0, y: 0 },
              data: resource,
            });
          }

          edges.push({
            id: `collection-${collection.collectionId}_${resourceID}`,
            type: "PolicyResourceEdge",
            source: `collection-${collection.collectionId}`,
            target: resourceID,
            data: resource,
            markerEnd,
          });
        }
      }
    }

    const layouted = await layoutElements(nodes, edges);

    setNodesRef.current(layouted.nodes);
    setEdgesRef.current(layouted.edges);
    setLastUpdate(new Date());
  }, []);

  const renderFirewallView = useCallback(
    async (items) => {
      let nodes = [];
      const edges = [];

      for (const device of items) {
        let item = nodes.find((node) => node.id === `device-${device.Id}`);
        if (!item) {
          item = {
            id: `device-${device.Id}`,
            type: "DeviceNode",
            position: { x: 0, y: 0 },
            data: device,
          };
          nodes.push(item);
        }

        for (const accessRule of device.accessRules) {
          const dt = new Date(accessRule.DateTime);

          if (dt < firewallFromDate || dt > firewallToDate) {
            continue;
          }

          let host = nodes.find((node) => node.id === `host-${accessRule.HostId}`);
          if (!host) {
            host = {
              id: `host-${accessRule.HostId}`,
              type: "HostNode",
              position: { x: 0, y: 0 },
              data: { HostName: accessRule.HostName || "" },
            };
            nodes.push(host);
          }

          let m = accessRule.HostName.match(/(\d+\.\d+\.\d+\.\d+)/);
          if (m && m[1]) {
            const address = m[1].replace(/\.\d+$/, ".0");

            if (isLanIP(address)) {
              let network = nodes.find((node) => node.id === `network-${address}`);
              if (!network) {
                network = {
                  id: `network-${address}`,
                  type: "NetworkNode",
                  position: { x: 0, y: 0 },
                  data: { address },
                };
                nodes.push(network);
              }

              let edge = edges.find((edge) => edge.id === `host-${accessRule.HostId}_network-${address}`);
              if (!edge) {
                edge = {
                  id: `host-${accessRule.HostId}_network-${address}`,
                  source: `host-${accessRule.HostId}`,
                  target: `network-${address}`,
                  markerEnd,
                  style: { stroke: "#b1b1b7" },
                  data: {},
                };
                edges.push(edge);
              }
            }
          }

          let edge = edges.find((edge) => edge.id === `device-${accessRule.DeviceDBId}_host-${accessRule.HostId}_action-${accessRule.Action}`);
          if (edge) {
            if (!edge.data.accessRules.find((rule) => rule.ServiceId === accessRule.ServiceId)) {
              edge.data.accessRules.push(accessRule);
            }
          } else {
            edge = {
              id: `device-${accessRule.DeviceDBId}_host-${accessRule.HostId}_action-${accessRule.Action}`,
              type: "PolicyResourceEdge",
              source: `device-${accessRule.DeviceDBId}`,
              target: `host-${accessRule.HostId}`,
              data: { accessRules: [accessRule] },
              markerEnd,
            };
            edges.push(edge);
          }
        }
      }

      const layouted = await layoutElements(nodes, edges);

      setNodesRef.current(layouted.nodes);
      setEdgesRef.current(layouted.edges);
      setLastUpdate(new Date());
    },
    [firewallFromDate, firewallToDate]
  );

  const getData = useCallback(async () => {
    if (!workspaceRef.current || !workspaceRef.current.ztnaDomain || !view) {
      return;
    }

    if (view === "security-policy") {
      const data = await ajax("/policy-schema/getSecurityPolicyViewItems", {
        ztnaDomainID: workspaceRef.current.ztnaDomain._id,
      });

      if (data.result !== "success") {
        setFetchError(true);
        return;
      }

      if (lastFetchResult.view === view && lastFetchResult.items && JSON.stringify(lastFetchResult.items) === JSON.stringify(data.items)) {
        return;
      }

      lastFetchResult.view = view;
      lastFetchResult.items = data.items;
      renderSecurityPolicyView(data.items);
    }

    if (view === "resource-group") {
      const data = await ajax("/policy-schema/getResourceGroupViewItems", {
        ztnaDomainID: workspaceRef.current.ztnaDomain._id,
      });

      if (data.result !== "success") {
        setFetchError(true);
        return;
      }

      if (lastFetchResult.view === view && lastFetchResult.items && JSON.stringify(lastFetchResult.items) === JSON.stringify(data.items)) {
        return;
      }

      lastFetchResult.view = view;
      lastFetchResult.items = data.items;
      renderResourceGroupView(data.items);
    }

    if (view === "firewall") {
      const data = await ajax("/policy-schema/getFirewallViewItems", {
        ztnaDomainID: workspaceRef.current.ztnaDomain._id,
      });

      if (data.result !== "success") {
        setFetchError(true);
        return;
      }

      if (lastFetchResult.view === view && lastFetchResult.items && JSON.stringify(lastFetchResult.items) === JSON.stringify(data.items)) {
        return;
      }

      lastFetchResult.view = view;
      lastFetchResult.items = data.items;
      renderFirewallView(data.items);
    }

    setTimeout(async () => {
      const rect = document.querySelector(".react-flow__renderer").getBoundingClientRect();
      const imageWidth = rect.width;
      const imageHeight = rect.height;
      const nodesBounds = getNodesBounds(nodesRef.current);
      const viewport = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

      let base64 = await toPng(document.querySelector(".react-flow__viewport"), {
        backgroundColor: "white",
        width: imageWidth,
        height: imageHeight,
        style: {
          width: imageWidth,
          height: imageHeight,
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
        },
      });

      base64 = await cropWhiteBackground(base64);
      base64 = await replaceWhiteWithColor(base64, "#FBFBFB");

      await ajax("/ztna-domain/saveScreenshot", {
        ztnaDomainID: workspaceRef.current.ztnaDomain._id,
        base64,
      });
    }, 100);
  }, [view, renderSecurityPolicyView, renderResourceGroupView, renderFirewallView]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!nodesRef.current) {
      return;
    }

    const _nodes = [...nodesRef.current];
    const _edges = [...edgesRef.current];

    const additionalsHidden = {};
    for (const node of _nodes) {
      node.hidden = false;

      if (componentsFilter.length !== 0 && !componentsFilter.some((item) => item.value === node.type)) {
        node.hidden = true;
      }

      if (view === "firewall") {
        if (statusFilter.value !== "all" && node.type === "DeviceNode") {
          if (statusFilter.value === "online" && node.data.SystemHealthState !== "Enabled") {
            node.hidden = true;
          }
          if (statusFilter.value === "offline" && node.data.SystemHealthState === "Enabled") {
            node.hidden = true;
          }
        }
        if (trustedFilter.value !== "all" && node.type === "DeviceNode") {
          if (trustedFilter.value === "trusted" && node.data.DeviceState !== 2) {
            node.hidden = true;
          }
          if (trustedFilter.value === "untrusted" && node.data.DeviceState === 2) {
            node.hidden = true;
          }
        }

        if (
          resourceGroupsFilter.value !== "all" &&
          node.type === "DeviceNode" &&
          (!node.data.GroupsNames ||
            !node.data.GroupsNames.split(",")
              .map((item) => item.trim())
              .includes(resourceGroupsFilter.value))
        ) {
          node.hidden = true;
        }

        if (statusFilter.value !== "all" || resourceGroupsFilter.value !== "all") {
          if (node.hidden) {
            const newHiddenNodes = hideConnectedNodes(node.id, _nodes, _edges, additionalsHidden);
            Object.assign(additionalsHidden, newHiddenNodes);
          }
        }
      }

      if (search) {
        let matchesSearch = false;

        for (const k in node.data) {
          if (typeof node.data[k] === "string" && node.data[k].toLowerCase().includes(search.toLowerCase())) {
            matchesSearch = true;
            break;
          }
        }

        if (!matchesSearch) {
          node.hidden = true;
        }
      }
    }

    for (const node of _nodes) {
      if (additionalsHidden[node.id]) {
        node.hidden = true;
      }
    }

    for (const edge of _edges) {
      edge.hidden = false;

      if (view === "firewall") {
        if ((actionFilter.value === "allowed" && edge.data.accessRules?.find((rule) => rule.ActionName !== "Allowed")) || (actionFilter.value === "denied" && edge.data.accessRules?.find((rule) => rule.ActionName !== "Denied"))) {
          edge.hidden = true;
        }
      }

      const connectedNodes = _nodes.filter((node) => edge.source === node.id || edge.target === node.id);

      if (connectedNodes.some((node) => node.hidden)) {
        edge.hidden = true;
      }
    }

    setNodes(_nodes);
    setEdges(_edges);
    onNodesChangeRef.current(_nodes);
    onEdgesChangeRef.current(_edges);
  }, [search, setNodes, setEdges, componentsFilter, resourceGroupsFilter, statusFilter, trustedFilter, view, actionFilter]);

  const onSelectionChange = useCallback(
    (selection) => {
      if (!selection) {
        return;
      }

      setSelectedNodes(selection.nodes);
      setSelectedEdges(selection.edges);

      if (selection.nodes.length > 0) {
        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.selected && node.hidden) {
              return { ...node, selected: false };
            }

            return node;
          })
        );
      }

      if (selection.edges.length > 0) {
        setEdges((prevEdges) =>
          prevEdges.map((edge) => {
            if (edge.selected) {
              const connectedNodes = findNodesForEdge(nodesRef.current, [edge.source, edge.target]);

              if (connectedNodes.every((node) => node.hidden)) {
                return { ...edge, selected: false };
              }
            }

            return edge;
          })
        );
      }
    },
    [setNodes, setEdges]
  );

  function renderReactFlow() {
    if (fetchError) {
      return (
        <div className={`${styles.loadingSchema} ${styles.error}`}>
          <FormattedMessage id='error-fetching-data' />
          <Link to={`/workspaces/${workspace._id}/overview`}>
            <FormattedMessage id='back' />
          </Link>
        </div>
      );
    }

    if (!workspace) {
      return (
        <div className={styles.loadingSchema}>
          <FormattedMessage id='loading-schema' />
          <Spinner />
        </div>
      );
    }

    if (!workspace.ztnaDomain) {
      return <NoZtna />;
    }

    if (!nodes || !edges) {
      return (
        <div className={styles.loadingSchema}>
          <FormattedMessage id='loading-schema' />
          <Spinner />
        </div>
      );
    }

    if (workspace.ztnaDomain.status !== "ready") {
      return (
        <div className={`${styles.loadingSchema} ${styles.error}`}>
          <FormattedMessage id='ztna-is-not-ready' />
          <BackButton toPath={`/workspaces/${workspace._id}/overview`} title={`Back to ${workspace.name}`} text={`Back to ${workspace.name}`}></BackButton>
        </div>
      );
    }

    return (
      <>
        <div className={styles.floatingBars}>
          <ActionsBar lastUpdate={lastUpdate} getData={getData} />

          <FiltersBar
            nodes={nodes}
            edges={edges}
            componentsFilter={componentsFilter}
            setComponentsFilter={setComponentsFilter}
            resourceGroupsFilter={resourceGroupsFilter}
            setResourceGroupsFilter={setResourceGroupsFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            trustedFilter={trustedFilter}
            setTrustedFilter={setTrustedFilter}
            actionFilter={actionFilter}
            setActionFilter={setActionFilter}
            search={search}
            setSearch={setSearch}
            firewallFromDate={firewallFromDate}
            setFirewallFromDate={setFirewallFromDate}
            firewallToDate={firewallToDate}
            setFirewallToDate={setFirewallToDate}
          />
        </div>

        <Sidebar nodes={nodes} selectedNodes={selectedNodes} onSelectionChange={onSelectionChange} setNodes={setNodes} selectedEdges={selectedEdges} />

        <HelpPanel />

        <ReactFlow
          proOptions={{ hideAttribution: true }}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onSelectionChange={onSelectionChange}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodesDraggable={!settings.schemaReadOnly}
          nodesConnectable={!settings.schemaReadOnly}
          deleteKeyCode={settings.schemaReadOnly ? "" : "Backspace"}
          zoomOnDoubleClick={false}
          minZoom={0.1}
          fitView
        >
          <MiniMap position='bottom-left' />
          <Background />
        </ReactFlow>
      </>
    );
  }

  return (
    <WithRole permission=''>
      <div className={styles.wrapper}>{renderReactFlow()}</div>
    </WithRole>
  );
}
