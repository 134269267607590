export const cropWhiteBackground = (base64Image) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Image;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data; // RGBA array

      let minX = canvas.width;
      let minY = canvas.height;
      let maxX = 0;
      let maxY = 0;

      // Scan for non-white pixels (assuming white is RGB 255,255,255)
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const i = (y * canvas.width + x) * 4;
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
          // Check if pixel is not white (allowing some tolerance)
          if (r !== 255 || g !== 255 || b !== 255) {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
          }
        }
      }

      // Add some padding (optional) and ensure bounds are valid
      const padding = 10;
      minX = Math.max(0, minX - padding);
      minY = Math.max(0, minY - padding);
      maxX = Math.min(canvas.width - 1, maxX + padding);
      maxY = Math.min(canvas.height - 1, maxY + padding);

      const cropWidth = maxX - minX + 1;
      const cropHeight = maxY - minY + 1;

      // Create new canvas for cropped image
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;
      const croppedCtx = croppedCanvas.getContext("2d");
      croppedCtx.drawImage(
        canvas,
        minX,
        minY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );

      const croppedBase64 = croppedCanvas.toDataURL("image/png");
      resolve(croppedBase64);
    };
  });
};

export function replaceWhiteWithColor(base64Image, targetColor) {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();
    img.src = base64Image;

    img.onload = () => {
      // Create a canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas size to match image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Get image data
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // Convert target color (#F5F5F5) to RGB
      const targetRGB = {
        r: parseInt(targetColor.slice(1, 3), 16),
        g: parseInt(targetColor.slice(3, 5), 16),
        b: parseInt(targetColor.slice(5, 7), 16),
      };

      // Loop through pixels
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const a = data[i + 3];

        // Check if pixel is white (255, 255, 255) with full opacity
        if (r === 255 && g === 255 && b === 255 && a === 255) {
          data[i] = targetRGB.r; // Red
          data[i + 1] = targetRGB.g; // Green
          data[i + 2] = targetRGB.b; // Blue
          // Alpha remains 255 (fully opaque)
        }
      }

      // Put modified data back onto canvas
      ctx.putImageData(imageData, 0, 0);

      // Convert canvas back to base64
      const newBase64 = canvas.toDataURL("image/png");
      resolve(newBase64);
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
}
