import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import CancelSvg from "../svgs/cancel.svg";

function CustomTag({
  withRemove = false,
  onRemove = () => {},
  onClick = () => {},
  color,
  className,
  id,
  children,
}) {
  // @deprecated
  if (color === "red" || color === "lighter-purple2") {
    return (
      <div
        id={id}
        className={`${styles.wrapper} ${className || ""} ${
          withRemove ? styles.withRemove : ""
        } ${styles[color]}`}
        onClick={onClick}
      >
        {withRemove && <CancelSvg onClick={onRemove} />}
        {children}
      </div>
    );
  }

  return (
    <div
      id={id}
      className={`${styles.wrapper} ${className || ""} ${
        withRemove ? styles.withRemove : ""
      } ${styles.withBackground}`}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {withRemove && <CancelSvg onClick={onRemove} />}
      {children}
    </div>
  );
}

CustomTag.propTypes = {
  withRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.any,
};

export default CustomTag;
