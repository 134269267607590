import styles from "./index.module.css";
import "react-quill/dist/quill.snow.css";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize-module-react";
import Message from "../message";
import { useAjax, useAlert } from "../../utils/hooks";
import { fileToBase64, stripTags } from "../../utils";

const Block = Quill.import("blots/block");
const Inline = Quill.import("blots/inline");
const ImageBlot = Quill.import("formats/image");
const Parchment = Quill.import("parchment");

Block.tagName = "DIV";
Quill.register(Block, true);

Inline.tagName = "SPAN";
Quill.register(Inline, true);

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);

function QuillEditor({
  value,
  setValue,
  placeholder,
  customToolbarComponents = { componentsNames: [], handlers: {} },
  startRTL = false,
}) {
  const reactQuillRef = useRef();

  const ajax = useAjax();
  const alert = useAlert();

  const alertRef = useRef(alert);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    if (!startRTL || !modules) {
      return;
    }

    reactQuillRef.current.editor.format("direction", "rtl");
    reactQuillRef.current.editor.format("align", "right");

    const content = reactQuillRef.current.editor.getContents();
    content.ops.forEach((op) => {
      if (op.insert) {
        op.attributes = op.attributes || {};
        op.attributes["direction"] = "rtl";
        op.attributes["align"] = "right";
      }
    });

    reactQuillRef.current.editor.setContents(content);
  }, [startRTL, modules]);

  useEffect(() => {
    if (!modules) {
      return;
    }

    reactQuillRef.current.editor.root.addEventListener("dblclick", (e) => {
      const img = Parchment.find(e.target);

      if (img instanceof ImageBlot) {
        reactQuillRef.current.editor.setSelection(
          img.offset(reactQuillRef.current.editor.scroll),
          1,
          "user"
        );
      }
    });
  }, [modules]);

  useEffect(() => {
    if (!modules) {
      return;
    }

    customToolbarComponents.quill = reactQuillRef.current;
  }, [customToolbarComponents, modules]);

  useEffect(() => {
    const res = value.match(/<img src="">/g);

    if (res && res.length > 0) {
      setValue(value.replace(/<img src="">/g, ""));
    }
  }, [value, setValue]);

  useEffect(() => {
    if (modules) {
      return;
    }

    const _modules = {
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ align: [] }],

          ["link", "image"],

          ["clean"],
        ],
      },
      imageUploader: {
        upload: async (file) => {
          const base64 = await fileToBase64(file);

          const data = await ajax("/quill/uploadImage", {
            lastModified: file.lastModified,
            name: file.name,
            size: file.size,
            type: file.type,
            base64,
          });

          if (data.result === "error") {
            alertRef.current({
              title: <Message id="error" />,
              message: <Message id={data.message} />,
            });
            return "";
          }

          return data.url;
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
      },
    };

    if (customToolbarComponents.componentsNames.length > 0) {
      _modules.toolbar.container.push(customToolbarComponents.componentsNames);
    }
    if (customToolbarComponents.handlers) {
      _modules.toolbar.handlers = {
        ..._modules.toolbar.handlers,
        ...customToolbarComponents.handlers,
      };
    }

    setModules(_modules);
  }, [ajax, customToolbarComponents, modules]);

  function handleTextChange(html) {
    const text = stripTags(html);

    if (text.length === 1) {
      const elements =
        reactQuillRef.current.editingArea.querySelectorAll(".ql-editor > *");

      elements.forEach((element) => {
        if (text[0] >= "א" && text[0] <= "ת") {
          element.classList.add("ql-align-right");
          element.classList.add("ql-direction-rtl");
        } else {
          element.classList.remove("ql-align-right");
          element.classList.remove("ql-direction-rtl");
        }
      });
    }

    setValue(html);
  }

  return (
    <div className={styles.wrapper}>
      {modules && (
        <ReactQuill
          ref={reactQuillRef}
          theme="snow"
          value={value}
          onChange={handleTextChange}
          modules={modules}
          placeholder={placeholder}
          className={styles.quill}
        />
      )}
    </div>
  );
}

QuillEditor.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  placeholder: PropTypes.string,
  startRTL: PropTypes.bool,
  customToolbarComponents: PropTypes.object,
};

export default QuillEditor;
