import styles from "./index.module.css";
import React from "react";
import PropTypes from "prop-types";

function InfoAlert({ children, className }) {
  return (
    <div className={`${styles.wrapper} ${className ? className : ""}`}>
      {children}
    </div>
  );
}

InfoAlert.propTypes = {
  children: PropTypes.any,
};

export default InfoAlert;
