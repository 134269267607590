import React from "react";

import CopyRow from "../copy-row";
import Box from "../box";

export default function EnvironmentAccessInstructions({ token }) {
  return (
    <Box
      title={"token-for-authentication"}
      titleTag="h2"
      description={"environment-access-instructions"}
    >
      {/* <div className="drow f60">
        <span>
          <Message id="server" />:
        </span>
        <div className={styles.tokenRow}>
          <b>{ztnaDomain.domain}</b>
          <CopySvg onClick={() => copy(ztnaDomain.domain)} />
        </div>
      </div> */}

      <CopyRow title="token" text={token} toCopy={token} />
    </Box>
  );
}
