import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useState, useEffect } from "react";
import SslSvg from "../../../components/svgs/ssl.svg?react";
import LockSvg from "../../../components/svgs/lock2.svg?react";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "../../stripe-form";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback } from "react";
import { useAjax } from "../../../utils/hooks";
import Message from "../../message";

const appearance = {
  theme: "stripe",
};
const options = {
  mode: "setup",
  setupFutureUsage: "off_session",
  currency: "usd",
  paymentMethodCreation: "manual",
  appearance,
};

const stripePromise = loadStripe(
  import.meta.env.VITE_APP_STRIPE_API_KEY || "pk_live_nL0rSp00qKl6X0H71MIAxJPu",
  {
    locale: "en",
  }
);

function AddCreditCardModal({ isOpen, onClose, user }) {
  const ajax = useAjax();
  const [iframeSrc, setIframeSrc] = useState(null);
  const [stripeIf, setStripeIf] = useState(false);
  const [error, setError] = useState("");
  const [paymentIntent, setPaymentIntent] = useState("");
  const [paymentMethodG, setPaymentMethodG] = useState("");
  const [paymentMethodID, setPaymentMethodID] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setIframeSrc(null);
      setStripeIf(false);
      setError("");
      setPaymentIntent("");
      setPaymentMethodG("");
      setPaymentMethodID("");
    }
  }, [isOpen]);

  const handleMessage = useCallback(
    async (e) => {
      if (typeof e.data !== "string") {
        return;
      }

      if (!e.data.startsWith("3DS-authentication-complete")) {
        return;
      }

      setStripeIf(false);
      setError("");

      if (e.data.includes("succeeded")) {
        setStripeIf(false);
        setError("");
        await ajax("/billing/stripe/addPaymentToDB", {
          paymentIntent,
          paymentMethodG,
          paymentMethodID,
          user,
        });
        onClose(true);
      }

      setError("3DS Error");
    },
    [onClose, ajax, paymentIntent, paymentMethodG, paymentMethodID, user]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  function renderBody() {
    if (stripeIf) {
      return (
        <iframe
          width={400}
          height={600}
          className={styles.addCreditCardContentIframe}
          src={iframeSrc}
        />
      );
    }

    return (
      <div className={styles.app}>
        <Elements options={options} stripe={stripePromise}>
          <StripeForm onPaymentData={handlePaymentData} />
        </Elements>
        <div className={styles.red}>{error}</div>
      </div>
    );
  }

  const handlePaymentData = (
    data,
    paymentIntent,
    paymentMethodG,
    paymentMethodID
  ) => {
    if (data === "") {
      setStripeIf(false);
      setError("");
      onClose(true);
    }

    setPaymentIntent(paymentIntent);
    setPaymentMethodG(paymentMethodG);
    setPaymentMethodID(paymentMethodID);
    setIframeSrc(data);
    setStripeIf(true);
  };

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
      size="md"
    >
      <ModalHeader toggle={() => onClose(false)}>
        <Message id="add-credit-card.title" />
      </ModalHeader>
      <ModalBody>{renderBody()}</ModalBody>
      <ModalFooter>
        <div className={styles.purchaseSecured}>
          <div>
            <LockSvg className={styles.lockSvg} />
            <Message id="your-purchase-secured" />
          </div>

          <div className={styles.images}>
            <img src="/img/pci.jpeg" />
            <SslSvg />
          </div>
        </div>
        {/* <DreamButton onClick={() => onClose()} color="gray">
          <Message id="cancel" />
        </DreamButton> */}
      </ModalFooter>
    </Modal>
  );
}

AddCreditCardModal.propTypes = {
  user: PropTypes.object,
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddCreditCardModal;
