import { useState } from "react";
import CustomText from "../../components/custom-text";
import DreamButton from "../../components/dream-button";
import Message from "../../components/message";
import styles from "./prices.module.css";

import React from "react";
import { ajax } from "../../utils";
import { useEffect } from "react";
import { useCallback } from "react";
import Spinner from "../../components/spinner";
import { useAlert } from "../../utils/hooks";

export default function ManagementPrices() {
  const alert = useAlert();

  const [monthly, setMonthly] = useState(null);
  const [yearly, setYearly] = useState(null);

  const [loading, setLoading] = useState(false);

  const listPrices = useCallback(async () => {
    const data = await ajax("/billing/prices/list");

    setMonthly(data.monthly || {});
    setYearly(data.yearly || {});
  }, []);

  useEffect(() => {
    listPrices();
  }, [listPrices]);

  function handleInputChanged(e, field, period) {
    (period === "monthly" ? setMonthly : setYearly)({
      ...(period === "monthly" ? monthly : yearly),
      [field]: parseInt(e.target.value),
    });
  }

  async function handleSaveClicked() {
    setLoading(true);

    await ajax("/billing/prices/save", { monthly, yearly });
    alert({
      title: <Message id="success" />,
      message: <Message id="settings-saved-successfully" />,
      notification: true,
    });

    setLoading(false);
  }

  function renderContent() {
    if (!monthly || !yearly) {
      return <Spinner />;
    }

    return (
      <>
        {["monthly", "yearly"].map((period) => (
          <div key={period}>
            <h2>
              <Message id={period} />
            </h2>
            <div className="drow f120">
              <span>
                <Message id="minimum-users" />
              </span>
              <CustomText
                type="number"
                value={(period === "monthly" ? monthly : yearly).minimumUsers}
                onChange={(e) => handleInputChanged(e, "minimumUsers", period)}
              />
            </div>
            <div className="drow f120">
              <span>
                <Message id="maximum-users" />
              </span>
              <CustomText
                type="number"
                value={(period === "monthly" ? monthly : yearly).maximumUsers}
                onChange={(e) => handleInputChanged(e, "maximumUsers", period)}
              />
            </div>
            <div className="drow f120">
              <span>
                <Message id="cost-per-user" /> $
              </span>
              <CustomText
                type="number"
                value={(period === "monthly" ? monthly : yearly).costPerUser}
                onChange={(e) => handleInputChanged(e, "costPerUser", period)}
              />
            </div>
          </div>
        ))}

        <div>
          <DreamButton
            loading={loading}
            disabled={loading}
            onClick={handleSaveClicked}
          >
            <Message id="save" />
          </DreamButton>
        </div>
      </>
    );
  }

  return <div className={styles.wrapper}>{renderContent()}</div>;
}
