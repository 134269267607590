import styles from "./index.module.css";

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax, useInitialDataFetching } from "../../../utils/hooks";
import Message from "../../message";
import { sanitize } from "../../../utils";
import KeyDetector from "../../key-detector";
import CustomText from "../../custom-text";
import DreamButton from "../../dream-button";

function TwoFactorAuthenticationModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const initialDataFetching = useInitialDataFetching();

  const [qr, setQR] = useState(null);
  const [secret, setSecret] = useState(null);
  const [code, setCode] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const get2FAQR = useCallback(async () => {
    const data = await ajax("/users/get2FAQR");

    setQR(data.qr);
    setSecret(data.secret);
  }, [ajax]);

  useEffect(() => {
    if (isOpen) {
      setCode("");
      get2FAQR();
    }
  }, [isOpen, get2FAQR]);

  async function handleActiveClicked() {
    setError(false);

    setLoading(true);
    const data = await ajax(`/users/active2FA`, { code });
    setLoading(false);

    if (data.result === "success") {
      await initialDataFetching();
      onClose(true);
    } else {
      setError(<Message id="two-factor-authentication-modal.wrong-code" />);
    }
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <Message id="two-factor-authentication-modal.title" />
      </ModalHeader>
      <ModalBody className={styles.center}>
        <Message id="two-factor-authentication-modal.instructions" />

        {qr && (
          <div className={styles.imgWrapper}>
            <img src={qr} width={166} height={166} />
          </div>
        )}

        {secret && (
          <div>
            <Message
              id="two-factor-authentication-modal.instructions2"
              values={{ secret, b: (val) => sanitize(`<b>${val}</b>`) }}
            />
          </div>
        )}

        <br />

        <div>
          <Message id="two-factor-authentication-modal.instructions3" />
        </div>

        <KeyDetector
          className={styles.textWrapper}
          onKeyDetected={handleActiveClicked}
        >
          <CustomText value={code} onChange={(e) => setCode(e.target.value)} />
        </KeyDetector>

        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          color="gray"
          onClick={() => onClose(false)}
        >
          <Message id="general.cancel" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          loading={loading}
          color="purple"
          onClick={handleActiveClicked}
        >
          <Message id="general.active" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

TwoFactorAuthenticationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TwoFactorAuthenticationModal;
