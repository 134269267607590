import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import Greentick from "../svgs/greentick.svg?react";
import Message from "../message";

function Wizard({ steps, currentStep }) {
  return (
    <div className={styles.flex}>
      <div className={styles.flex}>
        <div className={styles.steps}>
          {typeof currentStep === "number" &&
            steps.map((step, key) => (
              <div key={key}>
                <div
                  className={`${styles.title} ${
                    currentStep - 1 == key ? styles.selected : ""
                  }`}
                >
                  {currentStep - 1 > key ? (
                    <Greentick className={styles.greentick} />
                  ) : (
                    <step.icon className={styles.svg} />
                  )}

                  <span>{step.title}</span>
                </div>
                <div
                  className={`${styles.step} ${
                    currentStep - 1 == key ? styles.selected : ""
                  }`}
                ></div>
              </div>
            ))}
        </div>
      </div>
      {steps[currentStep - 1] && (
        <div className={styles.content}>{steps[currentStep - 1].component}</div>
      )}
      {!steps[currentStep - 1] && (
        <div className={styles.content}>
          <Message id="illegal-step" />
        </div>
      )}
    </div>
  );
}

Wizard.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
};

export default Wizard;
