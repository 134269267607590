import styles from "./step2.module.css";

import React from "react";
import PropTypes from "prop-types";
import Target from "../../components/svgs/permission-target.svg?react";
import InfoAlert from "../info-alert";
import Message from "../message";
import { getFullName } from "../../utils/user";
import CheckButton from "../check-button";
import DreamButton from "../dream-button";

function PermissionsStep2({
  setStep,
  step,
  permission = "sites",
  setPermission,
  user,
}) {
  function handleNextClicked() {
    setStep(step + 1);
  }

  return (
    <div>
      <div className={styles.flextitle}>
        {!user && (
          <div>
            <h3>
              <Message id="step2.title" />
            </h3>
          </div>
        )}
        {user && (
          <span className={styles.flextitleUser}>
            <h3>
              <Message id="step2.title-edit" />
            </h3>
            <div className={styles.bluediv}>{getFullName(user)}</div>
          </span>
        )}
      </div>
      {user ? (
        <Message id="step2.sub-title-edit" />
      ) : (
        <Message id="step2.sub-title" />
      )}

      <br />
      <br />
      <div className={styles.flex}>
        <CheckButton
          selected={permission === "sites"}
          onClick={() => setPermission("sites")}
          text={<Message id="permission-1" />}
          // text={`גישה לאתרים`}
        />
        <CheckButton
          selected={permission === "billing"}
          onClick={() => setPermission("billing")}
          text={<Message id="permission-2" />}
          // text={`גישה לחשבונאות`}
        />
        <CheckButton
          selected={permission === "full"}
          onClick={() => setPermission("full")}
          text={<Message id="permission-3" />}
          // text={`גישה מלאה`}
        />
      </div>
      <InfoAlert>
        <Target />
        {permission === "sites" && <Message id="step2.alert-sites" />}
        {permission === "billing" && <Message id="step2.alert-billing" />}
        {permission === "full" && <Message id="step2.alert-text2" />}
      </InfoAlert>
      <div className={styles.flex}>
        <DreamButton
          className={styles.nobackGround}
          href="/account/permissions"
          as="/account/permissions"
          color="white"
        >
          <Message id="step1.discard" />
        </DreamButton>
        <DreamButton disabled={!permission} onClick={handleNextClicked}>
          <Message id="step1.next" />
        </DreamButton>
      </div>
    </div>
  );
}

PermissionsStep2.propTypes = {
  setStep: PropTypes.func,
  step: PropTypes.number,
  permission: PropTypes.string,
  setPermission: PropTypes.func,
  user: PropTypes.any,
};

export default PermissionsStep2;
