import React from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import Message from "../message";

function NoItems({ children, title, description, image }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.image}>{image}</div>
        <h2 className={styles.title}>
          <Message id={title} />
        </h2>
        <div className={styles.description}>
          <Message id={description} />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}

NoItems.propTypes = {
  title: PropTypes.string,
  image: PropTypes.any,
  description: PropTypes.string,
  children: PropTypes.any,
};

export default NoItems;
