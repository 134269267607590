import { SUPER_ADMIN } from "../../utils/user";

export default function getSidebar(user, params) {
  if (user.role === SUPER_ADMIN) {
    return [
      {
        title: "ztna",
        icon: "cloud",
        permissions: ["sites", "full"],
        to: `/ztna/manage`,
        selected: /ztna\/manage/,
      },
      {
        title: "sidebar.clients",
        icon: "account",
        to: `/clients/clients-list`,
        selected: /clients\/clients-list/,
      },
      {
        title: "sidebar.billing",
        icon: "billing",
        to: `/billing/invoices`,
        selected: /billing\/invoices/,
      },
      {
        title: "sidebar.system-management",
        icon: "management",
        to: `/management/prices`,
        selected: /management\/prices/,
      },
    ];
  } else {
    return [
      {
        title: "workspaces",
        icon: "cloud",
        permissions: ["sites", "full"],
        to: `/workspaces`,
        selected: /workspaces/,
      },
      {
        title: "ztna",
        icon: "cloud",
        permissions: ["sites", "full"],
        to: `/workspaces/${params.workspaceId}/overview`,
        selected: /.+\/overview/,
      },
      {
        title: "downloads",
        icon: "cloudDownload",
        permissions: ["sites", "full"],
        to: `/workspaces/${params.workspaceId}/downloads`,
        selected: /.+\/downloads/,
      },
      {
        title: "allowed-access",
        icon: "account",
        permissions: ["sites", "full"],
        to: `/workspaces/${params.workspaceId}/allowed-access`,
        selected: /.+\/allowed-access/,
      },
      {
        title: "subscriptions",
        icon: "billing",
        permissions: ["sites", "full"],
        to: `/workspaces/${params.workspaceId}/subscriptions`,
        selected: /.+\/subscriptions/,
      },
      {
        title: "policy-schema",
        icon: "schema",
        permissions: ["sites", "full"],
        to: `/workspaces/${params.workspaceId}/policy-schema`,
        selected: /.+\/policy-schema/,
        links: [
          {
            title: "security-policy",
            to: `/workspaces/${params.workspaceId}/policy-schema/security-policy`,
            selected: /.+\/security-policy/,
          },
          {
            title: "resource-group",
            to: `/workspaces/${params.workspaceId}/policy-schema/resource-group`,
            selected: /.+\/resource-group/,
          },
          {
            title: "firewall",
            to: `/workspaces/${params.workspaceId}/policy-schema/firewall`,
            selected: /.+\/firewall/,
          },
        ],
      },
      {
        title: "support",
        icon: "support",
        permissions: ["sites", "full"],
        to: `/support/new-request`,
        selected: /support\/new-request/,
      },
      {
        title: "sidebar.billing",
        icon: "billing",
        permissions: ["billing", "full"],
        to: `/billing/subscription`,
        selected: /billing\//,
      },
    ];
  }
}
