export const findNodesForEdge = (nodes, ids) => {
  const result = [];

  for (const node of nodes) {
    if (ids.includes(node.id)) {
      result.push(node);
    }
  }

  return result;
};
