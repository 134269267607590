import styles from "./index.module.css";

import React from "react";

export default function LockedUser() {
  const user = useUser();

  return (
    <div className={styles.wrapper}>
      <Box>
        <div>
          <Message
            id="locked-user.description"
            values={{
              reason: (
                <Message
                  id={`locked-user.issue-${getSuspendedReason(user.status)}`}
                />
              ),
              phoneNumber: sanitize(user.companyPhone),
              b: (arr) => sanitize(`<b>${arr[0]}</b>`),
            }}
          />
        </div>
      </Box>
    </div>
  );
}
