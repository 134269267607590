import React, { useEffect, useMemo, useState } from "react";
import RegisterLayout from "../../components/register-layout";
import RegisterPageStep1 from "../../components/register-page/step1";
import RegisterPageStep3 from "../../components/register-page/step3";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useUser } from "../../utils/hooks";
import Message from "../../components/message";
import BillingCheckout from "../billing/checkout";

export default function Register() {
  const user = useUser();
  const navigate = useNavigate();
  const { paramStep } = useParams();

  const [paymentType, setPaymentType] = useState("monthly");
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [step, setStep] = useState(1);
  const [backCustomer, setBackCustomer] = useState(false);

  const [smsMode, setSmsMode] = useState("before");

  useEffect(() => {
    if (paramStep > 3 || step > 3) {
      return navigate(`/workspaces`);
    }
    if (parseInt(step) !== parseInt(paramStep)) {
      navigate(`/user/register/${step}`);
    }
  }, [paramStep, navigate, step]);

  useEffect(() => {
    if (user.registerStep === -1 || user.registerStep === -2) {
      return navigate(`/workspaces`);
    }
    if (user.registerStep) {
      setStep(user.registerStep + 1);
    } else {
      setStep(1);
    }
  }, [user, navigate]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const back = urlSearchParams.get("back");
    const id = urlSearchParams.get("id");
    const pay = urlSearchParams.get("pay");

    if (id) {
      setPaymentType(pay ? pay : "yearly");
    }
    if (back === "1") {
      setBackCustomer(true);
    }
  }, []);

  function renderStepContent() {
    if (step === 1) {
      return (
        <RegisterPageStep1
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />
      );
    } else if (step === 2) {
      return <RegisterPageStep3 mode={smsMode} setMode={setSmsMode} />;
    } else if (step === 3) {
      return <BillingCheckout />;
    }
  }

  const title = useMemo(() => {
    if (step === 1) {
      return <Message id="register-page.step1.title" />;
    }
    if (step === 2) {
      return (
        <Message
          id={
            smsMode === "before"
              ? "register-page.step3.before.title"
              : "register-page.step3.after.title"
          }
        />
      );
    }
    if (step === 3) {
      return backCustomer ? (
        <Message id="register-page.step4.title.back" />
      ) : (
        ""
      );
    }

    return "";
  }, [step, smsMode, backCustomer]);

  const subtitle = useMemo(() => {
    if (step === 1) {
      return <Message id="register-page.step1.subtitle" />;
    }

    return "";
  }, [step]);

  const description = useMemo(() => {
    if (step === 1) {
      return "";
    }
    if (step === 2) {
      return "";
    }
    if (step === 3) {
      return "";
    }
    if (step === 4) {
      if (!selectedPackage) {
        return "";
      }

      return backCustomer ? (
        <Message id="register-page.step4.description.back1" />
      ) : (
        ""
      );
    }

    return "";
  }, [step, backCustomer, selectedPackage]);

  return (
    <RegisterLayout
      title={title}
      subtitle={subtitle}
      description={description}
      step={step}
      backCustomer={backCustomer}
    >
      {renderStepContent()}
    </RegisterLayout>
  );
}
