import styles from "./index.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Message from "../../message";
import DreamButton from "../../dream-button";
import CustomText from "../../custom-text";
import { ajax } from "../../../utils";
import { useWorkspaces } from "../../../utils/atoms";
import CustomReactSelect from "../../custom-react-select";
import { useEffect } from "react";

const billingCycleOptions = [
  { label: <Message id="monthly" />, value: "monthly" },
  { label: <Message id="yearly" />, value: "yearly" },
];

function CreateNewWorkspaceModal({ isOpen, onClose }) {
  const { workspaces, updateWorkspaces } = useWorkspaces();

  const [name, setName] = useState("");
  const [billingCycle, setBillingCycle] = useState(billingCycleOptions[0]);
  const [users, setUsers] = useState(10);

  const [prices, setPrices] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function listPrices() {
      const data = await ajax("/billing/prices/list");

      setPrices({ monthly: data.monthly, yearly: data.yearly });
    }

    if (isOpen) {
      listPrices();
    }
  }, [isOpen]);

  async function handleCreateClicked() {
    const _name = name.trim();

    if (!_name) {
      return setError(<Message id="errors.name" />);
    }
    if (
      workspaces.find(
        (workspace) => workspace.name.toLowerCase() === _name.toLowerCase()
      )
    ) {
      return setError(<Message id="errors.name-exists" />);
    }

    if (
      parseInt(users) < prices[billingCycle.value].minimumUsers ||
      parseInt(users) > prices[billingCycle.value].maximumUsers
    ) {
      return setError(
        <Message
          id="wrong-users-for-purchase"
          values={{
            min: prices[billingCycle.value].minimumUsers,
            max: prices[billingCycle.value].maximumUsers,
          }}
        />
      );
    }

    setLoading(true);
    const data = await ajax("/workspace/new", {
      name: _name,
      users,
      billingCycle: billingCycle.value,
    });
    if (data.result === "error") {
      setLoading(false);
      return;
    }

    await updateWorkspaces();
    onClose(true);
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <Message id="create-new-workspace-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className="drow f250">
          <span>
            <Message id="choose-name-for-workspace" />
          </span>
          <CustomText value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="drow f250">
          <span>
            <Message id="billing-cycle" />
          </span>
          <CustomReactSelect
            options={billingCycleOptions}
            value={billingCycle}
            onChange={(item) => setBillingCycle(item)}
          />
        </div>
        <div className="drow f250">
          <span>
            <Message id="users" />
          </span>
          <CustomText
            type="number"
            value={users}
            onChange={(e) => setUsers(e.target.value)}
          />
        </div>

        {billingCycle.value === "yearly" && (
          <>
            <div className={styles.labels}>
              <span>
                <Message id="annual-savings" />
              </span>
              $
              {(users * prices.monthly?.costPerUser -
                users * prices.yearly?.costPerUser) *
                12}
            </div>
            <hr />
          </>
        )}
        <div>
          <div className={`${styles.labels} ${styles.total}`}>
            <span>
              <Message id="total" />
            </span>
            $
            {users *
              prices[billingCycle.value]?.costPerUser *
              (billingCycle.value === "monthly" ? 1 : 12)}
            /{billingCycle.value === "monthly" ? "mo" : "yr"}
          </div>
          <div className={styles.labels}>
            <span>
              <Message
                id={
                  billingCycle.value === "monthly"
                    ? "billed-monthly"
                    : "billed-annually"
                }
              />
            </span>
            <Message id="tax-and-fees" />
          </div>
        </div>
        {error && <div className="error">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <DreamButton
          onClick={handleCreateClicked}
          disabled={loading}
          loading={loading}
          color="purple"
        >
          <Message id="create" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          color="text"
          onClick={() => onClose(false)}
        >
          <Message id="cancel" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

CreateNewWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateNewWorkspaceModal;
