import React from "react";
import Message from "../../../components/message";
import { useWorkspace } from "../../../utils/atoms";
import { Link } from "react-router-dom";

export default function ZtnaManagementSubscriptions() {
  const { workspace } = useWorkspace();

  if (!workspace) {
    return null;
  }

  return (
    <div>
      <h2 className='title'>
        <Message id='subscriptions-title' />
      </h2>
      <div className='drow f120'>
        <span>
          <Message id='license' />:
        </span>
        <strong>Enterprise</strong>
      </div>
      <div className='drow f120'>
        <span>
          <Message id='workspace' />:
        </span>
        <strong>{workspace.name}</strong>
      </div>
      <div className='drow f120'>
        <span>
          <Message id='total-licenses' />:
        </span>
        <strong>0/{workspace.total_users}</strong>
      </div>

      <Link to='/billing/subscription?edit_current=1'>
        <Message id='add-more-licenses' />
      </Link>
    </div>
  );
}
