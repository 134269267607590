import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import SecurityPassword from "../../security/password";
import Message from "../../message";

function ChangeUserPasswordModal({ isOpen, onClose }) {
  return (
    <Modal className={styles.wrapper} isOpen={isOpen}>
      <ModalHeader>
        <Message id="change-user-password-modal.title" />
      </ModalHeader>
      <ModalBody>
        <SecurityPassword inModal={onClose} />
      </ModalBody>
    </Modal>
  );
}

ChangeUserPasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChangeUserPasswordModal;
