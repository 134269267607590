import styles from "./index.module.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import CustomText from "../custom-text";
import Message from "../message";
import { useAlert } from "../../utils/hooks";

function CodeDigits({ onDone = () => {}, helpers = {}, className = "" }) {
  const onDoneRef = useRef(onDone);
  onDoneRef.current = onDone;

  const wrapperRef = useRef();

  const alert = useAlert();
  const alertRef = useRef(alert);

  const [codes, setCodes] = useState({});

  useEffect(() => {
    helpers.reset = () => {
      setCodes({});
      wrapperRef.current.children[0].getElementsByTagName("input")[0].focus();
    };
  }, [helpers]);

  useEffect(() => {
    const keys = Object.keys(codes);

    if (keys.length === 6) {
      onDoneRef.current(Object.values(codes).join(""));
    }
  }, [codes]);

  const handleKeyDown = useCallback(async (e) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode == 86) {
      try {
        const text = await navigator.clipboard.readText();

        if (text && new RegExp(/^\d{6}$/).test(text)) {
          setCodes({
            1: text[0],
            2: text[1],
            3: text[2],
            4: text[3],
            5: text[4],
            6: text[5],
          });
        }
      } catch (err) {
        alertRef.current({
          title: <Message id="error" />,
          message: <Message id="no-clipboard-permission" />,
        });
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  function handleCodeWrapperClicked(e) {
    e.preventDefault();

    if (e.target.tagName === "INPUT") {
      if (e.target.value === "") {
        for (const child of wrapperRef.current.children) {
          if (child.getElementsByTagName("input")[0].value === "") {
            child.getElementsByTagName("input")[0].focus();
            return;
          }
        }
      } else {
        e.target.select();
      }
    }
  }

  function handleCodeChanged(e, num) {
    if (e.target.value >= "0" && e.target.value <= "9") {
      codes[num] = e.target.value;
      setCodes({ ...codes });
    }
  }

  function handleCodeKeyUp(e, num) {
    if (e.key === "Backspace") {
      codes[num - 1] = e.target.value;
      setCodes({ ...codes });

      if (e.target.parentElement.previousElementSibling) {
        e.target.parentElement.previousElementSibling.children[0].focus();
      }

      return;
    }

    if (e.target.value && e.target.parentElement.nextElementSibling) {
      e.target.parentElement.nextElementSibling.children[0].focus();
      e.target.parentElement.nextElementSibling.children[0].select();
    }
  }

  return (
    <div
      ref={wrapperRef}
      className={`${styles.wrapper} ${className}`}
      onClick={(e) => handleCodeWrapperClicked(e)}
    >
      {[1, 2, 3, 4, 5, 6].map((num) => (
        <CustomText
          autoFocus={num === 1}
          className={styles.code}
          key={num}
          placeholder="-"
          maxLength={1}
          value={codes[num] || ""}
          onChange={(e) => handleCodeChanged(e, num)}
          onKeyUp={(e) => handleCodeKeyUp(e, num)}
        />
      ))}
    </div>
  );
}

CodeDigits.propTypes = {
  helpers: PropTypes.object,
  onDone: PropTypes.func,
  className: PropTypes.string,
};

export default CodeDigits;
