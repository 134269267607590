import styles from "./invite-users.module.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { useAjax, useUser } from "../../../utils/hooks";
import Message from "../../../components/message";
import Spinner from "../../../components/spinner";
import CustomText from "../../../components/custom-text";
import KeyDetector from "../../../components/key-detector";
import DreamButton from "../../../components/dream-button";
import CustomTag from "../../../components/custom-tag";
import { SUPER_ADMIN, USER } from "../../../utils/user";
import LayoutMainAction from "../../../components/layout-main-action";
import Box from "../../../components/box";
import { useIntl } from "react-intl";

export default function AccountPermissionsInviteUsers() {
  const ajax = useAjax();
  const navigate = useNavigate();
  const user = useUser();
  const intl = useIntl();

  const [permissionType, setPermissionType] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);

  const [editMode, setEditMode] = useState(false);

  const [error, setError] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUsersOfParent = useCallback(async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const edit = urlSearchParams.get("edit");

    const data = await ajax("/roles/getUserOfParent", { userID: edit });

    setPermissionType(data.user.permission_type);

    setTimeout(() => {
      const permissions = {};

      data.user.permissions.forEach((type) => {
        permissions[type] = true;
      });

      setPermissions(permissions);
    }, 1);

    setLoadingEdit(false);
  }, [ajax]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const edit = urlSearchParams.get("edit");

    if (edit) {
      setEditMode(true);
      setLoadingEdit(true);
      getUsersOfParent();
    }
  }, [getUsersOfParent]);

  useEffect(() => {
    let permissions;

    switch (permissionType) {
      case "full-access": {
        permissions = {
          api: true,
          billing: true,
        };
        break;
      }
      case "site-access": {
        permissions = {
          api: true,
          billing: false,
        };
        break;
      }
      case "billing-access": {
        permissions = {
          api: false,
          billing: true,
        };
        break;
      }

      case "whitelabel-full-access": {
        permissions = {
          api: true,
          billing: true,
          "admin.full-access": true,
          "admin.support": true,
          "admin.sales": true,
        };
        break;
      }
      case "whitelabel-support-access": {
        permissions = {
          api: true,
          billing: false,
          "admin.support": true,
        };
        break;
      }
      case "whitelabel-sales-access": {
        permissions = {
          api: false,
          billing: true,
          "admin.sales": true,
        };
        break;
      }

      case "super-admin-full-access": {
        permissions = {
          api: true,
          billing: true,
          "admin.full-access": true,
          "admin.support": true,
          "admin.sales": true,
          "super-admin.full-access": true,
          "super-admin.support": true,
          "super-admin.sales": true,
        };
        break;
      }
      case "support-access": {
        permissions = {
          api: true,
          billing: false,
          "admin.support": true,
          "super-admin.support": true,
        };
        break;
      }
      case "sales-access": {
        permissions = {
          api: false,
          billing: true,
          "admin.sales": true,
          "super-admin.sales": true,
        };
        break;
      }
    }

    if (permissions) {
      setPermissions(permissions);
    }
  }, [permissionType]);

  function handleEmailEnterClicked() {
    let _email = email.trim();

    if (validator.isEmail(_email)) {
      emails.push(_email);
      setEmails([...emails]);
      setEmail("");
    }
  }

  function handleEmailRemoveClicked(key) {
    emails.splice(key, 1);
    setEmails([...emails]);
  }

  function handlePermissionTypeClicked(type) {
    setPermissionType(type);
  }

  const smallButton = useMemo(
    () => ({
      title: <Message id="general.back" />,
      href: "/account/permissions",
      to: `/account/permissions`,
    }),
    []
  );

  const description = useMemo(() => {
    switch (permissionType) {
      case "full-access":
        return (
          <Message id="account-permissions-invite-users.full-access.description" />
        );
      case "site-access":
        return (
          <Message id="account-permissions-invite-users.site-access.description" />
        );
      case "billing-access":
        return (
          <Message id="account-permissions-invite-users.billing-access.description" />
        );
      case "support-access":
        return (
          <Message id="account-permissions-invite-users.support-access.description" />
        );
      case "sales-access":
        return (
          <Message id="account-permissions-invite-users.sales-access.description" />
        );
      default:
        return null;
    }
  }, [permissionType]);

  async function handleInviteClicked() {
    setError(false);

    setLoading(true);

    if (editMode) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const edit = urlSearchParams.get("edit");

      await ajax("/roles/edit", {
        editID: edit,
        permissions: Object.keys(permissions).filter((k) => permissions[k]),
        permissionType,
      });
    } else {
      await ajax("/roles/create", {
        emails,
        permissions: Object.keys(permissions).filter((k) => permissions[k]),
        permissionType,
      });
    }

    navigate(`/account/permissions`);
  }

  function renderForUser() {
    return (
      <div className={styles.permissions}>
        <div
          className={permissionType === "full-access" ? styles.selected : ""}
          onClick={() => handlePermissionTypeClicked("full-access")}
        >
          <Message id="account-permissions-invite-users.full-access" />
        </div>
        <div
          className={permissionType === "site-access" ? styles.selected : ""}
          onClick={() => handlePermissionTypeClicked("site-access")}
        >
          <Message id="account-permissions-invite-users.site-access" />
        </div>
        <div
          className={permissionType === "billing-access" ? styles.selected : ""}
          onClick={() => handlePermissionTypeClicked("billing-access")}
        >
          <Message id="account-permissions-invite-users.billing-access" />
        </div>
      </div>
    );
  }

  function renderForSuperAdmin() {
    return (
      <div className={styles.permissions}>
        <div
          className={
            permissionType === "super-admin-full-access" ? styles.selected : ""
          }
          onClick={() => handlePermissionTypeClicked("super-admin-full-access")}
        >
          <Message id="account-permissions-invite-users.full-access" />
        </div>
        <div
          className={permissionType === "support-access" ? styles.selected : ""}
          onClick={() => handlePermissionTypeClicked("support-access")}
        >
          <Message id="account-permissions-invite-users.support-access" />
        </div>
        <div
          className={permissionType === "sales-access" ? styles.selected : ""}
          onClick={() => handlePermissionTypeClicked("sales-access")}
        >
          <Message id="account-permissions-invite-users.sales-access" />
        </div>
      </div>
    );
  }

  function renderBoxContent() {
    if (loadingEdit) {
      return <Spinner />;
    }

    return (
      <>
        {!editMode && (
          <div className={styles.emails}>
            <div className={styles.input}>
              <KeyDetector onKeyDetected={handleEmailEnterClicked} keyCode={13}>
                <CustomText
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={intl.formatMessage({ id: "general.type-email" })}
                />
              </KeyDetector>
              <DreamButton
                disabled={!validator.isEmail(email)}
                onClick={handleEmailEnterClicked}
              >
                <Message id="general.add-email" />
              </DreamButton>
            </div>
            <div className={styles.emailsList}>
              <span>
                <Message id="general.emails" />:
              </span>
              {emails.length === 0 && <div>-</div>}
              {emails.map((email, key) => (
                <CustomTag
                  key={key}
                  withRemove
                  onRemove={() => handleEmailRemoveClicked(key)}
                >
                  {email}
                </CustomTag>
              ))}
            </div>
          </div>
        )}

        <div>
          <Message
            id="account-permissions-invite-users.permission-type"
            tagName="b"
          />
        </div>

        {user.role === USER && renderForUser()}
        {user.role === SUPER_ADMIN && renderForSuperAdmin()}

        {description && <div className={styles.description}>{description}</div>}

        {error && <div className="error">{error}</div>}

        <div className={styles.inviteWrp}>
          <DreamButton
            disabled={
              (emails.length === 0 && !editMode) || !permissionType || loading
            }
            loading={loading}
            onClick={handleInviteClicked}
          >
            <Message id={editMode ? "general.update" : "general.invite"} />
          </DreamButton>
        </div>
      </>
    );
  }

  return (
    <LayoutMainAction
      title={
        <Message
          id={editMode ? "permissions.edit-user" : "general.invite-users"}
        />
      }
      smallButton={smallButton}
    >
      <Box>{renderBoxContent()}</Box>
    </LayoutMainAction>
  );
}
