import styles from "./new-request.module.css";

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAjax } from "../../utils/hooks";
import Message from "../../components/message";
import CustomText from "../../components/custom-text";
import QuillEditor from "../../components/quill-editor";
import DreamButton from "../../components/dream-button";
import DreamAlert from "../../components/dream-alert";

export default function SupportNewRequest() {
  const location = useLocation();

  const ajax = useAjax();

  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const subject = urlSearchParams.get("subject");

    if (subject) {
      setSubject(subject);

      window.history.replaceState(
        null,
        null,
        location.href.replace(/\?.*/, "")
      );
    }
  }, [location]);

  async function handleSubmitTicketClicked() {
    const _subject = subject ? subject.trim() : false;
    const _content = content ? content.trim() : false;

    setError(false);

    if (!_subject) {
      return setError(<Message id="open-new-ticket.no-subject" />);
    } else if (!content) {
      return setError(<Message id="open-new-ticket.no-content" />);
    }

    setLoading(true);
    await ajax("/tickets/createTicket", {
      subject: _subject,
      content: _content,
    });
    setLoading(false);

    setSubject("");
    setContent("");

    setSuccess(<Message id="ticket-sent-successfully" />);
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        <Message id="open-new-ticket.title" />
      </h2>
      <div className={styles.description}>
        <Message id="open-new-ticket.description" />
      </div>
      <div>
        <div className={styles.fields}>
          <div>
            <label htmlFor="subject">
              <Message tagName="span" id="open-new-ticket.subject" />
            </label>
            <CustomText
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* <hr className={styles.hr} /> */}

      <QuillEditor value={content} setValue={setContent} />

      {error && <DreamAlert alertStyle="danger">{error}</DreamAlert>}
      {success && <DreamAlert alertStyle="success">{success}</DreamAlert>}

      <DreamButton
        className={styles.createButton}
        loading={loading}
        disabled={loading || success}
        color="light-purple"
        onClick={handleSubmitTicketClicked}
      >
        <Message id="open-new-ticket.support-please" />
      </DreamButton>
    </div>
  );
}
