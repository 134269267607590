import styles from "./reset-password.module.css";

import React, { useState, useEffect } from "react";
import LoginLayout from "../../components/login-layout";
import { useNavigate } from "react-router-dom";
import GeneratePasswordModal from "../../components/modals/generate-password";
import generatePassword from "password-generator";
import EmailBox from "../../components/email-box";
import { useAjax } from "../../utils/hooks";
import Message from "../../components/message";
import Spinner from "../../components/spinner";
import KeyDetector from "../../components/key-detector";
import CustomText from "../../components/custom-text";
import DreamPasswordStrengthBar from "../../components/password-strength-bar";
import DreamButton from "../../components/dream-button";

export default function ResetPasswordToken() {
  const ajax = useAjax();
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [isGeneratePasswordModalOpen, setIsGeneratePasswordModalOpen] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function getUserFromToken() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const token = urlSearchParams.get("token");
      const code = urlSearchParams.get("code");

      if (token && code) {
        const data = await ajax(`/users/getUserFromTokenAndCode`, {
          token,
          code,
        });

        if (data.result === "success") {
          setToken(token);
          setCode(code);
          setEmail(data.user.email);
        } else {
          navigate(`/user/login`);
        }
      } else {
        navigate(`/user/login`);
      }
    }

    getUserFromToken();
  }, [navigate, ajax]);

  async function handleResetClicked() {
    const _password = password.trim();
    const _passwordConfirm = passwordConfirm.trim();

    setError(false);

    if (passwordScore <= 1) {
      return setError(<Message id="general.weak-password" />);
    } else if (_password !== _passwordConfirm) {
      return setError(<Message id="general.password-mismatch" />);
    }

    setLoading(true);
    const data = await ajax(`/users/setPassword`, {
      token,
      code,
      newPassword: password,
    });
    setLoading(false);

    if (data.result === "success") {
      navigate(`/user/login?after_set_password=1`);
    } else {
      setError(<Message id={data.message} />);
    }
  }
  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
      setPasswordConfirm(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  function handleGeneratePasswordModalOpen() {
    let password;

    do {
      password = generatePassword(16, false, /([A-Za-z\d?-])/g);

      if (
        /\d/.test(password) &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password)
      ) {
        break;
      }
    } while (true);

    setPassword(password);
    setPasswordConfirm(password);
    // setIsGeneratePasswordModalOpen(true);
  }

  function renderFormBody() {
    if (!email) {
      return <Spinner />;
    }

    return (
      <div className={styles.form}>
        <KeyDetector
          className={styles.inputWrapper}
          onKeyDetected={handleResetClicked}
        >
          <label>
            <Message id="general.new-password" />
          </label>
          <div>
            <span>
              <CustomText
                type="password"
                maxLength={100}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </span>
          </div>
        </KeyDetector>
        <KeyDetector
          className={styles.inputWrapper}
          onKeyDetected={handleResetClicked}
        >
          <label>
            <Message id="general.password-verify" />
          </label>

          <CustomText
            type="password"
            maxLength={100}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </KeyDetector>

        <div className={styles.passwordStrengthBarWrp}>
          <DreamPasswordStrengthBar
            password={password}
            onChangeScore={setPasswordScore}
          />
        </div>

        <div className={styles.row}>
          <DreamButton
            color="white"
            className={styles.fullWidth}
            onClick={handleGeneratePasswordModalOpen}
          >
            <Message id="general.generate-password" />
          </DreamButton>
          <div className={styles.smalletTxt}>
            <Message id="general.password-description" />
          </div>
        </div>

        {error && <div className={`error ${styles.error}`}>{error}</div>}

        <div className={styles.btn}>
          <DreamButton
            disabled={loading}
            loading={loading}
            className={styles.btn}
            onClick={handleResetClicked}
          >
            <Message id="general.update-password" />
          </DreamButton>
        </div>
        <GeneratePasswordModal
          isOpen={isGeneratePasswordModalOpen}
          onClose={handleGeneratePasswordModalClosed}
        />
      </div>
    );
  }

  return (
    <LoginLayout
      title={<Message id="reset-password-token.title" />}
      description={email ? <EmailBox>{email}</EmailBox> : ""}
    >
      {renderFormBody()}
    </LoginLayout>
  );
}
