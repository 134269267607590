import styles from "./index.module.css";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { Tooltip } from "reactstrap";
import InfoSvg from "../svgs/info.svg?react";

let currentHoveredID = null;

function InfoTooltip({ content, children }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [id, setID] = useState(null);

  useEffect(() => {
    setID(shortid.generate());
  }, []);

  function handleMouseEntered() {
    currentHoveredID = id;
  }

  return (
    <div
      className={children ? "" : styles.wrapper}
      onMouseEnter={handleMouseEntered}
    >
      {children && <div id={`tooltip-${id}`}>{children}</div>}
      {!children && <InfoSvg className={styles.infoSvg} id={`tooltip-${id}`} />}

      {id && (
        <Tooltip
          placement="bottom"
          isOpen={isTooltipOpen || currentHoveredID === id}
          target={`tooltip-${id}`}
          toggle={() => setIsTooltipOpen(!isTooltipOpen)}
        >
          {content}
        </Tooltip>
      )}
    </div>
  );
}

InfoTooltip.propTypes = {
  content: PropTypes.any,
  children: PropTypes.any,
};

export default InfoTooltip;
