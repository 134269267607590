import styles from "./two-factor-authentication.module.css";

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LoginLayout from "../../components/login-layout";
import Message from "../../components/message";
import CodeDigits from "../../components/code-digits";
import Spinner from "../../components/spinner";
import DreamAlert from "../../components/dream-alert";
import { useAjax, useInitialDataFetching } from "../../utils/hooks";
import { useRecoilState } from "recoil";
import { settingsState } from "../../utils/atoms";

const codeDigitsHelpers = {};

export default function TwoFactorAuthentication() {
  const [settings] = useRecoilState(settingsState);
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const initialDataFetching = useInitialDataFetching();
  const initialDataFetchingRef = useRef(initialDataFetching);

  const ajax = useAjax();
  const ajaxRef = useRef(ajax);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!window.p1 || !window.p2) {
      delete window.p1;
      delete window.p2;

      setTimeout(() => {
        navigate(`/user/login`);
      }, 1);

      return;
    }

    setEmail(window.p1);
    setPassword(window.p2);

    delete window.p1;
    delete window.p2;
  }, [navigate]);

  async function handleCodeDigitsDone(code) {
    const _code = code.trim();

    setLoading(true);
    setError(false);

    if (!_code) {
      return setError("general.empty-code");
    }

    const data = await ajaxRef.current(`/users/login2FA`, {
      email,
      password,
      code,
      fingerprint: settings.fingerprint,
      fingerprintVisitorId: settings.fingerprintVisitorId,
    });

    if (data.result === "success") {
      if (await initialDataFetchingRef.current(true)) {
        return navigateRef.current(`/workspaces`);
      }
    } else {
      codeDigitsHelpers.reset();
      setLoading(false);
      setError("general.wrong-code");
    }
  }

  return (
    <LoginLayout
      title={<Message id="two-factor-authentication.title" />}
      description={<Message id="two-factor-authentication.description" />}
      logoutBtn={true}
    >
      <div className={styles.form} disabled={loading}>
        <CodeDigits
          className={styles.codeDigits}
          onDone={handleCodeDigitsDone}
          helpers={codeDigitsHelpers}
          autoFocus
          error={error}
        />
        {loading && (
          <div className={styles.loaderOverlay}>
            <Spinner />
          </div>
        )}
      </div>

      {error && (
        <DreamAlert alertStyle={"danger"}>
          <Message id={error} />
        </DreamAlert>
      )}
    </LoginLayout>
  );
}
