import styles from "./sidebar.module.css";

import { FormattedMessage } from "react-intl";
import React from "react";
import { DeviceNodeSidebarHeader, DeviceNodeSidebarSingle } from "./device-node";
import { PolicyNodeSidebarHeader, PolicyNodeSidebarSingle } from "./policy-node";
import { HostNodeSidebarHeader, HostNodeSidebarSingle } from "./host-node";
import { NetworkNodeSidebarHeader, NetworkNodeSidebarSingle } from "./network-node";
import { PolicyResourceEdgeSidebarSingle } from "./policy-resource-edge";
import { WorkgroupPolicyNodeSidebarHeader, WorkgroupPolicyNodeSidebarSingle } from "./workgroup-policy";
import { SimpleNodeSidebarHeader, SimpleNodeSidebarSingle } from "./simple-node";
import { ConnectorNodeSidebarHeader, ConnectorNodeSidebarSingle } from "./connector-node";
import { ConnectorPolicyNodeSidebarHeader, ConnectorPolicyNodeSidebarSingle } from "./connector-policy-node";
import { GroupNodeSidebarHeader, GroupNodeSidebarSingle } from "./group-node";
import { useState } from "react";
import { useEffect } from "react";
import ChevronSvg from "../../components/svgs/chevron.svg?react";
import CloseSvg from "../../components/svgs/cancel.svg?react";

export default function Sidebar({ nodes, selectedNodes, onSelectionChange, setNodes, selectedEdges }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function handleSingleNodeClicked(node) {
    setNodes((prevNodes) => prevNodes.map((_node) => ({ ..._node, selected: _node === node })));

    onSelectionChange({ nodes: [node], edges: [] });
  }

  function renderNodeContent(node) {
    switch (node.type) {
      case "DeviceNode":
        return DeviceNodeSidebarSingle(node);

      case "HostNode":
        return HostNodeSidebarSingle(node);

      case "NetworkNode":
        return NetworkNodeSidebarSingle(node);

      case "PolicyNode":
        return PolicyNodeSidebarSingle(node);

      case "WorkgroupPolicyNode":
        return WorkgroupPolicyNodeSidebarSingle(node);

      case "ConnectorNode":
        return ConnectorNodeSidebarSingle(node);

      case "ConnectorPolicyNode":
        return ConnectorPolicyNodeSidebarSingle(node);

      case "GroupNode":
        return GroupNodeSidebarSingle(node);

      case "SimpleNode":
        return SimpleNodeSidebarSingle(node);

      default:
        return null;
    }
  }

  function renderNodeHeader(node) {
    switch (node.type) {
      case "DeviceNode":
        return DeviceNodeSidebarHeader(node);

      case "HostNode":
        return HostNodeSidebarHeader(node);

      case "NetworkNode":
        return NetworkNodeSidebarHeader(node);

      case "PolicyNode":
        return PolicyNodeSidebarHeader(node);

      case "WorkgroupPolicyNode":
        return WorkgroupPolicyNodeSidebarHeader(node);

      case "ConnectorNode":
        return ConnectorNodeSidebarHeader(node);

      case "ConnectorPolicyNode":
        return ConnectorPolicyNodeSidebarHeader(node);

      case "GroupNode":
        return GroupNodeSidebarHeader(node);

      case "SimpleNode":
        return SimpleNodeSidebarHeader(node);

      default:
        return null;
    }
  }

  function renderEdgeContent(edge) {
    switch (edge.type) {
      case "PolicyResourceEdge":
        return PolicyResourceEdgeSidebarSingle(edge, nodes);

      default:
        return null;
    }
  }

  function renderTheSidebar() {
    if (selectedNodes?.length === 1) {
      return renderNodeContent(selectedNodes[0]);
    }

    if (selectedNodes?.length > 1) {
      return (
        <div className={styles.multiple}>
          <div>
            <FormattedMessage id='n-nodes-selected' values={{ total: selectedNodes.length }} />
          </div>

          {selectedNodes.map((node, idx) => (
            <div key={idx} className={styles.node} onClick={() => handleSingleNodeClicked(node)}>
              {renderNodeHeader(node)}
            </div>
          ))}
        </div>
      );
    }

    if (selectedEdges?.length === 1) {
      return renderEdgeContent(selectedEdges[0]);
    }

    return null;
  }

  useEffect(() => {
    if (selectedNodes?.length || selectedEdges?.length) {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  }, [selectedNodes?.length, selectedEdges?.length]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className={`${styles.wrapper}  ${isSidebarOpen ? styles.visible : ""}`}>
      <button className={styles.button} onClick={toggleSidebar}>
        {isSidebarOpen ? <CloseSvg /> : <ChevronSvg />}
      </button>
      <div>{renderTheSidebar()}</div>
    </div>
  );
}
