import styles from "./policy-resource-edge.module.css";

import React from "react";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "@xyflow/react";
import { findNodesForEdge } from "../../utils/react-flow/edges";
import { Fragment } from "react";
import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { settingsState } from "../../utils/atoms";

export default function PolicyResourceEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) {
  const [settings] = useRecoilState(settingsState);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const isVertical = Math.abs(sourceX - targetX) < 30;
  const offsetY = isVertical ? 30 : 0;

  const strokeColor = useMemo(() => {
    if (!data.accessRules) {
      return undefined;
    }

    return data.accessRules.some((accessRule) => accessRule.Action === "1")
      ? "green"
      : "red";
  }, [data.accessRules]);

  return (
    <>
      <BaseEdge id={id} path={edgePath} stroke={strokeColor} />
      {data.accessRules && settings.schemaAnimated && (
        <circle r='4' fill='blue'>
          <animateMotion dur='3s' repeatCount='indefinite' path={edgePath} />
        </circle>
      )}
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${
              labelY + offsetY
            }px)`,
            maxWidth: "100px",
          }}
          className={styles.wrapper}
        >
          <div className={styles.items}>
            {data.accessRules?.map((accessRule, idx) => (
              <span key={idx}>
                {accessRule.ServiceName}
                {accessRule.ApplicationName &&
                  ` (${accessRule.ApplicationName})`}
              </span>
            ))}
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export const PolicyResourceEdgeSidebarHeader = (edge, nodes) => {
  const connectedNodes = findNodesForEdge(nodes, [edge.source, edge.target]);
  let header = [];

  for (const node of connectedNodes) {
    if (node.type === "DeviceNode") {
      header.push(node.data.Name);
    }
    if (node.type === "HostNode") {
      header.push(node.data.HostName);
    }
  }

  return <div className={styles.header}>{header.join(" <> ")}</div>;
};

export const PolicyResourceEdgeSidebarSingle = (edge, nodes) => {
  function renderValue(value) {
    if (Array.isArray(value)) {
      return value.map((item) => item.name).join(", ");
    }
    if (typeof value === "boolean") {
      return value ? "True" : "";
    }
    if (!value || value.toString().trim() === "") {
      return "-";
    }

    return value;
  }

  return (
    <>
      {PolicyResourceEdgeSidebarHeader(edge, nodes)}
      <hr />
      <div className={styles.tableWrapper}>
        <table>
          <tbody>
            {edge.data.accessRules?.map((accessRule, idx) => (
              <Fragment key={idx}>
                {Object.entries(accessRule).map(([key, value]) => (
                  <tr key={`${idx}_${key}`}>
                    <td className={styles.tableKey}>{key}</td>
                    <td>{renderValue(value)}</td>
                  </tr>
                ))}

                <tr>
                  <td colSpan={100}>
                    <hr />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
