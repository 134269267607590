import LayoutMainAction from "../../components/layout-main-action";
import styles from "./index.module.css";

import React from "react";
import { useWorkspaces } from "../../utils/atoms";
import { useFormattedDates } from "../../utils/dates";
import { Link } from "react-router-dom";
import Message from "../../components/message";
import GridSvg from "../../components/svgs/grid.svg?react";
import { FormattedMessage } from "react-intl";

export default function Workspaces() {
  const { workspaces } = useWorkspaces();
  const { formattedDistance } = useFormattedDates();

  function renderScreenshot(ztnaDomain) {
    if (!ztnaDomain) {
      return (
        <div className={styles.noImg}>
          <FormattedMessage id='no-ztna-domain' />
        </div>
      );
    }

    if (!ztnaDomain.screenshot_url) {
      return (
        <div className={styles.noImg}>
          <FormattedMessage id='no-screenshot-available' />
        </div>
      );
    }

    return <img src={ztnaDomain.screenshot_url} />;
  }

  return (
    <div>
      <LayoutMainAction>
        <div className={styles.wrapper}>
          <div className={styles.bar}>
            <button className='actionBtn'>
              <GridSvg className={styles.currentDisplay} />
            </button>
          </div>
          <ul className={`${styles.grid} clearList`}>
            {workspaces.length === 0 && (
              <div>
                <FormattedMessage id='no-workspaces' />
              </div>
            )}

            {workspaces.map((workspace, idx) => (
              <div key={idx}>
                <Link to={`/workspaces/${workspace._id}/overview`}>
                  <li className={styles.item}>
                    <div className={styles.img}>
                      {renderScreenshot(workspace.ztnaDomain)}
                    </div>
                    <div className={styles.name}>{workspace.name}</div>
                    <div className={styles.time}>
                      <Message id='edited' />{" "}
                      {formattedDistance(workspace.updatedAt)}
                    </div>
                    {/* 
                    {ztnaDomain?.status !== "ready" && (
                      <div className={styles.notReady}>
                        <div className={styles.notReadyInner}>
                          <Spinner />
                          <Message id='ztna-is-creating' />
                        </div>
                      </div>
                    )} */}
                  </li>
                </Link>
              </div>
            ))}
          </ul>
        </div>
      </LayoutMainAction>
    </div>
  );
}
