import styles from "./payment-method.module.css";

import React, { useCallback, useEffect, useState } from "react";
import TrashSvg from "../../components/svgs/trash.svg?react";
import { WithRole } from "../../components/with-role";
import AddCreditCardModal from "../../components/modals/add-credit-card";
import Badge from "../../components/badge";
import { useAjax, useConfirm, useUser } from "../../utils/hooks";
import Message from "../../components/message";
import ClickTable from "../../components/click-table";
import { getCreditCardSvg } from "../../utils/billing";
import CustomMenu from "../../components/custom-menu";
import CustomMenuItem from "../../components/custom-menu/item";
import DreamButton from "../../components/dream-button";

export default function BillingPaymentMethod() {
  const ajax = useAjax();
  const confirm = useConfirm();
  const user = useUser();
  const [currentUser, setCurrentUser] = useState(user);
  const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [creditCards, setCreditCards] = useState(null);

  function handleMenuToggle(key) {
    isMenuOpen[key] = !isMenuOpen[key];
    setIsMenuOpen({ ...isMenuOpen });
  }

  async function handleCreditCardModalClose() {
    getCreditCards();
    setIsCreditCardModalOpen(false);
  }

  async function handleCheckoutRedirectClicked() {
    setIsCreditCardModalOpen(true);
  }

  const getCreditCards = useCallback(async () => {
    const data = await ajax("/credit-card/getCreditCards");
    setCurrentUser(data.user);
    setCreditCards(data.creditCards);
  }, [ajax]);

  useEffect(() => {
    getCreditCards();
  }, [getCreditCards]);

  async function handleSetAsPrimaryCreditCardClicked(card) {
    await ajax("/credit-card/setAsPrimary", {
      userID: currentUser._id,
      creditCardId: card._id,
    });

    await getCreditCards();
  }

  async function handleRemoveCreditCardClicked(card) {
    const state = await confirm({
      title: <Message id="billing-manage-credit-card.remove-card-title" />,
      message: <Message id="billing-manage-credit-card.remove-card-content" />,
    });

    if (state !== "button2") {
      return;
    }

    await ajax("/credit-card/removeCreditCard", {
      creditCardId: card._id,
    });

    await getCreditCards();
  }

  return (
    <WithRole permission="billing">
      <div className={styles.wrapper}>
        <ClickTable className={styles.creditCardTable}>
          <thead>
            <tr>
              <th>
                <Message id="manage-credit-card.card" />
              </th>
              <th>
                <Message id="manage-credit-card.number" />
              </th>
              <th>
                <Message id="manage-credit-card.expired" />
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {creditCards?.length === 0 && (
              <tr>
                <td colSpan={100}>
                  <Message id="billing-manage-credit-card.no-cards" />
                </td>
              </tr>
            )}

            {creditCards?.map((card, key) => (
              <tr key={key}>
                <td>
                  <div className={styles.cardSvg}>
                    {getCreditCardSvg(card.cardType)}
                  </div>
                </td>
                <td>{card.last4Digits}</td>
                <td>
                  {card.cardMonth}/{card.cardYear}
                </td>
                <td>
                  {card.primary && (
                    <Badge color="blue">
                      <Message id="billing-manage-credit-card.primary" />
                    </Badge>
                  )}
                </td>
                <td width="30">
                  <button
                    disabled={card.primary}
                    className="action-btn cleanBtn"
                    onClick={() => handleRemoveCreditCardClicked(card)}
                  >
                    <TrashSvg className="svg-trash" />
                  </button>
                </td>
                <td width="50">
                  {!card.primary && (
                    <CustomMenu
                      isOpen={isMenuOpen[`${key}`]}
                      toggle={() => handleMenuToggle(`${key}`)}
                    >
                      <CustomMenuItem
                        onClick={() =>
                          handleSetAsPrimaryCreditCardClicked(card)
                        }
                      >
                        <Message id="billing-manage-credit-card.set-as-primary-button" />
                      </CustomMenuItem>
                    </CustomMenu>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </ClickTable>
        <div className={styles.addNewCreditCardWrapper}>
          <div className={styles.buttonWrapper}>
            <DreamButton
              color="light-purple"
              onClick={handleCheckoutRedirectClicked}
              icon="plus"
            >
              <Message id="billing-manage-credit-card.add-new-card" />
            </DreamButton>
          </div>
        </div>

        <AddCreditCardModal
          isOpen={isCreditCardModalOpen}
          onClose={handleCreditCardModalClose}
          user={currentUser}
        />
      </div>
    </WithRole>
  );
}
