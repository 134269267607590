import styles from "./password.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import GeneratePasswordModal from "../modals/generate-password";
import generatePassword from "password-generator";
import { useAjax, useAlert, useUser } from "../../utils/hooks";
import Message from "../message";
import KeyDetector from "../key-detector";
import CustomText from "../custom-text";
import DreamPasswordStrengthBar from "../password-strength-bar";
import DreamButton from "../dream-button";

function SecurityPassword({ inModal }) {
  const user = useUser();
  const ajax = useAjax();
  const alert = useAlert();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isGeneratePasswordModalOpen, setIsGeneratePasswordModalOpen] =
    useState(false);

  async function handleChangeClicked() {
    setError(false);

    if (!password) {
      return setError(<Message id="general.enter-password" />);
    }
    if (passwordScore <= 1) {
      return setError(<Message id="general.weak-password" />);
    } else if (password !== passwordConfirm) {
      return setError(<Message id="general.password-mismatch" />);
    }

    setLoading(true);
    const data = await ajax("/users/updatePassword", {
      oldPassword,
      password,
    });
    setLoading(false);

    if (data.result === "error") {
      return setError(
        <Message id={data.message ? data.message : "general.same-password"} />
      );
    }

    if (inModal) {
      inModal();
    } else {
      await alert({
        title: <Message id="security-password.change-password.title" />,
        message: <Message id="security-password.change-password.content" />,
        notification: true,
      });
    }
  }

  function handleGeneratePasswordModalOpen() {
    let password;

    do {
      password = generatePassword(16, false, /([A-Za-z\d?-])/g);

      if (
        /\d/.test(password) &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password)
      ) {
        break;
      }
    } while (true);

    setPassword(password);
    setPasswordConfirm(password);
    // setIsGeneratePasswordModalOpen(true);
  }

  function handleGeneratePasswordModalClosed(password) {
    if (password) {
      setPassword(password);
      setPasswordConfirm(password);
    }

    setIsGeneratePasswordModalOpen(false);
  }

  if (!user._id) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {user.passwordLastTimeChanged && (
        <div className={styles.lastTimeWrapper}>
          <Message
            id={
              inModal
                ? "security-password.last-time-changed-in-modal"
                : "security-password.last-time-changed"
            }
            values={{
              date: format(
                new Date(user.passwordLastTimeChanged),
                "dd/MM/yy HH:mm"
              ),
            }}
          />
        </div>
      )}

      <KeyDetector onKeyDetected={handleChangeClicked}>
        <label>
          <span>
            <Message id="old-password" />
          </span>
          <CustomText
            type="password"
            maxLength={100}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </label>
      </KeyDetector>
      <KeyDetector onKeyDetected={handleChangeClicked}>
        <label>
          <span>
            <Message id="new-password" />
          </span>
          <CustomText
            type="password"
            maxLength={100}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
      </KeyDetector>
      <KeyDetector onKeyDetected={handleChangeClicked}>
        <label>
          <span>
            <Message id="confirm-password" />
          </span>
          <CustomText
            type="password"
            maxLength={100}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </label>
      </KeyDetector>

      <div className={styles.passwordStrengthBarWrapper}>
        <DreamPasswordStrengthBar
          password={password}
          onChangeScore={setPasswordScore}
        />
      </div>

      <div className={styles.buttons}>
        <DreamButton color="white" onClick={handleGeneratePasswordModalOpen}>
          <Message id="general.generate" />
        </DreamButton>

        <span>
          <Message id="change-password.description" />
        </span>

        <DreamButton
          disabled={loading}
          loading={loading}
          color="light-purple"
          onClick={handleChangeClicked}
        >
          <Message id="general.update" />
        </DreamButton>
      </div>

      {error && <div className={`error ${styles.error}`}>{error}</div>}

      <GeneratePasswordModal
        isOpen={isGeneratePasswordModalOpen}
        onClose={handleGeneratePasswordModalClosed}
      />
    </div>
  );
}

SecurityPassword.propTypes = {
  inModal: PropTypes.func,
};

export default SecurityPassword;
