import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import copy from "copy-to-clipboard";
import CopySvg from "../svgs/copy.svg?react";
import DreamButton from "../dream-button";
import Message from "../message";
import EyeOnSvg from "../svgs/eye.svg?react";
import EyeOffSvg from "../svgs/eye-off.svg?react";
import ClipboardSvg from "../svgs/clipboard.svg?react";

function copySize(size) {
  let copySize;

  switch (size) {
    case "sm":
      copySize = "260px";
      break;
    case "md":
      copySize = "360px";
      break;
    case "lg":
      copySize = "460px";
      break;
    default:
      copySize = "fit-content";
  }

  return copySize;
}

export default function CopyRow({ title, text, toCopy, secured, size }) {
  const [copied, setCopied] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  let timerId = null;
  const maxWidth = copySize(size);

  function handleCopy(toCopy) {
    copy(toCopy);
    setCopied(true);
    timerId = setTimeout(() => {
      setCopied(false);
    }, 500);
  }

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  const EyeSvg = passwordVisible ? EyeOffSvg : EyeOnSvg;

  return (
    <div className={`${styles.wrapper}`} style={{ maxWidth: `${maxWidth}` }}>
      {title && (
        <div className={styles.title}>
          <Message id={title} />
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.field}>{secured && !passwordVisible ? <span className={styles.password}>**********</span> : text}</div>
        <DreamButton color='text' className={styles.btn} onClick={() => setPasswordVisible(!passwordVisible)}>
          {secured && <EyeSvg className={styles.svg} />}
        </DreamButton>
        <DreamButton color='text' className={`${styles.btn} ${styles.btnCopy}`} onClick={() => handleCopy(toCopy)}>
          {copied ? <div className={styles.copied}>Copied!</div> : null}
          <CopySvg className={styles.svg} /> Copy
        </DreamButton>
      </div>
    </div>
  );
}
