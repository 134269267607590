import { format, formatDistance } from "date-fns";
import { enUS } from "date-fns/locale";
export const useFormattedDates = () => {
  let locale = enUS;

  return {
    formattedDate: (date) => {
      return format(new Date(date), "PPPP", {
        locale: locale,
      });
    },
    formattedShortDate: (date) => {
      return format(new Date(date), "PP", {
        locale: locale,
      });
    },
    formattedTime: (time) => {
      return format(new Date(time), "HH:mm", {
        locale: locale,
      });
    },
    formattedDistance: (date) => {
      return formatDistance(new Date(date), new Date(), {
        locale: locale,
        addSuffix: true,
      });
    },
  };
};
