import styles from "./index.module.css";

import React from "react";
import { useUser } from "../../utils/hooks";
import { useEffect } from "react";

export default function Avatar() {
  const user = useUser();

  const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`.toUpperCase();

  useEffect(() => {
    document.documentElement.style.setProperty("--user-profile-color", user.profileColor);
  }, [user.profileColor]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.hightlight}>
        <span className={styles.avatar} style={{ background: user.profileColor }}>
          {user.profile ? <img src={user.profilePicture} /> : initials}
        </span>
      </div>
    </div>
  );
}
