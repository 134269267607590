import styles from "./index.module.css";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";
import { Outlet, useLocation } from "react-router-dom";
import { useAjax } from "../../utils/hooks";
import Message from "../message";
import LayoutMainAction from "../layout-main-action";
import { getFullName } from "../../utils/user";
import Navigator from "../navigator";

function AdminUserLayout({ user }) {
  const ajax = useAjax();
  const location = useLocation();

  const [items, setItems] = useState([]);

  const [loginByLink, setLoginByLink] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      return setItems([]);
    }

    const arr = [
      {
        href: "/clients/clients-list/[id]/summary",
        as: `/clients/clients-list/${user._id}/summary`,
        selected: location.pathname.endsWith("/summary"),
        title: <Message id="admin-user-layout.summary" />,
      },
      {
        href: "/clients/clients-list/[id]/documents",
        as: `/clients/clients-list/${user._id}/documents`,
        selected: location.pathname.endsWith("/documents"),
        title: <Message id="admin-user-layout.documents" />,
      },
      {
        title: <Message id="admin-user-layout.show-invoices" />,
        href: "/billing/invoices",
        as: `/billing/invoices?filter=${user.email}`,
      },
    ];

    setItems(arr);
  }, [location, user]);

  const handleCreateLoginClickClicked = useCallback(async () => {
    setLoading(true);
    const data = await ajax("/admin/createLoginLink", { userID: user._id });
    setLoading(false);

    if (data.result === "success") {
      setLoginByLink(
        `${
          import.meta.env.VITE_APP_SERVER_URL
        }/login_by_token?token=${encodeURIComponent(data.token)}`
      );

      setTimeout(() => {
        setLoginByLink(false);
        setLinkCopied(false);
      }, 5000);
    }
  }, [ajax, user]);

  const handleCopyLoginClickClicked = useCallback(() => {
    copy(loginByLink);
    setLinkCopied(true);
  }, [loginByLink]);

  const buttons = useMemo(() => {
    if (loginByLink) {
      return [
        {
          title: (
            <Message
              id={
                linkCopied
                  ? "admin-user-layout.login-link-copied"
                  : "admin-user-layout.copy-login-link"
              }
            />
          ),
          color: "purple",
          onClick: handleCopyLoginClickClicked,
        },
      ];
    }

    return [
      {
        title: <Message id="admin-user-layout.create-login-link" />,
        onClick: handleCreateLoginClickClicked,
        disabled: loading,
      },
    ];
  }, [
    handleCopyLoginClickClicked,
    handleCreateLoginClickClicked,
    linkCopied,
    loading,
    loginByLink,
  ]);

  return (
    <LayoutMainAction
      title={user && `${getFullName(user)}, ${user.email}`}
      buttons={buttons}
      smallButton={{
        title: <Message id="admin-user-layout.back-button" />,
        href: "/clients/clients-list",
        to: `/clients/clients-list`,
      }}
    >
      <Navigator
        className={styles.navigatorWrapper}
        items={items}
        styleMode={2}
      />

      <Outlet context={{ user }} />
    </LayoutMainAction>
  );
}

AdminUserLayout.propTypes = {
  user: PropTypes.object,
};

export default AdminUserLayout;
