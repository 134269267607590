import styles from "./actions-bar.module.css";
import RefreshSvg from "../svgs/refresh.svg?react";
import LockSvg from "../svgs/lock.svg?react";
import UnlockSvg from "../svgs/unlock.svg?react";
import PlayCircleSvg from "../svgs/play-circle.svg?react";
import PauseCircleSvg from "../svgs/pause-circle.svg?react";

import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";
import { Tooltip } from "reactstrap";
import { settingsState } from "../../utils/atoms";
import { useRecoilState } from "recoil";

let timerID;

export default function ActionsBar({ lastUpdate, getData }) {
  const [settings, setSettings] = useRecoilState(settingsState);

  const [refreshing, setRefreshing] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleRefreshClicked = useCallback(async () => {
    clearTimeout(timerID);

    setRefreshing(true);

    await getData();

    setRefreshing(false);

    timerID = setTimeout(() => {
      handleRefreshClicked();
    }, 5000);
  }, [getData]);

  useEffect(() => {
    timerID = setTimeout(() => {
      handleRefreshClicked();
    }, 5000);

    return () => {
      clearTimeout(timerID);
    };
  }, [handleRefreshClicked]);

  function handleLockClicked() {
    setSettings({
      ...settings,
      schemaReadOnly: !settings.schemaReadOnly,
    });
  }

  function handleToggleAnimation() {
    setSettings({
      ...settings,
      schemaAnimated: !settings.schemaAnimated,
    });
  }

  const LockUnlockSvg = settings.schemaReadOnly ? LockSvg : UnlockSvg;

  return (
    <div className={styles.wrapper}>
      <button className='actionBtn' style={{ color: settings.schemaReadOnly ? "var(--primary-color)" : "" }} title={settings.schemaReadOnly ? "Schema is locked for readonly" : "Schema unlocked for edit"}>
        <LockUnlockSvg onClick={handleLockClicked} />
      </button>

      <button className='actionBtn' disabled={refreshing} title='Refresh'>
        <RefreshSvg id='tooltip-refresh' onClick={handleRefreshClicked} className={refreshing ? "spin" : ""} />
        <Tooltip placement='left' isOpen={tooltipOpen} target='tooltip-refresh' toggle={() => setTooltipOpen(!tooltipOpen)}>
          <FormattedMessage id='last-update' /> {lastUpdate ? format(lastUpdate, "HH:mm:ss") : "-"}
        </Tooltip>
      </button>

      {settings.schemaAnimated && <PauseCircleSvg onClick={handleToggleAnimation} />}
      {!settings.schemaAnimated && <PlayCircleSvg onClick={handleToggleAnimation} />}
    </div>
  );
}
