import styles from "./index.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import Message from "../message";

function Box({ title, titleOpts = {}, expanded, className, highlight = false, style = {}, children, titleTag = "h2", titleSpace, description }) {
  const [isOpen, setIsOpen] = useState(false);

  const Title = ({ as: Tag = "h2", children }) => {
    let size;

    switch (Tag) {
      case "h1":
        size = "32px";
        break;
      case "h2":
        size = "22px";
        break;
      default:
        size = "18px";
    }

    return <Tag style={{ fontSize: size }}>{children}</Tag>;
  };

  return (
    <div onClick={() => setIsOpen(!isOpen)} className={`${className || ""} ${styles.wrapper} ${title ? styles.hasTitle : ""} ${expanded ? styles.cursor : ""} ${highlight && "highlight"}`} style={style}>
      {title && (
        <div className={titleOpts.inBox ? styles.titleInBox : styles.title}>
          <Title as={titleTag} style={titleSpace ? { marginBottom: "1.5rem" } : {}}>
            <Message id={title} />
          </Title>
        </div>
      )}

      {description && (
        <div className={styles.description}>
          <Message id={description} />
        </div>
      )}

      {expanded ? children(isOpen) : children}

      {expanded && (
        <div onClick={(e) => e.stopPropagation()} className={`expanded ${isOpen ? "isOpen" : ""} ${styles.expanded} ${isOpen ? styles.isOpen : ""}`}>
          {expanded}
        </div>
      )}
    </div>
  );
}

Box.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  titleOpts: PropTypes.object,
  expanded: PropTypes.any,
  highlight: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

export default Box;
