import styles from "./login.module.css";

import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../../components/login-layout";
import EmailBox from "../../components/email-box";
import { useAjax, useInitialDataFetching } from "../../utils/hooks";
import Message from "../../components/message";
import KeyDetector from "../../components/key-detector";
import CustomText from "../../components/custom-text";
import DreamAlert from "../../components/dream-alert";
import DreamButton from "../../components/dream-button";
import { sanitize } from "../../utils";
import { settingsState } from "../../utils/atoms";
import { useRecoilState } from "recoil";
import { useIntl } from "react-intl";

export default function Login() {
  const navigate = useNavigate();
  const ajax = useAjax();
  const intl = useIntl();
  const initialDataFetching = useInitialDataFetching();
  const [settings] = useRecoilState(settingsState);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordStep, setPasswordStep] = useState(false);

  useEffect(() => {
    if (window.location.search === "?after_reset_password=1") {
      setMessage("login.check-mail");
    }

    if (window.location.search === "?after_set_password=1") {
      setMessage("login.password-set");
    }

    if (window.location.search === "?after_create_account=1") {
      setMessage("login.account-created");
    }
  }, []);

  const setCookie = useCallback(async () => {
    navigate(`/workspaces`);
  }, [navigate]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const ls = urlSearchParams.get("ls");

    if (ls) {
      setCookie(ls);
    }
  }, [setCookie]);

  async function handleLoginClicked() {
    const _email = email.trim().toLowerCase();
    const _password = password.trim();

    setError(false);
    setErrorAlert(false);

    if (!_password) {
      return setError("general.weak-password");
    }

    setLoading(true);

    const data = await ajax(`/users/login`, {
      email: _email,
      password,
      fingerprint: settings.fingerprint,
      fingerprintVisitorId: settings.fingerprintVisitorId,
    });

    if (data.result === "success") {
      if (data.message === "new-ip") {
        window.p1 = data.user.email;
        window.p2 = password;
        return navigate(`/user/email-verification`);
      }

      if (!data.userid) {
        window.p1 = data.user.email;
        window.p2 = password;
        return navigate(`/user/two-factor-authentication`);
      }

      if (await initialDataFetching(true)) {
        if (data.accounts > 0) {
          navigate(`/user/accounts`);
        } else if (data.registerStep !== -1) {
          localStorage.setItem("openModal", true);
          navigate(
            `/user/register` + (data.registerStep === 2 ? "?back=1" : "")
          );
        } else {
          localStorage.setItem("openModal", true);
          navigate(`/workspaces`);
        }
      }
    } else {
      if (data.message === "ip-not-authorized") {
        setErrorAlert("general.ip-not-authorized");
      } else {
        setError(data.message || "general.wrong-email-password");
      }

      setLoading(false);
    }
  }

  useEffect(() => {
    if (!email) {
      setError(false);
    }
  }, [email]);

  async function handleEmailClicked() {
    const _email = email.trim().toLowerCase();
    setMessage(false);
    setError(false);
    setLoading(true);

    const data = await ajax(`/users/checkEmail`, {
      email: _email,
    });

    if (data.result === "success") {
      setMessage(false);
      setEmail(data.user.email);
      setFirstName(data.user.firstName);
      setPasswordStep(true);
      setTimeout(() => {
        const passwordInput = document.querySelector(".password-input input");
        if (passwordInput) {
          passwordInput.focus();
        }
      }, 1);
    } else {
      setError(data.message);
    }
    setLoading(false);
  }

  if (!passwordStep) {
    return (
      <LoginLayout title={<Message id="login.title" />}>
        <div className={`userForm ${styles.form}`}>
          <KeyDetector
            className={styles.inputWrapper}
            onKeyDetected={handleEmailClicked}
          >
            <label>
              <Message id="general.email-phone" />
            </label>

            <CustomText
              autoFocus
              inputClassName="ltr text-align-left"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="username"
              id="username"
              key="username"
              errorText={error}
            />
          </KeyDetector>

          <div className={styles.forgotWrapper}>
            <Link to={`/user/forgot-email`}>
              <Message id="did-you-forgot-email" />
            </Link>
          </div>

          {errorAlert && (
            <DreamAlert alertStyle={"danger"}>
              <Message id={errorAlert} />
            </DreamAlert>
          )}

          {message && (
            <DreamAlert alertStyle="success">
              <Message id={message} />
            </DreamAlert>
          )}

          <div className={styles.loginWrapper}>
            <DreamButton
              disabled={!email ? true : loading}
              loading={loading}
              onClick={handleEmailClicked}
            >
              <Message id="next" />
            </DreamButton>
          </div>

          <div className={styles.or}>
            {/* <hr /> */}
            <Message id="login.register.text1" />
          </div>

          <DreamButton
            className={styles.createButton}
            href="/user/register"
            as={`/user/register`}
            color="white"
          >
            <Message id="login.register" />
          </DreamButton>
        </div>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout
      title={
        <Message id="hi-user" values={{ firstName: sanitize(firstName) }} />
      }
      description={<EmailBox>{email}</EmailBox>}
      logoutBtn={true}
    >
      <form
        className={`userForm ${styles.form}`}
        onSubmit={(e) => e.preventDefault()}
      >
        <KeyDetector
          className={styles.inputWrapper}
          onKeyDetected={handleLoginClicked}
        >
          <label>
            <Message id="general.password" />
          </label>

          <CustomText
            autoFocus
            className="focusField password-input"
            eyeClassName={styles.eye}
            type="password"
            maxLength={100}
            value={password}
            name="password"
            id="password"
            key="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder={intl.formatMessage({ id: "general.password" })}
            errorText={error}
          />
        </KeyDetector>

        <div className={styles.forgotWrapper}>
          <Link to={`/user/forgot-password?email=${email}`}>
            <Message id="forgot-the-password" />
          </Link>
        </div>

        {errorAlert && (
          <DreamAlert alertStyle="danger">{errorAlert}</DreamAlert>
        )}

        {message && (
          <DreamAlert alertStyle="success">
            <Message id={message} />
          </DreamAlert>
        )}

        <div className={styles.loginWrapper}>
          <DreamButton
            disabled={!password ? true : loading}
            loading={loading}
            onClick={handleLoginClicked}
          >
            <Message id="next" />
          </DreamButton>
        </div>
      </form>
    </LoginLayout>
  );
}
