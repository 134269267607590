import styles from "./forgot-email.module.css";

import React, { useEffect, useMemo, useState } from "react";
import validator from "validator";
import { parsePhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";
import LoginLayout from "../../components/login-layout";
import LongArrowLeft from "../../components/svgs/arrow-left-lined.svg?react";
import EmailBox from "../../components/email-box";
import { useAjax, useSmsCounter } from "../../utils/hooks";
import { countriesPhoneCodes } from "../../utils/countries";
import Message from "../../components/message";
import CodeDigits from "../../components/code-digits";
import DreamAlert from "../../components/dream-alert";
import DreamButton from "../../components/dream-button";
import KeyDetector from "../../components/key-detector";
import PhoneInput from "../../components/phone-input";
import { replaceNCharsWithAsterisks, sanitize } from "../../utils";
import { useRecoilState } from "recoil";
import { settingsState } from "../../utils/atoms";

let codeDigitsHelpers = {};

export default function ForgotEmail() {
  const ajax = useAjax();
  const smsCounter = useSmsCounter();
  const [settings] = useRecoilState(settingsState);

  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [pageState, setPageState] = useState("waiting-for-phone");
  const [hash, setHash] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!phoneNumber) {
      setError(false);
    }
  }, [phoneNumber]);

  async function handleNextClicked() {
    setError(false);
    try {
      parsePhoneNumber(phoneNumber, countryCode);

      setLoading(true);
      const data = await ajax("/users/restoreEmail", {
        phoneCode: countriesPhoneCodes[countryCode],
        phoneNumber: phoneNumber.startsWith("0")
          ? phoneNumber.substring(1)
          : phoneNumber,
        countryCode,
      });
      setLoading(false);

      if (data.result === "success") {
        smsCounter.start();
        setHash(data.hash);
        setPageState("waiting-for-code");
      } else {
        setError(<Message id="phone-number-not-valid" />);
      }
    } catch (err) {
      setError(<Message id="phone-number-not-valid" />);
    }
  }

  async function handleCodeDigitsDone(code) {
    setLoading(true);
    const data = await ajax("/users/restoreEmailAfterCode", { code, hash });
    setLoading(false);

    if (data.result === "success") {
      setEmail(data.email);
      setPageState("showing-email");
    } else {
      codeDigitsHelpers.reset();
      setError(<Message id="wrong-code" />);
    }
  }

  function handleResendClicked() {
    smsCounter.reset();
    codeDigitsHelpers.reset();
    handleNextClicked();
  }

  function userCountry() {
    return !settings.countryOfIp ? "US" : settings.countryOfIp;
  }

  function renderFormBody() {
    if (pageState === "showing-email") {
      return <EmailBox>{email}</EmailBox>;
    }

    if (pageState === "waiting-for-code") {
      return (
        <div className={styles.waitingForCode}>
          <div className={`userForm`}>
            <CodeDigits
              className={styles.codeDigits}
              onDone={handleCodeDigitsDone}
              helpers={codeDigitsHelpers}
              loginWrapper
              autoFocus
            />
          </div>

          {error && <DreamAlert alertStyle={"danger"}>{error}</DreamAlert>}

          <div className={styles.loginWrapper}>
            {smsCounter.counter > 0 ? (
              <div className={styles.resend}>
                <Message
                  id="general.resend-code-counter"
                  values={{ counter: smsCounter.counter }}
                />
              </div>
            ) : (
              <div className={styles.resend}>
                <Message id="general.dont-recive-code" />
                <DreamButton
                  disabled={loading || smsCounter.counter > 0}
                  color="text"
                  onClick={handleResendClicked}
                  loading={loading}
                >
                  <Message id="general.resend-code" />
                </DreamButton>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        <KeyDetector
          className={`userForm ${styles.inputWrapper}`}
          onKeyDetected={handleNextClicked}
        >
          <label>
            <Message id="phone-number" />
          </label>
          <PhoneInput
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            defaultCountry={userCountry()}
            errorText={error}
          />
        </KeyDetector>
        <div className={styles.loginWrapper}>
          <DreamButton
            disabled={loading || !validator.isNumeric(phoneNumber)}
            loading={loading}
            onClick={handleNextClicked}
          >
            <Message id="next" />
          </DreamButton>
        </div>
      </>
    );
  }

  const description = useMemo(() => {
    if (pageState === "showing-email") {
      return (
        <div className={styles.description}>
          <Message id="email-address-restore.description-email" />
        </div>
      );
    }

    if (pageState === "waiting-for-code") {
      return (
        <div className={styles.description}>
          <Message
            id="email-address-restore.description-after"
            values={{
              number: sanitize(
                `<span style="direction: ltr; display: inline-block;">${
                  countriesPhoneCodes[countryCode]
                }${replaceNCharsWithAsterisks(
                  phoneNumber,
                  phoneNumber.length - 3
                )}</span>`
              ),
            }}
          />
        </div>
      );
    }

    return (
      <div className={styles.description}>
        <Message id="email-address-restore.description" />
      </div>
    );
  }, [pageState, countryCode, phoneNumber]);

  const layoutTitle = useMemo(() => {
    if (pageState === "showing-email") {
      return <Message id="email-address-restore.showing-email" />;
    }

    if (pageState === "waiting-for-code") {
      return <Message id="email-address-restore.waiting-for-code" />;
    }

    return <Message id="email-address-restore" />;
  }, [pageState]);

  return (
    <LoginLayout title={layoutTitle} description={description}>
      <div
        className={styles.form}
        style={{
          maxWidth: pageState === "waiting-for-code" ? "440px" : "350px",
        }}
      >
        {renderFormBody()}

        <div className={styles.backWrapper}>
          <Link to={`/user/login`}>
            <LongArrowLeft className="svg-arrow" />
            <Message id="back-to-login" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
