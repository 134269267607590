import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import OptionsSvg from "../svgs/options.svg?react";

function CustomMenu({
  isOpen,
  toggle,
  color = "purple",
  children,
  className,
  direction = null,
  customSvg = null,
}) {
  return (
    <Dropdown
      direction={direction}
      className={`${styles.wrapper} ${styles[color]} ${
        className ? className : ""
      }`}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle nav>
        {customSvg || <OptionsSvg className={styles.svgOptions} />}
      </DropdownToggle>
      <DropdownMenu right>{children}</DropdownMenu>
    </Dropdown>
  );
}

CustomMenu.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  color: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  direction: PropTypes.oneOf(["up", "down", "left", "right"]),
  customSvg: PropTypes.any,
};

export default CustomMenu;
