import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import Greentick from "../svgs/greentick.svg?react";

function CheckButton({ selected, text, onClick }) {
  return (
    <div
      className={`${styles.main} ${selected ? styles.selected : null}`}
      onClick={onClick}
    >
      {selected ? <Greentick /> : null}
      <span>{text}</span>
    </div>
  );
}

CheckButton.propTypes = {
  selected: PropTypes.bool,
  text: PropTypes.any,
  onClick: PropTypes.func,
};

export default CheckButton;
