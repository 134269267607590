import styles from "./security.module.css";

import React from "react";
import SecurityPassword from "../../components/security/password";
import AutoLogout from "../../components/security/auto-logout";
import TwoFactorAuthentication from "../../components/security/two-factor-authentication";
import Message from "../../components/message";

export default function AccountSecurity() {
  return (
    <div className={`${styles.wrapper} ${styles.securityWrap}`}>
      <div className={styles.boxWrapper}>
        <div className={styles.larger}>
          <Message id="account-security.main-description" />
        </div>
        <SecurityPassword />
      </div>
      <hr />
      <div className={styles.boxWrapper}>
        <div className={styles.larger}>
          <Message id="account-security.two-factor-authentication" />
          <h2 className={styles.mainDescription}>
            <Message id="account-security.two-factor-description" />
          </h2>
        </div>
        <TwoFactorAuthentication />
      </div>
      <hr />
      <div className={styles.boxWrapper}>
        <div className={styles.larger}>
          <Message id="account-security.auto-logout-settings" />
        </div>
        <AutoLogout />
      </div>
    </div>
  );
}
