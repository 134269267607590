import styles from "./index.module.css";

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ChevronSvg from "../svgs/chevron.svg?react";

function Pagination({ page, setPage, totalRows, maxRowsInPage, className }) {
  const maxPages = useMemo(() => {
    return Math.ceil(totalRows / maxRowsInPage);
  }, [maxRowsInPage, totalRows]);

  if (!maxPages) {
    return <div className={styles.placeholder}></div>;
  }

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <button
        className={styles.btn}
        disabled={page === 0}
        onClick={() => setPage(page - 1)}
      >
        <ChevronSvg className={page === 0 ? styles.disabledSvg : ""} />
      </button>

      <div className={styles.text}>
        {page + 1} <span className={styles.split}>/</span> {maxPages}
      </div>

      <button
        className={styles.btn}
        disabled={page + 1 === maxPages}
        onClick={() => setPage(page + 1)}
      >
        <ChevronSvg
          className={`${styles.chevronNext} ${
            page + 1 === maxPages ? styles.disabledSvg : ""
          }`}
        />
      </button>
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  totalRows: PropTypes.number,
  maxRowsInPage: PropTypes.number,
  className: PropTypes.string,
};

export default Pagination;
