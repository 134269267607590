import styles from "./node-wrapper.module.css";
import PolicySvg from "../svgs/policy.svg?react";
import React from "react";
import NodeWrapper from "./node-wrapper";
import { Handle } from "@xyflow/react";

export default function PolicyNode(props) {
  function handleDoubleClick(e) {
    e.preventDefault();
  }

  return (
    <NodeWrapper {...props} onDoubleClick={handleDoubleClick}>
      <Handle className={styles.handle} type='target' id={props.id} />
      <Handle className={styles.handle} type='source' id={props.id} />
      <div>
        <PolicySvg />
      </div>
      <div className={styles.label}>
        {props.data.collectionName} {props.data.adaptiveMode ? `(adaptive mode)` : null}
      </div>
    </NodeWrapper>
  );
}

export const PolicyNodeSidebarHeader = (node) => {
  return (
    <div className={styles.header}>
      <PolicySvg /> {node.data.collectionName}
    </div>
  );
};

export const PolicyNodeSidebarSingle = (node) => {
  function renderValue(value) {
    if (Array.isArray(value)) {
      return value.map((item) => item.name).join(", ");
    }
    if (typeof value === "boolean") {
      return value ? "True" : "";
    }
    if (!value || value.toString().trim() === "") {
      return "-";
    }

    return value;
  }

  return (
    <>
      {PolicyNodeSidebarHeader(node)}
      <hr />
      <div className={styles.tableWrapper}>
        <table>
          <tbody>
            {Object.entries(node.data).map(([key, value]) => (
              <tr key={key}>
                <td className={styles.tableKey}>{key}</td>
                <td>{renderValue(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
