import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";

function Spinner({ width = 22, height = 22, className = "" }) {
  return <Loader className={`${styles.wrapper} ${className}`} type='Oval' color='currentColor' width={width} height={height} />;
}

Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default Spinner;
