import styles from "./index.module.css";
import EditSvg from "../../../../components/svgs/edit-purple.svg?react";

import React from "react";
import Message from "../../../../components/message";
import { format } from "date-fns";
import InfoAlert from "../../../../components/info-alert";
import Spinner from "../../../../components/spinner";
import { useWorkspace, useWorkspaces } from "../../../../utils/atoms";
import { ajax } from "../../../../utils";
import { usePrompt } from "../../../../utils/hooks";
import Show from "../../../../components/show";

import ExternalLinkSvg from "../../../../components/svgs/external-link.svg?react";
import CopyRow from "../../../../components/copy-row";
import NewZtnaDomain from "../../../../components/new-ztna-domain";

export default function ZtnaManagementOverview() {
  const prompt = usePrompt();
  const { workspace } = useWorkspace();
  const { updateWorkspaces } = useWorkspaces();

  function handleRenameWorkspaceClicked() {
    prompt({
      title: (
        <Message
          id="rename-workspace-title"
          values={{ name: workspace.name }}
        />
      ),
      message: <Message id="rename-workspace-message" />,
      defaultText: workspace.name,
      acceptOnlyValue: (name) => {
        const _name = name.trim();

        return { status: !!_name, reason: <Message id="errors.name" /> };
      },
      beforeClose: async (name) => {
        const _name = name.trim();

        await ajax("/workspace/rename", {
          workspaceID: workspace._id,
          name: _name,
        });

        await updateWorkspaces();
      },
    });
  }

  // function handleRenameDomainClicked() {
  //   prompt({
  //     title: <Message id='change-domain-title' values={{ domain: ztnaDomain.domain }} />,
  //     message: <Message id='change-domain-message' />,
  //     defaultText: ztnaDomain.domain,
  //     acceptOnlyValue: async (domain) => {
  //       const _domain = domain.trim();

  //       if (_domain === ztnaDomain.domain) {
  //         return {
  //           status: false,
  //           reason: <Message id='same-domain' />,
  //         };
  //       }

  //       if (!_domain.endsWith(".ztna.me")) {
  //         return {
  //           status: false,
  //           reason: <Message id='domain-must-end-with' values={{ domain: ".ztna.me" }} />,
  //         };
  //       }

  //       if (!validator.isFQDN(_domain)) {
  //         return {
  //           status: false,
  //           reason: <Message id='errors.domain' />,
  //         };
  //       }

  //       const data = await ajax("/ztna-domain/rename", {
  //         ztnaDomainID: ztnaDomain._id,
  //         newDomain: domain,
  //       });

  //       if (data.result === "error") {
  //         return {
  //           status: false,
  //           reason: <Message id={data.message} />,
  //         };
  //       }

  //       return { status: true };
  //     },
  //     beforeClose: async () => {
  //       await updateZtnaDomain();
  //     },
  //   });
  // }

  if (!workspace.ztnaDomain) {
    return <NewZtnaDomain />;
  }

  return (
    <div className={styles.wrapper}>
      <h1 className="title">{workspace?.name}</h1>
      <div className={styles.flex}>
        <span>
          <Message id="workspace-name" />
        </span>
        <div className={styles.withEdit}>
          <div className={styles.grey}>{workspace?.name || <Spinner />}</div>
          <EditSvg onClick={handleRenameWorkspaceClicked} />
        </div>
      </div>

      <div className={styles.flex}>
        <span>
          <Message id="ssl-domain" />
        </span>
        <div className={styles.grey}>{workspace.ztnaDomain.domain}</div>
      </div>

      <div className={styles.flex}>
        <span>
          <Message id="location" />
        </span>
        <div className={styles.grey}>Israel</div>
      </div>
      <div className={styles.flex}>
        <span>
          <Message id="created-at" />
        </span>
        <div className={styles.grey}>
          {format(new Date(workspace.ztnaDomain.createdAt), "PPpp")}
        </div>
      </div>
      <div className={styles.title}>
        <Message id="manage-ztna" />
      </div>
      <Show when={workspace.ztnaDomain?.status !== "ready"} fallback={<br />}>
        <InfoAlert>
          <Spinner />
          <Message id="server-set-up" />
        </InfoAlert>
      </Show>

      <div
        className={`${
          workspace.ztnaDomain?.status !== "ready" ? "disabled" : ""
        }`}
      >
        <Message id="server-url" />
        <div className={styles.link}>
          <a target="_blank" href={`https://${workspace.ztnaDomain.domain}`}>
            https://{workspace.ztnaDomain.domain}
            <ExternalLinkSvg className={styles.iconExternalLink} />
          </a>
        </div>
        <CopyRow
          title="username"
          text="truepassad@cloudraw.local"
          toCopy="truepassad@cloudraw.local"
          size="sm"
        />
        <CopyRow
          title="password"
          text="ZAQ!1qazXS"
          toCopy="ZAQ!1qazXS"
          size="sm"
          secured
        />
      </div>
    </div>
  );
}
