import styles from "./index.module.css";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import IframePopupModal from "../modals/iframe-popup";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAjax, useUser } from "../../utils/hooks";
import { useMemo } from "react";

const options = {
  fields: {
    billingDetails: {
      address: {
        country: "never",
      },
    },
  },
};

const StripeForm = ({
  formRef,
  onPaymentData,
  users,
  billingCycle,
  setLoading,
}) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const ajax = useAjax();
  const user = useUser();
  const [error, setError] = useState("");
  const [iframe, setIframe] = useState("");
  const [isopen, setIsOpen] = useState(false);

  let paymentMethodID = user.billing.stripe;

  const handleMessage = useCallback(
    async (e) => {
      if (typeof e.data !== "string") {
        return;
      }

      if (!e.data.startsWith("3DS-authentication-complete")) {
        return;
      }
      setIframe("");

      if (e.data.includes("succeeded")) {
        await ajax("/users/upStep", { u_id: user._id });
        return navigate("/workspaces");
      }

      setError("3DS Error");
    },
    [ajax, user._id, navigate]
  );

  useEffect(() => {
    if (onPaymentData) {
      return;
    }

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage, onPaymentData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let paymentMethodG;

    setIframe("");
    setIsOpen(false);

    if (!stripe || !elements) {
      setError("Try again");
      return;
    }

    const { error } = await elements.submit();
    if (error) {
      setError("Try again");
      return;
    }

    if (typeof setLoading === "function") {
      setLoading(true);
    }

    {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            address: {
              country: user.country,
            },
          },
        },
      });
      if (error) {
        if (typeof setLoading === "function") {
          setLoading(false);
        }
        setError(error.message);
        return;
      } else {
        paymentMethodID = paymentMethod.id;
        paymentMethodG = paymentMethod;
      }
    }

    {
      try {
        const paymentIntent = await ajax(
          "/billing/stripe/createPaymentMethod",
          {
            userid: user._id,
            paymentMethodID,
            paymentMethodG,
            addDB: onPaymentData ? false : true,
            users,
            billingCycle,
          }
        );

        if (paymentIntent.result === "error") {
          if (typeof setLoading === "function") {
            setLoading(false);
          }
          setError(paymentIntent.message);
          setIframe("");
          return;
        }

        if (paymentIntent.paymentIntent.next_action) {
          if (onPaymentData) {
            if (typeof setLoading === "function") {
              setLoading(false);
            }
            return onPaymentData(
              paymentIntent.paymentIntent.next_action.redirect_to_url.url,
              paymentIntent.paymentIntent,
              paymentMethodG,
              paymentMethodID
            );
          }
          setIframe(
            paymentIntent.paymentIntent.next_action.redirect_to_url.url
          );
          setIsOpen(true);
        } else {
          if (onPaymentData) {
            if (typeof setLoading === "function") {
              setLoading(false);
            }
            return onPaymentData("");
          }
          await ajax("/users/upStep", { u_id: user._id });
          if (typeof setLoading === "function") {
            setLoading(false);
          }
          return navigate("/workspaces");
        }
      } catch (err) {
        if (typeof setLoading === "function") {
          setLoading(false);
        }
        setIframe("");
        setError(err.message);
      }
    }
  };

  function handleClose() {}

  const buttonStyle = useMemo(() => {
    if (formRef) {
      return { display: "none" };
    }

    return undefined;
  }, [formRef]);

  if (iframe) {
    return (
      <IframePopupModal iframe={iframe} onClose={handleClose} isOpen={isopen} />
    );
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <PaymentElement options={options} />

      <button style={buttonStyle} className={styles.btn} disabled={!stripe}>
        Submit
      </button>
      <div className={styles.red}>{error}</div>
    </form>
  );
};

export default StripeForm;
