import styles from "./account-details.module.css";

import React, { useEffect, useState } from "react";
import PhoneSmsModal from "../../components/modals/phone-sms";
import EmailVerificationModal from "../../components/modals/email-verification";
import GoogleAuthComplete from "../../components/google-autocomplete";
import EditSvg from "../../components/svgs/edit.svg?react";
import { useRef } from "react";
import {
  useAjax,
  useInitialDataFetching,
  useResponseAlert,
  useUser,
} from "../../utils/hooks";
import Message from "../../components/message";
import KeyDetector from "../../components/key-detector";
import CustomText from "../../components/custom-text";
import { SUPER_ADMIN } from "../../utils/user";
import { getCountryByCode } from "../../utils/countries";
import DreamButton from "../../components/dream-button";

export default function AccountDetails() {
  const companyNameRef = useRef();
  const ajax = useAjax();
  const user = useUser();
  const { showAlert } = useResponseAlert();
  const fetchInitialData = useInitialDataFetching();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ldOrphc, setLdOrphc] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostCode] = useState("");
  const [isPhoneSmsModalOpen, setIsPhoneSmsModalOpen] = useState(false);

  const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] =
    useState(false);

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setEmail(user.email || "");
    setCompanyName(user.companyName || "");
    setLdOrphc(user.ld_or_phc || "");
    setPhoneCode(user.phoneCode || "");
    setPhoneNumber(user.phoneNumber || "");
    setCountry(user.country || "");
    setAddress(user.street_address || "");
    setStreetNumber(user.street_number || "");
    setCity(user.city || "");
    setPostCode(user.postcode || "");
  }, [user]);

  async function handleUpdateClicked() {
    setError({});
    const _firstName = firstName.trim();
    const _lastName = lastName.trim();
    const _companyName = companyName.trim();
    const _ldOrphc = ldOrphc.trim();
    const _postcode = postcode.trim();

    const error = {};
    if (_firstName.match(/[0-9]/g)) {
      error.firstName = "general.wrong-first-name";
    }
    if (_lastName.match(/[0-9]/g)) {
      error.lastName = "general.wrong-last-name";
    }
    // if (!_companyName) {
    //   error.companyName = "errors.company-name";
    // }
    // if (!_ldOrphc) {
    //   error.ldOrphc = "errors.ldOrphc";
    // }

    // if (!_postcode) {
    //   error.postCode = "errors.post-code";
    // }

    if (Object.keys(error).length > 0) {
      setError(error);
      return;
    }
    setLoading(true);

    const dataSent = await ajax(`/users/updateClientData`, {
      firstName: _firstName,
      lastName: _lastName,
      companyName: _companyName,
      ldOrphc: _ldOrphc,
      country,
      city,
      streetAddress: address || "",
      streetNumber,
      postcode: _postcode,
      lang: "en",
    });
    await fetchInitialData();

    showAlert(dataSent.result, dataSent.message ? dataSent.message : "");
    setLoading(false);
  }

  async function handlePhoneSmsModalClosed(state) {
    if (state) {
      await fetchInitialData();
    }

    setIsPhoneSmsModalOpen(false);
  }

  async function handleEmailVerificationModalClosed() {
    setIsEmailVerificationModalOpen(false);
  }

  function handleAddressChanged(obj) {
    setAddress(obj.streetAddress ? obj.streetAddress : "-1");
    setStreetNumber(obj.streetNumber ? obj.streetNumber : "-1");
    setCity(obj.city);
    setCountry(obj.country);
  }

  function streetAddresstoView() {
    if (!user.street_address) {
      return "";
    } else {
      return user.street_address + " ";
    }
  }

  function streetNumberToView() {
    if (!user.street_number) {
      return "";
    } else {
      return user.street_number + " ";
    }
  }

  function cityToView() {
    if (!user.city) {
      return "";
    } else {
      return user.city + ", ";
    }
  }

  return (
    <div>
      <KeyDetector
        className={`${styles.wrapper}`}
        onKeyDetected={handleUpdateClicked}
      >
        <div className={styles.box}>
          <div className={`${styles.wrapper} ${styles.details}`}>
            <div className={styles.header}>
              {/* <Message className={styles.grey} id="account-details.personal-information" /> */}
            </div>
            <div className={styles.box}>
              <div className={styles.cols}>
                <div className={styles.fieldBlock}>
                  <label>
                    <Message id="account-details.first-name" />
                    <span className="required"></span>
                  </label>
                  <CustomText
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoComplete="given-name"
                    errorText={error.firstName}
                  />
                </div>
                <div className={styles.fieldBlock}>
                  <label>
                    <Message id="account-details.last-name" />
                    <span className="required"></span>
                  </label>
                  <CustomText
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    autoComplete="family-name"
                    errorText={error.lastName}
                  />
                </div>
              </div>
              <div className={styles.cols}>
                <div>
                  <label>
                    <Message id="account-details.email" />
                  </label>
                  <div className={styles.row} style={{ margin: "0" }}>
                    <CustomText value={email} readOnly />
                    {![SUPER_ADMIN].includes(user.role) && (
                      <button
                        onClick={() => setIsEmailVerificationModalOpen(true)}
                        className="action-btn cleanBtn"
                      >
                        {/* <Message id="general.change" /> */}
                        <EditSvg className="svg-edit" />
                      </button>
                    )}
                  </div>
                  {/* <div className={styles.beaware}>
                    <Message id="account-details.change-email" />
                  </div> */}
                </div>
                <div>
                  <label>
                    <Message id="account-details.phone-number" />
                  </label>
                  <div className={styles.row} style={{ margin: "0" }}>
                    <CustomText
                      className={styles.phoneNumber}
                      value={`+${phoneCode}${phoneNumber}`}
                      readOnly
                    />
                    {![SUPER_ADMIN].includes(user.role) && (
                      <button
                        onClick={() => setIsPhoneSmsModalOpen(true)}
                        className="action-btn cleanBtn"
                      >
                        {/* <Message id="general.change" /> */}
                        <EditSvg className="svg-edit" />
                      </button>
                    )}
                  </div>
                  {/* <div className={styles.beaware}>
                    <Message id="account-details.change-phone" />
                  </div> */}
                </div>
              </div>
            </div>

            <h3 className={styles.subtitle}>
              <Message id="account-details.physical-address" />
            </h3>
            <div className={styles.box}>
              <div className={styles.cols}>
                {/* <div className={styles.fieldBlock}>
                  <label>
                    <Message id="account-details.country" />
                  </label>
                  <CustomReactSelect
                    id="country"
                    value={country}
                    onChange={(item) => setCountry(item)}
                    options={countryOptions}
                  />
                </div> */}
                <div>
                  {/* <label>{<Message id="account-details.full-address" />}</label> */}
                  <div className={styles.googleDiv}>
                    <GoogleAuthComplete
                      onChange={handleAddressChanged}
                      defaultValue={`${streetAddresstoView()}${streetNumberToView()}${cityToView()}${getCountryByCode(
                        user.country
                      )}`}
                    />

                    {/* {country && (
                      <ReactCountryFlag
                        countryCode={country}
                        svg={true}
                        className={styles.countrySvg}
                        cdnUrl={
                          "https://my.click.press/api/assets/img/round-flags/"
                        }
                      />
                    )} */}
                  </div>
                </div>

                <div className={styles.fieldBlock}>
                  <div>
                    <label>
                      <Message id="account-details.post-code" />
                    </label>
                    <CustomText
                      value={postcode}
                      onChange={(e) => setPostCode(e.target.value)}
                      autoComplete="organization"
                      //errorText={error.postCode}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className={styles.subtitle}>
                <Message id="account-details.company-details" />
              </h3>
              <div
                className={`${styles.box} ${styles.companyDetails}`}
                ref={companyNameRef}
              >
                <div className={styles.cols}>
                  <div className={styles.fieldBlock}>
                    <div>
                      <label>
                        <Message id="account-details.company-name" />
                        {/* <span className="required"></span> */}
                      </label>
                      <CustomText
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        autoComplete="organization"
                        errorText={error.companyName}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.fieldBlock}>
                      <label>
                        <Message id="account-details.company-number" />
                      </label>
                      <CustomText
                        value={ldOrphc}
                        onChange={(e) => setLdOrphc(e.target.value)}
                        autoComplete="organization"
                        errorText={error.ldOrphc}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {![SUPER_ADMIN].includes(user.role) && (
            <div className={styles.buttonWrapper}>
              <DreamButton
                disabled={loading}
                loading={loading}
                color="light-purple"
                onClick={handleUpdateClicked}
              >
                <Message id="general.save-changes" />
              </DreamButton>
            </div>
          )}
        </div>
      </KeyDetector>

      <PhoneSmsModal
        isOpen={isPhoneSmsModalOpen}
        onClose={handlePhoneSmsModalClosed}
      />

      <EmailVerificationModal
        isOpen={isEmailVerificationModalOpen}
        onClose={handleEmailVerificationModalClosed}
      />
    </div>
  );
}
