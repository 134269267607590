import styles from "./index.module.css";

import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import PlusSvg from "../svgs/calendar.svg?react";
import PropTypes from "prop-types";
import ClickSelect from "../click-select";
import CustomText from "../custom-text";
import DreamButton from "../dream-button";
import Pagination from "../pagination";
import Message from "../message";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap";
import { endOfDay, startOfDay } from "date-fns";

/*
type=dropdown
  title
  selectedText
  items[]
    label
    value
  onChange
  placeholder

type=input
  value
  onChange
  placeholder
  icon

type=ranges
  placeholder
  onChange

type=pagination
  page
  setPage
  totalRows
  maxRowsInPage

type=button
  onClick
  FormattedMessage
  icon

type=sum
  FormattedMessage
  sum
*/
function TableHeader({ components, className = "" }) {
  const [isButtonsDropdownOpen, setIsButtonsDropdownOpen] = useState({});

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  function handleRangesChanged(dates, component) {
    const [start, end] = dates;

    component.onStartDateChanged(start ? startOfDay(start) : start);
    component.onEndDateChanged(end ? endOfDay(end) : end);
  }

  function handleButtonsDropDownToggle(key) {
    isButtonsDropdownOpen[key] = !isButtonsDropdownOpen[key];
    setIsButtonsDropdownOpen({ ...isButtonsDropdownOpen });
  }

  function renderComponent(component, key) {
    if (component.type === "dropdown") {
      return (
        <div key={key} className={`${styles.item} ${styles.dropdown}`}>
          <div className={styles.title}>{component.title}</div>
          <ClickSelect
            options={component.items}
            value={component.selectedItem}
            onChange={(item) => component.onChange(item)}
            placeholder={component.placeholder}
            isMulti={component.isMulti}
          />
        </div>
      );
    }

    if (component.type === "input") {
      return (
        <div key={key} className={styles.item}>
          <div className={styles.title}>{component.title}</div>
          <CustomText
            placeholder={component.placeholder}
            value={component.value}
            onChange={component.onChange}
            icon={component.icon}
          />
        </div>
      );
    }

    if (component.type === "ranges") {
      return (
        <div key={key} className={styles.item}>
          <div className={styles.title}>{component.title}</div>
          <div className={styles.calendar}>
            <DatePicker
              className={`${styles.ranges}`}
              placeholderText={component.placeholder}
              selected={component.startDate || new Date()}
              startDate={component.startDate || new Date()}
              endDate={component.endDate}
              onChange={(dates) => handleRangesChanged(dates, component)}
              selectsRange
              dateFormat={
                component.dateFormat ? component.dateFormat : "dd/MM/yyyy"
              }
              isClearable
            />

            <PlusSvg />
          </div>
        </div>
      );
    }

    if (component.type === "button") {
      return (
        <DreamButton
          key={key}
          onClick={component.onClick}
          icon={component.icon}
          loading={component.loading}
          disabled={component.disabled}
          href={component.href}
          as={component.as}
          target={component.target}
          color={component.color}
        >
          <Message id={component.FormattedMessage} />
        </DreamButton>
      );
    }

    if (component.type === "buttons") {
      return (
        <div key={key} className={styles.item}>
          <div className={styles.title}>{component.title}</div>
          <ButtonDropdown
            className={`${styles.purple} ${
              component.disabled ? styles.disabled : ""
            }`}
            isOpen={isButtonsDropdownOpen[key]}
            toggle={() => handleButtonsDropDownToggle(key)}
            disabled={component.disabled}
            id={`tooltip-item-${key}`}
          >
            <DropdownToggle caret>
              <Message id='billing-invoices.actions.title' />
            </DropdownToggle>
            <DropdownMenu right>
              {component.items.map((item, key2) => (
                <DropdownItem key={`${key}_${key2}`} onClick={item.onClick}>
                  {item.element}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
          {component.tooltip && (
            <Tooltip
              autohide={false}
              isOpen={isTooltipOpen}
              toggle={() => setIsTooltipOpen(!isTooltipOpen)}
              target={`tooltip-item-${key}`}
            >
              <Message id={component.tooltip.props.id} />
            </Tooltip>
          )}
        </div>
      );
    }

    if (component.type === "space") {
      return <div key={key} className={styles.space}></div>;
    }
    if (component.type === "seperator") {
      return <span key={key} className={styles.seperator}></span>;
    }

    if (component.type === "pagination") {
      return (
        <Pagination
          key={key}
          page={component.page}
          setPage={component.setPage}
          totalRows={component.totalRows}
          maxRowsInPage={component.maxRowsInPage}
        />
      );
    }

    if (component.type === "layout") {
      return (
        <div key={key} className={styles.flex}>
          <Message id={component.FormattedMessage} />
          {component.firstBtn}
          {component.secondBtn}
        </div>
      );
    }
    if (component.type === "sum") {
      return (
        <Fragment key={key}>
          <Message id={component.placeholder} /> {component.sum}
        </Fragment>
      );
    }
    if (component.type === "date") {
      return (
        <div key={key} className={styles.dateText}>
          <Message id={component.placeholder} /> {component.sum}
        </div>
      );
    }
    if (component.type === "any") {
      return <Fragment key={key}>{component.content}</Fragment>;
    }
    return null;
  }

  return (
    <div className={`${styles.header} ${className}`}>
      {components
        .filter((component) => !component.hide)
        .map((component, key) => renderComponent(component, key))}
    </div>
  );
}

TableHeader.propTypes = {
  components: PropTypes.array,
  className: PropTypes.string,
};

export default TableHeader;
