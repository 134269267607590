import { useAjax, useAlert, useUser } from "../../utils/hooks";
import CustomReactSelect from "../custom-react-select";
import DreamButton from "../dream-button";
import Message from "../message";
import styles from "./auto-logout.module.css";

import React, { useEffect, useMemo, useState } from "react";

export default function AutoLogout() {
  const ajax = useAjax();
  const alert = useAlert();
  const user = useUser();

  const [minutes, setMinutes] = useState(null);

  const [loading, setLoading] = useState(false);

  const options = useMemo(
    () => [
      {
        label: <Message id="auto-logout.5min" />,
        value: 5,
      },
      {
        label: <Message id="auto-logout.10min" />,
        value: 10,
      },
      {
        label: <Message id="auto-logout.30min" />,
        value: 30,
      },
      {
        label: <Message id="auto-logout.1hour" />,
        value: 60,
      },
      {
        label: <Message id="auto-logout.2hours" />,
        value: 120,
      },
    ],
    []
  );

  useEffect(() => {
    if (!user._id || minutes) {
      return;
    }

    if (user.auto_logout_minutes) {
      setMinutes(
        options.find((minute) => minute.value === user.auto_logout_minutes)
      );
    } else {
      setMinutes(options[0]);
    }
  }, [options, user, minutes]);

  async function handleSaveClicked() {
    setLoading(true);
    const data = await ajax(`/users/updateAutoLogoutMinutes`, {
      minutes: minutes.value,
    });
    setLoading(false);

    if (data.result === "success") {
      await alert({
        title: <Message id="auto-logout.saved.title" />,
        message: <Message id="auto-logout.saved.content" />,
        notification: true,
        alertStyle: "success",
      });
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        <Message id="auto-logout.description" />
      </div>

      <div className={styles.selectWrapper}>
        <CustomReactSelect
          instanceId="auto-logout"
          options={options}
          value={minutes}
          onChange={(value) => setMinutes(value)}
        />
      </div>

      <div className={styles.buttonsWrapper}>
        <DreamButton
          disabled={loading}
          loading={loading}
          color="white"
          onClick={handleSaveClicked}
        >
          <Message id="general.save" />
        </DreamButton>
      </div>
    </div>
  );
}
