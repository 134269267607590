import React, { useMemo } from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import WarningSvg from "../svgs/warning2.svg?react";
import InfoSvg from "../svgs/info2.svg?react";
import SuccessSvg from "../svgs/check-bold.svg?react";
import DangerSvg from "../svgs/x-mark.svg?react";

function DreamAlert({ alertStyle, children, icon = null }) {
  const iconSvgByStyle = useMemo(() => {
    if (alertStyle === "danger") {
      return <DangerSvg />;
    }
    if (alertStyle === "success") {
      return <SuccessSvg />;
    }
    if (alertStyle === "warning") {
      return <WarningSvg />;
    }
    if (alertStyle === "info") {
      return <InfoSvg />;
    } else {
      return icon;
    }
  }, [alertStyle, icon]);

  return (
    <div className={`${styles.wrapper} ${styles[alertStyle]}`}>
      {iconSvgByStyle}
      <div className={styles.content}>{children}</div>
    </div>
  );
}

DreamAlert.propTypes = {
  alertStyle: PropTypes.oneOf(["danger", "success", "warning", "info"]),
  children: PropTypes.any,
  icon: PropTypes.string,
};

export default DreamAlert;
