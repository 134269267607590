import { useEffect, useCallback, useState, useRef } from "react";
import { ReactFlowProvider } from "@xyflow/react";
import { IntlProvider } from "react-intl";
import axios from "axios";
import { useRecoilState } from "recoil";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useConfetti, useWebSocket, useAjax, useAutoLogout } from "./utils/hooks";
import en from "./en.json";
import { userState, settingsState } from "./utils/atoms";

import ChangeUserPasswordModal from "./components/modals/change-user-password/index.jsx";
import AlertModal from "./components/modals/alert/index.jsx";
import ConfirmModal from "./components/modals/confirm/index.jsx";
import PromptModal from "./components/modals/prompt/index.jsx";
import CloudLayout from "./components/cloud-layout/index.jsx";
import WithUser from "./components/with-user/index.jsx";
import AdminUserLayout from "./components/admin-user-layout/index.jsx";

import ResetPasswordToken from "./pages/user/reset-password.jsx";
import ConfirmPassword from "./pages/user/confirm-password.jsx";
import LoginEmailVerification from "./pages/user/email-verification.jsx";
import ForgotPassword from "./pages/user/forgot-password.jsx";
import LoginWithSMS from "./pages/user/login-sms.jsx";
import TwoFactorAuthentication from "./pages/user/two-factor-authentication.jsx";
import Register from "./pages/user/register.jsx";
import Login from "./pages/user/login.jsx";
import Account from "./pages/account/index.jsx";
import ClientsList from "./pages/clients/index.jsx";
import ClientsListSummary from "./pages/clients/[id]/summary.jsx";
import ClientsListDocuments from "./pages/clients/[id]/documents.jsx";
import Accounts from "./pages/user/accounts.jsx";
import TwoFactorSetting from "./pages/user/two-factor-setting.jsx";
import ForgotEmail from "./pages/user/forgot-email.jsx";
import SupportNewRequest from "./pages/support/new-request.jsx";
import AccountPermissionsInviteUsers from "./pages/account/permissions/invite-users.jsx";
import NewRole from "./pages/account/permissions/new-role.jsx";
import Billing from "./pages/billing/index.jsx";
import Management from "./pages/management/index.jsx";
import PolicySchemaView from "./pages/workspaces/[id]/policy-schema/index.jsx";
import Workspaces from "./pages/workspaces/index.jsx";

import WorkspaceLayout from "./pages/workspaces/workspace-layout.jsx";
import ZtnaManagementOverview from "./pages/workspaces/[id]/overview/index.jsx";
import ZtnaManagementDownloads from "./pages/workspaces/[id]/downloads/index.jsx";
import ZtnaManagementAllowedAccess from "./pages/workspaces/[id]/allowed-access/index.jsx";
import ZtnaManagementSubscriptions from "./pages/workspaces/[id]/subscriptions.jsx";

const reloadUser = {};
const enJson = en as unknown as Record<string, string>;

function App() {
  const [, setRecoilUser] = useRecoilState(userState);
  const [settings, setSettings] = useRecoilState<any>(settingsState);
  const navigate = useNavigate();
  const location = useLocation();
  const ajax = useAjax();
  const { connect } = useWebSocket();

  useAutoLogout();

  const confetti = useConfetti();
  const confettiRef = useRef(confetti);
  confettiRef.current = confetti;

  const [user, setUser] = useState(null);

  const [isChangeUserPasswordModalOpen, setIsChangeUserPasswordModalOpen] = useState(false);

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { components, visitorId } = await fp.get();

      setSettings((prev: any) => ({
        ...prev,
        fingerprint: components,
        fingerprintVisitorId: visitorId,
      }));
    };

    setFp();
  }, [location, setSettings]);

  const redirectToLogin = useCallback(() => {
    if (/\/user/.test(location.pathname)) {
      return;
    }

    navigate(`/user/login`);
  }, [location.pathname, navigate]);

  const redirectToDashboard = useCallback(() => {
    if (/\/user\/accounts/.test(location.pathname)) {
      return;
    }

    if (location.pathname === "/" || /\/user/.test(location.pathname)) {
      navigate(`/workspaces`);
    }
  }, [location.pathname, navigate]);

  const redirectToRegister = useCallback(
    (registerStep: number) => {
      navigate(`/user/register/${registerStep}`);
    },
    [navigate]
  );

  const fetchInitialData = useCallback(async () => {
    if (settings.fetchedInitialData) {
      return;
    }

    try {
      const data = await ajax("/initialData");
      setSettings((prev: any) => ({
        ...prev,
        countryOfIp: data.countryOfIp,
        fetchedInitialData: true,
      }));

      if (data.result === "error") {
        redirectToLogin();
        return;
      }

      setRecoilUser(data);
      connect();

      if (data.registerStep >= 1 && data.registerStep <= 2) {
        redirectToRegister(data.registerStep);
        return;
      }

      redirectToDashboard();
    } catch (err) {
      if (err instanceof Error || axios.isAxiosError(err)) {
        alert("Error occurred while fetching data from the server, please try again later.");
      }
    }
  }, [ajax, connect, redirectToDashboard, redirectToLogin, redirectToRegister, setRecoilUser, setSettings, settings.fetchedInitialData]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  function handleAlertClosed(closedVia: () => void) {
    if (!settings.alertModalData) {
      return;
    }

    setSettings((prev: any) => ({
      ...prev,
      alertModalData: {
        button1: settings.alertModalData.button1,
        button2: settings.alertModalData.button2,
        isOpen: false,
        size: settings.alertModalData.size,
        alertStyle: settings.alertModalData.alertStyle,
      },
    }));

    if (typeof settings.alertModalData.resolve === "function") {
      settings.alertModalData.resolve(closedVia);
    }
  }

  function handleConfirmClosed(closedVia: () => void) {
    if (!settings.confirmModalData) {
      return;
    }

    setSettings((prev: any) => ({
      ...prev,
      confirmModalData: {
        button1: settings.confirmModalData.button1,
        button2: settings.confirmModalData.button2,
        buttons: settings.confirmModalData.buttons,
        isOpen: false,
      },
    }));

    settings.confirmModalData.resolve(closedVia);
  }

  function handlePromptClosed(closedVia: () => void) {
    if (!settings.promptModalData) {
      return;
    }

    setSettings((prev: any) => ({
      ...prev,
      promptModalData: {
        button1: settings.promptModalData.button1,
        button2: settings.promptModalData.button2,
        isOpen: false,
      },
    }));

    settings.promptModalData.resolve(closedVia);
  }

  function handleChangeUserPasswordModalClosed() {
    setIsChangeUserPasswordModalOpen(false);
  }

  if (!settings.fetchedInitialData) {
    return (
      <IntlProvider messages={enJson} locale='en' defaultLocale='en'>
        <div data-path={location.pathname}>
          <Routes>
            <Route path='*' element={<CloudLayout asPlaceholder />}></Route>
          </Routes>
        </div>
      </IntlProvider>
    );
  }

  return (
    <IntlProvider messages={enJson} locale='en' defaultLocale='en'>
      <div data-path={location.pathname}>
        <Routes>
          {/* USER */}
          <Route path='user'>
            <Route path='reset-password' element={<ResetPasswordToken />} />
            <Route path='confirm-password' element={<ConfirmPassword />} />
            <Route path='email-verification' element={<LoginEmailVerification />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='forgot-email' element={<ForgotEmail />} />
            <Route path='login-sms' element={<LoginWithSMS />} />
            <Route path='two-factor-authentication' element={<TwoFactorAuthentication />} />
            <Route path='two-factor-setting' element={<TwoFactorSetting />} />
            <Route path='register/:paramStep' element={<Register />} />
            <Route path='register' element={<Register />} />
            <Route path='login' element={<Login />} />

            <Route path='accounts' element={<Accounts />} />

            <Route path='' element={<Navigate to='login' />} />
          </Route>

          {/* WORKSPACES */}
          <Route path='workspaces' element={<CloudLayout />}>
            <Route path='' element={<Workspaces />} />

            <Route path=':workspaceId' element={<WorkspaceLayout />}>
              <Route path='overview' element={<ZtnaManagementOverview />} />
              <Route path='downloads' element={<ZtnaManagementDownloads />} />
              <Route path='allowed-access' element={<ZtnaManagementAllowedAccess />} />
              <Route path='subscriptions' element={<ZtnaManagementSubscriptions />} />

              <Route path='' element={<Navigate to='overview' />} />
            </Route>
          </Route>

          <Route path='workspaces' element={<CloudLayout fullWidth />}>
            <Route path=':workspaceId' element={<WorkspaceLayout />}>
              <Route
                path='policy-schema/:view'
                element={
                  <ReactFlowProvider>
                    <PolicySchemaView />
                  </ReactFlowProvider>
                }
              />
              <Route path='policy-schema' element={<Navigate to='security-policy' />} />
            </Route>
          </Route>

          {/* SUPPORT */}
          <Route path='support' element={<CloudLayout />}>
            <Route path='new-request' element={<SupportNewRequest />} />
          </Route>

          {/* BILLING */}
          <Route path='billing' element={<CloudLayout />}>
            <Route path='subscription' element={<Billing />} />
            <Route path='payment-method' element={<Billing />} />
            <Route path='invoices' element={<Billing />} />

            <Route path='' element={<Navigate to='subscription' />} />
          </Route>

          {/* ACCOUNT */}
          <Route path='account' element={<CloudLayout />}>
            <Route path='account-details' element={<Account />} />
            <Route path='security' element={<Account />} />
            <Route path='permissions' element={<Account />} />
            <Route path='permissions/new-role' element={<Account />} />

            <Route path='permissions/invite-users' element={<AccountPermissionsInviteUsers />} />
            <Route path='permissions/new-role/:editID' element={<NewRole />} />

            <Route path='' element={<Navigate to='account-details' />} />
          </Route>

          {/* CLIENTS */}
          <Route path='clients' element={<CloudLayout />}>
            <Route path='clients-list' element={<ClientsList />} />

            <Route
              path='clients-list/:id'
              element={
                <WithUser setUser={setUser} reload={reloadUser}>
                  <AdminUserLayout user={user} setUser={setUser} reload={reloadUser}></AdminUserLayout>
                </WithUser>
              }
            >
              <Route path='summary' element={<ClientsListSummary user={user} reloadUser={reloadUser} />} />

              <Route path='documents' element={<ClientsListDocuments user={user} reloadUser={reloadUser} />} />

              <Route path='' element={<Navigate to='summary' />} />
            </Route>
          </Route>

          {/* MANAGEMENT */}
          <Route path='management' element={<CloudLayout />}>
            <Route path='prices' element={<Management />} />

            <Route path='' element={<Navigate to='prices' />} />
          </Route>

          {/* FALLBACK */}
          <Route path='' element={null} />
        </Routes>

        {settings.alertModalData && (
          <AlertModal
            isOpen={settings.alertModalData.isOpen}
            onClose={handleAlertClosed}
            title={settings.alertModalData.title}
            message={settings.alertModalData.message}
            button={settings.alertModalData.button}
            notification={settings.alertModalData.notification}
            size={settings.alertModalData.size}
            alertStyle={settings.alertModalData.alertStyle}
          />
        )}

        {settings.confirmModalData && (
          <ConfirmModal
            isOpen={settings.confirmModalData.isOpen}
            onClose={handleConfirmClosed}
            title={settings.confirmModalData.title}
            message={settings.confirmModalData.message}
            button1={settings.confirmModalData.button1}
            button2={settings.confirmModalData.button2}
            buttons={settings.confirmModalData.buttons}
            beforeClose={settings.confirmModalData.beforeClose}
          />
        )}

        {settings.promptModalData && (
          <PromptModal
            isOpen={settings.promptModalData.isOpen}
            onClose={handlePromptClosed}
            title={settings.promptModalData.title}
            message={settings.promptModalData.message}
            defaultText={settings.promptModalData.defaultText}
            button1={settings.promptModalData.button1}
            button2={settings.promptModalData.button2}
            acceptOnlyValue={settings.promptModalData.acceptOnlyValue}
            textType={settings.promptModalData.textType}
            beforeClose={settings.promptModalData.beforeClose}
            placeholder={settings.promptModalData.placeholder}
          />
        )}

        <ChangeUserPasswordModal isOpen={isChangeUserPasswordModalOpen} onClose={handleChangeUserPasswordModalClosed} />
      </div>
    </IntlProvider>
  );
}

export default App;
