import styles from "./index.module.css";

import React from "react";

import ZtnaDownloadClient from "../../../../components/ztna-downloads-section/download-client";
import ZtnaDownloadServer from "../../../../components/ztna-downloads-section/download-server";
import EnvironmentAccessInstructions from "../../../../components/environment-access-instructions";
import LockSvg from "../../../../components/svgs/lock.svg?react";
import Message from "../../../../components/message";
import { useWorkspace } from "../../../../utils/atoms";
import Spinner from "../../../../components/spinner";

export default function ZtnaManagementDownloads() {
  const { workspace } = useWorkspace();

  function renderContent() {
    if (!workspace) {
      return <Spinner />;
    }

    return (
      <>
        <div className={`${!workspace.ztnaDomain || workspace.ztnaDomain.status !== "ready" ? "disabled" : ""}`}>
          <div className={`${styles.boxes}`}>
            <ZtnaDownloadClient />

            <ZtnaDownloadServer />
          </div>

          {workspace.ztnaDomain && <EnvironmentAccessInstructions token={`${workspace.ztnaDomain.domain}!!hFMDcyQRY5NEEEQwEzRx5MUQtODODQ0UtRDNC00RDZDLUIzD@71821b60-6F0F-462D-aE5B-4f20FDd459c5`} />}
        </div>

        {(!workspace.ztnaDomain || workspace.ztnaDomain.status !== "ready") && (
          <div className={styles.notReady}>
            <div className={styles.notReadyInner}>
              <LockSvg />
              <Message id="server-is-not-ready" />
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h1 className="title">
        <Message id="downloads-title" />
      </h1>
      {renderContent()}
    </div>
  );
}
