import styles from "./subscription.module.css";
import EditSvg from "../../components/svgs/edit-purple.svg?react";
import TrashSvg from "../../components/svgs/trash.svg?react";
import VSvg from "../../components/svgs/check.svg?react";
import XSvg from "../../components/svgs/cancel.svg?react";

import React from "react";
import { useState } from "react";
import Message from "../../components/message";
import DreamButton from "../../components/dream-button";
import ClickTable from "../../components/click-table";
import { ajax } from "../../utils";
import { format } from "date-fns";
import CustomText from "../../components/custom-text";
import { useMemo } from "react";
import { useAlert, useConfirm, useUser } from "../../utils/hooks";
import CreateNewWorkspaceModal from "../../components/modals/create-new-workspace";
import { useWorkspaces } from "../../utils/atoms";
import { useEffect } from "react";
import { useWorkspace } from "../../utils/atoms";

export default function BillingSubscription() {
  const user = useUser();
  const { workspace } = useWorkspace();
  const { workspaces, updateWorkspaces } = useWorkspaces();
  const alert = useAlert();
  const confirm = useConfirm();

  const [editItem, setEditItem] = useState(null);
  const [animationRow, setAnimationRow] = useState(false);

  const [prices, setPrices] = useState({});

  const [isCreateNewWorkspaceModalOpen, setIsCreateNewWorkspaceModalOpen] =
    useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const editCurrent = urlSearchParams.get("edit_current");

    if (editCurrent !== "1" || !workspace) {
      return;
    }

    setEditItem({
      _id: workspace._id,
      name: workspace.name,
      billingCycle: workspace.billing_cycle,
      costPerUser: workspace.cost_per_user,
      totalUsers: workspace.total_users,
      newTotalUsers: workspace.total_users,
    });
    setAnimationRow(workspace._id);

    const url = new URL(window.location.href);
    url.searchParams.delete("edit_current");
    window.history.replaceState({}, "", url);
  }, [workspace]);

  useEffect(() => {
    async function listPrices() {
      const data = await ajax("/billing/prices/list");

      setPrices({ monthly: data.monthly, yearly: data.yearly });
    }

    listPrices();
  }, []);

  function handleRemoveWorkspaceClicked(workspace) {
    confirm({
      title: <Message id="remove-workspace-title" />,
      message: (
        <Message
          id="remove-workspace-message"
          values={{ name: `<b>${workspace.name}</b>` }}
        />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/workspace/remove", { workspaceID: workspace._id });
        await updateWorkspaces();
      },
    });
  }

  function handleCreateWorkspaceClicked() {
    setIsCreateNewWorkspaceModalOpen(true);
  }

  async function handleCreateNewWorkspaceModalClosed(state) {
    if (state) {
      await updateWorkspaces();
    }

    setIsCreateNewWorkspaceModalOpen(false);
  }

  function handleEditWorkspaceClicked(workspace) {
    setEditItem({
      _id: workspace._id,
      name: workspace.name,
      billingCycle: workspace.billing_cycle,
      costPerUser: workspace.cost_per_user,
      totalUsers: workspace.total_users,
      newTotalUsers: workspace.total_users,
    });
  }

  function handleEditItemChange(e) {
    setEditItem((prev) => ({
      ...prev,
      newTotalUsers: parseInt(e.target.value),
    }));
  }

  async function handleUpdateTotalUsersClicked() {
    if (
      editItem.newTotalUsers < prices[editItem.billingCycle].minimumUsers ||
      editItem.newTotalUsers > prices[editItem.billingCycle].maximumUsers
    ) {
      await alert({
        title: <Message id="error" />,
        message: (
          <Message
            id="wrong-users-for-purchase"
            values={{
              min: prices[editItem.billingCycle].minimumUsers,
              max: prices[editItem.billingCycle].maximumUsers,
            }}
          />
        ),
      });
      return;
    }

    confirm({
      title: (
        <Message
          id="update-workspace-total-users"
          values={{ name: editItem.name }}
        />
      ),
      message: (
        <Message
          id="update-workspace-total-users-costs"
          values={{
            from: `<b>${editItem.totalUsers}</b>`,
            to: `<b>${editItem.newTotalUsers}</b>`,
            fromCost: `<b>$${editItem.totalUsers * editItem.costPerUser}</b>`,
            toCost: `<b>$${editItem.newTotalUsers * editItem.costPerUser}</b>`,
          }}
        />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/workspace/update", {
          workspaceID: editItem._id,
          totalUsers: editItem.newTotalUsers,
        });

        await updateWorkspaces();

        setEditItem(null);
      },
    });
  }

  const totalMonthlyCost = useMemo(
    () =>
      workspaces
        ?.filter(
          (workspace) =>
            workspace.user_id === user._id &&
            workspace.billing_cycle === "monthly"
        )
        .reduce(
          (total, item) => total + item.cost_per_user * item.total_users,
          0
        ) || 0,
    [user._id, workspaces]
  );

  const totalYearlyCost = useMemo(
    () =>
      workspaces
        ?.filter(
          (workspace) =>
            workspace.user_id === user._id &&
            workspace.billing_cycle === "yearly"
        )
        .reduce(
          (total, item) => total + item.cost_per_user * item.total_users,
          0
        ) * 12 || 0,
    [user._id, workspaces]
  );

  return (
    <div>
      <div className={styles.nextPayment}>
        <Message
          id="account-billed-monthly"
          values={{ date: format(new Date(), "MMM dd, yyyy") }}
        />
        <DreamButton icon="plus" onClick={handleCreateWorkspaceClicked}>
          <Message id="workspace" />
        </DreamButton>
      </div>
      <div>
        <ClickTable asPlaceholder={!workspaces}>
          <thead>
            <tr>
              <th>
                <Message id="workspace" />
              </th>
              <th>
                <Message id="product" />
              </th>
              <th>
                <Message id="plan" />
              </th>
              <th>
                <Message id="details" />
              </th>
              <th>
                <Message id="cost" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {workspaces
              ?.filter((item) => item.user_id === user._id)
              .map((item, key) => (
                <tr
                  key={key}
                  className={animationRow === item._id ? styles.animation : ""}
                >
                  <td>{item.name}</td>
                  <td>ZTNA</td>
                  <td>Enterprise</td>
                  <td>
                    {editItem?._id !== item._id && (
                      <div className={styles.users}>
                        {item.total_users}
                        <Message id="users" />
                        <EditSvg
                          className="pointer"
                          onClick={() => handleEditWorkspaceClicked(item)}
                        />
                      </div>
                    )}
                    {editItem?._id === item._id && (
                      <div className={styles.users}>
                        <CustomText
                          className={styles.input}
                          value={editItem.newTotalUsers}
                          onChange={handleEditItemChange}
                        />
                        <VSvg
                          className={`${styles.svg} ${styles.v} ${
                            editItem.newTotalUsers === item.total_users
                              ? "disabled"
                              : ""
                          }`}
                          onClick={handleUpdateTotalUsersClicked}
                        />
                        <XSvg
                          className={`${styles.svg} ${styles.x}`}
                          onClick={() => setEditItem(null)}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    {item.billing_cycle === "monthly" && (
                      <>
                        ${item.cost_per_user * item.total_users}
                        {" - "}
                        <Message id="per-month" />
                      </>
                    )}
                    {item.billing_cycle === "yearly" && (
                      <>
                        ${item.cost_per_user * item.total_users * 12}
                        {" - "}
                        <Message id="per-year" />
                      </>
                    )}
                  </td>
                  <td>
                    {item._id !== user.current_workspace_id && (
                      <TrashSvg
                        className="pointer"
                        onClick={() => handleRemoveWorkspaceClicked(item)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            {totalMonthlyCost > 0 && (
              <tr className={styles.totalCost}>
                <td colSpan={4}>
                  <Message id="total-monthly-cost" />
                </td>
                <td>${totalMonthlyCost}</td>
                <td></td>
              </tr>
            )}
            {totalYearlyCost > 0 && (
              <tr className={styles.totalCost}>
                <td colSpan={4}>
                  <Message id="total-yearly-cost" />
                </td>
                <td>${totalYearlyCost}</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </ClickTable>
      </div>

      <CreateNewWorkspaceModal
        isOpen={isCreateNewWorkspaceModalOpen}
        onClose={handleCreateNewWorkspaceModalClosed}
      />
    </div>
  );
}
