import styles from "./index.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import PasswordStrengthBar from "react-password-strength-bar";
import Message from "../message";

function DreamPasswordStrengthBar(props) {
  const [score, setScore] = useState(0);

  function handleScoreChanged(score) {
    setScore(score);

    props.onChangeScore(score);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Message id="general.password-strength" />
      </div>
      <PasswordStrengthBar {...props} onChangeScore={handleScoreChanged} />
      <div className={styles.text}>
        <Message id={`general.score${score}-word`} />
      </div>
    </div>
  );
}

DreamPasswordStrengthBar.propTypes = {
  onChangeScore: PropTypes.func,
};

export default DreamPasswordStrengthBar;
