import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

function Message(props) {
  const intl = useIntl();

  for (const k in props.values) {
    if (
      typeof props.values[k] === "function" &&
      props.values[k].toString().match(/<a.+href.+<\/a>/)
    ) {
      return (
        <span
          className={`click-message-component ${styles.flexin}`}
          data-id={props.id}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: props.id }, props.values),
            }}
          ></div>
        </span>
      );
    }
  }

  return (
    <span
      className={`click-message-component ${styles.flexin}`}
      data-id={props.id}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: props.id }, props.values),
        }}
      ></div>
    </span>
  );
}

Message.propTypes = {
  id: PropTypes.string,
  values: PropTypes.any,
};

export default Message;
