import React from "react";
import { differenceInDays, endOfMonth } from "date-fns";
import VisaSvg from "../components/svgs/visa.svg?react";
import AmericanExpressSvg from "../components/svgs/american-express.svg?react";
import MasterCardSvg from "../components/svgs/master-card.svg?react";
import DiscoverSvg from "../components/svgs/discover.svg?react";
import DinersSvg from "../components/svgs/diners.svg?react";
import NoCardSvg from "../components/svgs/nocard.svg?react";

export const currencySymbols = {
  EUR: "€",
  USD: "$",
  ILS: "₪",
  GBP: "£",

  // AFN: "؋",
  // ARS: "$",
  // AWG: "ƒ",
  // AUD: "$",
  // AZN: "₼",
  // BSD: "$",
  // BBD: "$",
  // BYN: "Br",
  // BZD: "BZ$",
  // BMD: "$",
  // BOB: "$b",
  // BAM: "KM",
  // BWP: "P",
  // BGN: "лв",
  // BRL: "R$",
  // BND: "$",
  // KHR: "៛",
  // CAD: "$",
  // KYD: "$",
  // CLP: "$",
  // CNY: "¥",
  // COP: "$",
  // CRC: "₡",
  // HRK: "kn",
  // CUP: "₱",
  // CZK: "Kč",
  // DKK: "kr",
  // DOP: "RD$",
  // XCD: "$",
  // EGP: "£",
  // SVC: "$",
  // FKP: "£",
  // FJD: "$",
  // GHS: "¢",
  // GIP: "£",
  // GTQ: "Q",
  // GGP: "£",
  // GYD: "$",
  // HNL: "L",
  // HKD: "$",
  // HUF: "Ft",
  // ISK: "kr",
  // INR: "",
  // IDR: "Rp",
  // IRR: "﷼",
  // IMP: "£",
  // JMD: "J$",
  // JPY: "¥",
  // JEP: "£",
  // KZT: "лв",
  // KPW: "₩",
  // KRW: "₩",
  // KGS: "лв",
  // LAK: "₭",
  // LBP: "£",
  // LRD: "$",
  // MKD: "ден",
  // MYR: "RM",
  // MUR: "₨",
  // MXN: "$",
  // MNT: "₮",
  // MZN: "MT",
  // NAD: "$",
  // NPR: "₨",
  // ANG: "ƒ",
  // NZD: "$",
  // NIO: "C$",
  // NGN: "₦",
  // NOK: "kr",
  // OMR: "﷼",
  // PKR: "₨",
  // PAB: "B/.",
  // PYG: "Gs",
  // PEN: "S/.",
  // PHP: "₱",
  // PLN: "zł",
  // QAR: "﷼",
  // RON: "lei",
  // RUB: "₽",
  // SHP: "£",
  // SAR: "﷼",
  // RSD: "Дин.",
  // SCR: "₨",
  // SGD: "$",
  // SBD: "$",
  // SOS: "S",
  // ZAR: "R",
  // LKR: "₨",
  // SEK: "kr",
  // CHF: "CHF",
  // SRD: "$",
  // SYP: "£",
  // TWD: "NT$",
  // THB: "฿",
  // TTD: "TT$",
  // TRY: "",
  // TVD: "$",
  // UAH: "₴",
  // UYU: "$U",
  // UZS: "лв",
  // VEF: "Bs",
  // VND: "₫",
  // YER: "﷼",
  // ZWD: "Z$"
};

export const getSymbolsForReactSelect = () => {
  return Object.keys(currencySymbols).map((currency, idx) => ({
    label: `${currency} (${currencySymbols[currency]})`,
    value: currency,
    key: idx,
  }));
};

export const paymentMethodToText = (paymentMethod) => {
  switch (paymentMethod) {
    case "smartpayplus_redirect":
      return "Secured Credit Card";
    default:
      return "-";
  }
};

export const convertCurrency = (number, from, to, rates, fixed) => {
  let ratesTo = rates[to];

  if (from === "USD" && to === "ILS" && fixed[from] > 0) {
    ratesTo = fixed[from];
  }

  return (number / rates[from]) * ratesTo;
};

export const paymentGateways = [
  {
    label: "smartpay",
    value: "smartpay",
  },
  {
    label: "icount",
    value: "icount",
  },
  {
    label: "payplus",
    value: "payplus",
  },
  {
    label: "stripe",
    value: "stripe",
  },
];

export const billingGateways = [
  {
    label: "icount",
    value: "icount",
  },
  {
    label: "green-invoice",
    value: "green-invoice",
  },
  {
    label: "dreamvps",
    value: "dreamvps",
  },
];

export const getVatPrec = (user) => {
  const country = user.country ? user.country.toUpperCase().trim() : "";

  if (user.payment_gateway === "stripe") {
    const vat = country === "NL" ? import.meta.env.VITE_APP_CURRENT_VAT_NL : 0;

    return 1 + vat / 100;
  }

  return 1;
};

export const getRestOfMonthDays = () => {
  const curDate = new Date();

  return differenceInDays(endOfMonth(curDate), curDate) + 1;
};

export const getClosestPrice = (value, pricingArray, direction) => {
  const min = pricingArray[0].value;
  const max = pricingArray[pricingArray.length - 1].value;

  let checker = pricingArray.find((price) => price.value === value);
  if (checker) {
    return checker;
  }

  let searchValue = value;

  do {
    searchValue += direction === "up" ? 1 : -1;

    if (searchValue > max) {
      return { value: max };
    }
    if (searchValue < min) {
      return { value: min };
    }

    checker = pricingArray.find((price) => price.value === searchValue);
    if (checker) {
      return checker;
    }
  } while (true); /* eslint-disable-line */
};

export const getCreditCardSvg = (cardType) => {
  const lowerCaseCardType = cardType.toLowerCase();

  if (
    lowerCaseCardType.includes("visa") ||
    lowerCaseCardType.includes("ויזה") ||
    lowerCaseCardType === "MAX" ||
    lowerCaseCardType === "MAX VISA"
  ) {
    return <VisaSvg />;
  } else if (
    lowerCaseCardType.includes("mastercard") ||
    lowerCaseCardType.includes("מסטרקארד") ||
    lowerCaseCardType.includes("מאסטרקארד") ||
    lowerCaseCardType === "יורוקרד מסטרקרד" ||
    lowerCaseCardType === "מסטרכרד תייר" ||
    cardType === "MAX"
  ) {
    return <MasterCardSvg />;
  } else if (
    lowerCaseCardType.includes("american-express") ||
    lowerCaseCardType === "אמריקן אקספרס"
  ) {
    return <AmericanExpressSvg />;
  } else if (lowerCaseCardType.includes("diners-club")) {
    return <DinersSvg />;
  } else if (lowerCaseCardType.includes("discover")) {
    return <DiscoverSvg />;
  } else if (lowerCaseCardType.includes("isracard")) {
    return <img src="./img/isracard-logo.png" />;
  } else if (lowerCaseCardType.includes("jcb")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("unionpay")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("maestro")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("mir")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("elo")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("hiper")) {
    return <NoCardSvg />;
  } else if (lowerCaseCardType.includes("hipercard")) {
    return <NoCardSvg />;
  } else {
    return <NoCardSvg />;
  }

  // switch (cardType) {
  //   case "visa":
  //     return <VisaSvg />;
  //   case "mastercard":
  //     return <MasterCardSvg />;
  //   case "american-express":
  //     return <AmericanExpressSvg />;
  //   case "diners-club":
  //     return <DinersSvg />;
  //   case "discover":
  //     return <DiscoverSvg />;
  //   case "isracard":
  //     return <img src="./img/isracard-logo.png" />;
  //   case "jcb":
  //     return <NoCardSvg />;
  //   case "unionpay":
  //     return <NoCardSvg />;
  //   case "maestro":
  //     return <NoCardSvg />;
  //   case "mir":
  //     return <NoCardSvg />;
  //   case "elo":
  //     return <NoCardSvg />;
  //   case "hiper":
  //     return <NoCardSvg />;
  //   case "hipercard":
  //     return <NoCardSvg />;
  //   default:
  //     return <NoCardSvg />;
  // }
};

export const isracardValidation = (number) => {
  if (number.length < 8 || number.length > 9) {
    return false;
  }

  number = number.padStart(9, "0");
  let sum = 0;

  for (let i = 0; i <= 8; i++) {
    sum += (9 - i) * number[i];
  }

  return sum % 11 === 0;
};

export const getInvoiceAmount = (invoice) => {
  if (!invoice) {
    return "";
  }

  if (import.meta.env.VITE_APP_APP_NAME === "cloud") {
    let amount = invoice.items.reduce(
      (total, curItem) => total + curItem.amount * curItem.quantity,
      0
    );

    amount = amount * (1 + invoice.vat / 100);

    return amount.toFixed(2);
  }

  let amount = null;
  if (["deal", "tax", "tax-receipt", "refund"].includes(invoice.invoice_type)) {
    amount = invoice.items.reduce(
      (total, curItem) =>
        total + parseFloat(curItem.price) * parseFloat(curItem.quantity),
      0
    );

    amount = amount * (1 + invoice.vat / 100);
  } else {
    amount = invoice.payments.reduce(
      (total, curItem) => total + parseFloat(curItem.price),
      0
    );
  }

  return amount.toFixed(2);
};

export const getInvoiceAmountFormatted = (invoice) => {
  if (!invoice) {
    return "";
  }

  return `${currencySymbols[invoice.currency]}${getInvoiceAmount(invoice)}`;
};

export const getInvoicePaymentMethod = (invoice) => {
  if (invoice.payments[0]) {
    if (invoice.payments[0].type === -1) {
      return "unpaid";
    }
    if (invoice.payments[0].type === 0) {
      return "withholding";
    }
    if (invoice.payments[0].type === 1) {
      return "cash";
    }
    if (invoice.payments[0].type === 2) {
      return "check";
    }
    if (invoice.payments[0].type === 3) {
      return "credit-card";
    }
    if (invoice.payments[0].type === 4) {
      return "bank-transfer";
    }
    if (invoice.payments[0].type === 5) {
      return "paypal";
    }
    if (invoice.payments[0].type === 10) {
      return "payment-app";
    }
    if (invoice.payments[0].type === 11) {
      return "other";
    }
  }

  if (invoice.user && invoice.user.pay_with) {
    return invoice.user.pay_with;
  }

  return "-";
};

export const getRelevantOtherProducts = (user, wldvps) => {
  return user.otherProducts
    .filter((op) => op.whitelabel === wldvps)
    .map((op) => op._id);
};
