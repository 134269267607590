import styles from "./header.module.css";

import React, { useState } from "react";
import HamburgerSvg from "../svgs/hamburger.svg?react";
import SidebarMobile from "./sidebar-mobile";
import { useUser } from "../../utils/hooks";
import Message from "../message";
import { sanitize } from "../../utils";
import WorkspaceSelector from "./workspace-selector";
import ParentSelector from "./parent-selector";
import { useParams } from "react-router-dom";

export default function Header() {
  const user = useUser();

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const { workspaceId } = useParams();

  function handleHamburgerClicked() {
    setIsMobileSidebarOpen(true);
  }

  return (
    <>
      {user.last_charge_was_failed && (
        <div className={styles.creditCardError}>
          <Message
            id='layout.header.credit-card-problem'
            values={{
              link: (val) => sanitize(`<a href="/billing/manage-credit-card">${val}</a>`),
            }}
          />
        </div>
      )}

      <div className={styles.headerWrapper}>
        {/* <Breadcrumbs className={styles.breadcrumbs} /> */}

        <div className={styles.selectors}>
          <ParentSelector />
          {!workspaceId && <WorkspaceSelector />}
        </div>

        <div className={styles.hamburgerWrapper} onClick={handleHamburgerClicked}>
          <HamburgerSvg />
        </div>

        <SidebarMobile isOpen={isMobileSidebarOpen} onClose={() => setIsMobileSidebarOpen(false)} />
      </div>
    </>
  );
}
