import styles from "./index.module.css";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useAjax, useUser } from "../../../utils/hooks";
import Message from "../../message";
import PhoneInput from "../../phone-input";
import { sanitize } from "../../../utils";
import { countriesPhoneCodes } from "../../../utils/countries";
import CustomText from "../../custom-text";
import DreamAlert from "../../dream-alert";
import DreamButton from "../../dream-button";
import { useIntl } from "react-intl";

const RESEND_TIMER_INIT = 30;
let timerID;

function PhoneSmsModal({ isOpen, onClose }) {
  const ajax = useAjax();
  const user = useUser();
  const intl = useIntl();

  const [mode, setMode] = useState("before");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(RESEND_TIMER_INIT);
  const counterRef = useRef(counter);
  counterRef.current = counter;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError("");
    timerID = setInterval(() => {
      if (counterRef.current > 0) {
        setCounter(counterRef.current - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  async function handleSendClicked() {
    setError(false);

    if (phoneNumber.trim() === "" || isNaN(phoneNumber)) {
      return;
    }

    setLoading(true);
    const data = await ajax(`/users/sendSMS`, {
      phoneCode: user.phoneCode,
      phoneNumber,
    });
    setLoading(false);

    if (data.result === "success") {
      setMode("after");

      setCounter(RESEND_TIMER_INIT);
    } else {
      setError(data.message);
    }
  }

  async function handleNextClicked() {
    setError(false);

    if (code.trim() === "") {
      return;
    }

    setLoading(true);
    const data = await ajax(`/users/verifySMS`, {
      code,
      action: "update-phone-number",
    });
    setLoading(false);

    if (data.result === "success") {
      onClose(true);
    } else {
      setError("phone-sms-modal.wrong-code");
    }
  }

  function handleResendClicked() {
    setCounter(RESEND_TIMER_INIT);
    handleSendClicked();
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <Message id="phone-sms-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.wrapper}>
          {mode === "before" && (
            <>
              <div className={styles.text}>
                <Message id="phone-sms-modal.content" />
              </div>
              <div className={styles.phone}>
                <PhoneInput
                  textClassName="ltr"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  countryCode={user.country}
                />
              </div>
            </>
          )}

          {mode === "after" && (
            <>
              <div className={styles.text}>
                <Message
                  id="phone-sms-modal.after-sms"
                  values={{
                    number: sanitize(
                      `(+${countriesPhoneCodes[user.country]})${phoneNumber}`
                    ),
                    span: (val) =>
                      sanitize(`<span style="direction: ltr !important; display: inline-flex; flex-direction: row-reverse; font-weight: bold">
                        ${val}
                      </span>`),
                  }}
                />
              </div>
              <div>
                <CustomText
                  placeholder={intl.formatMessage({
                    id: "phone-sms-modal.enter-code",
                  })}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </>
          )}

          {error && (
            <DreamAlert alertStyle={"danger"}>
              <Message id={error} />
            </DreamAlert>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={styles.buttons}>
          {mode === "before" && (
            <DreamButton
              disabled={!phoneNumber || loading}
              loading={loading}
              color="purple"
              onClick={handleSendClicked}
            >
              <Message id="general.send" />
            </DreamButton>
          )}

          {mode === "after" && (
            <div className={styles.afterButtons}>
              <DreamButton
                disabled={!code || loading}
                loading={loading}
                color="purple"
                onClick={handleNextClicked}
              >
                <Message id="general.verify" />
              </DreamButton>
              <DreamButton
                className={styles.resendButton}
                disabled={loading || counterRef.current > 0}
                loading={loading}
                color="white"
                onClick={handleResendClicked}
              >
                {counterRef.current > 0 ? (
                  <Message
                    id="general.resend-code-counter"
                    values={{ counter: sanitize(counterRef.current) }}
                  />
                ) : (
                  <Message id="general.resend-code" />
                )}
              </DreamButton>
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

PhoneSmsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PhoneSmsModal;
