import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import styles from "./index.module.css";
import SvgSelector from "../svg-selector";

function TextField({
  icon,
  value,
  onChange,
  type,
  placeholder,
  autoFocus,
  className = "",
}) {
  function renderPlaceholder(top) {
    if (typeof placeholder === "object" && !value) {
      return (
        <div
          className={styles.placeholder}
          style={{
            top,
            pointerEvents: "none",
          }}
        >
          {placeholder}
        </div>
      );
    }

    return null;
  }

  return (
    <div className={styles.textFieldWrapper}>
      {renderPlaceholder("10px")}

      <SvgSelector icon={icon} />
      <Input
        className={`${styles.textField} ${className}`}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={typeof placeholder === "string" ? placeholder : null}
        autoFocus={autoFocus}
      />
    </div>
  );
}

TextField.propTypes = {
  icon: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.any,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
};

export default TextField;
