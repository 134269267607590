import styles from "./sidebar.module.css";
import SidebarCloudSvg from "../svgs/sidebar-cloud.svg?react";
import SidebarDomainsSvg from "../svgs/sidebar-domains.svg?react";
import SidebarSupportSvg from "../svgs/sidebar-support.svg?react";
import SidebarBillingSvg from "../svgs/sidebar-billing.svg?react";
import SidebarAccountSvg from "../svgs/sidebar-account.svg?react";
import SidebarSchemaSvg from "../svgs/sidebar-schema.svg?react";
import SidebarCloudDownloadSvg from "../svgs/sidebar-downloads.svg?react";

import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import getSidebar from "./sidebar-json.js";
import LogoSvg from "../svgs/logo.svg?react";
import LogoMiniSvg from "../svgs/logo-mini.svg?react";
import ChevronSvg from "../svgs/chevron.svg?react";
import SidebarManagementSvg from "../svgs/management.svg?react";
import { useUser } from "../../utils/hooks";
import { useWorkspace } from "../../utils/atoms";
import { useLocation } from "react-router-dom";

import Account from "../account/index.jsx";
import BackButton from "../back-button/index.jsx";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import usePState from "../../utils/hooks.jsx";

function Sidebar() {
  const location = useLocation();
  const user = useUser();
  const params = useParams();

  const { workspace } = useWorkspace();

  const sidebar = useMemo(() => getSidebar(user, params), [user, params]);
  const [isCollapsed, setIsCollapsed] = usePState("Sidebar.isCollapsed", false);

  function handleSidebarFilter(item) {
    if (params.workspaceId) {
      return ["ztna", "downloads", "allowed-access", "subscriptions", "policy-schema"].includes(item.title);
    } else {
      return ["workspaces", "support", "sidebar.billing"].includes(item.title);
    }

    // if (user.current_parent && item.permissions) {
    //   const role = user.roles.find((item) => item.parent_user_id === user.current_parent);

    //   for (let i = 0; i < item.permissions.length; i++) {
    //     if (role.permission === item.permissions[i]) {
    //       return true;
    //     }
    //   }

    //   return false;
    // }

    // if (user.current_parent && ["emails-history", "permissions", "logs", "general-settings"].includes(item.id)) {
    //   return false;
    // }

    // return true;
  }

  function renderSvg(icon) {
    switch (icon) {
      case "cloud":
        return <SidebarCloudSvg />;
      case "cloudDownload":
        return <SidebarCloudDownloadSvg />;
      case "domains":
        return <SidebarDomainsSvg />;
      case "support":
        return <SidebarSupportSvg />;
      case "billing":
        return <SidebarBillingSvg />;
      case "account":
        return <SidebarAccountSvg />;
      case "schema":
        return <SidebarSchemaSvg />;
      case "management":
        return <SidebarManagementSvg className={styles.fill} />;
      default:
        return null;
    }
  }

  function toggleCollapsedSidebar() {
    setIsCollapsed(!isCollapsed);
  }

  function renderSubMenu(item) {
    return (
      <ul className={`clearList ${styles.subNav}`}>
        {item.links.map((subItem, idx) => (
          <li key={idx} className={styles.subItem}>
            <Link to={subItem.to} title={isCollapsed ? subItem.title : null} className={`${subItem.selected?.test(location.pathname) ? styles.selected : styles.subItem}`}>
              {isCollapsed ? <span className={styles.circle}></span> : <FormattedMessage id={subItem.title} />}
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <aside className={`${styles.sidebarWrapper} ${isCollapsed ? styles.collapsed : ""} `}>
      <>
        <button className={`cleanBtn ${styles.collapsedBtn}`} onClick={() => toggleCollapsedSidebar()} title={isCollapsed ? "Open navigation" : "Close navigation"}>
          <ChevronSvg className={styles.chevron} />
        </button>
        <div className={styles.sidebarFloat}>
          <nav className={styles.nav}>
            <div className={styles.navTop}>
              {params.workspaceId ? (
                <div className={styles.buttonWrapper}>
                  <BackButton toPath='/workspaces' title='Back to workspaces' />{" "}
                  <span className={styles.workspaceName} title={workspace?.name}>
                    {workspace?.name}
                  </span>
                </div>
              ) : (
                <Link to={`/workspaces`}>{isCollapsed ? <LogoMiniSvg className={`${styles.logoSvg} ${styles.logoMini}`} /> : <LogoSvg className={`${styles.logoSvg} ${styles.logo}`} />}</Link>
              )}
            </div>
            <div className={styles.overflow}>
              <ul className={`clearList ${styles.menuList}`}>
                {sidebar.filter(handleSidebarFilter).map((item, idx) => (
                  <li key={idx} className={`${styles.menuListItem} ${item.selected?.test(location.pathname) ? styles.selected : ""}`}>
                    <Link to={item.to} className={styles.menuListItemLink} title={isCollapsed ? item.title : null}>
                      {renderSvg(item.icon)}

                      <div className={styles.menuListItemLinkTitle}>{<FormattedMessage id={item.title} />}</div>

                      {item.links && <ChevronSvg className={styles.chevron} />}
                    </Link>
                    {item.links && renderSubMenu(item)}
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          <Account isCollapsed={isCollapsed} />
        </div>
      </>
    </aside>
  );
}

export default Sidebar;
