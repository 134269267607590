import styles from "./index.module.css";
import React from "react";
import PropTypes from "prop-types";

function EmailBox({ children }) {
  return <div className={styles.wrapper}>{children}</div>;
}

EmailBox.propTypes = {
  children: PropTypes.any,
};

export default EmailBox;
