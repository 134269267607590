import styles from "./node-wrapper.module.css";

import React from "react";

export default function NodeWrapper(props) {
  return (
    <div id={`tooltip-${props.id}`} className={`${props.className || ""} ${styles.wrapper} ${props.selected ? styles.selected : ""}`} onDoubleClickCapture={props.onDoubleClick}>
      {props.children}
    </div>
  );
}
