import styles from "./node-wrapper.module.css";
import ServiceSvg from "../svgs/service.svg?react";

import React from "react";
import NodeWrapper from "./node-wrapper";
import { Handle } from "@xyflow/react";
import { FormattedMessage } from "react-intl";

export default function ServiceNode(props) {
  return (
    <NodeWrapper {...props}>
      <Handle className={styles.handle} type='target' id={props.id} />
      <div className={styles.header}>
        <ServiceSvg />
      </div>
      <div className={styles.label}>{props.data.name || <FormattedMessage id='all' />}</div>
    </NodeWrapper>
  );
}
