import styles from "./help-panel.module.css";
import React from "react";
import HelpCircleSvg from "../../components/svgs/help-circle.svg?react";
import CloseSvg from "../../components/svgs/cancel.svg?react";
import PcSvg from "../../components/svgs/pc.svg?react";
import { useState } from "react";

export default function HelpPanel() {
  const [isOpenPanel, setIsOpenPanel] = useState(false);

  function togglePanel() {
    setIsOpenPanel((prev) => !prev);
  }

  return (
    <>
      <button className={styles.buttonHelp} onClick={() => togglePanel()}>
        <HelpCircleSvg />
      </button>

      {isOpenPanel && (
        <div className={styles.panel}>
          <div className={styles.panelInner}>
            <h2 className={styles.title}>Help panel</h2>
            <div className={styles.item}>
              <kbd className={styles.key}>Shift</kbd> + and click to select multiple items.
            </div>
            <div className={styles.item}>
              <span className={styles.key}>
                <PcSvg className={`${styles.pc} ${styles.pcUntrusted}`} />
              </span>
              Trusted
            </div>
            <div className={styles.item}>
              <span className={styles.key}>
                <PcSvg className={`${styles.pc} ${styles.pcOffline}`} />
              </span>
              Offline
            </div>
          </div>
          <button className={styles.buttonClose} onClick={() => togglePanel()}>
            <CloseSvg />
          </button>
        </div>
      )}
    </>
  );
}
