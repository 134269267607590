import styles from "./node-wrapper.module.css";
import ChainSvg from "../svgs/chain.svg?react";
import React from "react";
import NodeWrapper from "./node-wrapper";
import { Handle } from "@xyflow/react";

export default function ConnectorNode(props) {
  return (
    <NodeWrapper {...props}>
      <Handle className={styles.handle} type='source' id={props.id} />
      <div>
        <ChainSvg />
      </div>
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: props.data.name }}></div>
    </NodeWrapper>
  );
}

export const ConnectorNodeSidebarHeader = (node) => {
  return (
    <div className={styles.header}>
      <ChainSvg /> {node.data.name}
    </div>
  );
};

export const ConnectorNodeSidebarSingle = (node) => {
  function renderValue(value) {
    if (typeof value === "boolean") {
      return value ? "True" : "";
    }
    if (!value || value.toString().trim() === "") {
      return "-";
    }

    return value;
  }

  return (
    <>
      {ConnectorNodeSidebarHeader(node)}
      <hr />
      <div className={styles.tableWrapper}>
        <table>
          <tbody>
            {Object.entries(node.data).map(([key, value]) => (
              <tr key={key}>
                <td className={styles.tableKey}>{key}</td>
                <td>{renderValue(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
