import styles from "./index.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DreamButton from "../../dream-button";
import Spinner from "../../spinner";
import sanitize from "sanitize-html";
import Message from "../../message";

function ConfirmModal({
  isOpen,
  onClose,
  title,
  message,
  button1 = {}, // deprecated
  button2 = {}, // deprecated
  buttons = null,
  beforeClose = null,
}) {
  const [loading, setLoading] = useState(false);

  async function handleCloseClicked(region) {
    if (typeof beforeClose === "function") {
      setLoading(true);
      await beforeClose(region);
      setLoading(false);
    }

    onClose(region);
  }

  function handleToggle() {
    if (loading) {
      return;
    }

    handleCloseClicked("modal");
  }

  function renderBody() {
    if (React.isValidElement(message)) {
      return <ModalBody className={styles.body}>{message}</ModalBody>;
    }

    return (
      <ModalBody
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: sanitize(message) }}
      ></ModalBody>
    );
  }

  function renderButtons() {
    if (buttons) {
      return (
        <div
          className={`${styles.buttons} ${
            buttons.length > 2 ? styles.many : ""
          }`}
        >
          {buttons.map((button, key) => (
            <DreamButton
              key={key}
              onClick={() =>
                handleCloseClicked(button.value || `button${key + 1}`)
              }
              {...button}
            >
              {button.text}
            </DreamButton>
          ))}
        </div>
      );
    }

    return (
      <div className={styles.buttons}>
        <DreamButton
          disabled={loading}
          icon={button1.icon || false}
          color={button1.color || "green"}
          onClick={() => handleCloseClicked("button1")}
          textColor={button1.textColor || false}
        >
          {button1.text || <Message id="general.close" />}
        </DreamButton>
        <DreamButton
          disabled={loading}
          icon={button2.icon || false}
          color={button2.color || "green"}
          onClick={() => handleCloseClicked("button2")}
          textColor={button2.textColor || false}
          href={button2.href}
          as={button2.as}
          target={button2.target}
        >
          {loading ? <Spinner /> : ""}
          {button2.text || <Message id="general.close" />}
        </DreamButton>
      </div>
    );
  }

  return (
    <Modal
      className={styles.wrapper}
      isOpen={isOpen}
      toggle={handleToggle}
      size="lg"
      style={{ maxWidth: "552px", width: "80%", top: "15%" }}
    >
      <ModalHeader className={styles.header} toggle={handleToggle}>
        {title}
      </ModalHeader>
      {renderBody()}
      <ModalFooter>{renderButtons()}</ModalFooter>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.any,
  message: PropTypes.any,
  button1: PropTypes.object,
  button2: PropTypes.object,
  buttons: PropTypes.array,
  beforeClose: PropTypes.func,
};

export default ConfirmModal;
