import styles from "./step1.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import validator from "validator";
import { useUser } from "../../utils/hooks";
import Message from "../message";
import { sanitize } from "../../utils";
import KeyDetector from "../key-detector";
import CustomText from "../custom-text";
import DreamButton from "../dream-button";

function PermissionsStep1({ setStep, setEmailsArray }) {
  const user = useUser();

  const [emails, setEmails] = useState("");

  const [error, setError] = useState(false);

  function handleNextClicked() {
    setError(false);

    if (!emails.trim()) {
      return setError(<Message id="errors.emails" />);
    }

    const emailsArr = emails.split(",");

    for (let i = 0; i < emailsArr.length; i++) {
      emailsArr[i] = emailsArr[i].trim();

      if (!validator.isEmail(emailsArr[i])) {
        return setError(
          <Message
            id="errors.wrong-email"
            values={{ email: sanitize(emailsArr[i]) }}
          />
        );
      }

      if (emailsArr[i] === user.email) {
        return setError(
          <Message
            id="errors.same-email"
            values={{ email: sanitize(emailsArr[i]) }}
          />
        );
      }
    }

    setEmailsArray(emailsArr);
    setStep(2);
  }

  return (
    <div>
      <h3>
        <Message id="step1.title" />
      </h3>
      <Message id="step1.sub-title" />
      <br />
      <br />
      <div className={styles.smaller}>
        <Message id="step1.mail" />
        <br />
        <span className={styles.grey}>
          <Message id="step1.sub-mail" />
        </span>
      </div>
      <br />
      <KeyDetector onKeyDetected={handleNextClicked}>
        <CustomText
          className={styles.input}
          type="text"
          placeholder="name1@example.com,name2@example.com,name3@example.com"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
        />
      </KeyDetector>
      {error && <div className="error">{error}</div>}
      <br />
      <div className={styles.flex}>
        <DreamButton
          className={styles.nobackGround}
          href="/account/permissions"
          as="/account/permissions"
          color="white"
        >
          <Message id="step1.discard" />
        </DreamButton>
        <DreamButton onClick={handleNextClicked}>
          <Message id="step1.next" />
        </DreamButton>
      </div>
    </div>
  );
}

PermissionsStep1.propTypes = {
  setStep: PropTypes.func,
  setEmailsArray: PropTypes.func,
};

export default PermissionsStep1;
