import styles from "./node-wrapper.module.css";
import UserSvg from "../svgs/user2.svg?react";
import React from "react";
import NodeWrapper from "./node-wrapper";
import { Handle } from "@xyflow/react";

export default function IdentityNode(props) {
  return (
    <NodeWrapper {...props}>
      <Handle className={styles.handle} type='source' id={props.id} />
      <div className={styles.header}>
        <UserSvg />
      </div>
      <div className={styles.label}>{props.data.name}</div>
    </NodeWrapper>
  );
}
