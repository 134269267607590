import styles from "./two-factor-setting.module.css";

import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAjax, useInitialDataFetching } from "../../utils/hooks";
import Message from "../../components/message";
import KeyDetector from "../../components/key-detector";
import CustomText from "../../components/custom-text";
import DreamButton from "../../components/dream-button";

function TwoFactorSetting() {
  const ajax = useAjax();
  const navigate = useNavigate();
  const initialDataFetching = useInitialDataFetching();

  const [qr, setQR] = useState(null);
  const [secret, setSecret] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const get2FAQR = useCallback(async () => {
    const data = await ajax("/users/get2FAQR");

    setQR(data.qr);
    setSecret(data.secret);
  }, [ajax]);

  useEffect(() => {
    setCode("");
    get2FAQR();
  }, [get2FAQR]);
  async function handleActiveClicked() {
    setError(false);

    setLoading(true);
    const data = await ajax(`/users/active2FA`, { code });
    setLoading(false);

    if (data.result === "success") {
      await initialDataFetching();
      navigate(`/account/security`);
    } else {
      setError(<Message id="two-factor-authentication-modal.wrong-code" />);
    }
  }

  return (
    <div>
      <div className={styles.buttons}>
        <div className={styles.header}>
          <Message id="step5.title" />
        </div>
        <br />
        <Message id="step5.text1" />

        <span className={styles.bold}>
          <Message id="step5.text2" />
        </span>

        <Message id="step5.text3" />
        {qr && (
          <div>
            <div className={styles.imgWrapper}>
              <img src={qr} width={166} height={166} />
            </div>
            {secret && (
              <div>
                <Message
                  id="two-factor-authentication-modal.instructions2"
                  values={{ secret, b: (val) => `<b>${val}</b>` }}
                />
              </div>
            )}
            <br />
            <KeyDetector
              className={styles.textWrapper}
              onKeyDetected={handleActiveClicked}
            >
              <CustomText
                className={styles.text}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <div className={styles.btn}>
                <DreamButton
                  disabled={loading}
                  loading={loading}
                  color="white"
                  onClick={handleActiveClicked}
                >
                  <Message id="general.active" />
                </DreamButton>
              </div>
            </KeyDetector>
            {error && <div className="error">{error}</div>}
          </div>
        )}
        {/* <div className={styles.header}>
          <Message id="step5.text4" />
        </div> */}
      </div>
    </div>
  );
}

export default TwoFactorSetting;
