import styles from "./index.module.css";

import React from "react";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import ManagementPrices from "./prices";
import Message from "../../components/message";
import Navigator from "../../components/navigator";
import { WithRole } from "../../components/with-role";
import LayoutMainAction from "../../components/layout-main-action";

export default function Management() {
  const location = useLocation();

  const selected = useMemo(() => {
    if (/\/management\/prices/.test(location.pathname)) {
      return "prices";
    }
  }, [location.pathname]);

  const navigatorItems = useMemo(
    () => [
      {
        title: <Message id="prices" />,
        to: `/management/prices`,
        selected: selected === "prices",
      },
    ],
    [selected]
  );

  return (
    <WithRole permission="">
      <LayoutMainAction>
        <Navigator items={navigatorItems} />
        <hr className={styles.hr} />
        {selected === "prices" && <ManagementPrices />}
      </LayoutMainAction>
    </WithRole>
  );
}
