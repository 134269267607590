import styles from "./index.module.css";

import React from "react";
import { useUser } from "../../utils/hooks";
import Message from "../message";
import Avatar from "../avatar";

export default function UserAvatar() {
  const user = useUser();

  function renderAvatar() {
    return (
      <div className={styles.wrapper}>
        <Avatar />
        <div className={styles.details}>
          <span className={styles.fullname}>
            <Message id='parent-selector.my-account' />
          </span>
          <span className={styles.email} title={user.email}>
            {user.email}
          </span>
        </div>
      </div>
    );
  }

  return <>{renderAvatar()}</>;
}
