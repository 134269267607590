import styles from "./index.module.css";

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import generatePassword from "password-generator";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopySvg from "../../svgs/copy.svg?react";
import RefreshSvg from "../../svgs/refresh.svg?react";
import CheckSvg from "../../svgs/check.svg?react";
import { selectText } from "../../../utils";
import Message from "../../message";
import DreamButton from "../../dream-button";

function GeneratePasswordModal({ isOpen, onClose }) {
  const passwordRef = useRef();

  const [generatedPassword, setGeneratedPassword] = useState("");
  const [copied, setCopied] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setCopied(false);
      setLoading(false);

      handleGenerateClicked();
    }
  }, [isOpen]);

  function handleGenerateClicked() {
    let password;

    do {
      password = generatePassword(16, false, /([A-Za-z\d?-])/g);

      if (
        /\d/.test(password) &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password)
      ) {
        break;
      }
    } while (true);

    setGeneratedPassword(password);

    if (passwordRef.current) {
      selectText(passwordRef.current);
    }
  }

  function handleCopyToClipboardClicked() {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  function handleActionClicked() {
    setLoading(true);
    onClose(generatedPassword);
  }

  return (
    <Modal
      className={styles.generatePasswordModal}
      isOpen={isOpen}
      toggle={() => onClose(false)}
    >
      <ModalHeader toggle={() => onClose(false)}>
        <Message id="generate-password-modal.title" />
      </ModalHeader>
      <ModalBody>
        <div className={styles.passwordBox}>
          <span ref={passwordRef}>{generatedPassword}</span>

          <span>
            {!copied && (
              <CopyToClipboard
                text={generatedPassword}
                onCopy={handleCopyToClipboardClicked}
              >
                <CopySvg />
              </CopyToClipboard>
            )}

            {copied && <CheckSvg />}

            <RefreshSvg onClick={handleGenerateClicked} />
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <DreamButton
          disabled={loading}
          color="gray"
          onClick={() => onClose(false)}
        >
          <Message id="general.cancel" />
        </DreamButton>
        <DreamButton
          disabled={loading}
          loading={loading}
          color="purple"
          onClick={handleActionClicked}
        >
          <Message id="generate-password-modal.action" />
        </DreamButton>
      </ModalFooter>
    </Modal>
  );
}

GeneratePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GeneratePasswordModal;
