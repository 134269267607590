import { useRecoilState } from "recoil";
import { atom } from "recoil";
import { ajax } from ".";
import { useCallback } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const userState = atom({
  key: "userState",
  default: {},
});

export const settingsState = atom({
  key: "settingsState",
  default: {
    alertModalData: { isOpen: false },
    sidebarSelection: {},
    schemaReadOnly: false,
    schemaAnimated: true,
  },
});

export const workspaceState = atom({
  key: "workspaceState",
  default: null,
});

export const useWorkspace = () => {
  const { workspaceId } = useParams();

  const { workspaces } = useWorkspaces();

  const [workspace, setWorkspace] = useRecoilState(workspaceState);

  useEffect(() => {
    const currentWorkspace = workspaces.find((workspace) => workspaceId === workspace._id);

    setWorkspace(currentWorkspace);
  }, [setWorkspace, workspaceId, workspaces]);

  return {
    workspace,
  };
};

const workspacesState = atom({
  key: "workspacesState",
  default: [],
});

export const useWorkspaces = () => {
  const [workspaces, setWorkspaces] = useRecoilState(workspacesState);

  const updateWorkspaces = useCallback(async () => {
    const data = await ajax("/workspace/list");

    setWorkspaces(data.workspaces);
  }, [setWorkspaces]);

  return {
    workspaces,
    updateWorkspaces,
  };
};
