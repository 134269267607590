import styles from "./index.module.css";

import React from "react";
import Select from "react-select";
import ChevronDownSvg from "../svgs/chevron-down.svg?react";

import { useIntl } from "react-intl";

export const CLICK_SELECT_COLOR_NORMAL = "normal";
export const CLICK_SELECT_COLOR_TRANSPARENT = "transparent";

function ClickSelect({
  instanceId,
  options,
  value,
  onChange,
  isMulti,
  isDisabled,
  containerClassName = "",
  className = "",
  placeholder = null,
  color = CLICK_SELECT_COLOR_NORMAL,
  ref = null,
  clearValue = null,
  FloatingSvg = ChevronDownSvg,
}) {
  const intl = useIntl();

  return (
    <div className={`${styles.container} ${containerClassName}`}>
      {FloatingSvg && <FloatingSvg className={styles.floatingSvg} />}

      <Select
        ref={ref}
        placeholder={
          placeholder || intl.formatMessage({ id: "general.select" })
        }
        isDisabled={isDisabled}
        instanceId={instanceId}
        clearValue={clearValue}
        options={options}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        className={`${styles.wrapper} ${className} ${
          styles[`color-${color}`]
        } ${FloatingSvg ? styles.withFloatingSvg : ""}`}
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused
              ? "var(--primary-color)"
              : base.borderColor,
            boxShadow: state.isFocused ? "var(--primary-color)" : "none",
            "&:hover": {
              borderColor: "var(--primary-color)",
            },
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected
              ? "var(--primary-color)"
              : state.isFocused
              ? "var(--neutral-neutral090)"
              : "white",

            ":active": {
              backgroundColor: "var(--primary-light)",
            },
          }),
        }}
      />
    </div>
  );
}

export default ClickSelect;
