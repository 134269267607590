import styles from "./node-wrapper.module.css";
import ChainSvg from "../svgs/chain.svg?react";
import PcSvg from "../svgs/pc.svg?react";

import React from "react";
import NodeWrapper from "./node-wrapper";
import { Handle } from "@xyflow/react";

export default function DeviceNode(props) {
  function getStateClassname() {
    return `${props.data.DeviceState === 2 ? styles.trusted : styles.untrusted} ${props.data.SystemHealthState === "Enabled" ? styles.online : styles.offline}`;
  }

  return (
    <NodeWrapper {...props}>
      <Handle className={styles.handle} type='source' id={props.id} />
      <div className={getStateClassname()}>
        {props.data.ActorID >= 10000 && <ChainSvg className={styles.connector} />}

        <PcSvg />
      </div>
      <div className={styles.label}>
        {props.data.Name} {props.data.IdentityOwnerName ? ` - ${props.data.IdentityOwnerName}` : ""}
      </div>
    </NodeWrapper>
  );
}

export const DeviceNodeSidebarHeader = (node) => {
  return (
    <div className={styles.header}>
      <PcSvg /> {node.data.Name} {node.data.IdentityOwnerName ? ` - ${node.data.IdentityOwnerName}` : ""}
    </div>
  );
};

export const DeviceNodeSidebarSingle = (node) => {
  function renderValue(value) {
    if (typeof value === "boolean") {
      return value ? "True" : "";
    }
    if (!value || value.toString().trim() === "") {
      return "-";
    }

    return value;
  }

  return (
    <>
      {DeviceNodeSidebarHeader(node)}
      <hr />
      <div className={styles.tableWrapper}>
        <table>
          <tbody>
            {Object.entries(node.data.details).map(([key, value]) => (
              <tr key={key}>
                <td className={styles.tableKey}>{key}</td>
                <td>{renderValue(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
