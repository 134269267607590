import { currencySymbols } from "./billing";

export const USER = "user";
export const WHITELABEL = "whitelabel";
export const SUPER_ADMIN = "super-admin";

export const getFullName = (user) => {
  if (!user) {
    return "";
  }

  if (!user.firstName || user.firstName.trim() === "") {
    return user.email;
  }

  return `${user.firstName} ${user.lastName}`;
};

export const getInitials = (user) => {
  if (!user) {
    return "";
  }

  if (!user.firstName || user.firstName.trim() === "") {
    return "@";
  }

  return `${user.firstName[0]}${user.lastName[0]}`;
};

export const isSuperAdmin = (user) => {
  return user.role === "super-admin";
};

export const userCurrency = (user) => {
  return currencySymbols[user.currency];
};

export const getSuspendedReason = (reason) => {
  switch (reason) {
    case "USER_STATUS_SUSPENDED_SCAM":
      return "scam";
    case "USER_STATUS_SUSPENDED_SPAM":
      return "spam";
    case "USER_STATUS_SUSPENDED_ATTACK":
      return "attack";
    case "USER_STATUS_SUSPENDED_ABUSE":
      return "abuse";
    case "USER_STATUS_SUSPENDED_PAYMENT_ISSUE":
      return "payment-issue";
    default:
      return "general-issue";
  }
};

export const trustPointLimits = (trustPoints) => {
  if (trustPoints <= 1) {
    return { cpu: 4, ram_mb: 8192, max_disk: 200 };
  }
  if (trustPoints === 2) {
    return { cpu: 8, ram_mb: 16384, max_disk: 500 };
  }
  if (trustPoints === 3) {
    return { cpu: 16, ram_mb: 24576, max_disk: 1000 };
  }
  if (trustPoints === 4) {
    return { cpu: 32, ram_mb: 65536, max_disk: 2000 };
  }

  return null;
};
