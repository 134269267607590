import styles from "./node-wrapper.module.css";
import ResourceSvg from "../svgs/resource.svg?react";
import React from "react";
import NodeWrapper from "./node-wrapper";
import { Handle } from "@xyflow/react";

export default function ResourceNode(props) {
  return (
    <NodeWrapper {...props}>
      <Handle className={styles.handle} type="target" id={props.id} />
      <Handle className={styles.handle} type="source" id={props.id} />
      <div>
        <ResourceSvg />
      </div>
      <div
        className={styles.label}
        dangerouslySetInnerHTML={{ __html: props.data.name }}
      ></div>
    </NodeWrapper>
  );
}
