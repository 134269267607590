import React from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types";

function Badge({ children, color, size }) {
  return (
    <div className={`${styles.wrapper} ${styles[size]} ${styles[color]}`}>
      {children}
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default Badge;
