import styles from "./step3.module.css";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Sheild from "../../components/svgs/permission-sheild.svg?react";
import Trash from "../../components/svgs/permission-trash.svg?react";
import InfoAlert from "../info-alert";
import { useAjax } from "../../utils/hooks";
import Message from "../message";
import Checkbox from "../checkbox";
import ClickTable from "../click-table";
import Spinner from "../spinner";
import { getFullName } from "../../utils/user";
import DreamButton from "../dream-button";
import { useIntl } from "react-intl";

function PermissionsStep3({ emails, permission, user }) {
  const ajax = useAjax();
  const navigate = useNavigate();
  const intl = useIntl();

  const [allSites, setAllSites] = useState([]);
  const [sites, setSites] = useState([]);
  const [allSitesAccess, setAllSitesAccess] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  async function handleConfirmClicked() {
    setError(false);

    if (permission === "sites" && sites.length === 0 && !allSitesAccess) {
      return setError(<Message id="errors.sites" />);
    }

    setLoading(true);

    if (user) {
      await ajax("/roles/edit", {
        userID: user._id,
        permission,
        sitesIds: allSitesAccess ? -1 : sites.map((site) => site.value),
      });
    } else {
      await ajax("/roles/create", {
        emails,
        permission,
        sitesIds: allSitesAccess ? -1 : sites.map((site) => site.value),
      });
    }

    navigate("/account/permissions");
  }

  function handleSitesSelectChanged(item) {
    sites.push(item);
    setSites([...sites]);

    const all = allSites?.filter((site) => site.value !== item.value);
    setAllSites(all);
  }

  function handleRemoveClicked(item) {
    const newSites = sites.filter((site) => site.value !== item.value);
    setSites(newSites);

    allSites.push(item);
    setAllSites([...allSites]);
  }

  function renderContent() {
    if (permission === "billing") {
      return (
        <>
          <Message id="permissions.step3.billing.sub-title" />
          <br />
          <br />
          <Message id="step3.billing-1" />

          <ul>
            <li>
              <Message id="step3.billing-2" />
            </li>
            <li>
              <Message id="step3.billing-3" />
            </li>
            <li>
              <Message id="step3.billing-4" />
            </li>
            <li>
              <Message id="step3.billing-5" />
            </li>
          </ul>
          <InfoAlert>
            <Sheild />
            <span className={styles.bold}>
              <Message id="step3.alert-text" />
            </span>
            <Message id="step3.alert-text2" />
          </InfoAlert>
        </>
      );
    }

    if (permission === "full") {
      return (
        <>
          <Message id="permissions.step3.full.sub-title" />
          <br />
          <br />
          <Message id="step3.billing-1" />

          <ul>
            <li>
              <Message id="step3.full-1" />
            </li>
            <li>
              <Message id="step3.full-2" />
            </li>
            <li>
              <Message id="step3.full-3" />
            </li>
            <li>
              <Message id="step3.full-4" />
            </li>
            <li>
              <Message id="step3.full-5" />
            </li>
            <li>
              <Message id="step3.full-6" />
            </li>
            <li>
              <Message id="step3.full-7" />
            </li>
          </ul>
          <InfoAlert>
            <Sheild />
            <span className={styles.bold}>
              <Message id="step3.alert-text" />
            </span>
            <Message id="step3.alert-text2" />
          </InfoAlert>
        </>
      );
    }

    return (
      <>
        <Message id="permissions.step3.sites.sub-title" />
        <InfoAlert>
          <Sheild />
          <span className={styles.bold}>
            <Message id="step3.alert-text" />
          </span>
          <Message id="step3.alert-text2" />
        </InfoAlert>
        <Message id="step3.select-text-head" />
        <div className={`${styles.flex} ${styles.customSelect}`}>
          <Select
            className={`${styles.selectComponent} ${
              allSitesAccess ? "disabled" : ""
            }`}
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            placeholder={intl.formatMessage({ id: "step3.select-text" })}
            value={null}
            onChange={handleSitesSelectChanged}
            options={allSites}
            formatOptionLabel={({ label }) => (
              <div className={styles.reactSelectRow}>
                <span>{label}</span>
                <span>
                  <Message id="general.add" />
                </span>
              </div>
            )}
          />
          <Checkbox
            checked={allSitesAccess}
            onChange={(e) => setAllSitesAccess(e.target.checked)}
            label={<Message id="step3.checkbox-label" />}
          />
        </div>
        <br />
        {!allSitesAccess && (
          <ClickTable className={styles.table}>
            <thead>
              <tr>
                <th>
                  <Message id="step3.site-name" />
                </th>
                <th>
                  <Message id="step3.address" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!sites && (
                <tr>
                  <td colSpan={100}>
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}

              {sites?.length === 0 && (
                <tr>
                  <td colSpan={100}>
                    <Message id="no-rows" />
                  </td>
                </tr>
              )}

              {sites?.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.label}</td>
                  <td>{item.first_domain}</td>
                  <td>
                    <div onClick={() => handleRemoveClicked(item)}>
                      <Trash className={styles.trash} />
                      <Message id="step3.trash" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </ClickTable>
        )}
      </>
    );
  }

  return (
    <div>
      <div className={styles.flextitle}>
        <h3>
          {permission === "sites" && (
            <Message id="permissions.step-3.title.sites" />
          )}
          {permission === "billing" && (
            <Message id="permissions.step-3.title.billing" />
          )}
          {permission === "full" && (
            <Message id="permissions.step-3.title.full" />
          )}
        </h3>
        {user && <div className={styles.bluediv}>{getFullName(user)}</div>}
      </div>
      {renderContent()}
      {error && <div className="error">{error}</div>}
      <br />
      <div className={styles.flex}>
        <DreamButton
          loading={loading}
          disabled={loading}
          onClick={handleConfirmClicked}
        >
          <Message id="step3.confirm" />
        </DreamButton>
      </div>
    </div>
  );
}

PermissionsStep3.propTypes = {
  emails: PropTypes.array,
  permission: PropTypes.string,
  user: PropTypes.any,
};

export default PermissionsStep3;
