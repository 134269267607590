import React, { useCallback, useRef, useEffect, useState } from "react";
import Message from "../components/message";
import io from "socket.io-client";
import confetti from "canvas-confetti";
import { getSocket, setSocket } from "./globals";
import axios from "axios";
import useIsMounted from "ismounted";
import { useNavigate } from "react-router-dom";
import { getFullName, SUPER_ADMIN, USER, WHITELABEL } from "./user";
import { useIntl } from "react-intl";
import { sanitize } from ".";
import { useRecoilState } from "recoil";
import { userState } from "./atoms";
import { settingsState } from "./atoms";

export const useResponseAlert = () => {
  const alert = useAlert();
  const showAlert = useCallback(
    async (result, txt) => {
      if (result === "success") {
        await alert({
          title: <Message id='general.update-notification.title' />,
          message: txt ? txt : <Message id='general.update-notification.message' />,
          notification: true,
          alertStyle: "success",
        });
      } else {
        alert({
          title: <Message id='error' />,
          message: txt ? txt : <Message id='try-again-later' />,
          notification: true,
          alertStyle: "danger",
        });
      }
    },
    [alert]
  );

  return { showAlert };
};

export const useInitialDataFetching = () => {
  const ajax = useAjax();
  const [, setUser] = useRecoilState(userState);
  const [, setSettings] = useRecoilState(settingsState);
  const { connect } = useWebSocket();

  return async (connectToSocket = false) => {
    const data = await ajax(`/initialData`);

    // if (data.registerStep === -2) {
    //   await logout();
    //   location.href = "/";
    //   return;
    // }

    setSettings((prev) => ({ ...prev, countryOfIp: data.countryOfIp }));

    if (data.result === "success") {
      setUser(data);

      if (connectToSocket) {
        connect();
      }

      return true;
    }

    return false;
  };
};

export const useWebSocket = () => {
  const logout = useLogout();
  const logoutRef = useRef(logout);

  const connect = useCallback(() => {
    const socket = io(import.meta.env.VITE_APP_SOCKET_URL, {
      withCredentials: true,
    });

    socket.on("auto-logout", () => {
      logoutRef.current();
    });

    setSocket(socket);

    return () => {
      socket.off("tasks-update");

      socket.off("sites-update");

      socket.off("site-update");

      socket.off("auto-logout");

      socket.off("staging-is-ready");
    };
  }, []);

  return { connect };
};

export const useSocketListener = (event, func) => {
  const socket = getSocket();

  useEffect(() => {
    if (socket) {
      socket.on(event, func);
    }

    return () => {
      if (socket) {
        socket.off(event, func);
      }
    };
  }, [event, func, socket]);
};

export const useConfetti = () => {
  return () => {
    [0, 0.25, 0.5, 0.75, 1].forEach((x) => {
      confetti({
        particleCount: 300,
        spread: 100,
        ticks: 100,
        origin: {
          x,
          y: 0.3,
        },
      });
    });
  };
};

export const useFetchUsers = (labelFormat = "{fullName} - ({email})", filter = () => true, handlers = {}) => {
  const ajax = useAjax();
  const user = useUser();
  const filterRef = useRef(filter);

  const [allUsers, setAllUsers] = useState(null);

  const getAllUsers = useCallback(async () => {
    const data = await ajax("/admin/getUsers");

    if (!data.users) {
      return;
    }

    const allUsers = data.users.filter(filterRef.current).map((user) => ({
      label: labelFormat.replace("{fullName}", getFullName(user)).replace("{email}", user.email),
      value: user._id,
      email: user.email,
      user,
    }));

    setAllUsers(allUsers);
  }, [ajax, labelFormat]);

  useEffect(() => {
    handlers.refresh = () => {
      getAllUsers();
    };
  }, [handlers, getAllUsers]);

  useEffect(() => {
    if (user.role === SUPER_ADMIN) {
      getAllUsers();
    }
  }, [ajax, labelFormat, user.role, getAllUsers]);

  return allUsers;
};

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useLogout = () => {
  const ajax = useAjax();

  return async () => {
    await ajax(`/users/logout`, {});
    location.href = `/user/login`;
  };
};

export const useMessage = () => {
  const intl = useIntl();

  return ({ id }, { values } = {}) => intl.formatMessage({ id }, values);
};

export const useAlert = () => {
  const [, setSettings] = useRecoilState(settingsState);

  return ({ title, message, button, notification, alertStyle }) => {
    return new Promise((resolve) => {
      setSettings((prev) => ({
        ...prev,
        alertModalData: {
          isOpen: true,
          title,
          message,
          button,
          notification,
          alertStyle,
          resolve,
        },
      }));
    });
  };
};

export const useConfirm = () => {
  const [, setSettings] = useRecoilState(settingsState);
  const messageHook = useMessage();

  return ({
    title,
    message,
    button1 = {
      text: messageHook({ id: "cancel" }),
      color: "gray",
    },
    button2 = {
      text: messageHook({ id: "yes" }),
      color: "light-purple",
    },
    buttons = null,
    beforeClose = null,
  }) => {
    return new Promise((resolve) => {
      setSettings((prev) => ({
        ...prev,
        confirmModalData: {
          isOpen: true,
          title,
          message,
          button1,
          button2,
          buttons,
          resolve,
          beforeClose,
        },
      }));
    });
  };
};

export const usePrompt = () => {
  const [, setSettings] = useRecoilState(settingsState);
  const messageHook = useMessage();

  return ({
    title,
    message,
    defaultText = "",
    button1 = {
      text: messageHook({ id: "cancel" }),
      color: "gray",
    },
    button2 = {
      text: messageHook({ id: "ok" }),
      color: "light-purple",
    },
    acceptOnlyValue,
    textType = "text",
    beforeClose = null,
    placeholder = null,
    withCheckbox = null,
  }) => {
    return new Promise((resolve) => {
      setSettings((prev) => ({
        ...prev,
        promptModalData: {
          isOpen: true,
          title,
          message,
          defaultText,
          button1,
          button2,
          resolve,
          acceptOnlyValue: typeof acceptOnlyValue === "string" ? sanitize(acceptOnlyValue) : acceptOnlyValue,
          textType,
          beforeClose,
          placeholder,
          withCheckbox,
        },
      }));
    });
  };
};

export const useAjax = () => {
  let serverUrl = window.serverUrl;

  const ajax = useCallback(
    async (url, params = {}, config = {}) => {
      do {
        try {
          const { data } = await axios.post(`${serverUrl}${url}`, params, {
            ...config,
            withCredentials: true,
          });

          if (data.result === "error" && data.message == "blocked-user" && !location.href.endsWith("/user/login")) {
            location.href = "/user/login";
            return;
          }

          return data;
        } catch (err) {
          const ret = {
            result: "error",
            message: "server-connection-failed",
            err: err.stack,
          };

          if (url === "/initialData") {
            return ret;
          }

          return ret;
        }
      } while (true); // eslint-disable-line
    },
    [serverUrl]
  );

  return ajax;
};

export const useUser = () => {
  const [user] = useRecoilState(userState);

  return user;
};

export const useRoles = () => {
  const [user] = useRecoilState(userState);

  const isAllowed = (role) => {
    if (!user.current_parent) {
      if (user.role === WHITELABEL && role && ((typeof role === "string" && role.startsWith("super-admin.")) || (Array.isArray(role) && role.find((item) => item.startsWith("super-admin."))))) {
        return false;
      }

      if (user.role === USER && role && ((typeof role === "string" && (role.startsWith("super-admin.") || role.startsWith("admin."))) || (Array.isArray(role) && (role.find((item) => item.startsWith("super-admin.")) || role.find((item) => item.startsWith("admin.")))))) {
        return false;
      }

      return true;
    }

    const roles = user.roles.find((r) => r.parent_user_id === user.current_parent);
    if (!roles) {
      return true;
    }

    if (document.body.dataset.product === "cloud") {
      return (Array.isArray(role) && roles.permissions.filter((item) => role && role.includes(item)).length > 0) || (typeof role === "string" && roles.permissions && roles.permissions.includes(role));
    }

    return (Array.isArray(role) && roles.permissions.filter((item) => role && role.includes(item)).length > 0) || (typeof role === "string" && (roles.permission === "full" || roles.permission === role));
  };

  return { isAllowed };
};

export const useTraceUpdate = (props) => {
  const prev = useRef(props);

  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});

    if (Object.keys(changedProps).length > 0) {
      console.info("Changed props:", changedProps);
    }

    prev.current = props;
  });
};

export const useAutoLogout = () => {
  const logout = useLogout();
  const user = useUser();

  const secondsWithoutActive = useRef(0);
  const [timerID, setTimerID] = useState(null);

  useEffect(() => {
    if (!import.meta.env.PROD) {
      return;
    }

    if (timerID || !user || typeof user.auto_logout_minutes !== "number") {
      return;
    }

    const id = setInterval(() => {
      secondsWithoutActive.current++;

      if (secondsWithoutActive.current >= user.auto_logout_minutes * 60) {
        logout();
      }
    }, 1000);

    setTimerID(id);
  }, [timerID, logout, user]);

  useEffect(() => {
    if (!import.meta.env.PROD) {
      return;
    }

    function handleMouseMove() {
      secondsWithoutActive.current = 0;
    }

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
};

export function useTaskStatus() {
  return function render(task) {
    if (task.failed || task.step === -1) {
      return "failed";
    }

    if (task.finishedAt || task.finished_at) {
      return task.failed || task.step === -1 ? "failed" : "success";
    }

    return -1;
  };
}

export const useSmsCounter = (initialTime = 30) => {
  const isMounted = useIsMounted();

  const [timerID, setTimerID] = useState(null);

  const [counter, setCounter] = useState(initialTime);
  const counterRef = useRef(counter);
  counterRef.current = counter;

  useEffect(() => {
    return () => {
      clearInterval(timerID);
    };
  }, [timerID]);

  return {
    start: () => {
      const tid = setInterval(() => {
        if (!isMounted.current) {
          return;
        }

        if (counterRef.current > 0) {
          setCounter(counterRef.current - 1);
        }
      }, 1000);

      setTimerID(tid);
    },
    reset: () => {
      setCounter(initialTime);
    },
    counter,
  };
};

export const useCatchHrefAndGo = () => {
  const navigate = useNavigate();

  return (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();

      navigate(e.target.href.replace(/^.*\/\/[^/]+/, ""));
    }
  };
};

export default function usePState(key, initialValue) {
  const [state, setInternalState] = useState(initialValue);

  useEffect(() => {
    const value = localStorage.getItem(key);

    if (!value) return;

    setInternalState(JSON.parse(value));
  }, [key]);

  const setState = (value) => {
    localStorage.setItem(key, JSON.stringify(value));
    setInternalState(value);
  };

  return [state, setState];
}
