import styles from "./index.module.css";
import React from "react";
import UserAvatar from "../user-avatar";
import { DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem } from "reactstrap";
import Avatar from "../avatar/index.jsx";
import copy from "copy-to-clipboard";
import { useLogout, useUser } from "../../utils/hooks";
import Message from "../message";
import { Link } from "react-router-dom";

export default function Account({ isCollapsed }) {
  const logout = useLogout();
  const user = useUser();

  return (
    <div className={styles.account}>
      <UncontrolledDropdown className={styles.dropdown}>
        <DropdownToggle className={styles.dropdownBtn}>{isCollapsed ? <Avatar /> : <UserAvatar />}</DropdownToggle>

        <DropdownMenu>
          <DropdownItem header>
            <Message id='header.account' />
          </DropdownItem>
          {import.meta.env.VITE_APP_ENV === "development" && (
            <DropdownItem onClick={() => copy(user._id)}>
              <Message id='header.copy-user-id' />
            </DropdownItem>
          )}
          <DropdownItem>
            <Link to={`/account/account-details`}>
              <Message id='account-details' />
            </Link>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => logout()}>
            <Message id='logout' />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
