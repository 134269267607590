import React, { useEffect } from "react";
import { useWorkspace } from "../../utils/atoms";
import { useWorkspaces } from "../../utils/atoms";
import { Spinner } from "reactstrap";
import { Outlet } from "react-router-dom";

export default function WorkspaceLayout() {
  const { workspace } = useWorkspace();
  const { updateWorkspaces } = useWorkspaces();

  useEffect(() => {
    updateWorkspaces();
  }, [updateWorkspaces]);

  if (!workspace) {
    return <Spinner />;
  }

  return <Outlet />;
}
