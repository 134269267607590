import styles from "./index.module.css";

import React from "react";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import AccountDetails from "./account-details";
import Message from "../../components/message";
import Navigator from "../../components/navigator";
import { WithRole } from "../../components/with-role";
import LayoutMainAction from "../../components/layout-main-action";
import AccountSecurity from "./security";
import AccountPermissions from "./permissions";
import AccountPermissionsNewRole from "./permissions/new-role";

export default function Account() {
  const location = useLocation();

  const selected = useMemo(() => {
    if (/\/account\/account-details/.test(location.pathname)) {
      return "account-details";
    }
    if (/\/account\/security/.test(location.pathname)) {
      return "security";
    }
    if (/\/account\/permissions\/new-role/.test(location.pathname)) {
      return "permissions/new-role";
    }
    if (/\/account\/permissions/.test(location.pathname)) {
      return "permissions";
    }
  }, [location.pathname]);

  const navigatorItems = useMemo(
    () => [
      {
        title: <Message id="account-details" />,
        to: `/account/account-details`,
        selected: selected === "account-details",
      },
      {
        title: <Message id="security" />,
        to: `/account/security`,
        selected: selected === "security",
      },
      {
        title: <Message id="permissions" />,
        to: `/account/permissions`,
        selected:
          selected === "permissions" || selected === "permissions/new-role",
      },
    ],
    [selected]
  );

  return (
    <WithRole permission="">
      <LayoutMainAction>
        <Navigator items={navigatorItems} />
        <hr className={styles.hr} />
        {selected === "account-details" && <AccountDetails />}
        {selected === "security" && <AccountSecurity />}
        {selected === "permissions" && <AccountPermissions />}
        {selected === "permissions/new-role" && <AccountPermissionsNewRole />}
      </LayoutMainAction>
    </WithRole>
  );
}
