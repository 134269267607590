import styles from "./accounts.module.css";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginLayout from "../../components/login-layout";
import Badge from "../../components/badge";
import { useAjax, useUser } from "../../utils/hooks";
import Message from "../../components/message";
import { useRecoilState } from "recoil";
import { settingsState } from "../../utils/atoms";

export default function Accounts() {
  const ajax = useAjax();
  const user = useUser();
  const navigate = useNavigate();
  const [, setSettings] = useRecoilState(settingsState);

  const [loading, setLoading] = useState({});

  async function handleAccountClicked(parentID) {
    if (Object.keys(loading).length > 0) {
      return;
    }

    loading[parentID] = true;
    setLoading({ ...loading });

    await ajax(`/users/changeWorkspace`, {
      parentID,
    });

    localStorage.setItem("openModal", true);
    navigate(`/workspaces`);
    setSettings((prev) => ({ ...prev, fetchedInitialData: false }));
  }

  return (
    <LoginLayout logoutBtn={true} title={<Message id="accounts.title" />}>
      <div className={styles.wrapper}>
        <button
          className={`account-btn ${styles.button} ${
            loading[null] ? styles.selected : ""
          }`}
          onClick={() => handleAccountClicked(null)}
        >
          {user.email}

          <Badge color="blue">
            <Message id="general.primary" />
          </Badge>

          {loading[null] && (
            <img
              className={`${styles.check} ${
                loading[null] ? styles.fadeIn : ""
              }`}
              src="/img/green-check.svg"
            />
          )}
        </button>
        {user.roles.map((role, key) => (
          <button
            key={key}
            className={`account-btn ${styles.button} ${
              loading[role.parentUser._id] ? styles.selected : ""
            }`}
            onClick={() => handleAccountClicked(role.parentUser._id)}
          >
            {role.parentUser.email}

            {loading[role.parentUser._id] && (
              <img
                className={`${styles.check} ${
                  loading[null] ? styles.fadeIn : ""
                }`}
                src="/img/green-check.svg"
              />
            )}
          </button>
        ))}
      </div>
    </LoginLayout>
  );
}
