import axios from "axios";

export const ajax = async (url, params = {}, config = {}) => {
  do {
    try {
      const { data } = await axios.post(`${window.serverUrl}${url}`, params, {
        ...config,
        withCredentials: true,
      });

      if (
        data.result === "error" &&
        data.message == "blocked-user" &&
        !location.href.endsWith("/user/login")
      ) {
        location.href = "/user/login";
        return;
      }

      return data;
    } catch (err) {
      const ret = {
        result: "error",
        message: "server-connection-failed",
        err: err.stack,
      };

      if (url === "/initialData") {
        return ret;
      }

      return ret;
    }
  } while (true);
};
