import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ArrowLeftSvg from "../svgs/arrow-left.svg?react";
import DreamButton from "../dream-button";

function LayoutMainAction({
  title,
  desc,
  smallButton,
  buttons = [],
  children,
  className,
  marginBottom = 20,
}) {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div
        className={`${styles.actions} ${desc ? "" : styles.alignCenter}`}
        style={{ marginBottom: marginBottom + "px" }}
      >
        <div>
          {smallButton && (
            <div className={styles.backWrapper}>
              <Link href={smallButton.href} to={smallButton.to}>
                <span className={styles.svgWrap}>
                  <ArrowLeftSvg />
                </span>
                {smallButton.title}
              </Link>
            </div>
          )}
          {title && <h2 className={styles.title}>{title}</h2>}
          {typeof desc === "object" && desc}
          {typeof desc === "string" && (
            <div
              className={styles.normal}
              dangerouslySetInnerHTML={{ __html: desc }}
            ></div>
          )}
        </div>
        {buttons && (
          <div className={styles.buttons}>
            {buttons.map((button, key) => (
              <DreamButton
                key={key}
                color={button.color || "green"}
                href={button.href}
                as={button.to}
                target={button.target}
                onClick={button.onClick}
                disabled={button.disabled}
                loading={button.loading}
                className={button.className}
                icon={button.icon}
              >
                {button.title}
              </DreamButton>
            ))}
          </div>
        )}
      </div>

      {/* {(title || buttons) && <div className={styles.hr}><hr /></div>} */}

      {children}
    </div>
  );
}

LayoutMainAction.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  smallButton: PropTypes.object,
  buttons: PropTypes.array,
  children: PropTypes.any,
  className: PropTypes.string,
  marginBottom: PropTypes.number,
};

export default LayoutMainAction;
