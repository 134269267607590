import styles from "./step3.module.css";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { phone } from "phone";
import useIsMounted from "ismounted";
import WhatsAppSvg from "../../components/svgs/whatsapp.svg?react";
import { useAjax, useInitialDataFetching, useUser } from "../../utils/hooks";
import { countriesPhoneCodes, getCountryByCode } from "../../utils/countries";
import Message from "../message";
import Spinner from "../spinner";
import KeyDetector from "../key-detector";
import PhoneInput from "../phone-input";
import DreamButton from "../dream-button";
import CodeDigits from "../code-digits";
import DreamAlert from "../dream-alert";

const RESEND_TIMER_INIT = 30;
let timerID;

let codeDigitsHelpers = {};

function RegisterPageStep3({ mode, setMode }) {
  const ajax = useAjax();
  const ajaxRef = useRef(ajax);

  const initialDataFetching = useInitialDataFetching();
  const initialDataFetchingRef = useRef(initialDataFetching);

  const user = useUser();
  const isMounted = useIsMounted();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [counter, setCounter] = useState(RESEND_TIMER_INIT);
  const counterRef = useRef(counter);
  counterRef.current = counter;

  const [checkingCode, setCheckingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorAfter, setErrorAfter] = useState(false);

  const [countryCode, setCountryCode] = useState("");
  const [country, setCountry] = useState(user.country);

  useEffect(() => {
    timerID = setInterval(() => {
      if (!isMounted.current) {
        return;
      }

      if (counterRef.current > 0) {
        setCounter(counterRef.current - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, [isMounted]);

  async function handleSendClicked(sendBy) {
    setError(false);

    const phoneObj = phone(
      `+${countriesPhoneCodes[countryCode]} ${phoneNumber}`
    );

    setCountry(countryCode);

    if (!phoneObj.isValid) {
      return setError(
        <Message
          id="general.wrong-phone-for-country"
          values={{ country: getCountryByCode(countryCode) }}
        />
      );
    }

    let _phoneCode = phoneObj.countryCode.replace("+", "");
    let _phoneNumber = phoneObj.phoneNumber.replace(phoneObj.countryCode, "");

    setLoading(true);
    const data = await ajax(`/users/sendSMS`, {
      phoneCode: _phoneCode,
      phoneNumber: _phoneNumber,
      action: "registration-step",
      sendBy,
      countryCode,
    });
    setLoading(false);

    if (data.result === "success") {
      setMode("after");

      setCounter(RESEND_TIMER_INIT);
    } else if (data.message === "already-exists") {
      setError(<Message id="complete-registration-modal.already-exists" />);
    } else {
      setError(<Message id={data.message} />);
    }
  }

  function handleResendClicked(sendBy) {
    setCounter(RESEND_TIMER_INIT);
    codeDigitsHelpers.reset();
    handleSendClicked(sendBy);
  }

  function handleEditPhoneClicked() {
    setError(false);
    setErrorAfter(false);
    setMode("before");
  }

  async function handleCodeDigitsDone(code) {
    setErrorAfter(false);
    setError(false);

    setCheckingCode(true);
    const data = await ajaxRef.current(`/users/verifySMS`, {
      code,
      action: "registration-step",
      countryCode,
    });

    if (!isMounted.current) {
      return;
    }

    await initialDataFetchingRef.current();

    if (data.result === "success") {
      await initialDataFetchingRef.current();
    } else if (data.message === "already-exists") {
      setError(<Message id="complete-registration-modal.already-exists" />);
      setCheckingCode(false);
    } else {
      codeDigitsHelpers.reset();
      setErrorAfter(<Message id="complete-registration-modal.wrong-code" />);
      setCheckingCode(false);
    }
  }

  return (
    <div
      className={`userForm ${styles.wrapper} ${
        checkingCode ? "disabled" : ""
      } ${styles.registerStep3}`}
    >
      {checkingCode && <Spinner className={styles.spinner} />}

      {mode === "before" && (
        <div className={styles.wrapperVerify}>
          <div className={styles.phoneVerifyText}>
            <Message id="phone-verify-text" />
          </div>
          <label htmlFor="phoneVerify">
            <span className="required">
              <Message id="account-details.phone-number" />
            </span>
          </label>
          <div className={styles.row}>
            <KeyDetector
              className={styles.key}
              onKeyDetected={() => handleSendClicked("sms")}
            >
              <PhoneInput
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                defaultCountry={country}
                id="phoneVerify"
                errorText={error}
                showSelector={true}
              />
            </KeyDetector>
          </div>
          <div className={styles.row}>
            <DreamButton
              className={styles.next}
              disabled={!phoneNumber ? true : loading}
              loading={loading}
              color="light-purple"
              onClick={() => handleSendClicked("sms")}
            >
              <Message id="general.continue" />
            </DreamButton>
          </div>
        </div>
      )}

      {mode === "after" && (
        <>
          <div className={styles.phoneVerifyText}>
            <Message id="phone-code-text" />
          </div>
          <CodeDigits
            className={styles.codeDigits}
            onDone={handleCodeDigitsDone}
            helpers={codeDigitsHelpers}
            autoFocus
          />
          {errorAfter && (
            <DreamAlert alertStyle="danger">{errorAfter}</DreamAlert>
          )}

          <div className={styles.afterButtons}>
            <DreamButton
              className={styles.editPhone}
              color="text"
              disabled={loading || counterRef.current > 0}
              onClick={handleEditPhoneClicked}
            >
              <Message id="register-page-step3.edit-phone" />
            </DreamButton>
            {counterRef.current > 0 ? (
              <div className={styles.resend}>
                <Message
                  id="general.resend-code-counter"
                  values={{ counter: counterRef.current }}
                />
              </div>
            ) : (
              <div className={styles.resend}>
                {/* <Message id="general.dont-recive-code" /> */}

                <DreamButton
                  color="text"
                  onClick={() => handleResendClicked("sms")}
                >
                  <Message id="general.send-via-sms" />
                </DreamButton>

                <DreamButton
                  color="white"
                  onClick={() => handleResendClicked("whatsapp")}
                  loading={loading}
                >
                  <Message id="general.send-via-whatsapp" />
                  <WhatsAppSvg />
                </DreamButton>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

RegisterPageStep3.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
};

export default RegisterPageStep3;
