import ELK from "elkjs/lib/elk.bundled.js";

const elk = new ELK();

const nodeWidth = 100;
const nodeHeight = 100;

export const layoutElements = async (nodes, edges) => {
  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.direction": "DOWN", // Top-to-bottom
      "elk.layered.spacing.nodeNodeBetweenLayers": "100", // Space between layers
      "elk.spacing.nodeNode": "50", // Space between nodes in the same layer
      "elk.layered.edgeLabels.sideSelection": "SMART", // Smartly choose label side
      "elk.edgeLabels.placement": "CENTER", // Position labels (try HEAD or TAIL too)
      "elk.layered.spacing.edgeNodeBetweenLayers": "50", // Space between edges and nodes
      "elk.layered.spacing.edgeEdgeBetweenLayers": "30", // Space between parallel edges
    },
    children: nodes.map((node) => ({
      id: node.id,
      width: nodeWidth,
      height: nodeHeight,
    })),
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
      labels: [
        {
          text: edge.label || `${edge.source}-${edge.target}`, // Define label text
          width: 100, // Adjust based on your label length
          height: 20, // Adjust based on font size
        },
      ],
    })),
  };

  const layout = await elk.layout(graph);
  return {
    nodes: layout.children.map((node) => ({
      ...nodes.find((n) => n.id === node.id),
      position: { x: node.x, y: node.y },
    })),
    edges: layout.edges.map((edge) => ({
      ...edges.find((e) => e.id === edge.id),
    })),
  };
};

export function hideConnectedNodes(nodeId, nodes, edges, hiddenNodes = {}) {
  // Mark this node as hidden
  hiddenNodes[nodeId] = true;

  // Find all edges connected to this node
  const connectedEdges = edges.filter(
    (edge) => edge.source === nodeId || edge.target === nodeId
  );

  // For each connected edge, get the node on the other end
  for (const edge of connectedEdges) {
    const connectedNodeId = edge.source === nodeId ? edge.target : edge.source;

    // Only process this node if it hasn't been hidden yet
    if (!hiddenNodes[connectedNodeId]) {
      // Recursively hide this node and its connections
      hideConnectedNodes(connectedNodeId, nodes, edges, hiddenNodes);
    }
  }

  return hiddenNodes;
}
