import styles from "./index.module.css";

import React, { useState, useEffect, useCallback, useMemo } from "react";
import EditSvg from "../../../components/svgs/edit.svg?react";
import TrashSvg from "../../../components/svgs/trash.svg?react";
import LockPermissions from "../../../components/svgs/lock-permissions.svg?react";
import { Link } from "react-router-dom";
import { WithRole } from "../../../components/with-role";
import { useAjax, useUser } from "../../../utils/hooks";
import { useConfirm } from "../../../utils/hooks";
import Message from "../../../components/message";
import { sanitize } from "../../../utils";
import NoItems from "../../../components/no-items";
import DreamButton from "../../../components/dream-button";
import LayoutMainAction from "../../../components/layout-main-action";
import CustomText from "../../../components/custom-text";
import ClickSelect from "../../../components/click-select";
import ClickTable from "../../../components/click-table";
import { getFullName } from "../../../utils/user";
import { useIntl } from "react-intl";

export default function AccountPermissions() {
  const ajax = useAjax();
  const confirm = useConfirm();
  const user = useUser();
  const intl = useIntl();

  const [users, setUsers] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [permissionType, setPermissionType] = useState(null);

  const getUsersOfParent = useCallback(async () => {
    const data = await ajax("/roles/getUsersOfParent");

    if (data.result === "success") {
      setUsers(data.users);
    }
  }, [ajax]);

  useEffect(() => {
    getUsersOfParent();
  }, [getUsersOfParent]);

  function handleRemoveClicked(user) {
    confirm({
      title: <Message id="account-permissions.remove" />,
      message: (
        <Message
          id="account-permissions.remove-message"
          values={{ email: sanitize(user.user.email) }}
        />
      ),
      beforeClose: async (state) => {
        if (state !== "button2") {
          return;
        }

        await ajax("/roles/removeUserWithRoles", { userID: user.user._id });
        await getUsersOfParent();
      },
    });
  }

  function handleContentChanged(e) {
    setSearchText(e.target.value);
  }

  function getTagClass(key) {
    if (key === "pending") {
      return styles.tagPending;
    }

    if (key === "approved") {
      return styles.tagConfirm;
    }

    return null;
  }

  const buttons = [
    {
      title: <Message id="general.invite-users" />,
      href: "/account/permissions/new-role",
      to: `/account/permissions/new-role`,
      as: `/account/permissions/new-role`,
    },
  ];

  const permissionTypes = useMemo(
    () => [
      { label: <Message id="permissions.full" />, value: null },
      {
        label: <Message id="permissions.sites" />,
        value: "sites",
      },
      {
        label: <Message id="permissions.billing" />,
        value: "billing",
      },
    ],
    []
  );

  if (user.rolesCount === 0 || users?.length === 0) {
    return (
      <WithRole permission={["sites", "billing"]}>
        <NoItems
          title={"general.no-permissions-titel"}
          image={<LockPermissions />}
          description={"permissions.no-permissions-description"}
        >
          <DreamButton
            href="/account/permissions/new-role"
            as={`/account/permissions/new-role`}
            icon="plus"
          >
            <Message id="permissions.add-user" />
          </DreamButton>
        </NoItems>
      </WithRole>
    );
  }
  return (
    <LayoutMainAction
      title={<Message id="account-permissions.title" />}
      // desc={<Message id="account-permissions.main-description" />}
      buttons={buttons}
    >
      {/* <Message id="account-permissions.search-text" /> */}
      <div className={styles.flex}>
        <CustomText
          className={styles.search}
          type="string"
          placeholder={intl.formatMessage({ id: "general.search-user" })}
          value={searchText}
          onChange={(e) => handleContentChanged(e)}
          icon="search"
        />

        <ClickSelect
          className={styles.dropdown}
          value={permissionType}
          onChange={(item) => setPermissionType(item)}
          options={permissionTypes}
        />
      </div>
      <ClickTable
        className={styles.wrapper}
        asPlaceholder={!users}
        placeholderCount={user.rolesCount}
      >
        <thead>
          <tr>
            <th>
              <Message id="name" />
            </th>
            <th>
              <Message id="general.email" />
            </th>
            <th>
              <Message id="general.access" />
            </th>
            <th>
              <Message id="general.status" />
            </th>
            <th>
              <Message id="general.actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.length === 0 && (
            <tr>
              <td colSpan={4}>
                <Message id="no-rows" />
              </td>
            </tr>
          )}

          {users
            ?.filter(
              (user) =>
                (!searchText ||
                  user.user.email.toLowerCase().includes(searchText) ||
                  user.user.firstName?.toLowerCase().includes(searchText) ||
                  user.user.lastName?.toLowerCase().includes(searchText)) &&
                (!permissionType ||
                  !permissionType.value ||
                  permissionType.value === user.permission)
            )
            .map((user, key) => (
              <tr key={key}>
                <td>{getFullName(user.user)}</td>
                <td>{user.user.email}</td>
                <td>
                  <Message id={`permissions.${user.permission}`} />{" "}
                  {user.permission === "sites" &&
                    (user.sites_ids === -1 ? (
                      <Message id={`full`} />
                    ) : (
                      <Message id={`partial`} />
                    ))}
                </td>
                <td>
                  <div className={getTagClass(user.status)}>
                    <Message id={`general.${user.status}`} />
                  </div>
                </td>
                <td>
                  <Link
                    className={styles.edit}
                    href="/account/permissions/new-role/[editID]"
                    to={`/account/permissions/new-role/${user.user_id}?permission=${user.permission}`}
                  >
                    <EditSvg className="svg-edit" />
                  </Link>
                  <TrashSvg onClick={() => handleRemoveClicked(user)} />
                </td>
              </tr>
            ))}
        </tbody>
      </ClickTable>
    </LayoutMainAction>
  );
}
