/* eslint-disable react-refresh/only-export-components */
import { FormattedMessage, useIntl } from "react-intl";
import TableHeader from "../table-header";
import styles from "./filters-bar.module.css";

import React, { useMemo, useState } from "react";
import DreamButton from "../dream-button";
import FilterSvg from "../../components/svgs/filter2.svg?react";
import { useParams } from "react-router-dom";

export const componentsFilterOptions = [
  { label: <FormattedMessage id='basic' />, value: "SimpleNode" },
  { label: <FormattedMessage id='devices' />, value: "DeviceNode" },
  { label: <FormattedMessage id='networks' />, value: "NetworkNode" },
  { label: <FormattedMessage id='policies' />, value: "PolicyNode" },
  { label: <FormattedMessage id='services' />, value: "ServiceNode" },
  { label: <FormattedMessage id='groups' />, value: "GroupNode" },
  { label: <FormattedMessage id='identities' />, value: "IdentityNode" },
  {
    label: <FormattedMessage id='connector-policies' />,
    value: "ConnectorPolicyNode",
  },
  { label: <FormattedMessage id='connectors' />, value: "ConnectorNode" },
  { label: <FormattedMessage id='hosts' />, value: "HostNode" },
  { label: <FormattedMessage id='resources' />, value: "ResourceNode" },
  {
    label: <FormattedMessage id='workgroup-policies' />,
    value: "WorkgroupPolicyNode",
  },
];

export const statusFilterOptions = [
  { label: <FormattedMessage id='online-offline' />, value: "all" },
  { label: <FormattedMessage id='online' />, value: "online" },
  { label: <FormattedMessage id='offline' />, value: "offline" },
];

export const trustedFilterOptions = [
  { label: <FormattedMessage id='trusted-untrusted' />, value: "all" },
  { label: <FormattedMessage id='trusted' />, value: "trusted" },
  { label: <FormattedMessage id='untrusted' />, value: "untrusted" },
];

export const actionFilterOptions = [
  { label: <FormattedMessage id='all-actions' />, value: "all" },
  { label: <FormattedMessage id='allowed' />, value: "allowed" },
  { label: <FormattedMessage id='denied' />, value: "denied" },
];

export const resourceGroupsFilterOptions = [{ label: <FormattedMessage id='all-resource-groups' />, value: "all" }];

export default function FiltersBar({
  nodes,
  componentsFilter,
  setComponentsFilter,
  resourceGroupsFilter,
  setResourceGroupsFilter,
  statusFilter,
  setStatusFilter,
  trustedFilter,
  setTrustedFilter,
  actionFilter,
  setActionFilter,
  search,
  setSearch,
  firewallFromDate,
  setFirewallFromDate,
  firewallToDate,
  setFirewallToDate,
}) {
  const intl = useIntl();
  const { view } = useParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const tableHeaderSearch = useMemo(() => {
    let items = [
      {
        type: "input",
        placeholder: intl.formatMessage({ id: "search" }),
        value: search,
        icon: "search",
        onChange: (e) => setSearch(e.target.value),
      },
    ];

    return items;
  }, [search, setSearch, intl]);

  const tableHeaderComponents = useMemo(() => {
    const _componentsFilterOptions = componentsFilterOptions.filter((item) => nodes.find((node) => node.type === item.value));

    let items = [
      {
        type: "dropdown",
        items: _componentsFilterOptions,
        selectedItem: componentsFilter,
        onChange: (items) => setComponentsFilter(items),
        placeholder: intl.formatMessage({ id: "all-components" }),
        isMulti: true,
      },
    ];

    if (view === "firewall") {
      const _resourceGroupsFilterOptions = [...resourceGroupsFilterOptions];

      for (const node of nodes) {
        if (node.type === "DeviceNode" && node.data.GroupsNames) {
          const groups = node.data.GroupsNames.split(",").map((item) => item.trim());

          for (const group of groups) {
            if (!_resourceGroupsFilterOptions.find((item) => item.value === group)) {
              _resourceGroupsFilterOptions.push({ label: group, value: group });
            }
          }
        }
      }

      items.push(
        {
          id: "groupsFilter",
          type: "dropdown",
          items: _resourceGroupsFilterOptions,
          selectedItem: resourceGroupsFilter,
          onChange: (item) => setResourceGroupsFilter(item),
        },
        {
          id: "statusFilter",
          type: "dropdown",
          items: statusFilterOptions,
          selectedItem: statusFilter,
          onChange: (item) => setStatusFilter(item),
        },
        {
          id: "trustedFilter",
          type: "dropdown",
          items: trustedFilterOptions,
          selectedItem: trustedFilter,
          onChange: (item) => setTrustedFilter(item),
        },
        {
          id: "actionFilter",
          type: "dropdown",
          items: actionFilterOptions,
          selectedItem: actionFilter,
          onChange: (item) => setActionFilter(item),
        },
        {
          type: "ranges",
          placeholder: intl.formatMessage({ id: "date" }),
          startDate: firewallFromDate,
          endDate: firewallToDate,
          onStartDateChanged: (date) => setFirewallFromDate(date),
          onEndDateChanged: (date) => setFirewallToDate(date),
        }
      );
    }

    return items;
  }, [nodes, componentsFilter, resourceGroupsFilter, statusFilter, setComponentsFilter, setResourceGroupsFilter, setStatusFilter, trustedFilter, setTrustedFilter, actionFilter, setActionFilter, firewallFromDate, setFirewallFromDate, firewallToDate, setFirewallToDate, view, intl]);

  function toggleFilter() {
    setIsFilterOpen((prev) => !prev);
  }

  return (
    <div className={styles.wrapper}>
      <TableHeader components={tableHeaderSearch} />

      <DreamButton className={styles.filterBtn} onClick={() => toggleFilter()}>
        <FilterSvg />
        {isFilterOpen ? <FormattedMessage id='general.close' /> : <FormattedMessage id='general.filters' />}
      </DreamButton>
      {isFilterOpen && <TableHeader className={styles.tableHeaderComponents} components={tableHeaderComponents} />}
    </div>
  );
}
