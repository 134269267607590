import styles from "./index.module.css";

import React from "react";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useUser } from "../../utils/hooks";
import Message from "../../components/message";
import Navigator from "../../components/navigator";
import { WithRole } from "../../components/with-role";
import LayoutMainAction from "../../components/layout-main-action";
import BillingSubscription from "./subscription";
import BillingPaymentMethod from "./payment-method";
import BillingInvoices from "./invoices";
import { SUPER_ADMIN } from "../../utils/user";

export default function Billing() {
  const user = useUser();
  const location = useLocation();

  const selected = useMemo(() => {
    if (/\/billing\/subscription/.test(location.pathname)) {
      return "subscription";
    }

    if (/\/billing\/payment-method/.test(location.pathname)) {
      return "payment-method";
    }

    if (/\/billing\/invoices/.test(location.pathname)) {
      return "invoices";
    }
  }, [location.pathname]);

  const navigatorItems = useMemo(() => {
    if (user.role === SUPER_ADMIN) {
      return [
        {
          title: <Message id="invoices" />,
          to: `/billing/invoices`,
          selected: selected === "invoices",
        },
      ];
    }

    return [
      {
        title: <Message id="subscription" />,
        to: `/billing/subscription`,
        selected: selected === "subscription",
      },
      {
        title: <Message id="payment-method" />,
        to: `/billing/payment-method`,
        selected: selected === "payment-method",
      },
      {
        title: <Message id="invoices" />,
        to: `/billing/invoices`,
        selected: selected === "invoices",
      },
    ];
  }, [selected, user.role]);

  return (
    <WithRole permission="">
      <LayoutMainAction>
        <div className={styles.wrapper}>
          <Navigator items={navigatorItems} />
          <hr className={styles.hr} />
          {selected === "subscription" && <BillingSubscription />}
          {selected === "payment-method" && <BillingPaymentMethod />}
          {selected === "invoices" && <BillingInvoices />}
        </div>
      </LayoutMainAction>
    </WithRole>
  );
}
