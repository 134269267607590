import styles from "./sidebar-mobile.module.css";

import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CancelSvg from "../svgs/cancel.svg?react";
import getSidebar from "./sidebar-json.js";
import { useUser } from "../../utils/hooks";
import { useParams } from "react-router-dom";

function SidebarMobile({ isOpen, onClose }) {
  const user = useUser();
  const params = useParams();

  const [openItem, setOpenItem] = useState(null);

  const sidebar = useMemo(() => {
    const sidebar = getSidebar(user, params);

    return sidebar;
  }, [user, params]);

  return (
    <div className={`${styles.wrapper} ${isOpen ? styles.open : ""}`}>
      <CancelSvg onClick={() => onClose(true)} className={styles.close} />

      {sidebar.map((item, key) => (
        <Fragment key={key}>
          <div className={`${styles.mainItem} ${openItem === key ? styles.selected : ""}`} onClick={() => setOpenItem(key)}>
            {item.title}
          </div>
        </Fragment>
      ))}
    </div>
  );
}

SidebarMobile.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SidebarMobile;
