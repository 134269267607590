import styles from "./step1.module.css";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import validator from "validator";
import { Link } from "react-router-dom";
import { useAjax, useInitialDataFetching } from "../../utils/hooks";
import { countries } from "../../utils/countries";
import Message from "../message";
import KeyDetector from "../key-detector";
import CustomText from "../custom-text";
import CustomReactSelect from "../custom-react-select";
import DreamPasswordStrengthBar from "../password-strength-bar";
import { sanitize } from "../../utils";
import DreamAlert from "../dream-alert";
import DreamButton from "../dream-button";
import { useRecoilState } from "recoil";
import { settingsState } from "../../utils/atoms";

function RegisterPageStep1() {
  const ajax = useAjax();
  const initialDataFetching = useInitialDataFetching();
  const [settings] = useRecoilState(settingsState);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //const [passwordConf, setPasswordConf] = useState("");

  const [country, setCountry] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);

  // const [recaptchaToken, setRecaptchaToken] = useState(false);

  const [error, setError] = useState({});
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getUserCountry() {
      const { countryCode } = await ajax("/users/getUserCountryByIp", {});

      let countriesByLang = countries;

      let _countries = Object.entries(countriesByLang).map(
        ([country, code]) => {
          return { label: country, value: code };
        }
      );

      setCountryOptions(_countries);

      const _country = _countries.find((c) => c.value === countryCode);
      if (_country) {
        setCountry(_country);
      }
    }
    getUserCountry();
  }, [ajax]);

  async function handleNextClicked() {
    const error = {};

    const _firstName = firstName.trim();
    const _lastName = lastName.trim();
    const _email = email.trim();
    const _password = password.trim();
    // const _passwordConf = passwordConf.trim();

    //setError(false);

    // if (_password !== _passwordConf) {
    //   return setError(<Message id="general.password-not-same" />);
    // }

    if (!_firstName) {
      error.firstName = "general.wrong-first-name";
    }
    if (!_lastName) {
      error.lastName = "general.wrong-last-name";
    }
    if (!validator.isEmail(_email)) {
      error.email = "general.wrong-email";
    }
    if (_firstName.match(/[0-9]/g)) {
      error.firstName = "name-contains-numbers";
    }
    if (_lastName.match(/[0-9]/g)) {
      error.lastName = "name-contains-numbers";
    }

    if (Object.keys(error).length > 0) {
      setError(error);
      return;
    }

    // if (!_firstName) {
    //   return setError(<Message id="general.wrong-first-name" />);
    // } else if (!_lastName) {
    //   return setError(<Message id="general.wrong-last-name" />);
    // } else if (_firstName.match(/[0-9]/g) || _lastName.match(/[0-9]/g)) {
    //   return setError(<Message id="name-contains-numbers" />);
    // } else if (!validator.isEmail(_email)) {
    //   return setError(<Message id="general.wrong-email" />);
    // }

    // if (passwordScore <= 1) {
    //   return setError(<Message id="general.weak-password" />);
    // }

    setLoading(true);

    const data = await ajax("/users/register", {
      email: _email,
      password: _password,
      firstName: _firstName,
      lastName: _lastName,
      fingerprint: settings.fingerprint,
      fingerprintVisitorId: settings.fingerprintVisitorId,
      country: country.value,
    });

    if (data.result === "success") {
      await initialDataFetching();
    } else {
      setErrorAlert(<Message id={data.message} />);
    }

    setLoading(false);
  }

  return (
    <div className={`userForm ${styles.registration} ${styles.sections}`}>
      <div className={styles.section}>
        {/* <div className={styles.paymentType}>
          <span>
            <Message id="general.yearly-payment" />
          </span>
          {paymentType === "yearly" && (
            <SwitchRightSvg onClick={handleTogglePaymentTypeClicked} />
          )}
          {paymentType === "monthly" && (
            <SwitchLeftSvg onClick={handleTogglePaymentTypeClicked} />
          )}
          <span>
            <Message id="general.monthly-payment" />
          </span>
        </div> */}
        {/* <div className={styles.registationPackageBox}>
          <PackageBox
            paymentType={paymentType}
            selectedPackage={selectedPackage}
            page="register"
          />
          <div className={styles.changePlanWrp}>
            <a href="#" onClick={handleChangePlanClicked}>
              <Message id="general.change-plan" />
            </a>
          </div>
        </div> */}

        {/* <div className={styles.changePlanWrp}>
          <a href="#" onClick={handleChangePlanClicked}>
            <Message id="general.change-plan" />
          </a>
        </div> */}
      </div>
      <div className={styles.section}>
        <div className={` ${styles.row} ${styles.two}`}>
          <div>
            <label htmlFor="firstName">
              <span className="required">
                <Message id="general.first-name" />
              </span>
            </label>
            <KeyDetector onKeyDetected={handleNextClicked}>
              <CustomText
                autoFocus
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                errorText={error.firstName}
                //placeholder={<Message id="general.fill-first-name" />}
              />
            </KeyDetector>
          </div>
          <div>
            <label htmlFor="lastName">
              <span className="required">
                <Message id="general.last-name" />
              </span>
            </label>
            <KeyDetector onKeyDetected={handleNextClicked}>
              <CustomText
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                errorText={error.lastName}
                //placeholder={<Message id="general.fill-last-name" />}
              />
            </KeyDetector>
          </div>
        </div>
        <div className={`${styles.row} ${styles.column}`}>
          <label htmlFor="email">
            <span className="required">
              <Message id="general.email" />
            </span>
          </label>

          <KeyDetector onKeyDetected={handleNextClicked}>
            <CustomText
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="ltr"
              errorText={error.email}
              //placeholder={<Message id="general.fill-email" />}
            />
          </KeyDetector>
        </div>
        <label htmlFor="country">
          <Message id="account-details.country" />
          <span className="required"></span>
        </label>
        <div className={`${styles.row} `}>
          {country && (
            <CustomReactSelect
              id="country"
              value={country}
              onChange={(item) => setCountry(item)}
              options={countryOptions}
              className="focusSelectField"
            />
          )}
        </div>
        <div className={`${styles.row} ${styles.column}`}>
          <label htmlFor="password">
            <span className="required">
              <Message id="general.password" />
            </span>
          </label>
          <KeyDetector onKeyDetected={handleNextClicked}>
            <CustomText
              id="password"
              type="password"
              maxLength={100}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              //placeholder={<Message id="general.fill-password" />}
            />
          </KeyDetector>
        </div>
        {/* <div className={`${styles.row} ${styles.column}`}>
          <span className="required">
            <Message id="general.password-rewrite" />
          </span>
          <KeyDetector onKeyDetected={handleNextClicked}>
            <CustomText
              type="password"
              maxLength={100}
              value={passwordConf}
              onChange={(e) => setPasswordConf(e.target.value)}
            />
          </KeyDetector>
        </div> */}
        <div className={`${styles.row} ${styles.passwordStrengthBarWrapper}`}>
          <DreamPasswordStrengthBar
            password={password}
            onChangeScore={() => {}}
          />
        </div>
        <div className={`${styles.termsRow}`}>
          {/* <LockSvg /> */}
          <Message
            id="register-page.agree-terms"
            values={{
              link: (val) =>
                sanitize(`<a
                        href="https://www.dreamvps.com/en/legal/terms-of-use"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ${val}
                      </a>`),
            }}
          />
        </div>
        {/* <div className={styles.row}>
          <ReCAPTCHA
            sitekey={import.meta.env.VITE_APP_RECAPTCHA_CLIENT_KEY}
            onChange={handleRecaptchaChanged}
            onExpired={() => setRecaptchaToken(false)}
            onErrored={() => setRecaptchaToken(false)}
          />
        </div> */}

        {errorAlert && (
          <DreamAlert alertStyle="danger">{errorAlert}</DreamAlert>
        )}

        <div className={styles.row}>
          <DreamButton
            className={styles.continue}
            color="light-purple"
            disabled={!firstName || !lastName || !email || !password}
            loading={loading}
            onClick={handleNextClicked}
          >
            <Message id="register-page.continue-with-email" />
          </DreamButton>
        </div>

        <div className={styles.loginLink}>
          <Message id="register-page.login1" />
          <Link href="/user/login" to="/user/login" className={styles.login}>
            <Message id="register-page.login" />
          </Link>
        </div>

        {/* <div>
          <div className={styles.social}>
            <SocialButtonGoogle
              clientID="904333487308-tleunp02guvtn3bh9e6aqnlpmljmnvgi.apps.googleusercontent.com"
              confirmPasswordUrl={`/user/confirm-password`}
              successUrl={`/workspaces`}
              twoFactorAuthenticationUrl={`/user/two-factor-authentication`}
              initialDataFetching={initialDataFetching}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

RegisterPageStep1.propTypes = {
  paymentType: PropTypes.string,
  setPaymentType: PropTypes.func,
  selectedPackage: PropTypes.object,
  setSelectedPackage: PropTypes.func,
};

export default RegisterPageStep1;
