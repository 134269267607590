import styles from "./index.module.css";

import sanitizeHtml from "sanitize-html";
import React from "react";
import axios from "axios";
import passwordValidator from "password-validator";
import SortTopSvg from "../components/svgs/sort-top.svg?react";
import SortDownSvg from "../components/svgs/sort-down.svg?react";

const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces();

export const isStrongPassword = (password) => {
  return schema.validate(password);
};

export const selectText = (node) => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn("Could not select text in node: Unsupported browser.");
  }
};

export const getDayAsText = (day) => {
  switch (day) {
    case 1:
      return "Sunday";
    case 2:
      return "Monday";
    case 3:
      return "Tuesday";
    case 4:
      return "Wednesday";
    case 5:
      return "Thursday";
    case 6:
      return "Friday";
    default:
      return "Saturday";
  }
};

export const syntaxHighlight = (json) => {
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
};

export const proxmoxStringToObject = (string) => {
  if (!string) {
    return null;
  }

  const ret = {};

  const stringArr = string.split(",");

  for (const k in stringArr) {
    const pair = stringArr[k].split("=");
    ret[pair[0]] = pair[1];
  }

  return ret;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stripTags = (text) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
};

export const getDirection = (content) => {
  content = stripTags(content);

  return content[0] >= "א" && content[0] <= "ת"
    ? { direction: "rtl", textAlign: "right" }
    : { direction: "ltr", textAlign: "left" };
};

export const cutString = (
  str,
  maxLength,
  CuttedWrapper = null,
  cuttedWrapperProps = {}
) => {
  if (str.length > maxLength) {
    str = `${str.slice(0, maxLength)}...`;

    if (CuttedWrapper) {
      return <CuttedWrapper {...cuttedWrapperProps}>{str}</CuttedWrapper>;
    }
  }

  return str;
};

export const setDir = (text) => {
  const stripped = stripTags(text);
  const isRTL = stripped[0] >= "א" && stripped[0] <= "ת";

  return (
    <div
      style={{
        direction: isRTL ? "rtl" : "ltr",
        textAlign: isRTL ? "right" : "left",
      }}
    >
      {text}
    </div>
  );
};

export const noop = () => {};

export const sanitize = (html) =>
  sanitizeHtml(html, {
    allowedTags: [
      "b",
      "i",
      "em",
      "strong",
      "a",
      "br",
      "pre",
      "p",
      "div",
      "span",
    ],
    allowedAttributes: {
      a: ["href", "target", "rel"],
    },
    allowedIframeHostnames: [],
  });

export const asyncTimeout = (interval) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, interval);
  });
};

export const getSpecificID = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const specific = urlSearchParams.get("specific");

  return specific;
};

export const removeNonAscii = (str) => {
  if (str === null || str === "") {
    return false;
  } else {
    str = str.toString();
  }

  return str.replace(/[^\x20-\x7E]/g, "");
};

export const abbreviateNumber = (value) => {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};

export function queryStringToJSON(qs) {
  qs = qs || location.search.slice(1);

  var pairs = qs.split("&");
  var result = {};
  pairs.forEach(function (p) {
    var pair = p.split("=");
    var key = pair[0];
    var value = decodeURIComponent(pair[1] || "");

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === "[object Array]") {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });

  return JSON.parse(JSON.stringify(result));
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isMongoDbID = (str) => {
  return /^[a-f\d]{24}$/i.test(str);
};

export const sortObjectByKeys = (objectToSort) => {
  return Object.keys(objectToSort)
    .sort()
    .reduce((obj, key) => {
      obj[key] = objectToSort[key];
      return obj;
    }, {});
};

export const isEnglishOnly = (str) => {
  let pattern = "^[a-zA-Z][a-zA-Z0-9]*$";

  const regex = new RegExp(pattern);

  return regex.test(str);
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const placeholder = (width, height, margin, circle) => {
  const borderRadius = circle ? { borderRadius: "50%" } : {};

  return (
    <div
      className={styles.loadWraper}
      style={{ ...borderRadius, width, maxWidth: "100%", height, margin }}
    >
      <div className={styles.activity}></div>
    </div>
  );
};

export const createLinkAndGo = (href, target = "_blank") => {
  const a = document.createElement("a");

  a.href = href;
  a.target = target;

  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
  }, 1);
};

export const isIterable = (obj) => {
  if (obj == null) {
    return false;
  }

  return typeof obj[Symbol.iterator] === "function";
};

export const replaceNCharsWithAsterisks = (inputString, n) => {
  if (n <= 0) {
    return inputString;
  }

  const asterisks = "*".repeat(n);

  const pattern = new RegExp(`^.{${n}}`);

  const replacedString = inputString.replace(pattern, asterisks);

  return replacedString;
};

export const dataLayerPush = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const knownServices = {
  80: "HTTP",
  443: "HTTPS",
  21: "FTP",
  25: "SMTP",
  465: "SMTP Secure",
  110: "POP3",
  995: "POP3 Secure",
  143: "IMAP",
  993: "IMAP over SSL",
  22: "SSH",
  389: "LDAP",
  3306: "MySQL",
  43: "WHOIS",
  3389: "RDP",
};

export const lorem = (chars = 10) => {
  return "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי ושבעגט ליבם סולגק. בראיט ולחת צורק מונחף, בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק? לתיג ישבעס.".slice(
    0,
    chars
  );
};

export const handleSortClicked = (e, newSort, sort, setSort) => {
  e.preventDefault();

  if (sort.startsWith(newSort) || newSort.startsWith(sort)) {
    if (newSort === sort) {
      setSort(`${newSort}_rev`);
    } else {
      setSort(newSort);
    }
  } else {
    setSort(`${newSort}_rev`);
  }
};

export const renderSortIcon = (ofSort, currentSort) => {
  let reverse = false;

  if (currentSort.endsWith("_rev")) {
    currentSort = currentSort.replace("_rev", "");
    reverse = true;
  }

  if (ofSort === currentSort) {
    return reverse ? <SortTopSvg /> : <SortDownSvg />;
  }

  return null;
};

export const getDomainFromURL = (url, withSubdomain = false) => {
  let domain = "";

  // Remove the protocol (e.g., "http://", "https://") from the URL
  const protocolIndex = url.indexOf("://");
  if (protocolIndex > -1) {
    url = url.slice(protocolIndex + 3);
  }

  // Remove everything after the first slash ("/")
  const slashIndex = url.indexOf("/");
  if (slashIndex > -1) {
    url = url.slice(0, slashIndex);
  }

  // Extract the domain based on the presence of a subdomain
  if (withSubdomain) {
    domain = url;
  } else {
    const parts = url.split(".");
    if (parts.length > 2) {
      domain = parts.slice(1).join(".");
    } else {
      domain = url;
    }
  }

  return domain;
};

export const ajax = async (url, params = {}, config = {}) => {
  do {
    try {
      const { data } = await axios.post(`${window.serverUrl}${url}`, params, {
        ...config,
        withCredentials: true,
      });

      if (
        data.result === "error" &&
        data.message == "blocked-user" &&
        !location.href.endsWith("/user/login")
      ) {
        location.href = "/user/login";
        return;
      }

      return data;
    } catch (err) {
      const ret = {
        result: "error",
        message: "server-connection-failed",
        err: err.stack,
      };

      if (url === "/initialData") {
        return ret;
      }

      return ret;
    }
  } while (true);
};
