import styles from "./email-verification.module.css";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../../components/login-layout";
import { useAjax, useAlert, useInitialDataFetching } from "../../utils/hooks";
import Message from "../../components/message";
import { sanitize } from "../../utils";
import KeyDetector from "../../components/key-detector";
import DreamButton from "../../components/dream-button";
import TextField from "../../components/textfield";
import { useRecoilState } from "recoil";
import { settingsState } from "../../utils/atoms";
import { useIntl } from "react-intl";

export default function LoginEmailVerification() {
  const alert = useAlert();
  const ajax = useAjax();
  const intl = useIntl();
  const navigate = useNavigate();
  const initialDataFetching = useInitialDataFetching();
  const [settings] = useRecoilState(settingsState);

  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!window.p1 || !window.p2) {
      delete window.p1;
      delete window.p2;

      setTimeout(() => {
        navigate(`/user/login`);
      }, 1);

      return;
    }

    setEmail(window.p1);
    setPassword(window.p2);

    delete window.p1;
    delete window.p2;
  }, [navigate]);

  async function handleVerifyClicked() {
    const _code = code.trim();

    setError(false);

    if (!_code) {
      return setError("general.empty-code");
    }

    setLoading(true);
    const data = await ajax(`/users/loginVerifyEmail`, {
      email,
      password,
      code,
      fingerprint: settings.fingerprint,
      fingerprintVisitorId: settings.fingerprintVisitorId,
    });

    if (data.result === "success") {
      if (await initialDataFetching(true)) {
        navigate(`/workspaces`);
      }
    } else {
      setError("general.wrong-code");
      setLoading(false);
    }
  }

  async function handleSendAnotherCodeClicked() {
    setLoading(true);
    await ajax(`/users/login`, {
      email,
      password,
      fingerprint: settings.fingerprint,
      fingerprintVisitorId: settings.fingerprintVisitorId,
    });
    setLoading(false);
    setCode("");
    setError(false);

    alert({
      title: <Message id="login-email-verification.send-another-code.title" />,
      message: (
        <Message id="login-email-verification.send-another-code.content" />
      ),
      notification: true,
    });
  }

  return (
    <LoginLayout>
      <div className={styles.wrapper}>
        <div className={`mb8 ${styles.bold}`}>
          <Message id="login-email-verification.title" />
        </div>
        <div className={`mb28 ${styles.normal}`}>
          <Message
            id="login-email-verification.content"
            values={{
              email: sanitize(email),
              b: (arr) => sanitize(`<b>${arr[0]}</b>`),
            }}
          />
        </div>
        <KeyDetector className="mb16" onKeyDetected={handleVerifyClicked}>
          <TextField
            icon="lock"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={intl.formatMessage({
              id: "login-email-verification.code-placeholder",
            })}
          />
        </KeyDetector>
        {error && (
          <div className={`mb28 ${styles.errorWrapper}`}>
            <Message id={error} />
            <a
              disabled={loading}
              loading={loading}
              className={styles.sendAnotherCode}
              href="#"
              onClick={handleSendAnotherCodeClicked}
            >
              <Message id="login-email-verification.send-another" />
            </a>
          </div>
        )}
        <div className="mb28">
          <DreamButton
            disabled={loading}
            icon="arrow"
            onClick={handleVerifyClicked}
            color="purple"
            className={styles.verifyButton}
          >
            <Message id="login-email-verification.verify" />
          </DreamButton>
        </div>
        <div className={styles.backWrapper}>
          <Link href={`/user/login`} to={`/user/login`}>
            <Message id="login-email-verification.back" />
          </Link>
        </div>
      </div>
    </LoginLayout>
  );
}
